define('deputee-office/services/cacheservice', ['exports', 'ember', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    /**
     * Очистка всех данных из кэша
     * @return {undefined}
     */
    clearCache: function clearCache() {
      try {
        localStorage.removeItem(_deputeeOfficeConfigEnvironment['default'].APP.cacheKey);
        if (!window.device) {
          return;
        }

        var fileDirectory = window.device.platform === 'Android' ? window.cordova.file.externalDataDirectory : window.cordova.file.dataDirectory;

        var success = function success() {
          console.log('Remove succeeded');
        };

        var fail = function fail(error) {
          console.log('Remove Files Error: ' + error.code);
        };

        var removeFiles = function removeFiles(entries) {
          var i;
          for (i = 0; i < entries.length; i++) {
            var e = entries[i];
            if (e.isFile) {
              e.remove(success, fail);
            }

            if (e.isDirectory) {
              e.removeRecursively(success, fail);
            }
          }
        };

        var onGetDirectoryWin = function onGetDirectoryWin(dirEntry) {
          // Get a directory reader
          var directoryReader = dirEntry.createReader();

          // Get a list of all the entries in the directory
          directoryReader.readEntries(removeFiles, fail);
        };

        window.resolveLocalFileSystemURL(fileDirectory, function (dir) {
          dir.getDirectory('', {
            create: true,
            exclusive: false
          }, onGetDirectoryWin, fail);
        });

        console.log('Cache cleared');
      } catch (e) {
        console.error('Cache not cleared: ' + e.message + '\n' + e.stack);
      }
    },

    /**
     * Добавление данных в localStorage
     * @param  {string} ключ в localStorage
     * @param  {string} ключ данных
     * @param  {string} срок валидности кэша
     * @param  {Object} данные для добавления в кэш
     * @return {undefined}
     */
    put: function put(globalKey, key, expiry, data) {
      var localStorageData = localStorage.getItem(globalKey);
      var allCache;
      if (localStorageData !== 'undefined') {
        allCache = JSON.parse(localStorageData);
      }

      var cacheObject = new Object({});
      cacheObject.expiry = expiry;
      cacheObject.data = data;
      if (!allCache) {
        allCache = new Object({});
      }

      allCache[key] = cacheObject;
      localStorage.setItem(globalKey, JSON.stringify(allCache));
    },

    /**
     * Получение данных из localStorage
     * @param  {string} ключ в localStorage
     * @param  {string} ключ данных
     * @param  {bool} флаг проверки валидности кэша, true - проверять валидность, false - не проверять
     * @return {Object}
     */
    get: function get(globalKey, key, checkExpiration) {
      var localStorageData = localStorage.getItem(globalKey);
      if (localStorageData !== 'undefined') {
        var allCache = JSON.parse(localStorage.getItem(globalKey));
        if (allCache) {
          if (key) {
            var cacheObj = allCache[key];
            if (cacheObj) {
              if (checkExpiration == null || checkExpiration === true) {
                var expiryDate = new Date(cacheObj.expiry);
                if (expiryDate > new Date()) {
                  return cacheObj.data;
                }
              } else {
                return cacheObj.data;
              }
            }
          } else {
            return allCache;
          }
        }
      }
    },

    /**
     * Добавление данных в кэш данных
     * @param  {string} ключ данных
     * @param  {string} срок валидности кэша
     * @param  {Object} данные для добавления в кэш
     * @return {undefined}
     */
    putCache: function putCache(key, expiry, data) {
      this.put(_deputeeOfficeConfigEnvironment['default'].APP.cacheKey, key, expiry, data);
    },

    /**
     * Получение данных из кэша данных
     * @param  {string} ключ данных
     * @param  {bool} флаг проверки валидности кэша, true - проверять валидность, false - не проверять
     * @return {Object}
     */
    getCache: function getCache(key, checkExpiration) {
      return this.get(_deputeeOfficeConfigEnvironment['default'].APP.cacheKey, key, checkExpiration);
    }
  });
});