define('deputee-office/controllers/favorites', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    searchPlaceholder: 'Поиск по избранному',
    componentDataLoading: false,
    notFound: false,

    favoriteStateNameChanged: _ember['default'].observer('favoriteState', 'model', function () {
      if (this.get('favoriteState')) {
        this.set('favoriteStateName', 'favorites/fav-list');
        if (this.get('model')) {
          this.set('selectedType', this.get('model').findBy('componentName', this.get('favoriteState')));
        }
      } else {
        this.set('selectedType', null);
        this.set('favoriteStateName', 'favorites/index');
      }
    }),

    searchString: {
      value: null
    },

    favComponentName: _ember['default'].computed('selectedType', function () {
      var selectedType = this.get('selectedType');

      if (selectedType) {
        return selectedType.componentName;
      }

      return null;
    }),

    grouped: _ember['default'].computed('model.[]', function () {
      var _this = this;

      var model = this.get('model');
      var favoritesService = this.get('favoritesService');

      var selectedTypeExist = false;

      if (model) {
        var _ret = (function () {
          var result = _ember['default'].A([]);
          var idx = [];

          model.toArray().sortBy('serviceName').forEach(function (record) {
            var group = record.group;
            var name = favoritesService.getGroupName(group);

            var selectedType = _this.get('selectedType');

            if (selectedType === record) {
              selectedTypeExist = true;
            }

            if (!result.isAny('order', group)) {
              idx[group + '_'] = _ember['default'].A([]);
              result.pushObject({ order: group, name: name, items: idx[group + '_'] });
            }

            idx[group + '_'].pushObject(record);
          });

          result = result.sortBy('order');

          if (result.length > 0 && !selectedTypeExist) {
            var selectedType = result[0].items[0];

            _this.set('selectedType', selectedType);
          }

          return {
            v: result
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
    }),

    setSearchString: function setSearchString(text) {
      this.set('searchString.value', text);
      _ember['default'].$('.scrollable-div').trigger('updatescroll');
    },

    actions: {
      search: function search(text) {
        _ember['default'].run.debounce(this, this.get('setSearchString'), text, 1500);
      },

      selectType: function selectType(info) {
        if (this.get('devicejs').isPhone()) {
          return true;
        } else {
          this.set('selectedType', info);
        }
      }
    }
  });
});