define('deputee-office/controllers/governor-decrees', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {

  var notFoundMsg = 'По вашему запросу ничего не найдено.';

  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    queryParams: ['viewMode'],
    viewMode: 'list',
    viewModeToggleIcon: _ember['default'].computed('viewMode', function () {
      if (this.get('viewMode') === 'list') {
        return 'icon-decreeviewtiles';
      } else {
        return 'icon-decreeviewlist';
      }
    }),

    dateSortingToggleIcon: _ember['default'].computed('descSortingOrder.value', function () {
      var descSortingOrder = this.get('descSortingOrder');

      if (descSortingOrder.value) {
        return 'icon-datesorting-desc';
      } else {
        return 'icon-datesorting';
      }
    }),

    grouped: _ember['default'].computed('model.[]', function () {
      var idx = {};
      this.get('model').forEach(function (record) {
        var _month = record.get('Month');
        if (!idx[_month]) {
          idx[_month] = _ember['default'].A([]);
        }

        idx[_month].pushObject(record);
      });
      return idx;
    }),

    errorMsg: _ember['default'].observer('grouped', function () {
      if (this.get('grouped') && this.get('grouped').length > 0) {
        this.set('errorMessage', null);
      } else {
        this.set('errorMessage', notFoundMsg);
      }
    }),
    actions: {
      toggleViewMode: function toggleViewMode() {
        var currentMode = this.get('viewMode');
        if (currentMode === 'list') {
          this.set('viewMode', 'tiles');
        } else {
          this.set('viewMode', 'list');
        }

        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      },

      toggleSortingOrder: function toggleSortingOrder() {
        this.toggleProperty('descSortingOrder.value');
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      }
    }
  });
});