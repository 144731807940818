define('deputee-office/adapters/application', ['exports', 'ember-data', 'ember', 'ember-simple-auth/mixins/data-adapter-mixin', 'deputee-office/mixins/BuildURLMixinCustom'], function (exports, _emberData, _ember, _emberSimpleAuthMixinsDataAdapterMixin, _deputeeOfficeMixinsBuildURLMixinCustom) {
  exports['default'] = _emberData['default'].RESTAdapter.extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], _deputeeOfficeMixinsBuildURLMixinCustom['default'], {
    settingsService: _ember['default'].inject.service('settingsservice'),
    namespace: 'api',
    authorizer: 'authorizer:custom',

    jqXHRs: {},

    query: function query(store, type, _query) {
      if (_query && _query.projection) {
        var query2 = _ember['default'].copy(_query);
        delete query2.projection;
        return this._super.call(this, store, type, query2);
      }

      return this._super.apply(this, arguments);
    },

    /**
     * Первоисточник - https://gist.github.com/vicentereig/c4a0cb65751ec23d188c
     */
    ajaxOptions: function ajaxOptions(url, type, hash) {
      // Get default AjaxOptions
      var ajaxOptions = this._super(url, type, hash);

      // If the function was defined in the DS.RESTAdapter object,
      // we must call it in out new beforeSend hook.
      var defaultBeforeSend = function defaultBeforeSend() {};

      if (typeof ajaxOptions.beforeSend === 'function') {
        defaultBeforeSend = ajaxOptions.beforeSend;
      }

      var modelName = _ember['default'].String.singularize(url.replace(this.urlPrefix() + '/', ''));

      ajaxOptions.beforeSend = function (jqXHR, settings) {
        var _this = this;

        defaultBeforeSend(jqXHR, settings);
        if (this.jqXHRs[modelName]) {
          this.jqXHRs[modelName].pushObject(jqXHR);
        } else {
          this.jqXHRs[modelName] = [jqXHR];
        }

        jqXHR.then(function (data, textStatus, jqXHR) {
          return _this.jqXHRs[modelName].removeObject(jqXHR);
        }, function (jqXHR) {
          return _this.jqXHRs[modelName].removeObject(jqXHR);
        });
      };

      return ajaxOptions;
    },

    cancelQuery: function cancelQuery(modelName) {
      if (this.jqXHRs[_ember['default'].String.camelize(modelName)]) {
        this.jqXHRs[_ember['default'].String.camelize(modelName)].forEach(function (req) {
          req.abort();
        });
      }
    }
  });
});