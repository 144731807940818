define('deputee-office/initializers/offline-globals', ['exports', 'ember-flexberry-data/initializers/offline-globals'], function (exports, _emberFlexberryDataInitializersOfflineGlobals) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberFlexberryDataInitializersOfflineGlobals['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberFlexberryDataInitializersOfflineGlobals.initialize;
    }
  });
});