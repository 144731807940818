define('deputee-office/serializers/zs-passport/zs-passport-document-work-group', ['exports', 'deputee-office/serializers/application', 'ember-data/serializers/embedded-records-mixin'], function (exports, _deputeeOfficeSerializersApplication, _emberDataSerializersEmbeddedRecordsMixin) {
  exports['default'] = _deputeeOfficeSerializersApplication['default'].extend(_emberDataSerializersEmbeddedRecordsMixin['default'], {
    attrs: {
      Files: { embedded: 'always' }
    },
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      payload[primaryModelClass.modelName] = payload.zsPassportDocumentWorkGroups;
      delete payload.zsPassportDocumentWorkGroups;
      return this._super.apply(this, arguments);
    }
  });
});