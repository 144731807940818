define('deputee-office/models/uppc-report', ['exports', 'ember-data', 'deputee-office/models/base', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    DocId: _emberData['default'].attr('string'),
    DocUrl: _emberData['default'].attr('string'),
    Title: _emberData['default'].attr('string'),
    children: _emberData['default'].hasMany('uppc-report', { inverse: 'parent' }),
    parent: _emberData['default'].belongsTo('uppc-report', { inverse: 'children' }),
    title: _ember['default'].computed('Title', function () {
      return this.get('Title');
    })
  });

  exports['default'] = Model;
});