define('deputee-office/models/question-comment', ['exports', 'deputee-office/models/base', 'ember', 'ember-data'], function (exports, _deputeeOfficeModelsBase, _ember, _emberData) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    QuestionId: _emberData['default'].attr('guid'),
    AuthorId: _emberData['default'].attr('guid'),
    AuthorFIO: _emberData['default'].attr('string'),
    TimeStamp: _emberData['default'].attr('string'),
    Place: _emberData['default'].attr('string'),
    OriginalText: _emberData['default'].attr('string'),
    NewText: _emberData['default'].attr('string'),
    Ticks: _emberData['default'].attr('string'),
    Editable: _emberData['default'].attr('boolean'),
    IsLongText: _ember['default'].computed('Place', 'OriginalText', 'NewText', function () {
      var PlaceLength = 0;

      if (this.get('Place')) {
        PlaceLength = this.get('Place').length;
      }

      var OriginalTextLength = 0;

      if (this.get('OriginalText')) {
        OriginalTextLength = this.get('OriginalText').length;
      }

      var NewTextLength = 0;

      if (this.get('NewText')) {
        NewTextLength = this.get('NewText').length;
      }

      if (PlaceLength > 10 || OriginalTextLength > 10 || NewTextLength > 10) {
        return true;
      }
    }),
    Extended: false,
    ExpangButtonClass: _ember['default'].computed('Extended', function () {
      if (this.get('Extended')) {
        this.set('Title', 'Свернуть');
        return 'btn-row-expanded device-btn-expand';
      } else {
        this.set('Title', 'Развернуть');
        return 'device-btn-expand';
      }
    })
  });

  exports['default'] = Model;
});