define('deputee-office/controllers/omsuschedule', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    isLoading: null,
    modelToEvent: function modelToEvent(eventModel) {
      var evt = {
        id: eventModel.get('id'),
        title: eventModel.get('Title'),
        start: eventModel.get('Start'),
        allDay: eventModel.get('AllDay'),
        end: eventModel.get('End'),
        description: eventModel.get('Description'),
        location: eventModel.get('Location')
      };

      _ember['default'].set(evt, 'IsMy', eventModel.get('IsMy'));
      _ember['default'].set(evt, 'EventType', eventModel.get('EventType'));

      var type = eventModel.get('EventType');
      if (type === 'CitizensReception') {
        evt.color = '#3bae4e';
      }

      var color = eventModel.get('Color');
      if (color) {
        evt.color = color;
      }

      var route = eventModel.get('ActionRoute');
      if (route) {
        evt.route = route;
        var routeParam = eventModel.get('EventObjectUID');
        if (routeParam) {
          evt.routeParam = routeParam;
        }

        evt.goTo = this.get('target').generate(evt.route, evt.routeParam);
      }

      return evt;
    },
    calcFilter: function calcFilter(eventModel, filters) {
      var result = true;

      if (filters.showMyEvents) {
        result = result && _ember['default'].get(eventModel, 'IsMy') === filters.showMyEvents;
      }

      if (filters.eventType) {
        result = result && _ember['default'].get(eventModel, 'EventType') === filters.eventType;
      }

      return result;
    },

    accessToFullCalendar: null,

    isFilterNotDefault: _ember['default'].computed('model.showMyEvents', 'model.eventType', function () {
      if ((!this.get('model.showMyEvents') || this.get('model.showMyEvents') === '') && (!this.get('model.eventType') || this.get('model.eventType') === '')) {
        return true;
      } else {
        return false;
      }
    }),

    actions: {
      removeFiltered: function removeFiltered() {
        var fc = _ember['default'].$('#omsueventscalc');
        var _this = this;
        fc.fullCalendar('removeEvents', function (event) {
          return !_this.calcFilter(event, {
            eventType: _this.get('model.eventType'),
            showMyEvents: _this.get('model.showMyEvents')
          });
        });
      },

      typeChanged: function typeChanged(newValue) {
        var value = newValue;

        if (value === '') {
          value = undefined;
        }

        this.set('model.eventType', value);
        this.send('removeFiltered');
        this.send('doGetData');
      },

      myChanged: function myChanged(newValue) {
        var value = newValue;

        if (value) {
          value = true;
        }

        this.set('model.showMyEvents', value);
        this.send('removeFiltered');
        this.send('doGetData');
      },

      clicked: function clicked(event) {
        this.get('target').send('showEvent', event);
      },
      doGetData: function doGetData() {
        var newDate = _ember['default'].$('#omsueventscalc').fullCalendar('getDate').format('MM.YYYY');
        var _this = this;
        this.set('isLoading', true);
        this.set('dateParam', newDate);
        this.store.cancelQuery('omsu-event');
        this.store.query('omsu-event', {
          Date: newDate,
          TerritoryId: this.get('territoryService').getTerritoryId()
        }).then(function (results) {
          _this.set('isLoading', false);
          _this.set('model.items', results);
        });
        newDate = _ember['default'].$('#omsueventscalc').fullCalendar('getDate').add(1, 'months').format('MM.YYYY');
        this.store.query('omsu-event', {
          Date: newDate,
          TerritoryId: this.get('territoryService').getTerritoryId()
        }).then(function (results) {
          _this.set('model.items', results);
        });
        newDate = _ember['default'].$('#omsueventscalc').fullCalendar('getDate').subtract(1, 'months').format('MM.YYYY');
        this.store.query('omsu-event', {
          Date: newDate,
          TerritoryId: this.get('territoryService').getTerritoryId()
        }).then(function (results) {
          _this.set('model.items', results);
        });
      }
    }

  });
});