define('deputee-office/components/contact-card', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    actions: {
      contactInfo: function functionName(contact) {
        this.sendAction('contactInfo', contact);
      }
    },
    wider: false,
    didRender: function didRender() {
      var m = this.get('model');
      var qwe = new Image();
      qwe.src = m.get('LocalPhoto');
      this.set('wider', qwe.height >= qwe.width ? false : true);
    }
  });
});