define('deputee-office/components/tree/tree-expand-icon', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['isExpand'],
    attributeBindings: ['style'],
    style: 'cursor: pointer',
    ExpandObserver: (function () {
      if (this.get('expanded')) {
        this.set('isExpand', [this.get('expandedClass')]);
      } else {
        this.set('isExpand', [this.get('notExpandedClass')]);
      }
    }).observes('expanded').on('init'),
    click: function click() {
      this.sendAction();
    }
  });
});