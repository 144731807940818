define('deputee-office/controllers/index', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/loader/material', 'deputee-office/mixins/loader/department', 'deputee-office/mixins/loader/events-plan', 'deputee-office/mixins/loader/work-plan'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsLoaderMaterial, _deputeeOfficeMixinsLoaderDepartment, _deputeeOfficeMixinsLoaderEventsPlan, _deputeeOfficeMixinsLoaderWorkPlan) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsLoaderDepartment['default'], _deputeeOfficeMixinsLoaderMaterial['default'], _deputeeOfficeMixinsLoaderEventsPlan['default'], _deputeeOfficeMixinsLoaderWorkPlan['default'], {
    session: _ember['default'].inject.service(),
    currentuserservice: _ember['default'].inject.service(),
    indexTemplateName: 'index-main',

    showTimer: true,

    queryParams: ['indexState', 'indexStateTransition'],
    indexState: null,
    indexStateTransition: null,

    serviceName: _ember['default'].computed('indexStateTransition', function () {
      switch (this.get('indexStateTransition')) {
        case 'zs-agenda-departments.index':
          return 'Прочие повестки';
        case 'yp-agenda-comissions.index':
          return 'Комиссии МП';
        case 'omsu-agenda-commitees.index':
          return 'Заседания комитетов/комиссий';
        default:
          return '';
      }
    }),

    indexStateChanged: _ember['default'].observer('indexState', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        switch (this.get('indexState')) {
          case 'comissions':
            var transition = this.get('indexStateTransition');
            this.loadDepartments(transition).then(function () {
              return _this.set('indexTemplateName', 'index-substate/select-department');
            });
            break;
          case 'material':
            this.loadMaterials();
            this.set('indexTemplateName', 'index-substate/select-material');
            break;
          case 'events-plan':
            this.loadEventsPlans();
            this.set('indexTemplateName', 'index-substate/select-events-plan');
            break;
          case 'work-plan':
            this.loadWorkPlans();
            this.set('indexTemplateName', 'index-substate/select-work-plan');
            break;
          default:
            this.set('indexTemplateName', 'index-main');
        }
      });
    }),

    groupedServices: _ember['default'].computed('model.[]', 'model.@each.IsVisible', function () {
      var result = _ember['default'].A([]);
      var idx = [];

      var isUserFromZS = this.get('currentuserservice').isUserFromZS();
      var isGuest = this.get('currentuserservice').get('isGuest');
      var isUserFromMP = this.get('currentuserservice').get('isMPUser');

      // Инициализируем известные подсистемы для соблюдения порядка.

      var zspkId = 'Мурманская областная Дума';
      idx[zspkId] = _ember['default'].A([]);
      var omsuId = 'ОМСУ';
      idx[omsuId] = _ember['default'].A([]);
      var mpId = 'Молодежный парламент';
      idx[mpId] = _ember['default'].A([]);

      if (!isUserFromZS && !isGuest) {
        result.pushObject({
          name: omsuId,
          items: idx[omsuId]
        });
        result.pushObject({
          name: zspkId,
          items: idx[zspkId]
        });
        result.pushObject({
          name: mpId,
          items: idx[mpId]
        });
      } else if (isUserFromMP) {
        result.pushObject({
          name: mpId,
          items: idx[mpId]
        });
        result.pushObject({
          name: zspkId,
          items: idx[zspkId]
        });
        result.pushObject({
          name: omsuId,
          items: idx[omsuId]
        });
      } else {
        result.pushObject({
          name: zspkId,
          items: idx[zspkId]
        });
        result.pushObject({
          name: omsuId,
          items: idx[omsuId]
        });
        result.pushObject({
          name: mpId,
          items: idx[mpId]
        });
      }

      this.get('model').filterBy('IsVisible', true).forEach(function (record) {
        var _type = record.get('SubsystemTitle');
        if (!result.isAny('name', _type)) {
          idx[_type] = _ember['default'].A([]);
          result.pushObject({
            name: _type,
            items: idx[_type]
          });
        }

        idx[_type].pushObject(record);
      });
      result.forEach(function (item) {
        item.items.sort(function (a, b) {
          if (a.get('OrderNum') > b.get('OrderNum')) {
            return 1;
          }

          if (a.get('OrderNum') < b.get('OrderNum')) {
            return -1;
          }

          return 0;
        });
      });
      return result;
    }),
    actions: {
      hideTimer: function hideTimer() {
        this.set('showTimer', false);
      },

      timerOver: function functionName() {
        this.send('hideTimer');
        return true;
      },

      selectMaterialType: function selectMaterialType(materialType) {
        var transition = this.get('indexStateTransition');
        this.get('target').send('selectMaterialType', materialType, {
          targetName: transition
        });
      },

      selectDepartment: function selectDepartment(department) {

        var transition = this.get('indexStateTransition');

        this.get('target').send('selectDepartmentForTransition', department.get('id'), {
          targetName: transition
        });
      },
      selectEventsPlan: function selectEventsPlan(eventsPlan) {
        this.get('target').send('selectEventsPlan', eventsPlan);
      },
      selectActivityType: function selectActivityType(activityType) {
        var transition = this.get('indexStateTransition');
        this.get('target').send('selectActivityType', activityType.get('id'), {
          targetName: transition
        });
      }
    }
  });
});