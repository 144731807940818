define('deputee-office/adapters/protocol-decision-offline', ['exports', 'deputee-office/adapters/application-offline', 'ember-flexberry-data', 'moment'], function (exports, _deputeeOfficeAdaptersApplicationOffline, _emberFlexberryData, _moment) {
  exports['default'] = _deputeeOfficeAdaptersApplicationOffline['default'].extend({
    caching: 'none',
    namespace: 'ember-flexberry-offline:store',
    query: function query(store, modelName, _query) {

      var filter = _query.filter;
      var page = _query.page;
      var perPage = _query.per_page;
      var territoryId = _query.TerritoryId;

      var queryInstance = _query instanceof _emberFlexberryData.Query.QueryObject;
      if (!queryInstance) {
        _query = {};
      }

      return this._super(store, modelName, _query).then(function (results) {
        results = results.data;
        if (filter && filter != 'null' && filter.length) {
          filter = decodeURI(filter).toUpperCase();
          results = results.filter(function (record) {
            return (record.Topic.toUpperCase().indexOf(filter) !== -1 || record.Decision.toUpperCase().indexOf(filter) !== -1 || record.Author.toUpperCase().indexOf(filter) !== -1) && record.TerritoryId === territoryId;
          });
        }

        var sortByDate = function sortByDate(a, b) {
          if ((0, _moment['default'])(a.Date, 'DD.MM.YYYY').diff((0, _moment['default'])(b.Date, 'DD.MM.YYYY')) < 0) {
            return 1;
          }

          if ((0, _moment['default'])(a.Date, 'DD.MM.YYYY').diff((0, _moment['default'])(b.Date, 'DD.MM.YYYY')) > 0) {
            return -1;
          }

          return a.Topic.localeCompare(b.Topic);
        };

        results.sort(sortByDate);

        // Оффлайн-пейджинг через slice.
        if (perPage && perPage !== 0) {
          var fromIndex = (page - 1) * perPage;
          var toIndex = page * perPage;

          results = results.slice(fromIndex, toIndex);
        }

        return { data: results };
      });
    }
  });
});