define('deputee-office/controllers/work-plan/index', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'moment'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _moment) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {

    searchPlaceholder: 'Поиск по плану работ',

    passportLink: 'work-plan.work-plan-view',

    activityTypePopoverId: 'activityTypeSelecorId',

    monthYearFormat: 'MMMM YYYY',

    workPlanListSortingByDate: ['Date:desc'],
    sortedByDateWorkPlanStrings: _ember['default'].computed.sort('workPlanStrings', 'workPlanListSortingByDate'),

    workPlanListSortingByOrderNum: ['OrderNum'],
    sortedByOrderNumWorkPlanStrings: _ember['default'].computed.sort('workPlanStrings', 'workPlanListSortingByOrderNum'),

    filterWorkPlans: _ember['default'].observer('selectedDate', 'searchText', function () {
      var _this = this;

      var selectedDate = this.get('selectedDate.Date');

      if (selectedDate) {
        (function () {
          var text = _this.get('searchText');
          var workPlanStrings = _this.get('sortedByOrderNumWorkPlanStrings');
          var filteredWorkPlanStrings = workPlanStrings.filter(function (workPlanString) {
            var textCondition = true;
            var dateCondition = workPlanString.get('Date').getTime() === selectedDate.getTime();
            if (text) {
              textCondition = workPlanString.get('Title').toLowerCase().indexOf(text.toLowerCase()) !== -1;
            }

            return textCondition && dateCondition;
          });
          _this.set('displayedWorkPlanStrings', filteredWorkPlanStrings);
          _this.hideLoadingSpinner();
        })();
      }
    }),

    activityTypeChanged: _ember['default'].observer('selectedActivityType', function () {
      var _this2 = this;

      this.set('error', false);
      var selectedActivityType = this.get('selectedActivityType');

      if (selectedActivityType) {
        this.set('displayedWorkPlanStrings', undefined);
        this.showLoadingSpinner();

        var activityTypeName = selectedActivityType.get('Name');
        var territoryId = this.get('territoryService').getTerritoryId();

        this.store.query('work-plan-string', {
          activityType: activityTypeName,
          TerritoryId: territoryId
        }).then(function (records) {
          _this2.set('workPlanStrings', records);
        })['catch'](function () {
          _this2.set('workPlanStrings', null);
          _this2.set('selectedDate', null);
          _this2.set('Dates', null);
          _this2.set('error', true);
        });
      }
    }),

    updateDatesAndSelectedDate: _ember['default'].observer('workPlanStrings', function () {

      var workPlanStrings = this.get('workPlanStrings');

      if (workPlanStrings) {
        workPlanStrings = this.get('sortedByDateWorkPlanStrings');
        var dates = workPlanStrings.map(function (workPlanString) {
          return workPlanString.get('Date').getTime();
        }).uniq().map(function (item) {
          var date = new Date(item);
          return _ember['default'].Object.create({
            id: date.getTime(),
            Date: date
          });
        });

        var selectedDate = undefined;
        var dateId = this.dateId;

        if (!dateId) {
          selectedDate = this.getNearestDate(dates);
        } else {
          var intDateId = parseInt(dateId);
          var date = new Date(intDateId);

          selectedDate = _ember['default'].Object.create({
            id: intDateId,
            Date: date,
            popoverFormattedDate: (0, _moment['default'])(date).format(this.get('monthYearFormat')).firstCharToUpper()
          });
        }

        this.set('selectedDate', selectedDate);
        this.set('Dates', dates);
      }
    }),

    getNearestDate: function getNearestDate(dates) {

      var currentDate = Date.now();
      var currentMonth = (0, _moment['default'])(currentDate).month();
      var currentYear = (0, _moment['default'])(currentDate).year();

      var futureDates = dates.filter(function (item) {
        var date = item.get('Date');
        var dateMonth = (0, _moment['default'])(date).month();
        var dateyear = (0, _moment['default'])(date).year();

        return dateyear >= currentYear && dateMonth >= currentMonth;
      });

      var nearestDate = futureDates.length ? futureDates.get('lastObject') : dates.get('firstObject');

      nearestDate.popoverFormattedDate = (0, _moment['default'])(nearestDate.get('Date')).format(this.get('monthYearFormat')).firstCharToUpper();
      return nearestDate;
    },

    showLoadingSpinner: function showLoadingSpinner() {
      _ember['default'].$('[loading-spinner-app]').show();
    },

    hideLoadingSpinner: function hideLoadingSpinner() {
      _ember['default'].$('[loading-spinner-app]').hide();
    },

    setSearchText: function setSearchText(text) {
      this.set('searchText', text);
    },

    actions: {

      selectActivityType: function selectActivityType(activityType) {
        _ember['default'].$('#' + this.get('activityTypePopoverId')).webuiPopover('hide');
        var activityTypeId = encodeURI(activityType.get('id'));
        this.transitionToRoute('work-plan', { queryParams: { activityTypeId: activityTypeId, dateId: undefined } });
        this.set('selectedActivityType', activityType);
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      },

      selectDate: function selectDate(date) {
        var dateId = date.get('id');
        this.set('selectedDate', date);
        this.transitionToRoute('work-plan', { queryParams: { dateId: dateId } });
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      },

      search: function search(text) {
        _ember['default'].run.debounce(this, this.get('setSearchText'), text, 1000);
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      }
    }
  });
});