define('deputee-office/models/pomopk-question', ['exports', 'ember-data', 'deputee-office/models/base-question', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBaseQuestion, _ember) {

  var Model = _deputeeOfficeModelsBaseQuestion['default'].extend({
    HeadCommittee: _emberData['default'].attr('string'),
    CoSpeaker: _emberData['default'].attr('string'),
    QuestionId: _emberData['default'].attr('guid'),
    SpeakerString: _ember['default'].computed('Speaker', function () {
      return 'Доклад: ' + this.get('Speaker');
    }),
    CoSpeakerString: _ember['default'].computed('CoSpeaker', function () {
      return 'Содоклад: ' + this.get('CoSpeaker');
    })
  });

  exports['default'] = Model;
});