define('deputee-office/controllers/pomopk-decisions', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {

  var notFoundMsg = 'По вашему запросу ничего не найдено.';

  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {

    searchPlaceholder: 'Поиск по решениям',

    templateName: 'pomopk-substate/pomopk-decisions',

    pomopkStateNameChanged: _ember['default'].observer('pomopkState', 'model', function () {
      if (this.get('pomopkState')) {
        this.set('templateName', 'pomopk-substate/pomopk-files');
      } else {
        this.set('templateName', 'pomopk-substate/pomopk-decisions');
      }
    }),

    groupedObs: _ember['default'].observer('decisions.[]', function () {
      var result = this.get('grouped');
      if (!result) {
        result = _ember['default'].A([]);
      }

      var idx = [];
      this.get('decisions').forEach(function (record) {
        var _year = record.get('Year');
        if (!result.isAny('year', _year)) {
          idx[_year] = _ember['default'].A([]);
          result.pushObject({ year: _year, items: idx[_year] });
        } else {
          idx[_year] = result.find(function (x) {
            return x.year === _year;
          }).items;
        }

        if (!idx[_year].isAny('id', record.get('id'))) {
          idx[_year].pushObject(record);
        }
      });
      if (!this.get('grouped')) {
        this.set('grouped', result);
      }
    }),

    errorMsg: _ember['default'].observer('grouped', function () {
      var grouped = this.get('grouped');
      if (grouped && grouped.length > 0) {
        this.set('errorMessage', null);
      } else {
        this.set('errorMessage', notFoundMsg);
      }
    }),

    searchByDecisions: function searchByDecisions(text) {
      this.send('searchDecisions', text);
      _ember['default'].$('.scrollable-div').trigger('updatescroll');
    },

    actions: {
      showPomopkFiles: function showPomopkFiles(decision) {
        var queryParams = {
          pomopkState: 'files'
        };
        this.set('currentDecision', decision);
        this.transitionToRoute('pomopk-decisions', {
          queryParams: queryParams
        });
      },

      search: function search(text) {
        _ember['default'].run.debounce(this, this.get('searchByDecisions'), text, 1000);
      }
    }
  });
});