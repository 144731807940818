define('deputee-office/controllers/budgets', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    // Группируем по повестке
    grouped: _ember['default'].computed('model.[]', function () {
      var budgets = this.get('model');
      if (budgets) {
        var result = _ember['default'].A([]);
        var idx = [];
        budgets.toArray().sortByProperty('OrderNum', 'asc').forEach(function (record) {
          var _budget = record.get('year');
          if (!result.isAny('key', _budget)) {
            idx[_budget + '_'] = _ember['default'].A([]);
            result.pushObject({ key: _budget, items: idx[_budget + '_'] });
          }

          idx[_budget + '_'].pushObject(record);
        });
        return result;
      }
    }),
    didInsertElement: function didInsertElement() {
      /* iPad/iPhone hover problem causes the user to double click a link */
      _ember['default'].$('a').on('click touchend', function () {
        var el = _ember['default'].$(this);
        var link = el.attr('href');
        window.location = link;
      });
    }

  });
});