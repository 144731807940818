define('deputee-office/controllers/phone/contacts', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeConfigEnvironment) {
  var notFoundMsg = 'По вашему запросу ничего не найдено.';

  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {
    contactTemplateName: 'contacts-substate/contacts-main',
    queryParams: ['contactSubState'],

    contactSubStateChacheg: _ember['default'].observer('contactSubState', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        switch (this.get('contactSubState')) {
          case 'showContact':
            this.set('contactTemplateName', 'contacts-substate/contact');
            break;
          default:
            this.set('contactTemplateName', 'contacts-substate/contacts-main');
        }
      });
    }),
    grouped: _ember['default'].computed('model.[]', function () {
      var idx = {};
      var model = this.get('model');
      this.setHeaderImages(model);
      model.forEach(function (record) {
        var _dep = record.get('departmentFullPath');
        if (!idx[_dep]) {
          idx[_dep] = _ember['default'].A([]);
        }

        idx[_dep].pushObject(record);
      });
      return idx;
    }),

    errorMsg: _ember['default'].observer('grouped', function () {
      if (this.get('grouped') && this.get('grouped').length > 0) {
        this.set('errorMessage', null);
      } else {
        this.set('errorMessage', notFoundMsg);
      }
    }),
    setHeaderImages: function setHeaderImages(model) {
      var settingsService = this.get('settingsservice');
      var currentUserService = this.get('currentuserservice');
      var fotoService = this.get('fotoService');
      var networkstatus = this.get('networkstatus');

      model.map(function (contact) {
        var photoId = contact.get('Photo');
        var isIdNotNull = photoId != null;
        var defaultImage = fotoService.getFotoUrlByName(contact.get('Name'));
        var isConnected = networkstatus.isInternetConnection();

        if (isIdNotNull && isConnected) {
          _ember['default'].$.ajax({
            url: settingsService.getHost() + '/api/contacts/photos/' + photoId,
            headers: { Authorization: currentUserService.getBasicAuthHeader() },
            type: 'GET',
            xhrPool: 'contacts',
            timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
          }).then(function (data) {
            if (data && data !== '') {
              contact.set('LocalPhoto', 'data:image;base64,' + data);
            } else {
              contact.set('LocalPhoto', defaultImage);
            }
          });
        } else {
          contact.set('LocalPhoto', defaultImage);
        }
      });
    },
    actions: {
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      }
    }
  });
});