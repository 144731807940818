define('deputee-office/components/download-file-document', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    actions: {
      downloadfile: function downloadfile() {
        var id = this.get('file').get('id');
        var settingsService = this.get('settingsservice');
        window.open(settingsService.getHost() + '/api/file/' + id + '?subsystemFileId=' + id, '_self');
      }
    }
  });
});