define('deputee-office/models/newquestion', ['exports', 'ember-data', 'deputee-office/models/base', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    IntId: _emberData['default'].attr('number'),
    RegDate: _emberData['default'].attr('string'),
    RegNum: _emberData['default'].attr('number'),
    Name: _emberData['default'].attr('string'),
    Subject: _emberData['default'].attr('string'),
    HeadComm: _emberData['default'].attr('string'),
    TerritoryId: _emberData['default'].attr('guid'),
    QuestionId: _ember['default'].computed('id', function () {
      return this.id;
    })
  });

  exports['default'] = Model;
});