define('deputee-office/services/preload-service', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {

  /**
   * Сервис предварительной загрузки данных.
   */
  exports['default'] = _ember['default'].Service.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    store: _ember['default'].inject.service(),

    passportRequestPoolName: 'passportPool',

    /**
     * preloadAgenda - Метод предваритольеной загрузки ближайших
     * заседаний ОМСУ и вложеннных вопросов
     *
     * @return {undefined}
     */
    preloadAgenda: function preloadAgenda() {
      var store = this.get('store');
      var settingsService = this.get('settingsservice');
      var url = settingsService.getHost();
      var territoryId = this.get('territoryService').getTerritoryId();
      _ember['default'].$.getJSON(url + '/api/omsuagendas/closestagenda?territoryId=' + territoryId, function (agenda) {
        _ember['default'].run.schedule('destroy', this, function () {
          var agendaId = agenda.id;
          store.query('omsuquestion', {
            AgendaId: agendaId,
            TerritoryId: territoryId
          });
        });
      });
    },

    /**
     * preloadZsAgenda - Метод предварительной загрузки ближайших
     * заседаний ЗС и вложеннных вопросов
     *
     * @return {undefined}
     */
    preloadZsAgenda: function preloadZsAgenda() {
      var _this = this;
      var store = this.get('store');
      var agendas = store.findAll('zsagenda');
      agendas.then(function (agendaRecords) {
        var agenda = _this._getClosestAgenda(agendaRecords);
        _ember['default'].run.schedule('destroy', this, function () {
          var agendaId = agenda.get('id');
          store.query('zsquestion', {
            AgendaId: agendaId
          });
        });
      });
    },

    _getClosestAgenda: function _getClosestAgenda(agendaRecords) {
      var currentDate = new Date().getTime();
      var closestAgendaNext = null;
      var closestAgendaPrev = null;
      agendaRecords.forEach(function (item) {
        // Поиск ближайшей будущей повестки.
        if (currentDate <= item.get('Date').getTime() && (closestAgendaNext == null || item.get('Date').getTime() <= closestAgendaNext.get('Date').getTime())) {
          closestAgendaNext = item;
        }

        // Поиск ближайшей прошлой повестки.
        if (currentDate >= item.get('Date').getTime() && (closestAgendaPrev == null || item.get('Date').getTime() >= closestAgendaPrev.get('Date').getTime())) {
          closestAgendaPrev = item;
        }
      });

      // Иинициализация текущей повестки в зависимости от того, найдена ли будущая повестка.
      var currentAgenda = closestAgendaNext != null ? closestAgendaNext : closestAgendaPrev;

      return currentAgenda;
    }
  });
});