define('deputee-office/models/protocol-decision', ['exports', 'ember-data', 'deputee-office/models/base', 'ember-flexberry-data', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _emberFlexberryData, _ember, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Topic: _emberData['default'].attr('string'),
    IsDone: _emberData['default'].attr('boolean'),
    Date: _emberData['default'].attr('string'),
    Author: _emberData['default'].attr('string'),
    PeriodOfExecution: _emberData['default'].attr('string'),
    Executor: _emberData['default'].attr('string'),
    File: _emberData['default'].attr('string'),
    Decision: _emberData['default'].attr('string'),
    TerritoryId: _emberData['default'].attr('guid'),
    Month: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])('' + this.get('Date'), 'DD.MM.YYYY').format('MMMM YYYY').firstCharToUpper();
    }),
    DayMonth: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])('' + this.get('Date'), 'DD.MM.YYYY').format('DD MMMM');
    }),
    ExecutorReplies: _emberData['default'].hasMany('ExecutorReply')
  });

  Model.defineProjection('protocol-decision', 'protocol-decision', {
    Topic: _emberFlexberryData.Projection.attr('Topic'),
    IsDone: _emberFlexberryData.Projection.attr('IsDone'),
    Date: _emberFlexberryData.Projection.attr('Date'),
    Author: _emberFlexberryData.Projection.attr('Author'),
    PeriodOfExecution: _emberFlexberryData.Projection.attr('PeriodOfExecution'),
    Executor: _emberFlexberryData.Projection.attr('Executor'),
    File: _emberFlexberryData.Projection.attr('File'),
    Decision: _emberFlexberryData.Projection.attr('Decision'),
    TerritoryId: _emberFlexberryData.Projection.attr('TerritoryId'),
    ExecutorReplies: _emberFlexberryData.Projection.hasMany('ExecutorReply', 'ExecutorReplies', {
      ExecutorFileName: _emberFlexberryData.Projection.attr('ExecutorFileName'),
      ExecutorFileUrl: _emberFlexberryData.Projection.attr('ExecutorFileUrl'),
      POMOPKDecisionFileName: _emberFlexberryData.Projection.attr('POMOPKDecisionFileName'),
      POMOPKDecisionFileUrl: _emberFlexberryData.Projection.attr('POMOPKDecisionFileUrl'),
      Note: _emberFlexberryData.Projection.attr('Note')
    })
  });

  exports['default'] = Model;
});