define('deputee-office/services/flag-service', ['exports', 'ember', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    getFlagUrlByTerritoryId: function getFlagUrlByTerritoryId() /*territoryId*/{
      // if (territoryId) {
      //   var url = 'assets/img/flags/' + territoryId + '.png';
      //   var isExist = this.isImageExist(url);
      //   if (isExist) {
      //     return url;
      //   }
      // }

      return 'assets/img/flags/logo.png';
    },
    isImageExist: function isImageExist(url) {
      var result;
      _ember['default'].$.ajax({
        type: 'GET',
        url: url,
        async: false,
        timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs,
        success: function success(data) {
          result = data;
        },
        error: function error() {
          result = null;
        }
      });
      return result;
    }
  });
});