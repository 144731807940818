define('deputee-office/mixins/loader/work-plan', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    loadWorkPlans: function loadWorkPlans() {
      var activityTypes = this.store.findAll('omsu-activity-type');
      var _this = this;
      return activityTypes.then(function (items) {
        _this.set('omsuActivityTypes', items);
        return items;
      });
    }
  });
});