define('deputee-office/adapters/blogitem-offline', ['exports', 'deputee-office/adapters/application-offline', 'ember-flexberry-data'], function (exports, _deputeeOfficeAdaptersApplicationOffline, _emberFlexberryData) {
  exports['default'] = _deputeeOfficeAdaptersApplicationOffline['default'].extend({
    query: function query(store, modelName, _query) {
      var queryInstance = _query instanceof _emberFlexberryData.Query.QueryObject;
      if (!queryInstance) {
        _query = {};
      }

      return this._super(store, modelName, _query).then(function (results) {
        results = results.data;
        var propertyName = 'Order';
        results = results.toArray().sort(function (a, b) {
          return a[propertyName] - b[propertyName];
        });
        return { data: results };
      });
    }
  });
});