define('deputee-office/serializers/omsu-passport/omsu-passport-header', ['exports', 'deputee-office/serializers/application', 'ember-data/serializers/embedded-records-mixin'], function (exports, _deputeeOfficeSerializersApplication, _emberDataSerializersEmbeddedRecordsMixin) {
  exports['default'] = _deputeeOfficeSerializersApplication['default'].extend(_emberDataSerializersEmbeddedRecordsMixin['default'], {
    attrs: {
      ProjectLegalActFiles: { embedded: 'always' }
    },

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      payload[primaryModelClass.modelName] = payload.passportHeader;
      delete payload.passportHeader;
      return this._super.apply(this, arguments);
    }
  });
});