define('deputee-office/components/share-button', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isMobile: window.device && (window.device.platform.toLowerCase() === 'ios' || window.device.platform.toLowerCase() === 'android'),
    disabled: _ember['default'].computed('message', 'subject', 'files', 'url', function () {

      return !(this.get('message') || this.get('subject') || this.get('files') || this.get('url'));
    }),
    actions: {
      clicked: function clicked() {
        var options = {
          message: this.get('message'),
          subject: this.get('subject'),
          files: this.get('files'), // an array of filenames either locally or remotely
          url: this.get('url')
        };

        var onSuccess = function onSuccess(result) {
          console.log('Share completed? ' + result.completed); // On Android apps mostly return false even while it's true
          console.log('Shared to app: ' + result.app); // On Android result.app is currently empty. On iOS it's empty when sharing is cancelled (result.completed=false)
        };

        var onError = function onError(e) {
          console.log('Sharing failed with message: ' + e);
        };

        var rect = this.element.getBoundingClientRect();
        var targetBounds = rect.left + ',' + rect.top + ',' + rect.width + ',' + rect.height;

        if (!window.device) {
          return;
        }

        if (window.device.platform.toLowerCase() === 'ios') {
          window.plugins.socialsharing.setIPadPopupCoordinates(targetBounds);
        }

        window.plugins.socialsharing.shareWithOptions(options, onSuccess, onError);
      }
    }
  });
});