define('deputee-office/components/favorites/favorites-contacts', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/favorites-component-base', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsFavoritesComponentBase, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsFavoritesComponentBase['default'], _deputeeOfficeMixinsServicesMixin['default'], {
    store: _ember['default'].inject.service(),

    loadableModels: ['favorite', 'contact'],

    init: function init() {
      this._super();
      this.set('model', _ember['default'].A([]));
      this.set('displayedModel', _ember['default'].A([]));
    },

    loadData: function loadData() {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var store = _this2.get('store');
        var model = _this2.get('model');
        store.query('favorite', { Model: 'contact' }).then(function (favorites) {

          var requests = favorites.map(function (favorite) {
            var id = JSON.parse(favorite.get('Params')).id;

            return store.findRecord('contact', id);
          });

          _ember['default'].RSVP.allSettled(requests).then(function (contacts) {
            model.pushObjects(_this2.settledHelper(contacts));
            if (model.length !== 0) {
              _this2.setHeaderImages(model);
            }

            resolve();
          })['catch'](function (e) {
            reject();
            console.error(e);
          });
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'favorites-contacts: loadData');
    },

    filterModel: function filterModel() {
      var model = this.get('model');
      var displayedModel = this.get('displayedModel');
      var filterText = this.get('searchString');

      displayedModel.clear();

      if (filterText && filterText.value) {
        model.forEach(function (contact) {
          if (contact.get('Name') && contact.get('Name').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1) {
            displayedModel.pushObject(contact);
          }
        });
      } else {
        displayedModel.pushObjects(model);
      }

      var sorted = displayedModel.sortBy('Name');

      displayedModel.clear();
      displayedModel.addObjects(sorted);
    },

    setHeaderImages: function setHeaderImages(model) {
      var _this = this;
      var settingsService = this.get('settingsservice');
      var currentUserService = this.get('currentuserservice');

      model.map(function (contact) {
        var photoId = contact.get('Photo');
        var isIdNotNull = photoId != null;
        var defaultImage = _this.get('fotoService').getFotoUrlByName(contact.get('Name'));
        var isConnected = _this.get('networkstatus').isInternetConnection();

        if (isIdNotNull && isConnected) {
          _ember['default'].$.ajax({
            url: settingsService.getHost() + '/api/contacts/photos/' + photoId,
            headers: { Authorization: currentUserService.getBasicAuthHeader() },
            type: 'GET',
            xhrPool: 'favorites-contacts',
            timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
          }).then(function (data) {
            if (data && data !== '') {
              contact.set('LocalPhoto', 'data:image;base64,' + data);
            } else {
              contact.set('LocalPhoto', defaultImage);
            }
          });
        } else {
          contact.set('LocalPhoto', defaultImage);
        }
      });
    },

    actions: {
      contactInfo: function contactInfo(contact) {
        this.sendAction('contactInfo', contact);
      }
    }

  });
});