define('deputee-office/models/legal-act', ['exports', 'ember', 'ember-data', 'deputee-office/models/base', 'moment'], function (exports, _ember, _emberData, _deputeeOfficeModelsBase, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    IntId: _emberData['default'].attr('number'),
    RegDate: _emberData['default'].attr('date'),
    FullRegNumber: _emberData['default'].attr('string'),
    RegNumber: _emberData['default'].attr('number'),
    Type: _emberData['default'].attr('string'),
    Subject: _emberData['default'].attr('string'),
    HeadCommittee: _emberData['default'].attr('string'),
    Name: _emberData['default'].attr('string'),
    Category: _emberData['default'].attr('string'),
    ActType: _emberData['default'].attr('string'),
    Agenda: _emberData['default'].attr('string'),
    State: _emberData['default'].attr('string'),
    TerritoryId: _emberData['default'].attr('guid'),
    AgendaSessionPointId: _emberData['default'].attr('guid'),
    PassportId: _ember['default'].computed.alias('id'),
    FormattedRegDate: _ember['default'].computed('RegDate', function () {
      return (0, _moment['default'])('' + this.get('RegDate')).format('DD.MM.YYYY');
    })
  });

  exports['default'] = Model;
});