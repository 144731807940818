define('deputee-office/routes/base-agenda-route/passport/notes', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _ember['default'].Route.extend({
    notificationService: _ember['default'].inject.service(),
    fileService: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    networkstatus: _ember['default'].inject.service(),
    templateName: 'base-agenda-route.notes',
    controllerName: 'base-agenda-route.passport.notes',

    queryParams: {
      notesState: {
        refreshModel: false,
        replace: false
      },
      notesStateParam: {
        refreshModel: false,
        replace: false
      }
    },

    tabs: [{
      id: 'note',
      Name: 'Заметки'
    }, {
      id: 'file',
      Name: 'Файлы'
    }],
    defaultModelName: 'zs-passport/zs-passport-personal-document',

    getBasePassportPersonalDocumentQuery: function getBasePassportPersonalDocumentQuery(passportId) {
      return this.store.query(this.get('defaultModelName'), {
        PassportId: passportId
      });
    },
    getBaseQuestionPersonalDocumentQuery: function getBaseQuestionPersonalDocumentQuery(questionId) {
      return this.store.query(this.get('defaultModelName'), {
        QuestionId: questionId
      });
    },

    platform: _ember['default'].computed(function () {
      return this.get('fileService.platform');
    }),

    errorMessage: function errorMessage(e) {
      console.error(e);
      var controller = this.get('controller');
      controller.set('model', false);
      controller.set('errorMessage', true);
    },

    model: function model(params) {
      this.set('params', params);
    },

    tabObjects: _ember['default'].computed(function () {
      return this.get('tabs').map(function (x) {
        return _ember['default'].Object.extend({}).create(x);
      });
    }),

    QuestionId: _ember['default'].computed(function () {
      return this.paramsFor(this.get('parentControllerName')).question_id;
    }),

    AgendaState: _ember['default'].computed('baseControllerName.currentAgenda.StageName', function () {
      return this.get('baseControllerName.currentAgenda.StageName');
    }),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('platform', this.get('platform'));
      var owner = _ember['default'].getOwner(this);
      var parentController = owner.lookup('controller:' + this.get('parentControllerName'));
      this.set('parentController', parentController);
      this.updateTab();
      this.updateModel();
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('notesState', null);
        controller.set('notesStateParam', null);
        controller.set('_currentNote', null);
      }
    },

    updateTab: _ember['default'].observer('params.current_tab', function () {
      var controller = this.get('controller');
      var tab = this.get('params.current_tab');

      if (controller && tab) {
        controller.set('selectedStage', decodeURI(tab));
        controller.set('tabs', this.get('tabObjects'));
      }
    }),

    FiltredSorted: _ember['default'].observer('params.current_tab', 'modelNotes.[]', function () {
      var model = this.get('modelNotes');
      if (model) {
        var controller = this.get('controller');
        controller.set('model', null);
        controller.set('errorMessage', null);
        var tabName = this.get('params.current_tab');
        var tabObject = this.get('tabObjects').findBy('Name', decodeURI(tabName));
        var result = model.filterBy('Type', tabObject.get('id')).sort(function (a, b) {

          if (!a.get('Date')) {
            return -1;
          }

          if (!b.get('Date')) {
            return 1;
          }

          if ((0, _moment['default'])(a.get('Date')).isBefore(b.get('Date'))) {
            return 1;
          }

          if ((0, _moment['default'])(a.get('Date')).isAfter(b.get('Date'))) {
            return -1;
          }

          return 0;
        });
        if (result && result.length) {
          controller.set('model', result);
        } else {
          this.errorMessage('Файлов для отображения не найдено');
        }

        controller.set('noteCount', result.length);
        controller.set('fileCount', model.get('length') - result.length);
      }
    }),

    updateModel: _ember['default'].observer('parentController.model.PassportHeader.id', 'parentController.model.PassportHeaderState', function () {
      var _this = this;
      var controller = this.get('controller');
      var passport = this.get('parentController.model');
      var passportDocuments;
      var questionDocuments;
      if (controller && passport && passport.get('PassportHeaderState') !== 'loading' && (passport.get('PassportHeader.id') || passport.get('id'))) {
        if (passport.get('PassportHeader.id')) {
          passportDocuments = this.getPassportPersonalDocumentQuery(passport.get('PassportHeader.id'));
        }

        if (passport.get('id') && this.getQuestionPersonalDocumentQuery) {
          questionDocuments = this.getQuestionPersonalDocumentQuery(passport.get('id'));
        }

        var promises = {
          questionDocuments: questionDocuments,
          passportDocuments: passportDocuments
        };
        _ember['default'].RSVP.hashSettled(promises, 'PersonalDoc: hashSettled').then(function (model) {
          var questionDocuments = [];
          var passportDocuments = [];
          if (model.passportDocuments && model.passportDocuments.value) {
            passportDocuments = model.passportDocuments.value;
          }

          if (model.questionDocuments && model.questionDocuments.value) {
            questionDocuments = model.questionDocuments.value;
          }

          model = passportDocuments.toArray().pushObjects(questionDocuments.toArray());
          _this.set('modelNotes', model);
        })['catch'](function (reason) {
          _this.set('modelNotes', null);
          _this.errorMessage(reason);
        });
      }
    }),

    openModal: function openModal(model) {
      if (this.get('devicejs').isPhone()) {
        var queryParams = {
          notesState: 'edit'
        };

        if (model.get('id')) {
          queryParams.notesStateParam = model.get('id');
        }

        this.transitionTo(this.get('parentControllerName') + '.notes', this.get('params.current_tab'), {
          queryParams: queryParams
        });
        this.set('controller._currentNote', model);
      } else {
        this.render('modals/note', {
          into: this.get('parentControllerName') + '/notes',
          outlet: 'modalNote',
          model: model
        });
      }
    },

    saveModel: function functionName(model) {
      var _this = this;

      if (this.get('devicejs').isPhone() && model.get('IsNote')) {
        window.history.back();
      }

      if (!model.get('isSaving')) {
        model.save()['catch'](function (e) {
          if (model.get('isNew')) {
            _this.get('modelNotes').removeObject(model);
            model.deleteRecord();
          }

          model.rollbackAttributes();
          if (e.status === 413) {
            _this.get('notificationService').alert('Размер прикрепляемого файла превышает допустимый предел.', null, 'Внимание!');
          } else {
            _this.get('notificationService').alert('При выполнении операции произошла ошибка.', null, 'Внимание!');
          }

          console.error('Save Error: ');
          console.error(e);
        });
        _this.send('addToArray', model);
      }
    },

    getNewModel: function getNewModel() {
      var _get$createRecord;

      var tabName = this.get('params.current_tab');
      var tabObject = this.get('tabObjects').findBy('Name', decodeURI(tabName));

      var passport = this.get('parentController.model');
      var nameId = undefined;
      var valueId = undefined;
      if (passport.get('PassportHeader.id')) {
        nameId = 'PassportId';
        valueId = passport.get('PassportHeader.id');
      } else {
        if (passport.get('id')) {
          nameId = 'QuestionId';
          valueId = passport.get('id');
        }
      }

      var newNote = this.get('store').createRecord('zs-passport/zs-passport-personal-document', (_get$createRecord = {}, _defineProperty(_get$createRecord, nameId, valueId), _defineProperty(_get$createRecord, 'Type', tabObject.get('id')), _get$createRecord));
      return newNote;
    },

    actions: {
      saveModel: function functionName(model) {
        return this.saveModel(model);
      },
      getShowNotes: function getShowNotes(model) {
        _ember['default'].$('.scrollable-div').trigger('updatescroll');
        if (this.get('devicejs').isPhone()) {
          this.transitionTo(this.get('parentControllerName') + '.notes', encodeURI(model), {
            queryParams: {
              notesState: 'items'
            }
          });
        } else {
          this.replaceWith(this.get('parentControllerName') + '.notes', encodeURI(model));
        }
      },
      removeNoteModal: function removeNoteModal() {
        if (this.get('devicejs').isPhone()) {
          window.history.back();
        } else {
          this.disconnectOutlet({
            outlet: 'modalNote',
            parentView: this.get('parentControllerName') + '/notes'
          });
        }
      },

      addToArray: function addToArray(model) {
        if (!this.get('modelNotes').findBy('id', model.get('id'))) {
          this.get('modelNotes').pushObject(model);
        }
      },

      getEmptyNote: function functionName(note) {
        var model = this.getNewModel();
        note.set('model', model);
      },

      add: function add() {
        var _this = this;
        var tabName = this.get('params.current_tab');
        var tabObject = this.get('tabObjects').findBy('Name', decodeURI(tabName));

        if (!this.get('networkstatus').isInternetConnection()) {
          if (tabObject.get('id') === 'note') {
            this.get('notificationService').alert('Для добавления заметки необходимо подключение к сети интернет.', null, 'Внимание!');
          } else {
            this.get('notificationService').alert('Для добавления файла необходимо подключение к сети интернет.', null, 'Внимание!');
          }

          return;
        }

        var newNote = this.getNewModel();

        if (tabObject.get('id') === 'note') {
          this.send('edit', newNote);
        } else {
          if (this.get('platform') === 'browser') {
            var $fileDialog = _ember['default'].$('#fileDialog');
            $fileDialog.unbind();
            $fileDialog.change(function () {
              var file = $fileDialog[0];
              if (file.files[0]) {
                newNote.set('file', file.files[0]);
                newNote.set('Name', file.files[0].name);
                _this.saveModel(newNote);
                $fileDialog.val('');
              }
            });
            $fileDialog.trigger('click');
          }

          var errorCallback = function functionName(e) {
            if (e.code === 1) {
              _this.get('notificationService').alert('Файл не найден.', null, 'Внимание!');
            } else if (typeof e === 'string') {
              if (e !== 'canceled') {
                _this.get('notificationService').alert(e, null, 'Внимание!');
              }
            } else {
              _this.get('notificationService').alert('При выполнении операции произошла ошибка.', null, 'Внимание!');
            }

            console.error(e);
          };

          var sendFileCallback = function functionName(path) {
            window.resolveLocalFileSystemURL(encodeURI(path), function functionName(fileEntry) {
              console.log('OK');
              fileEntry.file(function (file) {
                var reader = new FileReader();
                reader.onloadend = function () {
                  var imgBlob = new Blob([this.result], {
                    type: file.type
                  });
                  var name = file.name;

                  imgBlob.name = name;
                  newNote.set('Name', name);
                  newNote.set('file', imgBlob);
                  _this.saveModel(newNote);
                };

                reader.readAsArrayBuffer(file);
              }, errorCallback);
            }, errorCallback);
          };

          if (this.get('platform') === 'android') {
            window.fileChooser.open(function (path) {
              console.log('You picked this file: ' + path);
              window.FilePath.resolveNativePath(path, function (path) {
                sendFileCallback(path);
              }, errorCallback);
            }, errorCallback);
          }

          if (this.get('platform') === 'ios') {
            window.FilePicker.isAvailable(function () {
              var successCallback = function successCallback(path) {
                console.log('You picked this file: ' + path);
                sendFileCallback('file://' + path);
              };

              var utis = ['public.composite-content'];
              window.FilePicker.pickFile(successCallback, errorCallback, utis);
            });
          }
        }
      },

      'delete': function _delete(model) {
        var tabName = this.get('params.current_tab');
        var tabObject = this.get('tabObjects').findBy('Name', decodeURI(tabName));

        if (!this.get('networkstatus').isInternetConnection()) {
          if (tabObject.get('id') === 'note') {
            this.get('notificationService').alert('Для удаления заметки необходимо подключение к сети интернет.', null, 'Внимание!');
          } else {
            this.get('notificationService').alert('Для удаления файла необходимо подключение к сети интернет.', null, 'Внимание!');
          }

          return;
        }

        var _this = this;

        var confirmCallback = function functionName(num) {
          if (num === 1) {
            model.destroyRecord().then(function functionName() {
              _this.get('modelNotes').removeObject(model);
            })['catch'](function (e) {
              if (e.status === 413) {
                _this.get('notificationService').alert('Размер прикрепляемого файла превышает допустимый предел.', null, 'Внимание!');
              } else {
                _this.get('notificationService').alert('При выполнении операции произошла ошибка.', null, 'Внимание!');
              }

              console.error('Save Error: ');
              console.error(e);
            });
          }
        };

        _this.get('notificationService').confirm('Удалить документ?', confirmCallback, 'Подтверждение удаления.', ['Да', 'Нет']);
      },
      edit: function edit(model) {
        if (!model.get('isSaving')) {
          if (model.get('Type') === 'note') {
            this.openModal(model);
          } else {
            var url = model.get('Url');
            if (url) {
              url = url.replace('#', '');
              this.transitionTo(url);
            }
          }
        }
      }
    }
  });
});