define('deputee-office/adapters/newsitem-offline', ['exports', 'deputee-office/adapters/application-offline', 'ember-flexberry-data', 'moment'], function (exports, _deputeeOfficeAdaptersApplicationOffline, _emberFlexberryData, _moment) {
  exports['default'] = _deputeeOfficeAdaptersApplicationOffline['default'].extend({
    query: function query(store, modelName, _query) {
      var filter = _query.filter;
      var categoryType = _query.categoryType;
      var sourceType = _query.sourceType;
      var category = _query.category;
      var date = _query.date;
      if (date) {
        date = date.getDate();
      }

      var page = _query.page;
      var perPage = _query.per_page;
      var personal = JSON.parse(_query.personal);

      var queryInstance = _query instanceof _emberFlexberryData.Query.QueryObject;
      if (!queryInstance) {
        _query = {};
      }

      return this._super(store, modelName, _query).then(function (results) {
        results = results.data;
        if (categoryType || date || personal !== undefined || sourceType || category || filter) {
          results = results.filter(function (record) {
            return (!categoryType || record.categoryType === categoryType) && (!date || record.Date && record.Date.getDate() === date) && (!filter || record.Date && (0, _moment['default'])('' + record.Date).format('DD.MM.YYYY').indexOf(filter) !== -1 || record.Title && record.Title.indexOf(filter) !== -1 || record.Content && record.Content.indexOf(filter) !== -1) && (!sourceType || record.sourceType && record.sourceType === sourceType) && (!category || record.category && record.category === category) && (personal === undefined || record.personal !== undefined && JSON.parse(record.personal) === personal);
          });
        }

        var propertyName = 'Date';
        results = results.toArray().sort(function (a, b) {
          if ((0, _moment['default'])(a[propertyName]).isAfter((0, _moment['default'])(b[propertyName]))) {
            return -1;
          } else {
            return 1;
          }
        });

        // Оффлайн-пейджинг через slice.
        if (perPage && perPage !== 0) {
          var fromIndex = (page - 1) * perPage;
          var toIndex = page * perPage;

          results = results.slice(fromIndex, toIndex);
        }

        return { data: results };
      });
    }
  });
});