define('deputee-office/models/contact', ['exports', 'ember-data', 'deputee-office/models/base'], function (exports, _emberData, _deputeeOfficeModelsBase) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Name: _emberData['default'].attr('string'),
    Address: _emberData['default'].attr('string'),
    PhoneNumber: _emberData['default'].attr('string'),
    Email: _emberData['default'].attr('string'),
    Post: _emberData['default'].attr('string'),
    Photo: _emberData['default'].attr('string'),
    department: _emberData['default'].belongsTo('department', { async: true }),
    OrderNum: _emberData['default'].attr('number'),
    departmentid: _emberData['default'].attr('string'),
    departmentName: _emberData['default'].attr('string'),
    departmentFullPath: _emberData['default'].attr('string')
  });

  exports['default'] = Model;
});