define('deputee-office/models/newsitem', ['exports', 'ember-data', 'deputee-office/models/base', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Title: _emberData['default'].attr('string'),
    Content: _emberData['default'].attr('string'),
    TaglessContent: _emberData['default'].attr('string'),
    Url: _emberData['default'].attr('string'),
    Type: _emberData['default'].attr('string'),
    sourceType: _emberData['default'].attr('string'),
    Source: _emberData['default'].attr('string'),
    Date: _emberData['default'].attr('date'),
    category: _emberData['default'].attr('string'),
    Author: _emberData['default'].attr('string'),
    personal: _emberData['default'].attr('boolean'),
    hDate: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])(this.get('Date')).format('DD MMMM YYYY года');
    }),
    Month: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])('' + this.get('Date')).format('MMMM YYYY года').firstCharToUpper();
    }),
    sourceTypeText: _ember['default'].computed('sourceType', function () {
      var type = this.get('sourceType');
      if (type === 'Internet') {
        return 'Интернет';
      }

      if (type === 'TvRadio') {
        return 'ТВ-Радио';
      }

      if (type === 'Pressa') {
        return 'Пресса';
      }

      return '';
    })
  });
  exports['default'] = Model;
});