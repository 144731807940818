define('deputee-office/components/service-tile', ['exports', 'deputee-office/components/link-to-url'], function (exports, _deputeeOfficeComponentsLinkToUrl) {
  exports['default'] = _deputeeOfficeComponentsLinkToUrl['default'].extend({
    classNames: ['col-xs-4', 'col-sm-4', 'col-md-2', 'col-lg-2', 'mainServiceT', 'no-touch'],
    classNameBindings: ['rightBorder'],
    rightBorder: false,
    click: function click() {
      this._super.apply(this, arguments);
      if (this.get('serviceClick')) {
        this.sendAction('serviceClick', this.get('item'));
      }
    }
  });
});