define('deputee-office/mixins/loader/department', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    loadDepartments: function loadDepartments(transition) {
      var _this = this;

      var departments = null;

      switch (transition) {
        case 'omsu-agenda-commitees.index':
          var territoryId = this.get('territoryService').getTerritoryId();
          departments = this.store.query('omsudepartment', {
            TerritoryId: territoryId
          }).then(function (result) {
            return result.toArray().sortDepartments();
          });
          break;
        case 'zs-agenda-departments.index':
          departments = this.store.findAll('zs-department').then(function (result) {
            return result.toArray().sortDepartments();
          });
          break;
        case 'yp-agenda-comissions.index':
          departments = this.store.findAll('yp-department').then(function (result) {
            return result.filterBy('Type', 'Комиссия');
          });
          break;
        default:
          throw new Error('Необработанный роут заседаний ' + transition);
      }
      return departments.then(function (result) {
        _this.set('departments', result);
        return result;
      });
    }
  });
});