define('deputee-office/routes/phone/my-documents', ['exports', 'deputee-office/routes/my-documents'], function (exports, _deputeeOfficeRoutesMyDocuments) {
  exports['default'] = _deputeeOfficeRoutesMyDocuments['default'].extend({
    queryParams: {
      selectedType: {
        refreshModel: false,
        replace: false
      },
      myDocParam: {
        refreshModel: false,
        replace: false
      }
    },

    openModal: function openModal(model) {
      var queryParams = {
        selectedType: this.get('controller.selectedType'),
        myDocParam: model.get('id')
      };

      this.transitionTo(this.get('routeName'), {
        queryParams: queryParams
      });
    },

    actions: {
      removeNoteModal: function removeNoteModal() {
        window.history.back();
      },
      saveModel: function saveModel(model) {
        this._super(model);
        window.history.back();
      }
    }
  });
});