define('deputee-office/components/yp-components/yp-mobile-timer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    agendaStateService: _ember['default'].inject.service('agenda-state'),
    Agenda: _ember['default'].computed('agendaStateService.currentAgenda', function () {
      return this.get('agendaStateService.currentAgenda');
    }),

    isOver: false,
    actions: {
      timerOver: function timerOver() {
        _ember['default'].run.schedule('afterRender', this, function () {
          this.set('isOver', true);
        });
      },
      timerRun: function timerRun() {
        _ember['default'].run.schedule('afterRender', this, function () {
          this.set('isOver', false);
        });
      }
    }
  });
});