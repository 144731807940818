define('deputee-office/components/passport/passport-header', ['exports', 'ember', 'deputee-office/mixins/grayscale-for-explorer10'], function (exports, _ember, _deputeeOfficeMixinsGrayscaleForExplorer10) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsGrayscaleForExplorer10['default'], {
    flagService: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    agendaStateService: _ember['default'].inject.service('agenda-state'),
    currentuserservice: _ember['default'].inject.service(),

    classNames: ['passportHeaderContainer'],
    voteObject: _ember['default'].computed('votingResults', function () {
      if (this.get('votingResults.length')) {
        return this.get('votingResults.firstObject');
      }

      return _ember['default'].Object.extend({}).create({});
    }),

    defaultLayout: _ember['default'].computed(function () {
      var layoutName = this.get('layoutName') || 'passport/passport-header-zs';
      return _ember['default'].getOwner(this).lookup('template:components/' + layoutName);
    }),

    isZg: _ember['default'].computed('AgendaState', 'StageOver', function () {
      var ass = this.get('agendaStateService');
      return ass.isZg(this.get('AgendaState'), this.get('StageOver'));
    }),

    isAfterZg: _ember['default'].computed('AgendaState', 'StageOver', function () {
      var ass = this.get('agendaStateService');
      return ass.isAfterZg(this.get('AgendaState'), this.get('StageOver'));
    }),

    isGp: _ember['default'].computed('AgendaState', 'StageOver', function () {
      var ass = this.get('agendaStateService');
      return ass.isGp(this.get('AgendaState'), this.get('StageOver'));
    }),

    isAfterGp: _ember['default'].computed('AgendaState', 'StageOver', function () {
      var ass = this.get('agendaStateService');
      return ass.isAfterGp(this.get('AgendaState'), this.get('StageOver'));
    }),

    isVoteAvailable: _ember['default'].computed('AgendaState', 'StageOver', function () {
      var cus = this.get('currentuserservice');
      var passport = this.get('passportModel');
      return cus.get('isMPUser') || passport.get('IsVoteAvailable');
    }),

    AmendmentOnly: _ember['default'].computed('comments.[]', function () {
      if (this.get('comments.length')) {
        var amendmentOnly = this.get('comments').filter(function (x) {
          return x.get('isAmendment');
        });
        amendmentOnly.set('AmendmentVote', true);
        return amendmentOnly;
      }
    }),

    allComments: _ember['default'].computed('comments.[]', function () {
      if (this.get('comments')) {
        var allComments = this.get('comments');
        allComments.set('AmendmentVote', false);
        return allComments;
      }
    }),

    AdditionalAllow: _ember['default'].computed('passportModel.VideoUrl', 'comments.[]', 'votingResults.[]', 'noteAllowed', function () {
      return this.get('passportModel.VideoUrl') || this.get('comments.length') !== undefined || this.get('votingResults.length') || this.get('noteAllowed');
    }),

    showTextBlock: _ember['default'].computed('model', function () {
      var ttt = this.get('model');
      if (ttt.get('LegalActFile') || ttt.get('ProjectLegalActFiles')) {
        return true;
      }

      return false;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      _ember['default'].$('.scrollable-div').trigger('updatescroll');
    },

    Flag: _ember['default'].computed('model', function () {
      var passportHeader = this.get('model');
      if (passportHeader) {
        var flagService = this.get('flagService');
        _ember['default'].run.next(this, function () {
          this.GrayForExplorer10('img.grayscale');
        });
        return flagService.getFlagUrlByTerritoryId(passportHeader.get('TerritoryId'));
      }
    }),
    actions: {
      showVideo: function showVideo(url) {
        this.get('target').send('showVideo', url);
      }
    }
  });
});