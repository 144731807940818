define('deputee-office/models/omsu-passport/omsu-question-history', ['exports', 'ember-data', 'deputee-office/models/base', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember, _moment) {
  exports['default'] = _deputeeOfficeModelsBase['default'].extend({
    Status: _emberData['default'].attr('string'),
    Date: _emberData['default'].attr('date'),
    QuestionId: _emberData['default'].attr('guid'),
    Agenda: _emberData['default'].attr('string'),
    Department: _emberData['default'].attr('string'),
    TerritoryId: _emberData['default'].attr('guid'),
    FormattedDate: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])('' + this.get('Date')).format('DD MMMM YYYY, HH.mm');
    })
  });
});