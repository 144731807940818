define('deputee-office/services/foto-service', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    getFiByName: function getFiByName(name) {
      try {
        var matches = name.match(/[А-Я]/g);
        var fi = [matches[0], matches[1]];
        return fi.join('');
      } catch (e) {
        return '';
      }
    },

    getFotoUrlByName: function getFotoUrlByName(name) {
      return this.getFotoUrlByFI(this.getFiByName(name));
    },

    getFotoUrlByFI: function getFotoUrlByFI(FI) {
      var font = '50px arial';
      var x = 40;
      var y = 45;

      var canvas = document.createElement('canvas');
      canvas.width = 150;
      canvas.height = 150;
      var ctx = canvas.getContext('2d');

      var grd = this.getLinearGradientByFI(FI, ctx);

      ctx.font = font;
      ctx.textBaseline = 'top';
      ctx.fillStyle = grd;

      ctx.fillRect(0, 0, 150, 150);

      ctx.fillStyle = 'white';
      ctx.fillText(FI, x, y);
      return canvas.toDataURL();
    },

    getLinearGradientByFI: function getLinearGradientByFI(FI, ctx) {
      var n = (FI.charCodeAt(0) + FI.charCodeAt(1)) % 5;
      var grd = ctx.createLinearGradient(0, 0, 0, 150);
      switch (n) {
        case 0:
          grd.addColorStop(0, '#5CD52A');
          grd.addColorStop(1, '#A5E787');
          break;
        case 1:
          grd.addColorStop(0, '#4ADEFE');
          grd.addColorStop(1, '#4D83F4');
          break;
        case 2:
          grd.addColorStop(0, '#FC5788');
          grd.addColorStop(1, '#FF7D61');
          break;
        case 3:
          grd.addColorStop(0, '#B234DE');
          grd.addColorStop(1, '#EA45A1');
          break;
        case 4:
          grd.addColorStop(0, '#FCE082');
          grd.addColorStop(1, '#F88A4C');
          break;
        default:
          grd.addColorStop(0, '#5CD52A');
          grd.addColorStop(1, '#A5E787');
      }
      return grd;
    }
  });
});