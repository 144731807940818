define('deputee-office/services/yp-comment-service', ['exports', 'ember', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    networkstatus: _ember['default'].inject.service(),
    currentuserservice: _ember['default'].inject.service(),
    settingsservice: _ember['default'].inject.service(),
    setCommentsAsRead: function setCommentsAsRead(passportId) {
      var isConnected = this.get('networkstatus').isInternetConnection();
      var authHeader = this.get('currentuserservice').getBasicAuthHeader();
      if (isConnected) {
        _ember['default'].run.schedule('destroy', this, function () {
          _ember['default'].$.ajax({
            url: this.get('settingsservice').getHost() + '/api/ypquestions/commentsRead/' + passportId,
            headers: {
              Authorization: authHeader
            },
            type: 'POST',
            timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
          });
        });
      }
    }
  });
});