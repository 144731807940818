define('deputee-office/components/scrollable-div', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    divId: _ember['default'].computed(function () {
      var ci = this.get('scrollId');
      if (ci) {
        return 'scrollable-div' + ci;
      }

      return 'scrollable-div';
    }),
    isMPlatform: _ember['default'].computed(function () {
      var device = this.get('devicejs');
      if (device.type() === 'desktop') {
        return false;
      }

      return true;
    }),
    curPos: 0,
    findId: 0,
    moveScroll: true,
    didInsertElement: function didInsertElement() {
      this._super();
      this.updateHeight();
      if (!this.get('isMPlatform')) {
        this.$('.scrollable-div').perfectScrollbar({ wheelspeed: 2 });
      }

      this.$('.scrollable-div').on('updatescroll', _ember['default'].$.proxy(this.recenter, this));
    },

    willDestroyElement: function willDestroyElement() {
      if (!this.get('isMPlatform')) {
        this.$('.scrollable-div').perfectScrollbar('destroy');
      }
    },

    didRender: function didRender() {
      var _this2 = this;

      var _this = this;
      var context = { name: 'debounce' };
      this.updateHeight();
      if (this.get('findId') !== 0 && this.get('scrollId') === 'agendaContainer') {
        (function () {
          var q = _this2.$('#' + _this2.get('findId'));
          if (q.length > 0) {
            if (q.offset().top > 0) {
              if (_this2.get('moveScroll') === true) {
                _ember['default'].run.debounce(context, function () {
                  _this.$('.scrollable-div').scrollTop(q.offset().top + _this.$('.scrollable-div').scrollTop() - _this.$('.scrollable-div').offset().top);
                  if (!_this.get('isMPlatform')) {
                    _this.$('.scrollable-div').perfectScrollbar('update');
                  }

                  _this.set('moveScroll', false);
                }, 800);
              } else {
                _this2.set('moveScroll', true);
              }
            }
          }
        })();
      }
    },

    didUpdateAttrs: function didUpdateAttrs() {
      if (!this.get('isMPlatform')) {
        this.$('.scrollable-div').perfectScrollbar('destroy');
        this.updateHeight();
        this.$('.scrollable-div').perfectScrollbar();
      }
    },

    actions: {
      pfscrolltopabsolute: function pfscrolltopabsolute(pix, time) {
        pix = pix || 0;
        time = time || 200;
        this.$('.scrollable-div').scrollTop(pix);
        if (!this.get('isMPlatform')) {
          this.$('.scrollable-div').perfectScrollbar('update');
        }
      },

      pfscrolltop: function pfscrolltop(pix, time) {
        pix = pix || 0;
        time = time || 200;
        var scsctop = this.$('.scrollable-div').scrollTop() + pix;
        this.$('.scrollable-div').stop().animate({
          scrollTop: scsctop
        }, time);
        if (!this.get('isMPlatform')) {
          this.$('.scrollable-div').perfectScrollbar('update');
        }
      }
    },
    recenter: function recenter() {
      var currentScroll = this.$('.scrollable-div');
      if (currentScroll === undefined) {
        currentScroll = _ember['default'].$('.scrollable-div');
      }

      if (currentScroll !== undefined) {
        if (!(this.get('findId') !== 0 && this.get('scrollId') === 'agendaContainer')) {
          this.send('pfscrolltopabsolute');
        }
      }
    },
    handleResize: function handleResize() {
      this.updateHeight();
      var currentScroll = this.$('.scrollable-div');
      if (currentScroll === undefined) {
        currentScroll = _ember['default'].$('.scrollable-div');
      }

      if (currentScroll !== undefined) {
        if (!this.get('isMPlatform')) {
          currentScroll.perfectScrollbar('destroy');
          currentScroll.perfectScrollbar();
        }
      }
    },

    bindResizeEvent: (function () {
      _ember['default'].$(window).on('resize', _ember['default'].run.bind(this, this.handleResize));
    }).on('init'),
    updateHeight: function updateHeight() {
      var currentScroll = this.$('.scrollable-div');
      if (currentScroll === undefined) {
        currentScroll = _ember['default'].$('.scrollable-div');
      }

      if (currentScroll !== undefined) {
        var height = _ember['default'].$(window).height() - currentScroll.offset().top;
        height = Math.round(height);
        if (this.get('height')) {
          height = this.get('height');
        }

        currentScroll.css('height', height);
      }
    }
  });
});