define('deputee-office/components/territory-component', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    store: _ember['default'].inject.service(),
    didInsertElement: function didInsertElement() {
      _ember['default'].$('.needPF').perfectScrollbar();
    },

    territoriesSorted: _ember['default'].computed('territories.@each', function () {
      var territories = this.get('territories');
      var selectedTerritory = this.get('selectedTerritory');
      if (territories && selectedTerritory) {
        territories = territories.sortBy('OrderNum');
        selectedTerritory = territories.filterBy('id', selectedTerritory.id);
        territories = territories.filter(function (x) {
          return !selectedTerritory.isAny('id', x.get('id'));
        });
        territories = selectedTerritory.pushObjects(territories.toArray());
        return territories;
      }
    }),

    willDestroyElement: function willDestroyElement() {
      _ember['default'].$('.needPF').perfectScrollbar('destroy');
      var selectedTerritory = this.get('territoryService').getTerritory();
      this.set('selectedTerritory', selectedTerritory);
    },

    actions: {
      selectTerritory: function selectTerritory(territory) {
        _ember['default'].$('#' + territory.id).find('input').prop('checked', true);
        this.set('selectedTerritory', territory);
      }
    }
  });
});