define('deputee-office/initializers/extensions', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() {

    String.prototype.firstCharToUpper = function () {
      return this.charAt(0).toUpperCase() + this.slice(1);
    };

    String.prototype.regexLastIndexOf = function (regex, startpos) {
      regex = regex.global ? regex : new RegExp(regex.source, 'g' + (regex.ignoreCase ? 'i' : '') + (regex.multiLine ? 'm' : ''));
      if (typeof startpos === 'undefined') {
        startpos = this.length;
      } else if (startpos < 0) {
        startpos = 0;
      }

      var stringToWorkWith = this.substring(0, startpos + 1);
      var lastIndexOf = -1;
      var nextStop = 0;
      var result;
      while ((result = regex.exec(stringToWorkWith)) != null) {
        lastIndexOf = result.index;
        regex.lastIndex = ++nextStop;
      }

      return lastIndexOf;
    };

    String.prototype.firstCharToLowerCase = function () {
      return this.charAt(0).toLowerCase() + this.slice(1);
    };

    String.prototype.extractFirstLetters = function () {
      var re = /\s/;
      return this.split(re).map(function (x) {
        return x[0];
      }).join('');
    };

    Array.prototype.sortByProperty = function (propertyName, direction) {
      return this.sort(function (a, b) {
        if (direction === 'asc') {
          return a.get(propertyName) - b.get(propertyName);
        } else if (direction === 'desc') {
          return b.get(propertyName) - a.get(propertyName);
        }
      });
    };

    Array.prototype.sortDepartments = function () {
      return this.sort(function (a, b) {

        var getOrderNumByName = function getOrderNumByName(Type) {
          if (Type === 'КОМИТЕТЫ') {
            return 0;
          } else if (Type === 'КОМИССИИ') {
            return 1;
          } else if (Type === 'ФРАКЦИИ') {
            return 2;
          } else {
            return 100;
          }
        };

        var aType = a.get('Type');
        var bType = b.get('Type');

        if (aType !== bType) {
          return getOrderNumByName(aType) - getOrderNumByName(bType);
        } else {
          if (a.get('FullName')) {
            return a.get('FullName').localeCompare(b.get('FullName'));
          } else {
            return 0;
          }
        }
      });
    };
  }

  exports['default'] = {
    name: 'extensions',
    initialize: initialize
  };
});