define('deputee-office/models/gos-program', ['exports', 'ember-data', 'deputee-office/models/base', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    DocId: _emberData['default'].attr('string'),
    Title: _emberData['default'].attr('string'),
    title: _ember['default'].computed('Title', function () {
      return this.get('Title');
    }),
    OrderNum: _emberData['default'].attr('number')
  });

  exports['default'] = Model;
});