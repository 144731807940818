define('deputee-office/components/comment-table', ['exports', 'deputee-office/components/base-view', 'deputee-office/config/environment', 'ember'], function (exports, _deputeeOfficeComponentsBaseView, _deputeeOfficeConfigEnvironment, _ember) {
  exports['default'] = _deputeeOfficeComponentsBaseView['default'].extend({
    store: _ember['default'].inject.service(),
    networkstatus: _ember['default'].inject.service(),
    setup: (function () {
      this.setQuestion();
      this.set('comments', this.loadComments());
    }).observes('model').on('init'),

    setQuestion: function setQuestion() {
      var _model = this.get('model').toArray()[0];
      var omsuquestion = this.get('store').peekRecord('omsuquestion', _model.id);
      var newquestion = this.get('store').peekRecord('newquestion', _model.id);
      var question = omsuquestion || newquestion;
      this.set('question', question);
    },
    compareAndUpdateComments: function compareAndUpdateComments(newComments) {
      var _this = this;
      var oldComments = this.get('comments');
      if (oldComments) {
        newComments.then(function (newCommentsData) {
          newCommentsData.every(function (newComment) {
            var isNew = true;
            oldComments.forEach(function (oldComment) {
              if (newComment.get('id') === oldComment.get('id')) {
                isNew = false;
              }
            });
            if (isNew) {
              _this.set('comments', newCommentsData);
              return false;
            }

            return true;
          });
          oldComments.every(function (oldComment) {
            var isDelete = true;
            newCommentsData.forEach(function (newComment) {
              if (newComment.get('id') === oldComment.get('id')) {
                isDelete = false;
              }
            });
            if (isDelete) {
              _this.set('comments', newCommentsData);
              return false;
            }

            return true;
          });
        });
      } else {
        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          //_this.set("comments",newComments);
          newComments.then(function (results) {
            _this.set('comments', results);
          });
        }
      }

      //Изменяем значение переменной чтобы заново запустить таймер.
      if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
        _this.set('reload', new Object({}));
      }
    },

    reLoadComments: (function () {
      _ember['default'].run.later(this, function () {
        this.loadComments();
      }, _deputeeOfficeConfigEnvironment['default'].APP.questionCommentsReloadInterval);
    }).observes('reload'),

    loadComments: function loadComments() {
      var _this = this;
      var _model = this.get('model');
      var question = this.get('question');
      if (question && question.get('QuestionId') && question.get('QuestionId') != null && _model && _model.toArray()[0].get('loadComments')) {
        var comments = this.get('store').query('questionComment', { questionId: question.get('QuestionId') });
        this.compareAndUpdateComments(comments);
        return comments;
      } else {
        if (!(_this.get('isDestroyed') || _this.get('isDestroying'))) {
          _this.set('reload', new Object({}));
        }
      }
    },

    actions: {
      addComment: function addComment() {
        var question = this.get('question');
        var comment = this.get('store').createRecord('questionComment', {
          QuestionId: question.get('QuestionId')
        });
        this.get('target').send('renderTemplate', 'comment-modal', comment);
      },

      editComment: function editComment(comment) {
        if (comment) {
          this.get('target').send('renderTemplate', 'comment-modal', comment);
        }
      },

      deleteComment: function deleteComment(comment) {
        if (comment) {
          this.get('target').send('renderTemplate', 'comment-delete-modal', comment);
        }
      },

      expand: function expand(comment) {
        var expandValue = comment.get('Extended');
        comment.set('Extended', !expandValue);
      }
    }

  });
});