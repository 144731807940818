define('deputee-office/models/zsdepartment/zsdepartmentquestion', ['exports', 'ember-data', 'deputee-office/models/base-question', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBaseQuestion, _ember) {
  exports['default'] = _deputeeOfficeModelsBaseQuestion['default'].extend({
    HeadCommittee: _emberData['default'].attr('string'),
    ZsDepartmentAgendaId: _emberData['default'].attr('guid'),
    Time: _emberData['default'].attr('string'),
    CoSpeaker: _emberData['default'].attr('string'),
    SpeakerString: _ember['default'].computed('Speaker', function () {
      return 'Доклад ' + this.get('Speaker');
    }),
    CoSpeakerString: _ember['default'].computed('CoSpeaker', function () {
      return 'Содоклад: ' + this.get('CoSpeaker');
    })
  });
});