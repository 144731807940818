define('deputee-office/components/favorites/favorites-files-zs-mp-base', ['exports', 'ember', 'deputee-office/mixins/favorites-component-base'], function (exports, _ember, _deputeeOfficeMixinsFavoritesComponentBase) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsFavoritesComponentBase['default'], {

    store: _ember['default'].inject.service(),
    favoritesService: _ember['default'].inject.service(),

    loadableModels: ['favorite'],

    init: function init() {
      this._super();
      this.set('model', _ember['default'].A([]));
      this.set('displayedModel', _ember['default'].A([]));
    },

    loadByDepartmentType: function loadByDepartmentType(departmentType) {
      var _this = this;

      var store = this.get('store');
      var favoritesService = this.get('favoritesService');

      var questionModelName = null;
      var agendaModelName = null;
      var questionDocumentName = null;
      var questionDocumentProjection = null;

      switch (departmentType) {
        case 'zsDepartment':
          questionModelName = 'zsquestion';
          agendaModelName = 'zsdepartmentagenda';
          questionDocumentName = 'zs-passport/zs-passport-document';
          questionDocumentProjection = 'zs-passport-document-full';
          break;
        case 'yp':
          questionModelName = 'yp-question';
          agendaModelName = 'yp-agenda';
          questionDocumentName = 'yp-passport/yp-passport-document';
          questionDocumentProjection = 'yp-passport-document-full';
          break;
        case 'pomopk':
          questionModelName = 'pomopk-question';
          agendaModelName = 'pomopk-agenda';
          questionDocumentName = 'omsu-passport/omsu-passport-document';
          questionDocumentProjection = 'pomopk-passport-document-full';
          break;
        default:
          questionModelName = 'zsquestion';
          agendaModelName = 'zsagenda';
          questionDocumentName = 'zs-passport/zs-passport-document';
          questionDocumentProjection = 'zs-passport-document-full';
      }
      this.get('loadableModels').pushObject(questionModelName);
      this.get('loadableModels').pushObject(agendaModelName);
      this.get('loadableModels').pushObject(questionDocumentName);

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        store.query('favorite', { Model: 'zs-passport/zs-passport-file' }).then(function (favorites) {
          return favorites.toArray().filter(function (favorite) {
            var params = JSON.parse(favorite.get('Params'));

            var serviceCondition = function serviceCondition() {
              return params.additionalParams.serviceName === _this.get('serviceName');
            };

            return params.additionalParams && serviceCondition();
          });
        }).then(function (favorites) {

          // Массив идентификаторов документов.
          var docIds = favoritesService.getUnique(favorites, 'docId');

          // Массив идентификаторов вопросов.
          var questionIds = favoritesService.getUnique(favorites, 'additionalParams.questionId');

          // Массив идентификаторов повесток, которые требуются для получения нужных вопрсов.
          var agendaIds = favoritesService.getUnique(favorites, 'additionalParams.agendaId');

          // Обертки для запросов получения документов.
          var wrappersForDocuments = favorites.map(function (favorite) {
            return JSON.parse(favorite.get('Params')).additionalParams;
          });

          var promises = [];

          wrappersForDocuments.forEach(function (item) {
            var promise;
            if (item.AddInfo === item.tab && departmentType !== 'pomopk') {
              var promisesDoc = {
                questionDocuments: store.query(questionDocumentName, {
                  QuestionId: item.questionId,
                  ZsDepartmentAgendaId: item.agendaId,
                  projection: questionDocumentProjection
                })
              };

              if (item.PassportId) {
                promisesDoc.passportDocuments = store.query('zs-passport/zs-passport-document', {
                  PassportId: item.PassportId,
                  TabName: item.tab,
                  projection: 'zs-passport-document-full'
                });
              }

              promise = _ember['default'].RSVP.hashSettled(promisesDoc, 'favorites-files-zs-mp-base: hashSettled wrappersForDocuments').then(function (_result) {
                var result = _result.questionDocuments.state === 'fulfilled' ? _result.questionDocuments.value : [];

                if (result.passportDocuments) {
                  result.pushObjects(result.passportDocuments.toArray());
                }

                var docsWrapper = {
                  questionId: item.questionId,
                  agendaId: item.agendaId,
                  additionalParams: item,
                  documents: result
                };

                return docsWrapper;
              });
            } else {
              var modelName = departmentType === 'pomopk' ? questionDocumentName : 'zs-passport/zs-passport-document';

              promise = store.query(modelName, {
                PassportId: item.PassportId,
                TabName: item.tab
              }).then(function (result) {

                var docsWrapper = {
                  questionId: item.questionId,
                  agendaId: item.agendaId,
                  additionalParams: item,
                  documents: result.toArray()
                };

                return docsWrapper;
              });
            }

            promises.push(promise);
          });

          var documentsPromise = _ember['default'].RSVP.allSettled(promises, 'favorites-files-zs-mp-base: documentsPromise').then(function (result) {
            return favoritesService.getUniqForModels(_this.settledHelper(result), docIds, 'documents', function (item, source) {
              return new Object({
                agendaId: source.agendaId,
                questionId: source.questionId,
                additionalParams: source.additionalParams,
                documentItem: item
              });
            });
          });

          // Промис для нахождения всех повесток.
          var agendasPromise = null;

          if (departmentType !== 'yp' && departmentType !== 'zsDepartment') {
            agendasPromise = store.findAll(agendaModelName).then(function (result) {
              return favoritesService.getUniqForModels(result, agendaIds);
            });
          } else {
            (function () {
              var departmentIds = favoritesService.getUnique(favorites, 'additionalParams.departmentId').filter(function (x) {
                return x;
              });
              var agendasPromises = [];

              departmentIds.forEach(function (item) {
                return agendasPromises.push(store.query(agendaModelName, { DepartmentId: item }));
              });

              agendasPromise = _ember['default'].RSVP.allSettled(agendasPromises, 'favorites-files-zs-mp-base: agendasPromise').then(function (result) {
                return favoritesService.getUniqForModels(_this.settledHelper(result), agendaIds);
              });
            })();
          }

          var qPromises = [];

          agendaIds.forEach(function (item) {
            var options = {};
            var agendaIdParamName = _this.get('agendaIdParamName');
            options[agendaIdParamName] = item;
            qPromises.push(store.query(questionModelName, options));
          });

          var questionsPromise = _ember['default'].RSVP.allSettled(qPromises, 'favorites-files-zs-mp-base: questionsPromise').then(function (result) {
            return favoritesService.getUniqForModels(_this.settledHelper(result), questionIds);
          });

          var promisesObject = {
            favorites: _ember['default'].RSVP.Promise.resolve(favorites),
            questions: questionsPromise,
            agendas: agendasPromise,
            documents: documentsPromise
          };

          return _ember['default'].RSVP.hashSettled(promisesObject, 'favorites-files-zs-mp-base: Hash for all').then(function (_result) {

            var result = new Object({
              favorites: _result.favorites.state === 'fulfilled' ? _result.favorites.value : [],
              questions: _result.questions.state === 'fulfilled' ? _result.questions.value : [],
              agendas: _result.agendas.state === 'fulfilled' ? _result.agendas.value : [],
              documents: _result.documents.state === 'fulfilled' ? _result.documents.value : []
            });

            // Массив идентификаторов файлов.
            var fileIds = _this.get('favoritesService').getUnique(result.favorites, 'id');

            // Построение групп для вывода в шаблоне.
            var questionGroups = result.questions.map(function (questionItem) {
              var agenda = null;
              var agendaIdParamName = _this.get('agendaIdParamName');

              result.agendas.forEach(function (agendaItem) {
                if (questionItem.get(agendaIdParamName) === agendaItem.get('id')) {
                  agenda = agendaItem;
                }
              });

              var documents = result.documents.filter(function (documentWrapperItem) {
                return documentWrapperItem.agendaId === agenda.get('id') && documentWrapperItem.questionId === questionItem.get('id');
              }).map(function (documentWrapperItem) {
                var files = documentWrapperItem.documentItem.get('Files').toArray();

                files = files.filter(function (file) {
                  return fileIds.indexOf(file.get('id')) !== -1;
                });

                var docToDisplay = {
                  id: documentWrapperItem.documentItem.get('id'),
                  title: documentWrapperItem.documentItem.get('Title'),
                  additionalParams: documentWrapperItem.additionalParams,
                  files: files,
                  displayFiles: _ember['default'].A([])
                };

                return docToDisplay;
              });

              var questionGroup = {
                Department: agenda.get('Department'),
                Date: agenda.get('Date'),
                FormattedDate: agenda.get('FormattedDate'),
                DisplayedDate: agenda.get('DisplayedDate'),
                question: questionItem,
                documents: documents,
                documentsGroups: {}
              };

              return questionGroup;
            });

            resolve(questionGroups);
          })['catch'](function (e) {
            reject();
            console.error(e);
          });
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'favorites-files-zs-mp-base: loadByDepartmentType');
    },

    filterModel: function filterModel() {
      var model = this.get('model');
      var displayedModel = this.get('displayedModel');
      var filterText = this.get('searchString');

      displayedModel.clear();

      if (filterText && filterText.value) {
        model.forEach(function (questionGroup) {
          var groupAdded = false;

          _ember['default'].set(questionGroup, 'documentsGroups', {});

          questionGroup.documents.forEach(function (doc) {
            var docAdded = false;

            doc.displayFiles.clear();

            doc.files.forEach(function (file) {
              if (file.get('Name').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1) {
                if (!groupAdded) {
                  displayedModel.pushObject(questionGroup);
                  groupAdded = true;
                }

                if (!docAdded) {
                  if (!questionGroup.documentsGroups[doc.title]) {
                    questionGroup.documentsGroups[doc.title] = new _ember['default'].A([]);
                  }

                  questionGroup.documentsGroups[doc.title].pushObject(doc);

                  docAdded = true;
                }

                doc.displayFiles.pushObject(file);
              }
            });
          });
        });
      } else {
        model.forEach(function (questionGroup) {
          _ember['default'].set(questionGroup, 'documentsGroups', {});

          questionGroup.documents.forEach(function (doc) {
            doc.displayFiles.clear();
            doc.displayFiles.pushObjects(doc.files);

            if (!questionGroup.documentsGroups[doc.title]) {
              questionGroup.documentsGroups[doc.title] = new _ember['default'].A([]);
            }

            questionGroup.documentsGroups[doc.title].pushObject(doc);
          });

          displayedModel.pushObject(questionGroup);
        });
      }

      displayedModel.sortBy('Department', 'Date', 'question.Num');
    }
  });
});