define('deputee-office/controllers/modals/omsu-decision', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    AcceptCount: _ember['default'].computed('model', function () {
      var model = this.model;
      if (model) {
        return model.filterBy('items.firstObject.OmsuAccept', true).length;
      }
    }),

    DisacceptCount: _ember['default'].computed('model', function () {
      var model = this.model;
      if (model) {
        return model.filterBy('items.firstObject.OmsuAccept', false).length;
      }
    }),

    OfferCount: _ember['default'].computed('model', function () {
      var model = this.model;
      if (model) {
        return model.mapBy('items.firstObject.OmsuOffer').reduce(function (x, y) {
          return x + y;
        });
      }
    }),

    actions: {
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      }
    }
  });
});