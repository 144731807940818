define('deputee-office/controllers/modals/month-selector', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Controller.extend({
    dateFormat: 'D MMMM',
    monthYearFormat: 'MMMM YYYY',
    actionName: 'selectDate',

    years: _ember['default'].computed('model', function () {
      var dateFieldName = this.get('model.dateFieldName');
      return this.get('model.dates').map(function (item) {
        return (0, _moment['default'])(item.get(dateFieldName)).year();
      }).uniq().sort();
    }),

    selectedYear: _ember['default'].computed('currentDate', '_selectedYear', function () {
      if (!this.get('_selectedYear')) {
        this.set('_selectedYear', (0, _moment['default'])(this.get('currentDate')).year());
      }

      return this.get('_selectedYear');
    }),

    currentDate: _ember['default'].computed('model.selected', function () {
      var dateFieldName = this.get('model.dateFieldName');
      var currentDate = this.get('model.selected').get(dateFieldName);
      return (0, _moment['default'])(currentDate);
    }),

    items: _ember['default'].computed('model.dates.@each', 'selectedYear', function () {
      return this.filter(this.get('model.dates'));
    }),

    filter: function filter(items) {
      var dateFieldName = this.get('model.dateFieldName');
      var selectedYear = this.get('selectedYear');
      var dateFormat = this.get('model.dateFormat') ? this.get('model.dateFormat') : this.get('dateFormat');
      var monthYearFormat = this.get('monthYearFormat');

      return items.filter(function (item) {
        if (selectedYear) {
          var itemDate = (0, _moment['default'])(item.get(dateFieldName));
          var result = selectedYear === itemDate.year();
          if (result) {
            if (dateFormat === monthYearFormat) {
              _ember['default'].set(item, 'popoverFormattedDate', itemDate.format(dateFormat).firstCharToUpper());
            } else {
              _ember['default'].set(item, 'popoverFormattedDate', itemDate.format(dateFormat));
            }
          }

          return result;
        } else {
          return true;
        }
      }).sort(function (a, b) {
        return b.get(dateFieldName).getTime() - a.get(dateFieldName).getTime();
      });
    },

    actions: {
      yearsListState: function functionName() {
        this.set('yearsListState', !this.get('yearsListState'));
        this.set('model.yearsListState', this.get('yearsListState'));
      },
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
        this.set('yearsListState', false);
        this.set('_selectedYear', null);
      },
      selectDate: function functionName() {
        var actionName = this.get('model.actionName') ? this.get('model.actionName') : this.get('actionName');
        if (this.get('model.target')) {
          this.get('model.target').send(actionName, this.get('model.selected'));
        } else {
          this.get('target').send(actionName, this.get('model.selected'));
        }
      }
    }
  });
});