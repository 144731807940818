define('deputee-office/components/passport/passport-toolbar', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    /*backButtonTitle: Ember.computed(function(){
     let cur = this.get('curentAgenda');
     return cur.Name;
     }),*/

    tagName: 'passport-toolbar',

    actions: {
      rightMenu: function rightMenu() {

        this.sendAction('renderRigthMenu', {});

        var sidebar = _ember['default'].$('[data-sidebar]#rightMenu');
        var overlay = _ember['default'].$('[data-sidebar-overlay]#rightMenu');
        if (overlay.is(':visible')) {
          sidebar.css('right', sidebar.width() * -1 + 'px');
          overlay.fadeTo('500', 0, function () {
            overlay.hide();
          });
        } else {
          sidebar.css('right', '0px');

          overlay.show(0, function () {
            overlay.fadeTo('500', 0.5);
          });
        }
      }
    }
  });
});