define('deputee-office/components/month-picker', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      hideAction: function hideAction() {
        _ember['default'].$('.popover-podl').hide();
      },

      showAction: function showAction() {
        _ember['default'].$('.popover-podl').show();

        // datepicker is shown
        _ember['default'].$('.next').html('>');
        _ember['default'].$('.prev').html('<');
        var popoverContent = _ember['default'].$('.webui-popover-content');
        if (popoverContent && popoverContent.length) {
          _ember['default'].$('.webui-popover-content .datepicker').remove();
          var picker = _ember['default'].$('.datepicker');
          picker.insertAfter(_ember['default'].$('#datepicker'));
          picker.css('top', '0px');
          picker.css('left', '0px');
        }
      },

      changeDateAction: function changeDateAction(date) {
        this.sendAction('changeDate', date);
      }
    }
  });
});