define('deputee-office/models/eventsplan', ['exports', 'ember-data', 'deputee-office/models/base', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    DocId: _emberData['default'].attr('string'),
    Title: _emberData['default'].attr('string'),
    DocUrl: _emberData['default'].attr('string'),
    isZsCal: _ember['default'].computed('DocUrl', function () {
      return this.get('DocUrl') === 'zscalendar://';
    }),
    OrderNum: _emberData['default'].attr('number')
  });

  exports['default'] = Model;
});