define('deputee-office/mixins/loader/material', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    getMaterialTypes: function getMaterialTypes(materials) {
      var result = materials.map(function (material) {
        return material.get('MaterialType');
      }, this).uniq().sort();
      return result;
    },

    loadMaterials: function functionName() {
      var materials = this.store.findAll('material-ksp');
      var _this = this;
      return materials.then(function (items) {
        var materialTypes = _this.getMaterialTypes(items);
        _this.set('materialTypes', materialTypes);
      });
    }
  });
});