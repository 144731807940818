define('deputee-office/controllers/news', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    newsTemplateName: 'news-main',
    queryParams: ['newsSubState', 'itemId'],
    itemId: null,
    newsSubState: null,
    modelObserver: _ember['default'].observer('model', function () {
      var _this = this;
      if (this.get('model').news) {
        this.get('model').news.then(function (items) {
          _this.set('news', items);
        });
      }

      if (this.get('model').pers) {
        this.get('model').pers.then(function (items) {
          _this.set('newsPers', items);
        });
      }

      if (this.get('model').blogs) {
        this.get('model').blogs.then(function (items) {
          _this.set('blogs', items);
        });
      }
    }),
    newsStateChanged: _ember['default'].observer('newsSubState', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        switch (this.get('newsSubState')) {
          case 'showNews':
            if (this.get('itemId') && !this.get('currentNews')) {
              this.set('currentNews', this.store.find('newsitem', this.get('itemId')));
            }

            this.set('newsTemplateName', 'news-substate/newsitem');
            break;
          case 'showFractions':
            this.set('newsTemplateName', 'news-substate/fraction');
            break;
          default:
            this.set('newsTemplateName', 'news-main');
        }
      });
    }),
    actions: {
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      },
      createPopover: function createPopover() {
        var device = this.get('devicejs');
        if (!device.isPhone()) {
          _ember['default'].run.schedule('afterRender', this, function () {
            _ember['default'].$('.SR').webuiPopover({
              content: function content() {
                var html = '<div class="container-fluid popoverSR" id="superpuper" style="position:relative;">';
                html += '<div class="row-popover">';
                html += '<span class="hrefSR" href="http://spravedlivo.ru">Федеральные ресурсы</span></div>';
                html += '<div class="row-popover"><span class="hrefSR" href="http://perm.spravedlivo.ru">Региональные ресурсы</span></div></div>';
                return html;
              },
              onShow: function onShow() {
                _ember['default'].$('.hrefSR').on('click', function () {
                  //console.log('inside href');
                  //e.preventDefault();

                  if (!window.device) {
                    return;
                  }

                  var platform = window.device.platform.toLowerCase();
                  var url = _ember['default'].$(this).attr('href');
                  if (platform === 'ios' || platform === 'android') {
                    window.cordova.InAppBrowser.open(url, '_blank', 'location=yes,closebuttoncaption=Закрыть,enableviewportscale=yes');
                  } else {
                    window.cordova.InAppBrowser.open(url, '_system', 'location=yes,closebuttoncaption=Закрыть,enableviewportscale=yes');
                  }
                });
              },
              onHide: function onHide() {
                _ember['default'].$('.hrefSR').off('click');
              }
            });
          });
        }
      }
    }
  });
});