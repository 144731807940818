define('deputee-office/routes/uppc-reports', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/territory-flag-name'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsTerritoryFlagName) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], {
    model: function model() {
      return this.store.findAll('uppc-report');
    },
    activate: function activate() {
      this.setFlagAndNameForZS();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
    },
    setupController: function setupController(controller, model) {
      model = model.slice(0, 1);
      this._super(controller, model);
      this.set('controller', controller);

      if (!window.device) {
        return;
      }

      var platform = window.device.platform.toLowerCase();

      if (platform === 'ios' || platform === 'android') {
        controller.set('desktop', false);
      } else {
        controller.set('desktop', true);
      }

      controller.set('resourceUrl', null);
      controller.set('actions', {
        hideSpinner: function hideSpinner() {
          this.set('spinner', false);
        } });
    },
    actions: {
      openResource: function openResource(resource) {
        var controller = this.get('controller');

        if (controller.get('desktop')) {

          controller.set('spinner', true);

          controller.set('resourceUrl', resource);
        } else {
          window.cordova.InAppBrowser.open(resource, '_blank', 'location=yes,closebuttoncaption=Закрыть');
        }
      }
    }
  });
});