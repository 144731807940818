define('deputee-office/services/offline-globals', ['exports', 'ember', 'ember-flexberry-data/services/offline-globals'], function (exports, _ember, _emberFlexberryDataServicesOfflineGlobals) {

  _emberFlexberryDataServicesOfflineGlobals['default'].reopen({
    processingQuery: false,
    networkstatus: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      var _this = this;
      _ember['default'].$(document).on('offline', { offlineGlobals: _this }, _this._offlineEventHandler);
      _ember['default'].$(document).on('online', { offlineGlobals: _this }, _this._onlineEventHandler);
    },

    willDestroy: function willDestroy() {
      var _this = this;
      _ember['default'].$(document).off('offline', _this._offlineEventHandler);
      _ember['default'].$(document).off('online', _this._onlineEventHandler);
      this._super.apply(this, arguments);
    },

    checkOnlineAvailable: function checkOnlineAvailable() {
      var networkstatus = this.get('networkstatus');

      return networkstatus.isInternetConnection();
    },

    _offlineEventHandler: function _offlineEventHandler(event) {
      var offlineGlobals = event.data.offlineGlobals;
      offlineGlobals.setOnlineAvailable(false);
    },

    _onlineEventHandler: function _onlineEventHandler(event) {
      var offlineGlobals = event.data.offlineGlobals;
      offlineGlobals.setOnlineAvailable(true);
    }
  });

  exports['default'] = _emberFlexberryDataServicesOfflineGlobals['default'];
});