define('deputee-office/components/attached-docs', ['exports', 'ember', 'deputee-office/utils/count-text'], function (exports, _ember, _deputeeOfficeUtilsCountText) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['attachedDocs'],
    firstGroupCount: _ember['default'].computed('items.[]', function () {
      var oC = 6;
      var oW = 1024;
      var curScreenW = _ember['default'].$(window).width();
      return Math.round(curScreenW / oW * oC);
    }),
    firstGroup: _ember['default'].computed('items.[]', function () {
      return this.get('items').slice(0, this.get('firstGroupCount'));
    }),
    secondGroup: _ember['default'].computed('items.[]', function () {
      return this.get('items').slice(this.get('firstGroupCount'));
    }),
    uniq: _ember['default'].computed(function () {
      return new Date().getTime();
    }),
    buttonText: _ember['default'].computed('secondGroup.[]', function () {
      var sC = this.get('secondGroup').length;
      var fP = 'Показать еще ';
      var mP = (0, _deputeeOfficeUtilsCountText['default'])(sC, 'файлов', 'файл', 'файла');
      return fP + sC + ' ' + mP + ' ПА';
    }),
    actions: {
      showAll: function showAll() {
        this.sendAction('showAll');
      }
    }
  });
});