define('deputee-office/components/favorites/favorites-reports', ['exports', 'ember', 'deputee-office/mixins/favorites-component-base'], function (exports, _ember, _deputeeOfficeMixinsFavoritesComponentBase) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsFavoritesComponentBase['default'], {
    store: _ember['default'].inject.service(),

    loadableModels: ['favorite', 'report'],

    init: function init() {
      this._super();
      this.set('model', _ember['default'].A([]));
      this.set('displayedModel', _ember['default'].A([]));
    },

    loadData: function loadData() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var store = _this.get('store');
        var model = _this.get('model');

        store.query('favorite', { Model: 'report' }).then(function (favorites) {

          var ids = favorites.map(function (favorite) {
            return JSON.parse(favorite.get('Params')).id;
          });

          store.findAll('report').then(function (reports) {
            reports.forEach(function (report) {
              var id = report.get('id');

              if (ids.indexOf(id) !== -1) {
                model.pushObject(report);
              }
            });

            resolve();
          })['catch'](function (e) {
            reject();
            console.error(e);
          });
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'favorites-reports: loadData');
    },

    filterModel: function filterModel() {
      var model = this.get('model');
      var displayedModel = this.get('displayedModel');
      var filterText = this.get('searchString');

      displayedModel.clear();

      if (filterText && filterText.value) {
        model.forEach(function (report) {
          if (report.get('Title').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1) {
            displayedModel.pushObject(report);
          }
        });
      } else {
        displayedModel.pushObjects(model);
      }
    }
  });
});