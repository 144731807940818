define('deputee-office/components/favorites/favorites-governor-decrees', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      dataLoadStart: function dataLoadStart() {
        this.sendAction('dataLoadStart');
      },

      dataLoadEnd: function dataLoadEnd() {
        this.sendAction('dataLoadEnd');
      },

      foundNothing: function foundNothing() {
        this.sendAction('foundNothing');
      }
    }
  });
});