define('deputee-office/serializers/application-offline', ['exports', 'ember-data', 'ember', 'ember-flexberry-data'], function (exports, _emberData, _ember, _emberFlexberryData) {
  exports['default'] = _emberFlexberryData.Serializer.Offline.extend(_emberData['default'].EmbeddedRecordsMixin, {
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      var key = relationship.key;
      var belongsTo = snapshot.belongsTo(key);
      key = this.keyForRelationship ? this.keyForRelationship(key, 'belongsTo', 'serialize') : key;
      json[key] = _ember['default'].isNone(belongsTo) ? 'null' : belongsTo.record.toJSON({ includeId: true });
    },
    extractRelationship: function extractRelationship(relationshipModelName, relationshipHash) {
      if (_ember['default'].isNone(relationshipHash) || relationshipHash === 'null') {
        return null;
      }

      return this._super.apply(this, arguments);
    },

    extractAttributes: function extractAttributes(model, hash) {
      var _this = this;

      var attributes = this._super.apply(this, arguments);
      model.eachAttribute(function (key) {
        var attributeKey = _this.keyForAttribute(key, 'deserialize');
        if (hash[attributeKey] === 'null') {
          attributes[key] = null;
        }
      });
      return attributes;
    },

    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      var value = snapshot.attr(key);
      if (value === null) {
        json[key] = 'null';
      } else {
        this._super(snapshot, json, key, attribute);
      }
    }
  });
});