define('deputee-office/adapters/law', ['exports', 'deputee-office/adapters/application'], function (exports, _deputeeOfficeAdaptersApplication) {
  exports['default'] = _deputeeOfficeAdaptersApplication['default'].extend({
    namespace: 'api',
    authorizer: 'authorizer:custom',

    query: function query(store, modelName, _query) {
      var headers = this.get('headers') == null ? {} : this.get('headers');

      if (headers && headers.filter) {
        delete headers.filter;
      }

      if (_query.filter) {
        var filterJSON = JSON.stringify(_query.filter);

        if (filterJSON != null && filterJSON !== undefined) {
          headers.filter = encodeURI(filterJSON);
          this.set('headers', headers);
        }

        delete _query.filter;
      }

      return this._super(store, modelName, _query);
    }
  });
});