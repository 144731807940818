define('deputee-office/models/omsu-passport/omsu-agenda-member', ['exports', 'ember-data', 'deputee-office/models/base'], function (exports, _emberData, _deputeeOfficeModelsBase) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Name: _emberData['default'].attr('string'),
    Role: _emberData['default'].attr('string'),
    Post: _emberData['default'].attr('string'),
    AgendaId: _emberData['default'].attr('guid'),
    IsAttended: _emberData['default'].attr('boolean'),
    DepartmentName: _emberData['default'].attr('string'),
    TerritoryId: _emberData['default'].attr('guid')
  });

  exports['default'] = Model;
});