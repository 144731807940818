define('deputee-office/services/settingsservice', ['exports', 'ember', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    exceptionKeys: ['network-setting'],
    /**
     * Очистка настроек из массива settingsKeys, за исключением exceptionKeys
     * @return {undefined}
     */
    clearSettings: function clearSettings() {
      var _this = this;
      var localStorageData = localStorage.getItem(_deputeeOfficeConfigEnvironment['default'].APP.settingsKey);
      if (localStorageData !== 'undefined') {
        var settingsData;

        (function () {
          settingsData = JSON.parse(localStorage.getItem(_deputeeOfficeConfigEnvironment['default'].APP.settingsKey));

          var savedData = new Object({});
          _this.exceptionKeys.forEach(function (item) {
            savedData[item] = settingsData[item];
          });
          localStorage.setItem(_deputeeOfficeConfigEnvironment['default'].APP.settingsKey, JSON.stringify(savedData));
        })();
      }
    },

    /**
     * Получение настроек сети.
     * @return {Object} объект настроек сети.
     */
    getNetworkSettings: function getNetworkSettings() {
      var settings = this.getSettings('network-setting', false);
      var owner = _ember['default'].getOwner(this);
      var networkSetting = owner.lookup('object:network-setting');

      if (settings) {
        networkSetting.setProperties(settings);

        return networkSetting;
      } else {
        this.putSettings('network-setting', new Date(), networkSetting.serialize());

        return networkSetting;
      }
    },

    /**
     * Получение адреса сервера
     * @return {string} адрес сервера
     */
    getHost: function getHost() {
      var settings = this.getNetworkSettings();

      return settings.get('Host');
    },
    /**
     * Добавление данных в localStorage
     * @param  {string} globalKey ключ для localStorage
     * @param  {string} key       ключ
     * @param  {string} expiry    срок валидности кэша
     * @param  {Object} data      данные для добавления в кэш
     * @return {undefined}
     */
    putInLocalStorage: function putInLocalStorage(globalKey, key, expiry, data) {
      var localStorageData = localStorage.getItem(globalKey);
      var allCache;
      if (localStorageData !== 'undefined') {
        allCache = JSON.parse(localStorageData);
      }

      var cacheObject = new Object({});
      cacheObject.expiry = expiry;
      cacheObject.data = data;
      if (!allCache) {
        allCache = new Object({});
      }

      allCache[key] = cacheObject;
      localStorage.setItem(globalKey, JSON.stringify(allCache));
    },

    /**
     * Получение данных из localStorage
     * @param  {string} globalKey       ключ для localStorage
     * @param  {string} key             ключ
     * @param  {bool} checkExpiration флаг проверки валидности кэша, true - проверять валидность, false - не проверять
     * @return {Object}
     */
    getFromLocalStorage: function getFromLocalStorage(globalKey, key, checkExpiration) {
      var localStorageData = localStorage.getItem(globalKey);
      if (localStorageData !== 'undefined') {
        var allCache = JSON.parse(localStorageData);
        if (allCache) {
          var cacheObj = allCache[key];
          if (cacheObj) {
            if (checkExpiration === undefined || checkExpiration == null || checkExpiration === true) {
              var expiryDate = new Date(cacheObj.expiry);
              if (expiryDate > new Date()) {
                return cacheObj.data;
              }
            } else {
              return cacheObj.data;
            }
          }
        }
      }
    },
    /**
     * Добавление данных в кэш настроек
     * @param  {string} key    ключ
     * @param  {string} expiry срок валидности кэша
     * @param  {string} data   данные для добавления в кэш
     * @return {undefined}
     */
    putSettings: function putSettings(key, expiry, data) {
      this.putInLocalStorage(_deputeeOfficeConfigEnvironment['default'].APP.settingsKey, key, expiry, data);
    },
    /**
     * Получение данных из кэша настроек
     * @param  {string} key             ключ
     * @param  {bool} checkExpiration флаг проверки валидности кэша, true - проверять валидность, false - не проверять
     * @return {Object}
     */
    getSettings: function getSettings(key, checkExpiration) {
      return this.getFromLocalStorage(_deputeeOfficeConfigEnvironment['default'].APP.settingsKey, key, checkExpiration);
    }
  });
});