define('deputee-office/routes/settings', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], {
    notificationService: _ember['default'].inject.service(),

    renderTemplate: function renderTemplate() {
      this._super();

      this.render('accept-button', {
        outlet: 'toolbar-right-outlet',
        controller: this.controllerFor('accept-button')
      });
    },
    deactivate: function functionName() {
      this.get('controller.model').forEach(function (item) {
        item.rollbackAttributes();
      });
    },
    actions: {
      accept: function accept() {
        this.get('controller.model').forEach(function (item) {
          if (item.changedAttributes().IsVisible) {
            item.save();
          }
        });
        window.history.back();
      },

      visibleChange: function visibleChange(model) {
        if (!this.get('currentuserservice.isGuest')) {
          model.set('IsVisible', !model.get('IsVisible'));
        }
      },
      clearHistory: function clearHistory() {
        var cacheService = this.get('cacheservice');
        cacheService.clearCache();
        this.store.unloadAll();
        this.store.init();
        this.store.adapterFor('aplication', false)['delete']();

        // Вернем информацию о последнем пользователе
        cacheService.putCache('userInfo', null, this.get('currentuserservice').get('user'));

        this.refresh();
        this.get('notificationService').alert('История загруженных файлов успешно очищена.', null, 'Внимание!');
      }
    },
    model: function model() {
      var result = this.store.findAll('service-setting', { useOnlineStore: true });
      return result;
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('controller', controller);
    }
  });
});