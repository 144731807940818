define('deputee-office/models/zs-passport/zs-passport-document', ['exports', 'ember', 'moment', 'ember-data', 'deputee-office/models/base-passport-document', 'ember-flexberry-data'], function (exports, _ember, _moment, _emberData, _deputeeOfficeModelsBasePassportDocument, _emberFlexberryData) {

  var Model = _deputeeOfficeModelsBasePassportDocument['default'].extend({
    Date: _emberData['default'].attr('string'),
    OmsuOffer: _emberData['default'].attr('number'),
    OmsuAccept: _emberData['default'].attr('boolean'),

    CanonicalAgendaName: _emberData['default'].attr('string'),
    CanonicalAgendaId: _emberData['default'].attr('string'),
    CanonicalQuestionName: _emberData['default'].attr('string'),
    CanonicalQuestionId: _emberData['default'].attr('string'),
    CanonicalDepartmentType: _emberData['default'].attr('string'),
    CanonicalDepartmentId: _emberData['default'].attr('string'),
    IsMP: _emberData['default'].attr('boolean'),
    IsOmsuAccept: _ember['default'].computed('property', function () {
      return this.get('OmsuAccept') ? 'Да' : 'Нет';
    }),
    ControlDate: _ember['default'].computed('Date', function () {
      if (this.get('Date')) {
        return (0, _moment['default'])('' + this.get('Date')).format('D MMMM YYYY года');
      }
    }),
    ShortControlDate: _ember['default'].computed('Date', function () {
      if (this.get('Date')) {
        return (0, _moment['default'])('' + this.get('Date')).format('D MMMM YYYY');
      }
    })
  });

  Model.defineProjection('zs-passport-document-full', 'zs-passport/zs-passport-document', {
    Type: _emberFlexberryData.Projection.attr('Type'),
    Title: _emberFlexberryData.Projection.attr('Title'),
    PassportId: _emberFlexberryData.Projection.attr('PassportId'),
    QuestionId: _emberFlexberryData.Projection.attr('QuestionId'),
    Date: _emberFlexberryData.Projection.attr('Date'),
    OrderNum: _emberFlexberryData.Projection.attr('OrderNum'),
    TabName: _emberFlexberryData.Projection.attr('TabName'),
    OmsuOffer: _emberFlexberryData.Projection.attr('OmsuOffer'),
    OmsuAccept: _emberFlexberryData.Projection.attr('OmsuAccept'),
    TypeName: _emberFlexberryData.Projection.attr('TypeName'),
    Files: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-file', 'Files', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      Url: _emberFlexberryData.Projection.attr('Url'),
      OrderNum: _emberFlexberryData.Projection.attr('OrderNum')
    })
  });

  exports['default'] = Model;
});