define('deputee-office/models/yp-passport/yp-amendment-voting-result', ['exports', 'deputee-office/models/omsu-passport/omsu-passport-voting-result', 'ember-flexberry-data', 'ember-data'], function (exports, _deputeeOfficeModelsOmsuPassportOmsuPassportVotingResult, _emberFlexberryData, _emberData) {

  var Model = _deputeeOfficeModelsOmsuPassportOmsuPassportVotingResult['default'].extend({
    CurrentUserVote: _emberData['default'].attr('string')
  });

  Model.defineProjection('yp-amendment-voting-result-full', 'yp-passport/yp-amendment-voting-result-result', {
    Name: _emberFlexberryData.Projection.attr('Name'),
    Title: _emberFlexberryData.Projection.attr('Title'),
    CurrentUserVote: _emberFlexberryData.Projection.attr('CurrentUserVote'),
    VoteForCount: _emberFlexberryData.Projection.attr('VoteForCount'),
    VoteAgainstCount: _emberFlexberryData.Projection.attr('VoteAgainstCount'),
    AbstainCount: _emberFlexberryData.Projection.attr('AbstainCount'),
    NotVoteCount: _emberFlexberryData.Projection.attr('NotVoteCount'),
    TerritoryId: _emberFlexberryData.Projection.attr('TerritoryId'),
    Type: _emberFlexberryData.Projection.attr('Type'),
    VotePersons: _emberFlexberryData.Projection.hasMany('omsu-passport/omsu-passport-voting-person', 'VotePersons', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      Url: _emberFlexberryData.Projection.attr('Url')
    })
  });

  exports['default'] = Model;
});