define('deputee-office/components/base-modal-dialog', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    needOkClose: true,
    classNameBindings: ['voteModal:voteModal', 'isBig:bigModal', 'isSmall:smallModal', 'passport:passportRelations', 'docHistory:docHistory', 'note:note', 'omsudec:omsudec', 'newsView:newsView', 'terraModal:terraModal', 'resetWidth'],
    isBig: false,
    voteModal: false,
    isSmall: false,
    passport: false,
    docHistory: false,
    note: false,
    omsudec: false,
    newsView: false,
    terraModal: false,
    closeContent: true,
    closeOnOk: true,
    defaultLayout: _ember['default'].computed(function () {
      var layoutName = this.get('layoutName') || 'modals/base-modal-dialog';
      return _ember['default'].getOwner(this).lookup('template:components/' + layoutName);
    }),
    modalContentHeight: _ember['default'].computed(function () {
      return _ember['default'].$(window).height() * 0.6 + 'px';
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.sendAction('didInsert');
    },

    actions: {
      customAction: function functionName(name) {
        for (var _len = arguments.length, params = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          params[_key - 1] = arguments[_key];
        }

        this.sendAction.apply(this, [name].concat(params));
      },
      ok: function ok() {
        if (this.get('closeOnOk')) {
          this.$('.modal').modal('hide');
          this.sendAction('ok');
        } else {
          this.sendAction('ok', this);
        }
      }
    },

    didRender: function didRender() {
      if (this.get('newsView')) {
        this.$('.scrollable-div').scrollTop(0);
        this.$('.scrollable-div').perfectScrollbar('update');
      }
    },

    show: (function () {
      this.$('.modal').modal().on('hidden.bs.modal', (function () {
        this.sendAction('close');
      }).bind(this)).on('shown.bs.modal', (function () {
        this.sendAction('didShown');
      }).bind(this));
      this.sendAction('initModel');
    }).on('didInsertElement'),
    removeSLayer: (function () {
      this.$('.modal').modal('hide');
    }).on('willDestroyElement')
  });
});