define('deputee-office/models/zs-passport/zs-passport-law-history', ['exports', 'ember-data', 'deputee-office/models/base', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Name: _emberData['default'].attr('string'),
    PassportId: _emberData['default'].attr('guid'),
    Date: _emberData['default'].attr('string'),
    DisplayedDate: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])('' + this.get('Date')).format('D MMMM YYYY г.');
    })
  });

  exports['default'] = Model;
});