define('deputee-office/models/base', ['exports', 'ember-flexberry-data', 'ember', 'ember-data'], function (exports, _emberFlexberryData, _ember, _emberData) {
  exports['default'] = _emberFlexberryData.Projection.Model.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var syncer = _ember['default'].getOwner(this).lookup('syncer:main');
      this.set('syncer', syncer);
    },

    EditTime: _emberData['default'].attr('date')
  });
});