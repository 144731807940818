define('deputee-office/routes/base-agenda-route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/territory-flag-name', 'deputee-office/mixins/group-by', 'deputee-office/config/environment'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsTerritoryFlagName, _deputeeOfficeMixinsGroupBy, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], _deputeeOfficeMixinsGroupBy['default'], {
    agendaDeepObservationService: _ember['default'].inject.service(),

    templateName: 'base-agenda-route',

    queryParams: {
      departmentType: {
        refreshModel: false,
        replace: true
      },
      departmentId: {
        refreshModel: false,
        replace: true
      },
      agendaId: {
        refreshModel: false,
        replace: true
      }
    },

    targetController: _ember['default'].computed('targetControllerName', function () {
      return this.controllerFor(this.get('targetControllerName'));
    }),

    errorFunction: function functionName(e) {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        this.get('targetController').set('error', true);
        this.get('targetController').set('displayedQuestions', false);
        console.error(e);
        this.get('targetController').set('loading', false);
      });
    },

    model: function model(params, transition) {

      this.set('curentRouteName', transition.targetName);
      this.set('params', params);

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var _this2 = this;

        this.get('targetController').set('loading', true);

        // В параметрах не задано подразделение и повестка - выводим окно выбора подразделения.
        if (!params.departmentId && !params.agendaId) {
          var _ret = (function () {
            var _this = _this2;
            var model = _this2.getDepartmentQuery();
            model.then(function (model) {
              var controller = _this.get('targetController');
              controller.set('error', false);
              controller.set('emptyFilterResult', false);
              model = model.toArray().sortDepartments();
              controller.set('departments', model);
              _this.send('selectDepartment', model.filter(function functionName(item) {
                if (!_this.get('withDepartment')) {
                  return true;
                }

                return item.get('Type');
              }).get('firstObject'));
              _this.get('targetController').set('loading', false);
              _ember['default'].run.scheduleOnce('afterRender', _this, function () {
                _ember['default'].$('#' + this.get('selectPopoverId')).webuiPopover('show');
              });
            })['catch'](function (e) {
              _this.errorFunction(e);
            });
            return {
              v: undefined
            };
          })();

          if (typeof _ret === 'object') return _ret.v;
        }

        // В параметрах задано подразделение - выводим вопросы первой повестки этого подразделения.
        if (params.departmentId && !params.agendaId) {
          this.goToDepartmentById(params.departmentId, transition);
          return;
        }

        this.goToAgendaById(params.agendaId, transition);
      });
    },

    deactivate: function deactivate() {
      this.get('targetController').set('loading', false);
    },

    renderTemplate: function renderTemplate() {
      this._super();

      if (this.get('curentRouteName').indexOf(this.get('passportLinkName')) < 0) {
        this.render('search-box', {
          outlet: 'toolbar-right-outlet',
          controller: this.controllerFor('base-agendas.base-agenda-search'),
          model: {
            searchText: this.get('searchText')
          }
        });
      }
    },

    setupController: function setupController() {
      var controller = this.get('targetController');
      controller.set('title', this.get('title'));
      controller.set('zsQuestion', this.get('zsQuestion'));
      controller.set('passportLinkName', this.get('passportLinkName'));
      controller.set('currentRouteName', this.get('routeName'));
      controller.set('isYp', this.get('isYp'));
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition) {
        this.set('searchText', null);
        this.get('targetController').send('clearData');
        controller.set('agendaId', undefined);
        controller.set('departmentId', undefined);
        controller.set('departmentType', undefined);
      }
    },

    goToDepartmentById: function goToDepartmentById(departmentId, transition) {
      var model = this.getDepartmentQuery();
      _ember['default'].run.schedule('afterRender', this, function () {
        this.get('targetController').set('loading', true);
        var _this = this;
        model.then(function (model) {
          var controller = _this.get('targetController');
          controller.set('departments', model);
          var grouped = _this.getGrouped(model, 'Type');
          _this.get('targetController').set('grouped', grouped);
          var dep = model.filter(function (department) {
            return department.get('id') === departmentId;
          })[0];
          transition.send('doUpdateAgendas', dep);
        })['catch'](function (e) {
          _this.errorFunction(e);
        });
      });
    },

    goToAgendaById: function goToAgendaById(agendaId, transition) {
      this.get('targetController').set('loading', true);
      var _this = this;
      var agendaRec = this.getAgendaQuery(agendaId);
      agendaRec.then(function (agenda) {
        if (agenda.get('length') < 1) {
          _this.set('targetController.currentAgenda', null);
          throw 'Повесток не найдено';
        }

        var departmentId = agenda.get('DepartmentId');
        var model = _this.getDepartmentQuery();
        model.then(function (model) {
          var controller = _this.get('targetController');
          controller.set('departments', model);
          var grouped = _this.getGrouped(model);
          _this.get('targetController').set('grouped', grouped);
          var dep = model.filter(function (department) {
            return department.get('id') === departmentId;
          })[0];
          transition.send('doUpdateAgendas', dep, agenda);
        })['catch'](function (e) {
          _this.errorFunction(e);
        });
      })['catch'](function (e) {
        _this.errorFunction(e);
      });
    },

    /**
     * Получение количества нескачанных файлов.
     *
     * @param  {Object} вопросы повестки.
     * @return {undefined}
     */
    __getUnreadFileCount: function __getUnreadFileCount(questions, agendaId) {
      var isConnected = this.get('networkstatus').isInternetConnection();
      var authHeader = this.get('currentuserservice').getBasicAuthHeader();
      var _this = this;

      if (isConnected && _this.unloadedFileCountUrl) {
        _ember['default'].run.schedule('destroy', this, function () {
          _ember['default'].$.ajax({
            url: _this.unloadedFileCountUrl(agendaId),
            headers: { Authorization: authHeader },
            type: 'GET',
            xhrPool: _this.get('routeName'),
            timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
          }).then(function (data) {
            questions.forEach(function (item, index) {
              _ember['default'].run.later(item.get('id'), function () {
                _ember['default'].run.schedule('destroy', this, function () {
                  if (data && data !== '') {
                    item.set('UnreadFileCount', data[item.id]);
                  } else {
                    item.set('UnreadFileCount', 0);
                  }
                });
              }, 350 * (index / 2));
            });
          });
        });
      }

      if (isConnected && _this.unreadCommentCountUrl) {
        _ember['default'].run.schedule('destroy', this, function () {
          _ember['default'].$.ajax({
            url: _this.unreadCommentCountUrl(questions),
            headers: { Authorization: authHeader },
            type: 'GET',
            xhrPool: _this.get('routeName'),
            timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
          }).then(function (data) {
            questions.forEach(function (item, index) {
              _ember['default'].run.later(item.get('id'), function () {
                _ember['default'].run.schedule('destroy', this, function () {
                  if (data && data !== '') {
                    item.set('UnreadCommentCount', data[item.id].newCommentCount);
                    item.set('AmendmentsExist', data[item.id].amendmentsExist);
                  } else {
                    item.set('UnreadCommentCount', 0);
                    item.set('AmendmentsExist', false);
                  }
                });
              }, 350 * (index / 2));
            });
          });
        });
      }
    },

    actions: {

      agendaMembers: function agendaMembers(members) {
        this.render('modals/agenda-members', {
          into: this.get('routeName'),
          outlet: 'modal',
          model: {
            members: members,
            dialogTitle: 'Участники заседания'
          }
        });
      },

      getPrintAgendaForm: function getPrintAgendaForm(id) {
        var settingsService = this.get('settingsservice');
        location.href = settingsService.getHost() + 'api/PrintFormAgendas?id=' + id;
      },

      goBack: function goBack() {
        if (history.length > 1 && history.back() !== undefined) {
          history.back();
        } else {
          this.replaceWith(this.routeName.split('.')[0], { queryParams: this.paramsFor });
        }
      },

      selectedDateModal: function functionName(dates, dateFormat, dateFieldName, selected, actionName) {
        this.render('modals/month-selector', {
          into: this.get('routeName'),
          outlet: 'modal',
          model: {
            dates: dates,
            dateFormat: dateFormat,
            dateFieldName: dateFieldName,
            selected: selected,
            actionName: actionName,
            title: 'Дата',
            target: this.get('targetController')
          }
        });
      },
      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: this.get('routeName')
        });
      },

      willTransition: function willTransition(transition) {
        if (transition.targetName.indexOf('passport') >= 0) {
          this.disconnectOutlet('toolbar-right-outlet');
        } else {
          this.render('search-box', {
            outlet: 'toolbar-right-outlet',
            controller: this.controllerFor('base-agendas.base-agenda-search'),
            model: {
              searchText: this.get('targetController').get('searchText')
            }
          });
        }

        return true;
      },

      didTransition: function didTransition() {
        this.get('targetController').send('saveSPos');
        return true;
      },

      search: function search(text) {
        this.get('targetController').send('search', text);
      },

      membersLoad: function membersLoad(currentAgenda) {
        if (this.getAgendaMembers && currentAgenda) {
          this.getAgendaMembers(currentAgenda.get('id')).then(function (members) {
            currentAgenda.set('AgendaMembers', members);
          });
        }
      },

      selectAgenda: function selectAgenda(agenda) {
        this.transitionTo({ queryParams: { agendaId: agenda.get('id') } });

        this.get('targetController').set('searchText', '');
        this.get('targetController').set('currentAgenda', agenda);
        this.get('agendaDeepObservationService').startObserveAgenda(agenda.get('id'));
        this.get('targetController').set('error', false);
        this.get('targetController').set('emptyFilterResult', false);
        this.send('updateQuestions');
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      },

      doUpdateAgendas: function doUpdateAgendas(department, agenda) {
        this.get('targetController').set('error', false);
        this.get('targetController').set('emptyFilterResult', false);
        this.get('targetController').set('loading', true);
        var _this = this;
        _this.get('targetController').set('department', department);
        var agendas = this.getAgendaByDepartmentQuery(department.get('id'));

        agendas.then(function (values) {
          if (values.get('length') < 1) {
            _this.set('targetController.currentAgenda', null);
            throw 'Повесток не найдено';
          }

          values = values.toArray().sortByProperty('Date', 'desc');

          _this.get('targetController').set('agendas', values);
          var currentAgenda = values.get('firstObject');
          if (agenda) {
            currentAgenda = agenda;
          }

          if (currentAgenda) {
            _this.get('targetController').set('currentAgenda', currentAgenda);
            _this.get('agendaDeepObservationService').startObserveAgenda(currentAgenda.get('id'));
            _this.get('targetController').set('error', false);
            _this.get('targetController').set('emptyFilterResult', false);
            _this.send('updateQuestions');
          } else {
            _this.get('targetController').set('questions', undefined);
            _this.get('targetController').set('displayedQuestions', undefined);
            _this.get('targetController').set('currentAgenda', undefined);
          }
        })['catch'](function (e) {
          _this.errorFunction(e);
        });
      },

      selectDepartment: function selectDepartment(department) {
        var controller = this.get('targetController');

        controller.set('errorMessage', null);
        controller.set('questions', null);
        controller.set('searchText', '');
        controller.set('displayedQuestions', null);

        this.transitionTo({ queryParams: { departmentId: department.get('id'), agendaId: undefined } });
        this.refresh();
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      },

      /**
       * Обновление списка вопросов при смене повестки.
       *
       * @return {undefined}
       */
      updateQuestions: function updateQuestions() {
        var controller = this.get('targetController');
        var device = this.get('devicejs');
        controller.set('loading', true);

        this.send('membersLoad', controller.get('currentAgenda'));

        var agendaId = controller.get('currentAgenda').get('id');
        var territoryId = this.get('territoryService').getTerritoryId();
        var _this = this;

        controller.set('errorMessage', null);
        controller.set('questions', null);
        controller.set('displayedQuestions', null);

        this.getQuestionsQuery(agendaId, territoryId).then(function (questions) {
          if (questions.get('length') < 1) {
            _this.errorFunction('Получен пустой список вопросов');
            return;
          }

          _this.__getUnreadFileCount(questions, agendaId);
          questions.toArray().sortByProperty('OrderNum', 'asc');
          controller.set('questions', questions);
          if (device.isPhone()) {
            controller.send('filterQuestions', controller.get('filterModel.filter'));
          }

          _ember['default'].$('.searchInput').trigger('clearSearchText');
        }, function () {
          controller.set('questions', null);
          controller.set('displayedQuestions', null);
          controller.set('loading', false);
        })['catch'](function (e) {
          _this.errorFunction(e);
        });
      },

      showFilter: function showFilter() {
        var controller = this.get('targetController');
        var filter = controller.get('filterModel.filter');
        controller.set('selectedReading', filter.reading);
        controller.set('selectedCommittee', filter.committee);
        controller.set('selectedStatus', filter.status);
        this.render('modals/agenda-filter-modal', {
          into: this.get('routeName'),
          outlet: 'modal',
          controller: controller
        });
      },

      applyFilter: function applyFilter() {
        var controller = this.get('targetController');
        var filter = controller.get('filterModel.filter');
        filter.reading = controller.get('selectedReading');
        filter.committee = controller.get('selectedCommittee');
        filter.status = controller.get('selectedStatus');
        controller.send('filterQuestions', filter);
      },

      selectExtendedFile: function selectExtendedFile(idAgenda) {
        this.render('modals/file-select-extension', {
          into: this.get('routeName'),
          outlet: 'modal',
          model: {
            idAgenda: idAgenda,
            dialogTitle: 'Выберте формат файла'
          }
        });
      }
    }
  });
});