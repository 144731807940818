define('deputee-office/models/calendar-event', ['exports', 'ember-data', 'deputee-office/models/base'], function (exports, _emberData, _deputeeOfficeModelsBase) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Description: _emberData['default'].attr('string'),
    Location: _emberData['default'].attr('string'),
    Summary: _emberData['default'].attr('string'),
    DtStart: _emberData['default'].attr('date'),
    DtEnd: _emberData['default'].attr('date'),
    Subsystem: _emberData['default'].attr('string')
  });

  exports['default'] = Model;
});