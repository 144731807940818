define('deputee-office/controllers/contacts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    customSorting: ['IsZspk:desc', 'Name'],
    sortedModels: _ember['default'].computed.sort('model', 'customSorting'),

    nameSorting: ['Name'],
    orderNumSorting: ['OrderNum'],
    sortedContactData: _ember['default'].computed.sort('contactData', 'orderNumSorting'),
    sortedFinded: _ember['default'].computed.sort('finded', 'nameSorting')
  });
});