define('deputee-office/routes/contactsphonegroups', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsServicesMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    modelLoadingWrapper: {
      value: false
    },

    modelLoading: _ember['default'].computed.alias('modelLoadingWrapper.value'),
    model: function model() {
      var result = this.store.findAll('phonedepartment');
      return result;
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('controller', controller);
      controller.set('modelLoading', this.get('modelLoadingWrapper'));
    },

    /**
     * Отмена выполняемых в рамках роута ajax-запросов.
     *
     * @return {undefined}
     */
    _abortRequests: function _abortRequests() {
      if (_ember['default'].$.xhrPool != null) {
        var routeName = this.get('routeName');
        _ember['default'].$.xhrPool.abort(routeName);
      }

      this.get('store').cancelQuery('phonedepartment');
    },

    /**
     * Отмена выполняемых ajax-запросов при выходе из роута.
     *
     * @return {undefined}
     */
    deactivate: function deactivate() {
      if (_ember['default'].$.xhrPool != null) {
        var routeName = this.get('routeName');
        _ember['default'].$.xhrPool.abort(routeName);
      }
    },

    actions: {
      sendVisibility: function sendVisibility(model) {
        model.save();
      },

      setAll: function setAll(visible) {
        var _this2 = this;

        if (!this.get('loading')) {
          (function () {
            _this2.set('loading', true);
            var _this = _this2;
            var settingsService = _this2.get('settingsservice');
            _ember['default'].run.schedule('sync', _this2, function () {
              _ember['default'].$.ajax({
                url: settingsService.getHost() + 'api/setdepartmentvisibilityall?visible=' + visible,
                type: 'POST',
                timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs,
                xhrPool: _this.get('routeName')
              }).then(function () {
                _this.store.findAll('phonedepartment')['finally'](function () {
                  _this.set('loading', false);
                });
              });
            });
          })();
        }
      },

      back: function back() {
        window.history.back();
      }
    }
  });
});