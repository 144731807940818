define('deputee-office/models/omsu-event', ['exports', 'ember-data', 'deputee-office/models/base', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Title: _emberData['default'].attr('string'),
    Description: _emberData['default'].attr('string'),
    EventType: _emberData['default'].attr('string'),
    AllDay: _emberData['default'].attr('boolean'),
    Color: _emberData['default'].attr('string'),
    EventUrl: _emberData['default'].attr('string'),
    Start: _emberData['default'].attr('date'),
    End: _emberData['default'].attr('date'),
    IsMy: _emberData['default'].attr('boolean'),
    EventObjectUID: _emberData['default'].attr('string'),
    Location: _emberData['default'].attr('string'),
    Date: _emberData['default'].attr('string'),
    TerritoryId: _emberData['default'].attr('guid'),
    LocationURI: _ember['default'].computed('Location', function () {
      return encodeURIComponent(this.get('Location'));
    }),
    ActionRoute: _ember['default'].computed('EventType', function () {
      var type = this.get('EventType');
      if (type === 'OMSUAgenda') {
        this.set('EventObjectUID', { queryParams: { agendaId: this.get('EventObjectUID') } });
        return 'omsu-agenda-assembly';
      }

      if (type === 'POMOPKAgenda') {
        this.set('EventObjectUID', { queryParams: { agendaId: this.get('EventObjectUID') } });
        return 'omsu-agenda-pomopk';
      }

      if (type === 'CommitteeAgenda') {
        this.set('EventObjectUID', { queryParams: { agendaId: this.get('EventObjectUID') } });
        return 'omsu-agenda-commitees';
      }

      return '';
    })
  });

  exports['default'] = Model;
});