define('deputee-office/controllers/modals/omsu-schedule-filter', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    selectedTypeObs: _ember['default'].observer('model.eventType', function () {
      this.set('selectedType', this.get('model.eventType') || '');
    }),

    selectedMyObs: _ember['default'].observer('model.showMyEvents', function () {
      this.set('selectedMy', this.get('model.showMyEvents') || '');
    }),

    actions: {
      filterEvents: function filterEvents() {
        this.get('target').send('filterEvents', this.get('selectedType'), this.get('selectedMy'));
      },

      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      }
    }
  });
});