define('deputee-office/components/group-selector-popover', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    didReceiveAttrs: function functionName() {
      this._super.apply(this, arguments);
    },

    actions: {
      selectItem: function selectItem(item) {
        this.sendAction('onSelect', item);
        _ember['default'].$('#' + this.get('popoverId')).webuiPopover('hide');
        _ember['default'].$('.searchInput').trigger('clearSearchText');
        _ember['default'].$('.filterContainer').trigger('clearFilter');
      }
    }
  });
});