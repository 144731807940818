define('deputee-office/controllers/comment-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    notificationService: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    actions: {
      save: function save() {
        var _this = this;
        var questionComment = this.get('model');
        questionComment.save()['catch'](function (e) {
          questionComment.rollbackAttributes();
          if (e) {
            _this.get('notificationService').alert(e.errors[0].detail, null, 'Ошибка! ' + e.errors[0].title);
          } else {
            _this.get('notificationService').alert('Неизвестная ошибка добавления', null, 'Ошибка!');
          }
        });
      },
      closeModal: function closeModal() {
        this.get('model').rollbackAttributes();
        this.get('target').send('removeModal');
      }
    }
  });
});