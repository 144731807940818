define('deputee-office/routes/base-agenda-route/passport/stage-details', ['exports', 'ember', 'deputee-office/mixins/group-by', 'deputee-office/mixins/right-menu-collapser'], function (exports, _ember, _deputeeOfficeMixinsGroupBy, _deputeeOfficeMixinsRightMenuCollapser) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsGroupBy['default'], _deputeeOfficeMixinsRightMenuCollapser['default'], {
    templateName: 'base-agenda-route.stage-details',
    controllerName: 'base-agenda-route.passport.stage-details',
    territoryService: _ember['default'].inject.service(),
    controlStageName: 'Контроль исполнения',

    queryParams: {
      stageDetailsState: {
        refreshModel: false,
        replace: false
      },
      stageDetailsStateParam: {
        refreshModel: false,
        replace: false
      }
    },

    errorMessage: function errorMessage(e) {
      var controller = this.get('controller');
      controller.set('model', false);
      controller.set('errorMessage', true);
      console.error(e);
    },

    updateModel: _ember['default'].observer('params.current_tab', 'parentController.model', function () {
      var _this = this;
      var controller = this.get('controller');
      var tab = decodeURI(this.get('params.current_tab'));
      var passport = this.get('parentController.model');

      if (controller && tab && passport) {
        controller.set('selectedStage', tab);
        controller.set('controlStageName', _this.get('controlStageName'));
        controller.set('passport', passport);
        controller.set('model', null);
        controller.set('errorMessage', null);
        if (passport.get('AddInfo.Name') === tab) {
          var questiontDocuments = [];
          var passportDocuments = [];

          if (passport.get('id')) {
            questiontDocuments = this.getQuestionDocumentQuery(passport.get('id'));
          }

          if (passport.get('PassportId')) {
            passportDocuments = this.getDocumentQuery(passport.get('PassportId'), tab);
          }

          var promises = {
            questionDocuments: questiontDocuments,
            passportDocuments: passportDocuments
          };

          _ember['default'].RSVP.hash(promises).then(function (model) {
            model = model.passportDocuments.toArray().pushObjects(model.questionDocuments.toArray());
            var result = _this.sortAndGroup(model);
            if (result && result.length) {
              controller.set('model', result);
              controller.set('modelList', model);
            } else {
              _this.errorMessage();
            }
          })['catch'](function (reason) {
            _this.errorMessage(reason);
          });
        } else {
          if (passport.get('PassportId')) {
            this.getDocumentQuery(passport.get('PassportId'), tab).then(function (model) {
              var result;
              if (tab === _this.get('controlStageName')) {
                result = _this.sortAndGroup(model, true);
              } else {
                result = _this.sortAndGroup(model, false);
              }

              if (result && result.length) {
                controller.set('model', result);
                controller.set('modelList', model);
              } else {
                _this.errorMessage();
              }
            })['catch'](function (reason) {
              _this.errorMessage(reason);
            });
          }
        }
      }
    }),

    sortAndGroup: function functionName(model, notFilterEmptyDoc) {
      var _this = this;
      var sortedFiltredModel = model;

      if (!notFilterEmptyDoc) {
        sortedFiltredModel = sortedFiltredModel.filter(function (document) {
          return document.get('Files.length') || document.get('VoteResult') || document.get('DecisionType') || document.get('Corrections');
        });
      }

      sortedFiltredModel = sortedFiltredModel.sortBy('Type').sortBy('OrderNum');
      var groupModel = _this.getGrouped(sortedFiltredModel, 'Type', 'type').map(function (group) {
        group.items = _this.getGrouped(group.items.sortBy('TypeName').sortBy('OrderNum'), 'TypeName', 'typename').map(function functionName(group) {
          group.items = _this.getGrouped(group.items.sortBy('Title'), 'Title', 'title');
          group.items.forEach(function (item) {
            item.items.forEach(function (doc) {
              doc.set('Files', doc.get('Files').sortBy('Name').sortBy('OrderNum'));
            });
          });
          return group;
        });
        return group;
      });
      return groupModel;
    },

    model: function model(params) {
      this.set('params', params);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var owner = _ember['default'].getOwner(this);
      var parentController = owner.lookup('controller:' + this.get('parentControllerName'));
      this.set('parentController', parentController);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('stageDetailsState', null);
        controller.set('stageDetailsStateParam', null);
      }
    },

    getRouteName: function getRouteName(document) {
      var routeName;
      var modelName = document.constructor.modelName;

      if (modelName.indexOf('omsu') >= 0) {
        routeName = 'omsu-agenda-commitees';
      } else {
        if (document.get('IsMP')) {
          if (document.get('CanonicalDepartmentType') !== 'mp') {
            routeName = 'yp-agenda-comissions';
          } else {
            routeName = 'yp-agenda-parlament';
          }
        } else {
          if (document.get('CanonicalDepartmentType') === 'zs') {
            routeName = 'zs-agenda';
          } else {
            routeName = 'zs-agenda-departments';
          }
        }
      }

      return routeName;
    },

    actions: {
      renderRigthMenu: function functionName(target) {
        target.route = this;
        return true;
      },

      goToState: function goToState(stageDetailsState, param) {
        var queryParams = {
          stageDetailsState: stageDetailsState
        };
        if (param) {
          queryParams.stageDetailsStateParam = param;
        }

        this.transitionTo(this.routeName, {
          queryParams: queryParams
        });
      },

      documentInfo: function documentInfo(document) {
        var model = this.get('controller.model.details') ? this.get('controller.model.details') : _ember['default'].Object.create({});

        model.set('document', document);
        if (this.getDocumentWorkGroupQuery) {
          this.getDocumentWorkGroupQuery(document.get('id')).then(function (workGroup) {
            model.set('workGroup', workGroup);
          });
        }

        if (this.getVotingResult && document.get('QuestionId')) {
          this.getVotingResult(document.get('QuestionId')).then(function (result) {
            model.set('votingResult', result.toArray().sort(function functionName(a) {
              if (a.get('Name') === 'Основное') {
                return -1;
              } else {
                return 1;
              }
            }));
          });
        }

        if (this.get('devicejs').isPhone()) {
          this.get('controller').set('model.details', model);
        } else {
          this.rightMenu();
          this.render('document-info', {
            controller: 'passport-info',
            into: 'application',
            outlet: 'right-menu',
            model: model
          });
        }
      },
      workGroupModal: function workGroupModal(workGroup) {
        this.render('modals/work-group', {
          into: 'application',
          outlet: 'modal',
          model: workGroup
        });
      },

      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },
      omsuResult: function omsuResult(model) {
        this.render('modals/omsu-decision', {
          into: 'application',
          outlet: 'modal',
          model: model
        });
      },
      omsuCorrections: function omsuCorrections(model) {
        this.render('modals/omsu-corrections', {
          into: 'application',
          controller: 'modals/document-decisions',
          outlet: 'modal',
          model: model
        });
      },
      passportRelationships: function passportRelationships(model) {
        model.set('route', this);
        return true;
      },
      agendaMembers: function agendaMembers(members) {
        members.set('route', this);
        return true;
      },
      votingResult: function votingResult(model) {
        model.set('route', this);
        return true;
      },
      documentDecisions: function documentDecisions(model) {
        model.set('route', this);
        return true;
      },
      documentHistory: function documentHistory(model) {
        model.set('route', this);
        return true;
      },
      questionHistory: function questionHistory(model) {
        model.set('route', this);
        return true;
      },
      legalActPublication: function legalActPublication(model) {
        model.set('route', this);
        return true;
      },
      getShowStageDetails: function getShowStageDetails(model) {
        _ember['default'].$('.scrollable-div').trigger('updatescroll');
        this.replaceWith(this.get('parentControllerName') + '.stage-details', encodeURI(model));
      },
      goToAgenda: function goToAgenda(document) {
        var routeName = this.getRouteName(document);

        var queryParams = { agendaId: document.get('CanonicalAgendaId') };

        if (routeName.indexOf('zs-agenda') >= 0) {
          if (document.get('CanonicalDepartmentType') === 'zs') {
            routeName = 'zs-agenda';
          } else {
            routeName = 'zs-agenda-departments';
            queryParams.departmentType = document.get('CanonicalDepartmentType');
            queryParams.departmentId = document.get('CanonicalDepartmentId');
          }
        }

        var transition = this.transitionTo('' + routeName, { queryParams: queryParams });
        var controller = _ember['default'].getOwner(this).lookup('controller:' + routeName + '.index');

        transition.then(function () {
          controller.get('target.router').refresh();
          controller.send('clearData');
        });
      },

      goToQuestion: function goToQuestion(document) {
        var routeName = this.getRouteName(document);

        var queryParams = {};

        if (routeName.indexOf('zs-agenda') >= 0) {
          if (document.get('CanonicalDepartmentType') === 'zs') {
            routeName = 'zs-agenda';
          } else {
            routeName = 'zs-agenda-departments';
            queryParams.departmentType = document.get('CanonicalDepartmentType');
            queryParams.departmentId = document.get('CanonicalDepartmentId');
          }
        }

        this.transitionTo(routeName + '.passport', document.get('CanonicalQuestionId'), { queryParams: queryParams });
      }
    },
    activate: function activate() {
      _ember['default'].$('.header').addClass('displayNone');
    },

    deactivate: function deactivate() {
      _ember['default'].$('.header').removeClass('displayNone');
      this.rightMenu(true);
    }
  });
});