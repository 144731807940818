define('deputee-office/mixins/vote', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    notificationService: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    ShowResult: _ember['default'].computed('model.ShowVoteForm', function () {
      return !this.get('model.ShowVoteForm');
    }),

    CurrentVote: _ember['default'].computed('model', function () {
      return this.get('model');
    }),

    DisableButtons: _ember['default'].computed('CurrentVote.hasDirtyAttributes', 'CurrentVote.isSaving', function () {
      return this.get('CurrentVote') && (!this.get('CurrentVote.hasDirtyAttributes') && this.get('CurrentVote.CurrentUserVote') || this.get('CurrentVote.isSaving'));
    }),

    actions: {
      showPersons: function showPersons() {
        var model = this.get('model');
        model.set('showPersons', !model.get('showPersons'));
      },
      showDiagram: function functionName() {
        this.set('isShowDiagram', true);
      },
      closeModal: function closeModal(vote) {
        if (!this.get('isDestroyed') && !this.get('isDestroying')) {
          this.set('isShowDiagram', false);

          if (vote) {
            this.send('clear', vote);
          } else {
            this.send('clear', this.get('CurrentVote'));
          }

          this.set('model.ShowVoteForm', null);
          if (!this.get('devicejs').isPhone()) {
            this.get('target').send('removeModal');
          }
        }
      },
      cancel: function cancel(vote) {
        this.send('closeModal', vote);
      },
      clear: function clear(vote) {
        if (vote && vote.get('hasDirtyAttributes')) {
          vote.rollbackAttributes();
        }
      },

      sendVote: function functionName() {
        var _this = this;

        if (!this.get('DisableButtons')) {
          this.get('CurrentVote').save().then(function () {
            _this.send('closeModal');
            if (_this.get('devicejs').isPhone() && _this.get('passportStateParam')) {
              window.history.back();
            }
          })['catch'](function (e) {
            if (e.errors) {
              _this.send('errorHandlerForVote', e.errors[0].detail);
            } else {
              _this.send('errorHandlerForVote', e);
            }
          });
        }
      },

      errorHandlerForVote: function functionName(message) {
        var notificationService = this.get('notificationService');
        var messageForUser = '';
        switch (message) {
          case 'user error':
            messageForUser = 'Вы не допущены до голосования!';
            break;
          case 'agenda status error':
            messageForUser = 'На данном этапе повестки голосование не доступно!';
            break;
          case 'vote type error':
            messageForUser = 'Для этой повестки установлено очное голосование!';
            break;
          case 'already voted':
          case 'pro':
          case 'contra':
          case 'abstained':
            messageForUser = 'Вы уже проголосовали по этому вопросу!';
            break;
          case 'stage finished':
            messageForUser = 'Этап заочного голосования завершен!';
            break;
          default:
            messageForUser = 'Произошла неизвестная ошибка!';
        }
        notificationService.alert(messageForUser, null, 'Ошибка!');
      },

      chooseVote: function functionName(vote) {
        if (!this.get('DisableButtons')) {
          this.set('CurrentVote.CurrentUserVote', vote);
        }
      }
    }
  });
});