define('deputee-office/components/simple-section-view', ['exports', 'deputee-office/components/base-view', 'ember'], function (exports, _deputeeOfficeComponentsBaseView, _ember) {
  exports['default'] = _deputeeOfficeComponentsBaseView['default'].extend({
    didRender: function didRender() {
      this._super(arguments);
      var isRoot = this.get('isRoot');
      if (isRoot) {
        _ember['default'].$('.nameImgLaw img').attr('src', 'data:image;base64,' + this.get('FlagBase64'));
        _ember['default'].$('#loading_spinner').hide();
      }
    },
    init: function init() {
      this._super(arguments);
      var isRoot = this.get('isRoot');
      if (isRoot) {
        _ember['default'].$('#loading_spinner').show();
      }
    }
  });
});