define('deputee-office/routes/legal-acts/passport/index', ['exports', 'deputee-office/routes/base-agenda-route/passport/index'], function (exports, _deputeeOfficeRoutesBaseAgendaRoutePassportIndex) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoutePassportIndex['default'].extend({
    parentControllerName: 'legal-acts.passport',
    detailsRouteName: 'legal-acts.passport.stage-details',
    notesRouteName: 'legal-acts.passport.notes',
    templateName: 'base-agenda-route.header-omsu',
    withoutNavButtons: true,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('withoutNavButtons', this.get('withoutNavButtons'));
    },

    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.set('withoutNavButtons', undefined);
    }
  });
});