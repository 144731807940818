define('deputee-office/helpers/func-helper', ['exports', 'ember'], function (exports, _ember) {
  exports.string = string;

  function _toArray(arr) { return Array.isArray(arr) ? arr : Array.from(arr); }

  function string(_ref) {
    var _ref2 = _toArray(_ref);

    var str = _ref2[0];
    var funName = _ref2[1];

    var params = _ref2.slice(2);

    return str[funName].apply(str, params);
  }

  exports['default'] = _ember['default'].Helper.helper(string);
});