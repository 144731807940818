define('deputee-office/models/zs-passport', ['exports', 'deputee-office/models/base-passport', 'ember', 'ember-data'], function (exports, _deputeeOfficeModelsBasePassport, _ember, _emberData) {

  var Model = _deputeeOfficeModelsBasePassport['default'].extend({
    Decision: _emberData['default'].attr('string'),
    DecisionProjectFiles: _emberData['default'].hasMany('zs-passport/zs-passport-file', { async: false }),
    PassportHeaderPromiseObserver: _ember['default'].observer('PassportHeaderPromise', function () {
      var _this = this;
      this.set('PassportHeaderState', 'loading');
      var promise = this.get('PassportHeaderPromise');
      promise.then(function (data) {
        _this.set('PassportHeaderState', 'loaded');
        return data;
      })['catch'](function (reason) {
        _this.set('PassportHeaderState', 'loaded');
        return reason;
      });
    }),
    EditTimePassport: _emberData['default'].attr('string'),
    EditTimePPZ: _emberData['default'].attr('string'),
    NamePPZ: _emberData['default'].attr('string')
  });

  exports['default'] = Model;
});