define('deputee-office/components/deputy-blog', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['deputy-blog'],
    name: _ember['default'].computed('item', function () {
      return this.get('item').get('Author').split(' ')[0];
    }),
    surname: _ember['default'].computed('item', function () {
      return this.get('item').get('Author').split(' ')[1];
    }),
    actions: {
      doOpenUrl: function doOpenUrl(url) {
        if (!window.device) {
          return;
        }

        var platform = window.device.platform.toLowerCase();
        if (platform === 'ios' || platform === 'android') {
          window.cordova.InAppBrowser.open(url, '_blank', 'location=yes,closebuttoncaption=Закрыть');
        } else {
          window.cordova.InAppBrowser.open(url, '_system', 'location=yes,closebuttoncaption=Закрыть');
        }
      }
    }
  });
});