define('deputee-office/routes/work-plan/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      if (this.get('devicejs').isPhone()) {
        this.render('search-box', {
          into: this.routeName,
          outlet: 'search-box',
          controller: this.controllerFor('work-plan'),
          model: { searchText: this.get('controller.searchText') }
        });
      }
    }
  });
});