define('deputee-office/controllers/download-file', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    store: _ember['default'].inject.service(),
    fileService: _ember['default'].inject.service(),

    queryParams: ['subsystemKey', 'docType', 'subsystemFileId', 'fileDocumentId'],
    subsystemKey: null,
    docType: null,
    subsystemFileId: null,
    fileDocumentId: null,
    notPdf: false,

    isNotIos: _ember['default'].computed(function () {
      if (!window.device) {
        return;
      }

      return window.device.platform.toLowerCase() !== 'ios';
    }),

    getFile: _ember['default'].observer('model', function () {
      var subsystemKey = this.get('subsystemKey');
      var docType = this.get('docType');
      var subsystemFileId = this.get('subsystemFileId');
      var fileDocumentId = this.get('fileDocumentId');

      var fileInfo = {
        SubsystemKey: subsystemKey,
        DocType: parseInt(docType),
        SubsystemFileId: subsystemFileId,
        fileDocumentId: fileDocumentId
      };

      this.set('fileInfo', fileInfo);
    }),

    actions: {
      showVersions: function showVersions() {
        this.get('route').showVersionsModal(this.get('fileInfo'));
      },

      closeModal: function closeModal() {
        this.get('route').send('removeModal');
      },
      fileDownloadComplete: function fileDownloadComplete() {
        this.set('fileLoaded', true);
        if (this.get('route.actions.fileDownloadComplete')) {
          this.get('route').send('fileDownloadComplete');
        }
      }
    }
  });
});