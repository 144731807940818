define('deputee-office/adapters/service-setting', ['exports', 'deputee-office/adapters/application', 'deputee-office/config/environment', 'ember-simple-auth/mixins/data-adapter-mixin', 'deputee-office/mixins/BuildURLMixinCustom', 'moment', 'ember'], function (exports, _deputeeOfficeAdaptersApplication, _deputeeOfficeConfigEnvironment, _emberSimpleAuthMixinsDataAdapterMixin, _deputeeOfficeMixinsBuildURLMixinCustom, _moment, _ember) {
  exports['default'] = _deputeeOfficeAdaptersApplication['default'].extend(_emberSimpleAuthMixinsDataAdapterMixin['default'], _deputeeOfficeMixinsBuildURLMixinCustom['default'], {
    settingsService: _ember['default'].inject.service('settingsservice'),
    store: _ember['default'].inject.service('store'),
    findAll: function findAll(store, type, sinceToken, snapshotRecordArray) {
      var _this = this;
      var settingsservice = this.get('settingsService');
      var key = type.modelName;

      var settingsData = settingsservice.getSettings(key, false);

      var settingsDataOnline = this._super(store, type, sinceToken, snapshotRecordArray).then(function (data) {
        if (settingsData) {
          var editedSettings = settingsData.serviceSettings.filter(function (x) {
            var settingFromSever = data.serviceSettings.findBy('id', x.id);
            if (settingFromSever) {
              return (0, _moment['default'])(x.EditTime).isAfter((0, _moment['default'])(settingFromSever.EditTime));
            }
          });

          editedSettings.forEach(function (item) {
            var url = _this.buildURL(type.modelName, item.id, null, 'updateRecord');
            data.serviceSettings[data.serviceSettings.indexOf(data.serviceSettings.findBy('id', item.id))] = item;
            _ember['default'].run.schedule('destroy', _this, function () {
              this.ajax(url, 'PUT', { data: { serviceSetting: item }, timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs });
            });
          });
        }

        var expireDate = new Date(new Date().getTime() + _deputeeOfficeConfigEnvironment['default'].APP.settingsExpirationSecondsTimeout * 1000);
        settingsservice.putSettings(key, expireDate, data);
        _this.get('store').pushPayload(data);
        return data;
      }, null);

      if (settingsData) {
        return settingsData;
      } else {
        return settingsDataOnline;
      }
    },
    updateRecord: function updateRecord(store, type, snapshot) {
      var key = type.modelName;
      var settingsservice = this.get('settingsService');
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var settingsData = settingsservice.getSettings(key);
      if (settingsData) {
        var index = settingsData.serviceSettings.map(function (el) {
          return el.id;
        }).indexOf(snapshot.id);
        var expireDate = new Date(new Date().getTime() + _deputeeOfficeConfigEnvironment['default'].APP.settingsExpirationSecondsTimeout * 1000);
        settingsData.serviceSettings[index].IsVisible = data.serviceSetting.IsVisible;
        settingsData.serviceSettings[index].EditTime = new Date();
        settingsservice.putSettings(key, expireDate, settingsData);
      }

      var url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
      return this.ajax(url, 'PUT', { data: data, timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs });
    }
  });
});