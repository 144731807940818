define('deputee-office/authenticators/custom', ['exports', 'ember', 'ember-simple-auth/authenticators/base', 'deputee-office/mixins/services-mixin', 'deputee-office/config/environment', 'npm:js-base64'], function (exports, _ember, _emberSimpleAuthAuthenticatorsBase, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeConfigEnvironment, _npmJsBase64) {
  exports['default'] = _emberSimpleAuthAuthenticatorsBase['default'].extend(_deputeeOfficeMixinsServicesMixin['default'], {
    restore: function restore(data) {
      var currentuser = this.get('currentuserservice');
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (!_ember['default'].isEmpty(data.login) && !_ember['default'].isEmpty(data.password)) {
          _ember['default'].$.ajaxSetup({
            headers: {
              Authorization: 'Basic ' + _npmJsBase64['default'].Base64.encode(data.login + ':' + data.password)
            },
            type: 'GET',
            timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs,
            contentType: 'application/json'
          });
          if (!currentuser.get('isDestroyed')) {
            currentuser.set('user', data);
          }

          resolve(data);
        } else {
          reject();
        }
      });
    },

    authenticate: function authenticate(credentials) {
      var _this = this;
      var settingsService = this.get('settingsservice');
      var cacheService = this.get('cacheservice');
      var url = settingsService.getHost();
      var header = {
        Authorization: 'Basic ' + _npmJsBase64['default'].Base64.encode(credentials.identification + ':' + credentials.password)
      };
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var storage = cacheService.getCache('userInfo', false);
        if (!_this.get('networkstatus').isInternetConnection() && storage && credentials.identification === storage.login && credentials.password === storage.password) {
          var user = {
            login: storage.login,
            password: storage.password,
            displayName: storage.displayName,
            department: storage.department,
            photoUrl: storage.photoUrl,
            party: storage.Party,
            territoryId: storage.TerritoryId,
            territoryName: storage.Territory,
            subsystemId: storage.SubsystemId
          };
          _this.get('currentuserservice').set('user', user);
          resolve(user);
        } else {
          _ember['default'].$.ajax({
            url: url + 'api/user',
            headers: header,
            type: 'GET',
            contentType: 'application/json',
            timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
          }).then(function (response) {
            if (response.PrimaryKey) {
              _ember['default'].$.ajaxSetup({
                headers: header,
                type: 'GET',
                contentType: 'application/json',
                timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
              });
              var user = {
                login: credentials.identification,
                password: credentials.password,
                displayName: response.DisplayName,
                department: response.Department,
                photoUrl: response.PhotoUrl,
                party: response.Party,
                territoryId: response.TerritoryId,
                territoryName: response.Territory,
                subsystemId: response.SubsystemId
              };
              _this.get('currentuserservice').set('user', user);
              _this.get('territoryService').setDefaultTerritory();
              resolve(user);
            } else {
              reject('Логин \'' + credentials.identification + '\' не найден');
            }
          }, function () {
            reject('Не удалось войти');
          });
        }
      });
    }
  });
});