define('deputee-office/mixins/BuildURLMixinCustom', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {

  /**
   * Данный миксин берет адрес не из поля адаптера,
   *  а запраживает у settingsservice, необходим для корректной работы настройки сервера.
   */
  exports['default'] = _ember['default'].Mixin.create(_emberData['default'].BuildURLMixin, {
    settingsService: _ember['default'].inject.service('settingsservice'),
    _buildURL: function _buildURL(modelName, id) {
      var url = [];
      var host = this.get('settingsService').getHost();
      var prefix = this.urlPrefix();
      var path;

      if (modelName) {
        path = this.pathForType(modelName);
        if (path) {
          url.push(path);
        }
      }

      if (id) {
        url.push(encodeURIComponent(id));
      }

      if (prefix) {
        url.unshift(prefix);
      }

      url = url.join('/');
      if (!host && url && url.charAt(0) !== '/') {
        url = '/' + url;
      }

      return url;
    },
    urlPrefix: function urlPrefix(path, parentURL) {
      var host = this.get('settingsService').getHost();
      var namespace = _ember['default'].get(this, 'namespace');
      var url = [];

      if (path) {
        // Protocol relative url
        //jscs:disable disallowEmptyBlocks
        if (/^\/\//.test(path)) {
          // Do nothing, the full host is already included. This branch
          // avoids the absolute path logic and the relative path logic.

          // Absolute path
        } else if (path.charAt(0) === '/') {
            //jscs:enable disallowEmptyBlocks
            if (host) {
              path = path.slice(1);
              url.push(host);
            }

            // Relative path
          } else if (!/^http(s)?:\/\//.test(path)) {
              url.push(parentURL);
            }
      } else {
        if (host) {
          url.push(host);
        }

        if (namespace) {
          url.push(namespace);
        }
      }

      if (path) {
        url.push(path);
      }

      return url.join('/');
    }

  });
});