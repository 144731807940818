define('deputee-office/routes/zs-agenda-departments/passport/index', ['exports', 'ember', 'deputee-office/routes/base-agenda-route/passport/index'], function (exports, _ember, _deputeeOfficeRoutesBaseAgendaRoutePassportIndex) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoutePassportIndex['default'].extend({
    parentControllerName: 'zs-agenda-departments.passport',
    detailsRouteName: 'zs-agenda-departments.passport.stage-details',
    notesRouteName: 'zs-agenda-departments.passport.notes',
    baseControllerName: 'zs-agenda-departments.index',

    questionExist: _ember['default'].computed('controller.passport.id', function () {
      return false;
    })
  });
});