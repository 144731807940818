define('deputee-office/controllers/modals/materialtype-modal', ['exports', 'ember', 'deputee-office/mixins/loader/material'], function (exports, _ember, _deputeeOfficeMixinsLoaderMaterial) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsLoaderMaterial['default'], {
    territoryService: _ember['default'].inject.service(),
    currentuserservice: _ember['default'].inject.service(),

    actions: {
      initModel: _ember['default'].on('init', function () {
        var _this = this;

        try {
          (function () {
            _this._super();

            var transition = _this.get('model').transition;
            _this.loadMaterials().then(function () {
              _this.set('redirect', false);
              _this.set('transition', transition);
            });
          })();
        } catch (e) {
          console.error(e);
          this.get('target').send('removeModal');
        }
      }),

      closeModal: function closeModal() {
        this.set('materialTypes', null);
        if (!this.get('redirect')) {
          this.get('target').send('removeModal');
        }
      },

      selectMaterialType: function selectMaterialType(materialType) {
        var transition = this.get('transition');
        this.set('redirect', true);
        this.get('target').send('selectMaterialType', materialType, transition);
      }
    }
  });
});