define('deputee-office/routes/my-documents', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/territory-flag-name'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsTerritoryFlagName) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], {
    notificationService: _ember['default'].inject.service(),
    networkstatus: _ember['default'].inject.service(),
    parentControllerName: 'laws.passport',
    queryParams: {
      selectedType: {
        refreshModel: false,
        replace: true
      }
    },

    model: function model() {
      return {};
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
    },

    openModal: function openModal(model) {
      this.render('modals/note', {
        into: 'application',
        outlet: 'modal',
        model: model
      });
    },

    actions: {
      saveModel: function saveModel(model) {
        var _this = this;
        model.save()['catch'](function (e) {
          if (model.get('isNew')) {
            var controller = _this.get('controller');
            controller.get('personalDocuments').removeObject(model);

            model.deleteRecord();
          }

          model.rollbackAttributes();
          _this.get('notificationService').alert('При выполнении операции произошла ошибка.', null, 'Внимание!');
          console.error('Save Error: ' + e);
        });
      },

      'delete': function _delete(model) {
        if (!this.get('networkstatus').isInternetConnection()) {
          if (model.get('Type') === 'note') {
            this.get('notificationService').alert('Для удаления заметки необходимо подключение к сети интернет.', null, 'Внимание!');
          } else {
            this.get('notificationService').alert('Для удаления файла необходимо подключение к сети интернет.', null, 'Внимание!');
          }

          return;
        }

        var _this = this;

        var confirmCallback = function functionName(num) {
          if (num === 1) {
            model.destroyRecord().then(function functionName() {
              var controller = _this.get('controller');
              controller.get('personalDocuments').removeObject(model);
              controller.send('refreshDocuments');
            })['catch'](function (e) {
              _this.get('notificationService').alert('При выполнении операции произошла ошибка.', null, 'Внимание!');
              console.error('Delete Error: ' + e);
            });
          }
        };

        _this.get('notificationService').confirm('Удалить документ?', confirmCallback, 'Подтверждение удаления.', ['Да', 'Нет']);
      },

      edit: function edit(model) {
        if (model.get('Type') === 'note') {
          this.openModal(model);
        } else {
          window.location.href = model.get('Url');
        }
      },

      removeNoteModal: function removeNoteModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      }
    }
  });
});