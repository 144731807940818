define('deputee-office/routes/k-s-materials', ['exports', 'ember', 'deputee-office/mixins/territory-flag-name', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsTerritoryFlagName, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsTerritoryFlagName['default'], _deputeeOfficeMixinsServicesMixin['default'], {
    model: function model() {
      var modelArray = _ember['default'].A([]);
      var _this = this;

      function getFilledModel(records) {
        modelArray.pushObjects(records.toArray());

        return modelArray;
      }

      function getRecords(useOnlineStore) {
        return _this.store.findAll('material-ksm', { useOnlineStore: useOnlineStore }).then(function (records) {
          _this.set('modelFromOnline', useOnlineStore);

          if (!useOnlineStore && records.get('length') === 0) {
            return getRecords(true);
          }

          return getFilledModel(records);
        })['catch'](function functionName() {
          if (!useOnlineStore) {
            return getRecords(true);
          }
        });
      }

      return getRecords(false);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (!this.get('modelFromOnline')) {
        this.store.findAll('material-ksm', { useOnlineStore: true }).then(function (records) {
          controller.get('model').clear();
          controller.get('model').pushObjects(records.toArray());
        });
      }
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (transition.targetName.indexOf('download-file') === -1) {
        controller.set('searchText', undefined);
        controller.set('selectedYear', undefined);
      }
    },

    renderTemplate: function renderTemplate() {
      this.render();
      if (this.get('devicejs').isPhone()) {
        this.render('search-box', {
          into: this.get('routeName'),
          outlet: 'search-box',
          controller: this.controllerFor('k-s-materials')
        });
        this.controllerFor('k-s-materials').set('model.searchText', this.get('controller.searchText'));
      } else {
        this.render('search-box', {
          outlet: 'toolbar-right-outlet',
          controller: this.controllerFor('k-s-materials')
        });
      }
    },
    activate: function activate() {
      this.setFlagAndNameForZS();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
    },

    actions: {
      selectedDateModal: function functionName(dates, selected, actionName) {
        this.render('modals/simple-selector', {
          into: this.get('routeName'),
          outlet: 'modal',
          model: {
            objects: dates,
            selected: selected,
            actionName: actionName,
            title: 'Выберите год',
            target: this.get('controller')
          }
        });
      },
      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: this.get('routeName')
        });
      }
    }
  });
});