define('deputee-office/routes/download-file', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return new Object({});
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('route', this);
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('filesForSharing', null);
        controller.set('fileLoaded', false);
        controller.set('fileInfo', null);
        controller.set('Version', null);
      }
    },

    showVersionsModal: function showVersionsModal(fileInfo) {
      var _this = this;
      this.get('store').query('file', fileInfo).then(function (fileRecords) {
        fileRecords = fileRecords.sortBy('FileVersion', 'UserFileVersion');
        _this.render('modals/file-version-modal', {
          into: 'application',
          outlet: 'modal',
          model: fileRecords
        });
      });
    },

    actions: {
      showVersion: function showVersion(version) {
        this.controller.set('Version', version);
        this.send('removeModal');
      },

      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      },
      hideToolBar: function hideToolBar() {
        _ember['default'].$('passport-toolbar').addClass('displayNone');
      }
    },
    deactivate: function deactivate() {
      _ember['default'].run.schedule('afterRender', this, function () {
        _ember['default'].$('passport-toolbar').removeClass('displayNone');
      });
    }
  });
});