define('deputee-office/serializers/zs-passport/zs-passport-personal-document', ['exports', 'deputee-office/serializers/application', 'ember-data/serializers/embedded-records-mixin', 'ember'], function (exports, _deputeeOfficeSerializersApplication, _emberDataSerializersEmbeddedRecordsMixin, _ember) {
  exports['default'] = _deputeeOfficeSerializersApplication['default'].extend(_emberDataSerializersEmbeddedRecordsMixin['default'], {
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload) {
      payload[primaryModelClass.modelName] = payload.zsPassportPersonalDocuments;
      delete payload.zsPassportPersonalDocuments;
      return this._super.apply(this, arguments);
    },

    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return _ember['default'].String.camelize(modelName.split('/').get('lastObject'));
    }
  });
});