define('deputee-office/mixins/members-info', ['exports', 'moment', 'ember'], function (exports, _moment, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    morningLess: _ember['default'].computed('Periods', function () {
      return this.get('Periods').isEvery('Morning', false);
    }),

    /**
     * Определение участников ЗС.
     */
    isZsMembers: _ember['default'].computed('Members.[]', function () {
      return this.get('Members').isAny('constructor.modelName', 'zs-passport/zs-passport-agenda-member');
    }),

    /**
     * Сгруппиванные по подразделениям.
     */
    GroupedMembers: _ember['default'].computed('Members.[]', function () {
      var _this = this;

      var members = this.get('Members');
      if (members) {
        return this.getGrouped(members.map(function (x) {
          if (x.get('Role') === 'Приглашенный') {
            x.set('DepartmentName', 'Приглашенные');
          }

          return x;
        }), 'DepartmentName', 'department').sort(function (a, b) {
          if (a.department === 'Приглашенные') {
            return 1;
          } else {
            return a.department.localeCompare(b.department);
          }
        }).map(function (x) {
          if (_this.get('isZsMembers')) {
            x.items.set('periods', members.get('periods'));
            x.periods = _this.groupedByPeriods(x.items);
          } else {
            var notAttended = x.items.filter(function (m) {
              return !m.get('IsAttended');
            });
            var attended = x.items.filter(function (m) {
              return m.get('IsAttended');
            });
            _ember['default'].set(x, 'MembersAttended', attended);
            _ember['default'].set(x, 'MembersNotAttended', notAttended);
          }

          return x;
        });
      }
    }),

    /**
     * Сгруппированные по периодам.
     */
    groupedByPeriods: function groupedByPeriods(members) {
      return this.get('groupedPeriods').map(function (x) {
        return new Object({
          date: x.date,
          items: x.items.map(function (period) {
            var periodWrapper = _ember['default'].Object.create({
              period: period
            });
            var notAttended = members.filter(function (m) {
              return m.get('Missing').contains(period);
            });
            var attended = members.filter(function (m) {
              return !m.get('Missing').contains(period);
            });
            periodWrapper.set('MembersAttended', attended);
            periodWrapper.set('MembersNotAttended', notAttended);
            return periodWrapper;
          })
        });
      });
    },

    /**
     * Группировка периодов
     */
    groupedPeriods: _ember['default'].computed('Periods', function () {
      return this.getGrouped(this.get('Periods'), 'FormatedDate', 'date').sort(function (a, b) {
        return (0, _moment['default'])(a.date, 'DD.MM.YYYY').isAfter((0, _moment['default'])(b.date, 'DD.MM.YYYY')) ? 1 : -1;
      }).map(function (x) {
        x.items.sort(function (a) {
          if (a.get('Morning')) {
            return -1;
          } else {
            return 1;
          }
        });
        return x;
      });
    })
  });
});