define('deputee-office/components/yp-comments-component', ['exports', 'ember', 'deputee-office/mixins/group-by', 'deputee-office/mixins/yp-comments', 'deputee-office/mixins/loader/yp-comments-reload'], function (exports, _ember, _deputeeOfficeMixinsGroupBy, _deputeeOfficeMixinsYpComments, _deputeeOfficeMixinsLoaderYpCommentsReload) {
  var _slice = Array.prototype.slice;
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsGroupBy['default'], _deputeeOfficeMixinsYpComments['default'], _deputeeOfficeMixinsLoaderYpCommentsReload['default'], {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (!this.get('model')) {
        window.history.back();
      } else {
        this.send('didInsert');
        this.set('isShowDiagram', true);
        this.updateScroll(true);
        this.reloadComments();
      }
    },

    commentsName: 'model',

    didRender: function didRender() {
      this.set('commentListHeight', this.$(window).outerHeight() - this.$('.toolBar').outerHeight() - this.$('.sendArea').outerHeight() - 15 + 'px');
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].run.cancel(this.get('runLater'));
    },

    voteResultIdChanged: _ember['default'].observer('voteResultId', function () {
      if (!this.get('voteResultId')) {
        this.send('cancel');
      }
    }),

    actions: {
      back: function functionName() {
        this.send.apply(this, ['cancel'].concat(_slice.call(arguments)));
        window.history.back();
      },
      votingResult: function votingResult(_votingResult) {
        var result = this._super.apply(this, arguments);
        if (result) {
          this.get('target').send('goToState', 'comments', _votingResult.get('id'));
        }
      }
    }

  });
});