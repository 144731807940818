define('deputee-office/controllers/modals/agenda-members', ['exports', 'ember', 'deputee-office/mixins/group-by', 'deputee-office/mixins/members-info'], function (exports, _ember, _deputeeOfficeMixinsGroupBy, _deputeeOfficeMixinsMembersInfo) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsGroupBy['default'], _deputeeOfficeMixinsMembersInfo['default'], {

    partialName: _ember['default'].computed('isZsMembers', 'morningLess', function () {
      if (!this.get('isZsMembers')) {
        return 'modals/agenda-members/simple';
      } else {
        return 'modals/agenda-members/with-periods';
      }
    }),

    Periods: _ember['default'].computed('model.members.periods', function () {
      return this.get('model.members.periods');
    }),

    Members: _ember['default'].computed('model.members.[]', function () {
      return this.get('model.members');
    }),

    /**
     * Определение количества периодов.
     */
    periodsCount: _ember['default'].computed('Periods', function () {
      return this.get('Periods.length');
    }),

    /**
     * Определение ширины столбца ФИО.
     */
    nameWidth: _ember['default'].computed('periodsCount', function () {
      return this.get('periodsCount') > 0 ? this.get('periodsCount') > 5 ? 2 : 4 : 6;
    }),

    /**
     * Определение ширины столбца Роль.
     */
    roleWidth: _ember['default'].computed('periodsCount', function () {
      return this.get('periodsCount') > 0 ? this.get('periodsCount') > 5 ? 1 : 2 : 6;
    }),

    /**
     * Определение ширины периода.
     */
    periodsGroupWidth: _ember['default'].computed('groupedPeriods', function () {
      return this.get('groupedPeriods.length') > 0 ? Math.floor(6 / this.get('groupedPeriods.length')) || 1 : 0;
    }),

    containerWidth: _ember['default'].computed('periodsCount', function () {
      var periodsCount = this.get('periodsCount');
      var width = 0;
      if (periodsCount > 5) {
        width = 1200;
      }

      return width + 'px';
    }),

    periodsGroupMinWidth: _ember['default'].computed('periodsCount', function () {
      var periodsCount = this.get('periodsCount');
      var width = 0;
      if (periodsCount > 5) {
        width = 900 / this.get('periodsCount');
      }

      return width + 'px';
    }),

    actions: {
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      },
      didShown: function functionName() {
        _ember['default'].$('.scrollable-div').perfectScrollbar('update');
      }
    }
  });
});