define('deputee-office/controllers/modals/omsuagenda-modal', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {

  var statuses = {
    active: 'Активные',
    archive: 'Архив'
  };

  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    store: _ember['default'].inject.service(),

    selectedStatusChanged: _ember['default'].observer('selectedStatus', function () {

      var selectedStatus = this.get('selectedStatus');
      var filteredAgendas = this.get('model').model.filter(function (agenda) {

        var status = agenda.get('Status');

        // Условие фильтрации по состоянию (статусу) повестки. Фильтрация из двух пунктов - 'Архив' и 'Активные'.
        // Активными считаются все повестки, у которых состояние != 'Архив'.
        var statusCondition = !status || status === statuses.archive && selectedStatus === statuses.archive || selectedStatus !== statuses.archive && status !== statuses.archive;

        return statusCondition;
      });

      this.set('displayedAgendas', filteredAgendas);
    }),

    actions: {
      initModel: _ember['default'].on('init', function () {
        this._super();
        this.set('currentAgenda', this.get('model').controller.get('currentAgenda'));

        var agendas = this.get('model').model;

        this.set('displayedAgendas', agendas);
        this.set('statuses', statuses);

        var selectedStatus = statuses.active;

        this.set('selectedStatus', selectedStatus);
      }),

      save: function save() {
        var currentAgenda = this.get('currentAgenda');
        this.get('model').controller.set('currentAgenda', currentAgenda);
        this.get('target').send('updateQuestions');
      },
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      },
      selectAgenda: function selectAgenda(agenda) {
        this.set('currentAgenda', agenda);
      }
    }
  });
});