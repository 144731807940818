define('deputee-office/routes/yp-agenda-comissions', ['exports', 'ember', 'deputee-office/routes/base-agenda-route'], function (exports, _ember, _deputeeOfficeRoutesBaseAgendaRoute) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoute['default'].extend({
    targetControllerName: 'yp-agenda-comissions.index',
    passportLinkName: 'yp-agenda-comissions.passport',
    searchText: null,
    withDepartment: true,
    selectPopoverId: 'selectPopoverId',
    isYp: true,

    getDepartmentQuery: function getDepartmentQuery() {
      var model = this.store.findAll('yp-department');
      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        model.then(function functionName(departments) {
          var comission = departments.filterBy('Type', 'Комиссия');
          resolve(comission);
        })['catch'](function (reason) {
          reject(reason);
        });
      });

      return promise;
    },

    unreadCommentCountUrl: function unreadCommentCountUrl(questions) {
      if (this.get('targetController.currentAgenda.Status') === 'Идет заседание' || this.get('targetController.currentAgenda.Status') === 'Формирование повестки') {
        return this.get('settingsservice').getHost() + '/api/ypquestions/unreadCommentCount/?passportId=' + questions.mapBy('id').join('&passportId=');
      }
    },

    getAgendaQuery: function getAgendaQuery(agendaId) {
      var agendaRec = this.store.queryRecord('yp-agenda', {
        id: agendaId
      });
      return agendaRec;
    },

    getAgendaByDepartmentQuery: function getAgendaByDepartmentQuery(departmentId) {
      var agendaRec = this.store.query('yp-agenda', {
        DepartmentId: departmentId
      });
      return agendaRec;
    },

    getQuestionsQuery: function getQuestionsQuery(agendaId) {
      var model = this.store.query('yp-question', {
        AgendaId: agendaId
      });
      return model;
    },

    activate: function activate() {
      this.setFlagAndNameForMP();
    },
    deactivate: function deactivate() {
      this._super();
      this.setDefaultFlag();
    },

    actions: {
      showTimer: function showTimer(agenda) {
        this.render('modals/timer-mp', {
          into: this.routeName,
          outlet: 'timer-mp',
          model: agenda
        });
      }
    }
  });
});