define('deputee-office/models/base-passport-document', ['exports', 'deputee-office/models/base', 'ember-data'], function (exports, _deputeeOfficeModelsBase, _emberData) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Type: _emberData['default'].attr('string'),
    Title: _emberData['default'].attr('string'),
    PassportId: _emberData['default'].attr('guid'),
    QuestionId: _emberData['default'].attr('guid'),
    OrderNum: _emberData['default'].attr('number'),
    TabName: _emberData['default'].attr('string'),
    TypeName: _emberData['default'].attr('string'),
    Date: _emberData['default'].attr('date'),
    NeedDetailButton: _emberData['default'].attr('boolean'),

    Files: _emberData['default'].hasMany('zs-passport/zs-passport-file', { async: false })
  });

  exports['default'] = Model;
});