define('deputee-office/services/current-question-service', ['exports', 'ember', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    model: null,
    currentInterval: null,
    init: function init() {
      this._super.apply(this, arguments);
      var _this = this;
      console.log('service CQ started');
      var check = function check() {
        _this.get('store').query('current-question', {}).then(function (cQuestions) {
          console.log('something found on server');
          _this.set('model', cQuestions);
        })['catch'](function () {
          console.log('nothing recieved');
          _this.set('model', null);
        });
      };

      check();
      _this.set('currentInterval', setInterval(check, _deputeeOfficeConfigEnvironment['default'].APP.currentQuestionsReloadInterval));
    },

    willDestroy: function willDestroy() {
      var _this = this;
      clearInterval(_this.get('currentInterval'));
    }
  });
});