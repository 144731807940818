define('deputee-office/services/agenda-deep-observation-service', ['exports', 'ember', 'deputee-office/config/environment', 'moment'], function (exports, _ember, _deputeeOfficeConfigEnvironment, _moment) {
  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {
    offlineGlobals: _ember['default'].inject.service(),
    settingsservice: _ember['default'].inject.service(),
    currentuserservice: _ember['default'].inject.service(),
    cacheservice: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    observedAgendas: _ember['default'].A(),

    _questionMetaEntity: _ember['default'].Object.extend({
      id: null,
      isDownloadComplete: false
    }),

    _observedAgendaEntity: _ember['default'].Object.extend({
      agendaId: null,
      runLater: null,
      lastUpdate: null,
      updateMeta: _ember['default'].A()
    }),

    _getAgendaObserver: function _getAgendaObserver(agendaId) {
      return this.get('observedAgendas').findBy('agendaId', agendaId);
    },

    _isAgendaObserver: function _isAgendaObserver(agendaId) {
      return !!this._getAgendaObserver(agendaId);
    },

    startObserveAgenda: function startObserveAgenda(agendaId) {
      var _this2 = this;

      if (agendaId && !this._isAgendaObserver(agendaId)) {
        var agendaObserver;

        (function () {
          agendaObserver = _this2.get('_observedAgendaEntity').create({
            agendaId: agendaId
          });

          var observedAgendas = _this2.get('observedAgendas');
          observedAgendas.clear();
          observedAgendas.pushObject(agendaObserver);
          var SETTINGSSERVICE = _this2.get('settingsservice');
          var CURRENTSERVICE = _this2.get('currentuserservice');
          var _this = _this2;

          _ember['default'].$.ajax({
            url: SETTINGSSERVICE.getHost() + '/api/zslastPpzEditTime/' + agendaId,
            headers: { Authorization: CURRENTSERVICE.getBasicAuthHeader() },
            type: 'GET',
            async: 'false'
          }).then(function (data) {
            var lastupdateObj = _this.get('store').peekRecord('last-update', agendaId);
            if (lastupdateObj == null) {
              lastupdateObj = _this.get('store').createRecord('last-update', {
                id: data.IdAgenda,
                IsFinish: false,
                AgendaID: data.IdAgenda });
              lastupdateObj.save();

              if (data.Passports) {
                data.Passports.forEach(function (itemPassport) {
                  var idpasportEdit = itemPassport.id;
                  var passportEditTime = _this.get('store').createRecord('last-edit-time-passport', {
                    id: idpasportEdit,
                    ObjectId: itemPassport.id });
                  lastupdateObj.get('Passports').pushObject(passportEditTime);
                  passportEditTime.save().then(function () {
                    lastupdateObj.save();
                  });
                });
              }

              if (data.Ppz) {
                data.Ppz.forEach(function (itemDataPPZ) {
                  var ppzId = itemDataPPZ.id;
                  var ppzEditTime = _this.get('store').createRecord('last-edit-time-ppz', {
                    id: ppzId,
                    ObjectId: itemDataPPZ.id });
                  lastupdateObj.get('Ppz').pushObject(ppzEditTime);
                  ppzEditTime.save().then(function () {
                    lastupdateObj.save();
                  });
                });
              }
            }

            _ember['default'].Logger.debug(agendaId + ' start observe');
            _this2._agendaDeepReload(agendaId);
          });
        })();
      }
    },

    stopObserveAgenda: function stopObserveAgenda(agendaId) {
      var agendaObserver = this._getAgendaObserver(agendaId);
      if (agendaObserver) {
        if (agendaObserver.get('runLater')) {
          _ember['default'].run.cancel(agendaObserver.get('runLater'));
        }

        _ember['default'].Logger.debug(agendaId + ' stop observe');
        this.get('observedAgendas').removeObject(agendaObserver);
      }
    },

    _agendaDeepReload: function _agendaDeepReload(agendaId) {
      var _this3 = this;

      var agendaObserver = this._getAgendaObserver(agendaId);
      if (agendaObserver) {
        this.agendaDeepReload(agendaId, agendaObserver, true).then(function (updateTime) {

          var lastUpdate = _this3.get('store').peekRecord('last-update', agendaId);
          if (lastUpdate === null) {
            lastUpdate = _this3.get('store').createRecord('last-update', { id: agendaObserver.agendaId, LastUpdate: updateTime, IsFinish: true });
            lastUpdate.save();
          } else {
            lastUpdate.set('LastUpdate', updateTime);
            lastUpdate.set('IsFinish', true);
            lastUpdate.save();
          }

          return updateTime;
        })['finally'](function () {
          var agendaRunLater = _ember['default'].run.later(_this3, function () {
            this._agendaDeepReload(agendaId);
          }, _deputeeOfficeConfigEnvironment['default'].APP.agendaReloadInterval);
          if (agendaObserver.get('runLater')) {
            _ember['default'].run.cancel(agendaObserver.get('runLater'));
          }

          agendaObserver.set('runLater', agendaRunLater);
        });
      }
    },

    agendaDeepReloadOnce: function agendaDeepReloadOnce(agendaId) {
      var observedAgendaStub = this.get('_observedAgendaEntity').create({
        agendaId: agendaId
      });
      this.agendaDeepReload(agendaId, observedAgendaStub, true);
      return observedAgendaStub.get('updateMeta');
    },

    agendaDeepReload: function agendaDeepReload(agendaId, agendaObserver, force) {
      var _this4 = this;

      var _this = this;
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (_this4.get('offlineGlobals.isOnline')) {
          var settingsService = _this4.get('settingsservice');
          var currentUserService = _this4.get('currentuserservice');
          _ember['default'].$.ajax({
            url: settingsService.getHost() + '/api/zslastPpzEditTime/' + agendaId,
            headers: { Authorization: currentUserService.getBasicAuthHeader() },
            type: 'GET'
          }).then(function (data) {
            _ember['default'].Logger.debug(agendaId + ' serverUpdated: ' + data + ' localUpdated: ' + agendaObserver.get('lastUpdate'));

            var lastUpdate = _this.get('store').peekRecord('last-update', agendaObserver.agendaId);

            if ((data.LastPpzEditTime === lastUpdate.get('LastUpdate.LastPpzEditTime') || lastUpdate.get('IsFinish') === false) && lastUpdate.get('LastUpdate.LastPpzEditTime') !== undefined) {
              _ember['default'].Logger.debug(agendaId + ' update don\'t needed');
              resolve(data);
            } else {
              if (agendaId !== lastUpdate.get('id')) {
                resolve(data);
              }

              _this4.getQuestionsQuery(agendaId).then(function (questionsInfo) {
                agendaObserver.get('updateMeta').clear();

                if (questionsInfo.modifiedOrNewQuestions.get('length')) {
                  _this4.trigger('ppzUpdate', questionsInfo.modifiedOrNewQuestions.mapBy('id'));
                }

                _this4.trigger('agendaUpdate', agendaId);

                questionsInfo.questionsOnline.forEach(function (x) {
                  agendaObserver.get('updateMeta').pushObject(_this4.get('_questionMetaEntity').create({
                    id: x.get('id'),
                    isDownloadComplete: !questionsInfo.modifiedOrNewQuestions.findBy('id', x.get('id')) && !force
                  }));
                });

                _this4.load(_this4.loadQuestions(agendaObserver.get('updateMeta'), agendaId)).then(function () {
                  _ember['default'].Logger.debug(agendaId + ' update complete');
                  resolve(data);
                  var lastUpdate = _this.get('store').peekRecord('last-update', agendaObserver.agendaId);
                  lastUpdate.get('Passports').forEach(function (itemPassport) {
                    _this.setEditTimeObjects(data, itemPassport, 'last-edit-time-passport', _this);
                  });
                  lastUpdate.get('Ppz').forEach(function (itemPPZ) {
                    _this.setEditTimeObjects(data, itemPPZ, 'last-edit-time-ppz', _this);
                  });
                })['catch'](function (e) {
                  reject(e);
                });
              })['catch'](function (e) {
                reject(e);
              });
            }
          }, function (e) {
            reject(e);
          });
        } else {
          _ember['default'].Logger.debug('application is offline');
          reject();
        }
      }, 'agendaDeepReload ' + agendaId);
    },

    setEditTimeObjects: function setEditTimeObjects(data, itemPassport, model, _this) {
      var objectId = itemPassport.get('ObjectId');
      var editTime = itemPassport.get('EditTime');
      data.Passports.forEach(function (pass) {
        if (pass.id === objectId && (pass.EditTime > editTime || pass.EditTime)) {
          var lastEditTimePassport = _this.get('store').peekRecord(model, objectId);
          lastEditTimePassport.set('EditTime', pass.EditTime);
          lastEditTimePassport.save();
        }
      });
    },

    getQuestionsQuery: function getQuestionsQuery(agendaId) {
      var _this5 = this;

      return this.get('store').query('zsquestion', {
        AgendaId: agendaId,
        useOnlineStore: false
      }).then(function (questionsOffline) {
        return _this5.get('store').query('zsquestion', {
          AgendaId: agendaId,
          useOnlineStore: true
        }).then(function (questionsOnline) {
          var removedQuestions = questionsOffline.filter(function (x) {
            return !questionsOnline.findBy('id', x.get('id'));
          });
          var modifiedOrNewQuestions = questionsOnline.filter(function (x) {
            var q = questionsOffline.findBy('id', x.get('id'));
            if (q) {
              return !(0, _moment['default'])(q.get('EditTime')).isSame(x.get('EditTime'));
            } else {
              return true;
            }
          });

          removedQuestions.forEach(function (x) {
            var request = window.indexedDB.open('ember-flexberry-data');
            request.onerror = function (event) {
              _ember['default'].Logger.error('DB error:', event.target.errorCode);
            };

            request.onsuccess = function (event) {
              var db = event.target.result;
              db.transaction(['zsquestion'], 'readwrite').objectStore('zsquestion')['delete'](x.get('id'));
              _ember['default'].Logger.debug('removedQuestion: ' + x.get('id'), x);
            };
          });

          _ember['default'].Logger.debug('removedQuestions:', removedQuestions);
          _ember['default'].Logger.debug('modifiedOrNewQuestions:', modifiedOrNewQuestions);
          return {
            questionsOnline: questionsOnline,
            modifiedOrNewQuestions: modifiedOrNewQuestions
          };
        });
      });
    },

    load: function execute(generator, yieldValue, callback) {
      var next = generator.next(yieldValue);
      if (!next.done) {
        return next.value.then(function (result) {
          return execute(generator, result, callback);
        }, function (err) {
          return generator['throw'](err);
        });
      }

      return _ember['default'].RSVP.Promise.resolve();
    },

    loadQuestions: regeneratorRuntime.mark(function loadQuestions(meta, agendaId) {
      var complete, i, qMeta;
      return regeneratorRuntime.wrap(function loadQuestions$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            complete = function complete(id) {
              var qMeta = meta.findBy('id', id);
              if (qMeta) {
                qMeta.set('isDownloadComplete', true);
              }
            };

            i = 0;

          case 2:
            if (!(i < meta.get('length'))) {
              context$1$0.next = 10;
              break;
            }

            qMeta = meta.objectAt(i);

            if (qMeta.get('isDownloadComplete')) {
              context$1$0.next = 7;
              break;
            }

            context$1$0.next = 7;
            return this.loadQuestion(qMeta.get('id'), agendaId).then(complete)['catch'](complete);

          case 7:
            i++;
            context$1$0.next = 2;
            break;

          case 10:
          case 'end':
            return context$1$0.stop();
        }
      }, loadQuestions, this);
    }),

    loadQuestion: function loadQuestion(id, agendaId) {
      var _this6 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        return _this6.getPassportQuery(id, agendaId).then(function (passport) {
          var promises = [];

          if (passport.get('PassportId')) {

            var updateDatetimePassport = _this6.get('store').peekRecord('last-edit-time-passport', passport.get('PassportId'));
            if (passport.get('EditTimePassport') > updateDatetimePassport.get('EditTime') || updateDatetimePassport.get('EditTime')) {
              promises.push(_this6.loadPassport(passport));
            }
          }

          if (passport.get('AddInfo')) {
            var updateDatetimePPZ = _this6.get('store').peekRecord('last-edit-time-ppz', id);
            if (passport.get('EditTimePPZ') > updateDatetimePPZ.get('EditTime') || updateDatetimePPZ.get('EditTime') === undefined) {
              promises.push(_this6.loadQuestionDocuments(id));
            }
          }

          _ember['default'].RSVP.all(promises, 'AgendaPreload: loadQuestion all promises').then(function () {
            resolve(id);
          });
        })['catch'](function (e) {
          _ember['default'].Logger.debug('Вопрос ' + id + ' не загружен', e);
          resolve(id);
        });
      }, 'AgendaPreload: loadQuestion ' + id);
    },

    getPassportQuery: function getPassportQuery(id, agendaId) {
      return this.get('store').queryRecord('zs-passport', {
        id: id,
        agendaId: agendaId
      });
    },

    PassportHeaderQuery: function PassportHeaderQuery(passport) {
      return this.get('store').queryRecord('zs-passport/zs-passport-header', {
        id: passport.get('PassportId'),
        projection: 'zs-passport-header-full'
      });
    },

    loadPassport: function loadPassport(passport) {
      var _this7 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this7.PassportHeaderQuery(passport).then(function (header) {
          _this7.loadPassportDocuments(header).then(function () {
            return resolve();
          });
        })['catch'](function () {
          return resolve();
        });
      }, 'AgendaPreload: loadPassport ' + passport.get('id'));
    },

    loadPassportDocuments: function loadPassportDocuments(header) {
      var _this8 = this;

      var updateDatetimePassport = this.get('store').peekRecord('last-edit-time-passport', header.get('id'));
      if (header.get('EditTime') > updateDatetimePassport.get('EditTime') || !updateDatetimePassport.get('EditTime')) {
        return new _ember['default'].RSVP.Promise(function (resolve) {
          var tabs = header.get('Tabs');
          var promises = [];
          tabs.forEach(function (tab) {
            return promises.push(_this8.loadTabDocuments(header.get('id'), tab.Name));
          });
          _ember['default'].RSVP.all(promises, 'AgendaPreload: loadPassportDocuments all promises').then(function () {
            return resolve();
          });
        }, 'AgendaPreload: loadPassportDocuments');
      } else {
        return new _ember['default'].RSVP.Promise(function (resolve) {
          resolve();
        });
      }
    },

    loadTabDocuments: function loadTabDocuments(id, tab) {
      var _this9 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this9.getDocumentQuery(id, tab).then(function () {
          var promises = [];
          _ember['default'].RSVP.all(promises, 'AgendaPreload: loadTabDocuments all promises').then(function () {
            return resolve();
          });
        })['catch'](function () {
          return resolve();
        });
      }, 'AgendaPreload: loadTabDocuments');
    },

    getDocumentQuery: function getDocumentQuery(passportId, currentTab) {
      return this.get('store').query('zs-passport/zs-passport-document', {
        PassportId: passportId,
        TabName: currentTab,
        projection: 'zs-passport-document-full'
      });
    },

    getQuestionDocumentQuery: function getQuestionDocumentQuery(questionId) {
      return this.get('store').query('zs-passport/zs-passport-document', {
        QuestionId: questionId,
        projection: 'zs-passport-document-full'
      });
    },

    loadQuestionDocuments: function loadQuestionDocuments(id) {
      var _this10 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        _this10.getQuestionDocumentQuery(id).then(function () {
          var promises = [];
          _ember['default'].RSVP.all(promises, 'AgendaPreload: loadQuestionDocuments all promises').then(function () {
            return resolve();
          });
        })['catch'](function () {
          return resolve();
        });
      }, 'AgendaPreload: loadQuestionDocuments ' + id);
    },

    loadFiles: function loadFiles(document) {
      var _this11 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        var promises = [];
        document.get('Files').forEach(function (file) {
          return promises.push(_this11.loadFile(file));
        });
        _ember['default'].RSVP.all(promises, 'AgendaPreload: loadFiles all promises').then(function () {
          return resolve();
        });
      }, 'AgendaPreload: loadFiles');
    },

    loadFile: function loadFile(file) {
      var _this12 = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {
        var fileService = _this12.get('fileService');
        _this12.get('store').query('file', _this12.fileInfo(file)).then(function (fileRecords) {
          fileRecords = fileRecords.sortBy('FileVersion', 'UserFileVersion');
          var latestFile = fileRecords.get('lastObject');
          fileService.crossplatformFileDownload(latestFile, fileRecords, function () {
            _ember['default'].Logger.debug('Файл загружен', file);
            resolve();
          }, function () {
            _ember['default'].Logger.debug('Файл не загружен', file);
            resolve();
          });
        })['catch'](function () {
          _ember['default'].Logger.debug('Ошибка получения списка версий файлов!');
          resolve();
        });
      }, 'AgendaPreload: loadFile');
    },

    fileInfo: function fileInfo(file) {
      var url = file.get('Url');
      var subsystemKey = this.getUrlParam('subsystemKey', url);
      var docType = this.getUrlParam('docType', url);
      var subsystemFileId = this.getUrlParam('subsystemFileId', url);
      var fileDocumentId = this.getUrlParam('fileDocumentId', url);

      return {
        SubsystemKey: subsystemKey,
        DocType: docType,
        SubsystemFileId: subsystemFileId,
        fileDocumentId: fileDocumentId
      };
    },

    getUrlParam: function getParameterByName(name, url) {
      if (!url) {
        url = window.location.href;
      }

      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      var results = regex.exec(url);
      if (!results) {
        return null;
      }

      if (!results[2]) {
        return '';
      }

      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

  });
});