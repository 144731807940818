define('deputee-office/router', ['exports', 'ember', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _deputeeOfficeConfigEnvironment['default'].locationType
  });

  Router.map(function () {
    this.route('login', { path: '/login' });

    // Главное меню
    //  - Мои документы
    //this.route('my-documents');

    //  - Избранное
    this.route('favorites');

    //  - Контакты
    //this.route('contacts');

    //  - Контакты (На телефоне) Выбор видимых подразделений
    //this.route('contactsphonegroups');

    //  - Настройки
    this.route('settings');

    // Протокольные решения
    this.route('protocol-decisions');

    //
    this.route('pomopk-decisions');

    // План мероприятий
    this.route('eventsplan');

    // Внешние ресурсы
    this.route('external-resources');

    // Указы губернатора
    this.route('governor-decrees');

    // Материалы КСП
    this.route('k-s-p-materials');

    // Материалы КС
    this.route('k-s-materials');

    //
    this.route('ik-reports');

    // Исполнение бюджета
    this.route('budgets');

    // Аналитические отчеты
    this.route('reports');

    // Государственные программы
    this.route('gos-programs');

    // Постановления правительства
    this.route('decrees');

    // Отчёты УППЧ
    this.route('uppc-reports');
    this.route('download-file');

    // Новостная лента
    this.route('news');
    this.route('newslist');

    // Паспорта правовых актов.
    this.route('legal-acts', function () {
      this.route('passport', { path: '/passport/:legalact_id' }, function functionName() {
        this.route('stage-details', { path: '/stage-details/:current_tab' });
        this.route('file-view');
      });
    });

    this.route('passport-view-render', { path: 'passport-view-render/:question_id' });

    // Поступившие вопросы
    this.route('newquestions', function () {
      this.route('passport', { path: '/passport/:newquestion_id' }, function functionName() {
        this.route('stage-details', { path: '/stage-details/:current_tab' });
        this.route('file-view');
      });
    });

    this.route('external-resources');

    this.route('settings');

    // План работы ОМСУ
    this.route('work-plan', function () {
      this.route('work-plan-view', { path: '/work-plan-view/:question_id' }, function functionName() {
        this.route('stage-details', { path: '/stage-details/:current_tab' });
        this.route('file-view');
      });
    });

    // Календарь ОМСУ
    this.route('omsuschedule');

    // Паспорта законов
    this.route('laws', function () {
      this.route('passport', { path: '/passport/:law_id' }, function functionName() {
        this.route('stage-details', { path: '/stage-details/:current_tab' });
        this.route('notes', { path: '/notes/:current_tab' });
        this.route('file-view');
      });
    });

    // Сервисы Заседаний
    //  ОМСУ
    //    Заседания комитетов
    this.route('omsu-agenda-commitees', function () {
      this.route('passport', { path: '/passport/:question_id' }, function functionName() {
        this.route('stage-details', { path: '/stage-details/:current_tab' });
        this.route('file-view');
      });
    });

    //    Заседания земского
    this.route('omsu-agenda-assembly', function () {
      this.route('passport', { path: '/passport/:question_id' }, function functionName() {
        this.route('stage-details', { path: '/stage-details/:current_tab' });
        this.route('file-view');
      });
    });

    //    Заседани ПОМОПК
    this.route('omsu-agenda-pomopk', function () {
      this.route('passport', { path: '/passport/:question_id' }, function functionName() {
        this.route('stage-details', { path: '/stage-details/:current_tab' });
        this.route('file-view');
      });
    });

    //  МП
    //    Заседания МП
    this.route('yp-agenda-parlament', function () {
      this.route('passport', { path: '/passport/:question_id' }, function functionName() {
        this.route('stage-details', { path: '/stage-details/:current_tab' });
        this.route('notes', { path: '/notes/:current_tab' });
        this.route('file-view');
      });
    });

    //    Комиссии МП
    this.route('yp-agenda-comissions', function () {
      this.route('passport', { path: '/passport/:question_id' }, function functionName() {
        this.route('stage-details', { path: '/stage-details/:current_tab' });
        this.route('notes', { path: '/notes/:current_tab' });
        this.route('file-view');
      });
    });

    //  ЗС
    //    Повестки комитетов, фракций, коммиссий
    this.route('zs-agenda-departments', function () {
      this.route('passport', { path: '/passport/:question_id' }, function functionName() {
        this.route('stage-details', { path: '/stage-details/:current_tab' });
        this.route('notes', { path: '/notes/:current_tab' });
        this.route('file-view');
      });
    });

    //    Повестки ЗС
    this.route('zs-agenda', function () {
      this.route('passport', { path: '/passport/:question_id' }, function functionName() {
        this.route('stage-details', { path: '/stage-details/:current_tab' });
        this.route('notes', { path: '/notes/:current_tab' });
        this.route('file-view');
      });
    });
  });

  exports['default'] = Router;
});