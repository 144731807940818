define('deputee-office/controllers/modals/omsuagenda-department-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    actions: {
      initModel: _ember['default'].on('init', function () {
        this._super();
        this.set('groups', this.get('model').model);
        this.set('selectedDepartment', this.get('model').controller.department);
      }),

      closeModal: function closeModal() {
        if (!this.get('selectedDepartment')) {
          this.get('model').controller.send('exitService');
        }

        this.get('model').controller.send('removeModal');
      },
      selectDepartment: function selectDepartment(department) {
        this.set('selectedDepartment', department);
        this.get('model').controller.send('selectDepartment', department);
        this.get('model').controller.send('removeModal');
      }
    }
  });
});