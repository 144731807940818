define('deputee-office/controllers/credentials-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      save: function save() {
        this.get('model').save();
      },
      closeModal: function closeModal() {
        this.get('model').rollbackAttributes();
        this.get('target').send('removeModal');
      }
    }
  });
});