define('deputee-office/routes/work-plan', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/territory-flag-name', 'deputee-office/mixins/group-by'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsTerritoryFlagName, _deputeeOfficeMixinsGroupBy) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], _deputeeOfficeMixinsGroupBy['default'], {

    controllerName: 'work-plan.index',

    activate: function activate() {
      this.setFlagAndNameBySetting();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
    },

    queryParams: {
      activityTypeId: {
        refreshModel: false,
        replace: true
      },
      dateId: {
        refreshModel: false,
        replace: true
      }
    },

    model: function model(params) {

      var _this = this;
      var activityTypeId = decodeURI(params.activityTypeId);

      this.store.findAll('omsu-activity-type').then(function (activityTypes) {
        _ember['default'].run.scheduleOnce('afterRender', _this, _this.get('setControllerProperties'), {
          activityTypes: activityTypes,
          activityType: _this.getActivityTypeById(activityTypeId, activityTypes)
        });
      });
    },

    getActivityTypeById: function getActivityTypeById(activityTypeId, activityTypes) {
      return activityTypes.findBy('id', activityTypeId);
    },

    setControllerProperties: function setControllerProperties(propertiesObject) {
      this.get('controller').setProperties({
        activityTypes: propertiesObject.activityTypes,
        selectedActivityType: propertiesObject.activityType
      });
    },

    renderTemplate: function renderTemplate() {
      this.render();
      if (!this.get('devicejs').isPhone()) {
        this.render('search-box', {
          outlet: 'toolbar-right-outlet',
          controller: this.controllerFor('work-plan')
        });
      }
    },

    setupController: function setupController(controller) {
      controller.set('Dates', undefined);
      controller.set('workPlanStrings', undefined);
      controller.set('selectedDate', undefined);
      controller.set('selectedActivityType', undefined);
      controller.set('error', undefined);
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition) {
        this.set('searchText', undefined);
        controller.set('Dates', undefined);
        controller.set('workPlanStrings', undefined);
        controller.set('selectedDate', undefined);
        controller.set('selectedActivityType', undefined);
        controller.set('activityTypeId', undefined);
        controller.set('dateId', undefined);
        controller.set('error', undefined);
      }
    },

    actions: {
      selectedDateModal: function functionName(dates, dateFormat, dateFieldName, selected) {
        this.render('modals/month-selector', {
          into: 'work-plan',
          outlet: 'modal',
          model: {
            dates: dates,
            dateFormat: dateFormat,
            dateFieldName: dateFieldName,
            selected: selected,
            title: 'Дата',
            target: this.get('controller')
          }
        });
      },
      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'work-plan'
        });
      },
      willTransition: function willTransition(transition) {
        if (!this.get('devicejs').isPhone()) {
          if (transition.targetName.indexOf('work-plan-view') >= 0) {
            this.disconnectOutlet('toolbar-right-outlet');
          } else {
            this.render('search-box', {
              outlet: 'toolbar-right-outlet',
              controller: this.controllerFor('work-plan'),
              model: {
                searchText: this.controller.get('searchText')
              }
            });
          }
        }

        return true;
      }
    }
  });
});