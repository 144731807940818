define('deputee-office/components/legal-acts-filter-popover', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    popoverId: 'popoverFilterLegalActId',
    filterIcon: _ember['default'].computed('selectedType', 'selectedView', 'selectedState', function () {
      var st = this.get('selectedType');
      var ss = this.get('selectedState');
      var sv = this.get('selectedView');
      if (st !== 'Все' || ss !== 'Все' || sv !== 'Все') {
        return 'glyphicon icon-filter-selected';
      }

      return 'glyphicon icon-filter';
    }),
    filterHeight: _ember['default'].computed(function () {
      return _ember['default'].$(window).height() * 0.8 + 'px';
    }),
    typePropertyChanged: function typePropertyChanged() {
      var filter = this.get('filterModel');
      var newType = this.get('selectedType');

      filter.type = newType;
      this.sendAction('onFilter', filter);
    },

    viewPropertyChanged: function viewPropertyChanged() {
      var filter = this.get('filterModel');
      var newView = this.get('selectedView');

      filter.view = newView;
      this.sendAction('onFilter', filter);
    },

    statePropertyChanged: function statePropertyChanged() {
      var filter = this.get('filterModel');
      var newState = this.get('selectedState');

      filter.state = newState;
      this.sendAction('onFilter', filter);
    },

    filterChanged: function filterChanged() {
      var filter = this.get('filterModel');
      if (!filter) {
        return;
      }

      this.set('selectedType', filter.type);
      this.set('selectedView', filter.view);
      this.set('selectedState', filter.state);
      this.addObserver('selectedType', this.typePropertyChanged);
      this.addObserver('selectedView', this.viewPropertyChanged);
      this.addObserver('selectedState', this.statePropertyChanged);

      if (this.get('filterObserverAdded')) {
        this.removeObserver('filterModel', this.filterChanged);
        this.set('filterObserverAdded', false);
      }
    },

    init: function init() {
      this._super();
      var filter = this.get('filterModel');

      if (!filter) {
        this.addObserver('filterModel', this.filterChanged);
        this.set('filterObserverAdded', true);
      } else {
        this.filterChanged();
      }
    },

    actions: {
      changeType: function changeType(newType) {
        this.set('selectedType', newType);
      },

      changeView: function changeView(newView) {
        this.set('selectedView', newView);
      },

      changeState: function changeState(newState) {
        this.set('selectedState', newState);
      }
    }
  });
});