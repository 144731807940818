define('deputee-office/models/zs-passport/zs-passport-agenda-period', ['exports', 'ember-data', 'deputee-office/models/base', 'moment', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _moment, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Date: _emberData['default'].attr('date'),
    AgendaId: _emberData['default'].attr('guid'),
    Morning: _emberData['default'].attr('boolean'),
    FormatedDate: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])(this.get('Date')).format('DD.MM.YYYY');
    }),
    Type: _ember['default'].computed('Morning', function () {
      return this.get('Morning') ? 'утро' : 'вечер';
    })
  });

  exports['default'] = Model;
});