define('deputee-office/models/base-passport', ['exports', 'ember-data', 'deputee-office/models/base', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Info: _emberData['default'].attr('string'),
    Num: _emberData['default'].attr('string'),
    Speaker: _emberData['default'].attr('string'),
    CoSpeaker: _emberData['default'].attr('string'),
    PreSpeak: _emberData['default'].attr('string'),
    CurrentTab: _emberData['default'].attr('json-data'),
    VideoUrl: _emberData['default'].attr('string'),
    Next: _emberData['default'].attr('string'),
    Prev: _emberData['default'].attr('string'),
    PassportId: _emberData['default'].attr('guid'),
    AddInfo: _emberData['default'].attr('json-data'),
    DecisionProjectFile: _emberData['default'].attr('string'),
    DecisionProjectName: _emberData['default'].attr('string'),
    DateTimeBegin: _emberData['default'].attr('string'),
    DateTimeEnd: _emberData['default'].attr('string'),
    ToolBarInfo: _ember['default'].computed('Info', function () {
      return this.get('Info');
    }),
    BeginTime: _ember['default'].computed('DateTimeBegin', function () {
      var timeBegin = this.get('DateTimeBegin');
      var result = '';

      if (timeBegin) {
        var count = timeBegin.length;
        result = timeBegin;

        if (count === 16) {
          result = (0, _moment['default'])(timeBegin, 'DD.MM.YYYY H:mm').format('H:mm');
        } else if (count === 5) {
          result = timeBegin;
        }

        if (count === 10) {
          result = '';
        }
      }

      return result;
    }),
    isMultiCoSpeaker: _ember['default'].computed('CoSpeaker', function () {
      var coSpeaker = this.get('CoSpeaker');
      if (coSpeaker.split(',').length > 1) {
        return true;
      } else {
        return false;
      }
    }),
    isMultiSpeaker: _ember['default'].computed('Speaker', function () {
      var speaker = this.get('Speaker');
      if (speaker.split(',').length > 1) {
        return true;
      } else {
        return false;
      }
    }),
    BeginDate: _ember['default'].computed('DateTimeBegin', function () {
      var timeBegin = this.get('DateTimeBegin');
      var result = '';

      if (timeBegin) {
        var count = timeBegin.length;

        if (count === 16) {
          result = (0, _moment['default'])(timeBegin, 'DD.MM.YYYY H:mm').format('DD.MM.YYYY');
        } else if (count === 10) {
          result = timeBegin;
        }
      }

      return result;
    }),
    EndTime: _ember['default'].computed('DateTimeEnd', function () {
      var timeEnd = this.get('DateTimeEnd');
      var result = '';

      if (timeEnd) {
        var count = timeEnd.length;

        if (count === 16) {
          result = (0, _moment['default'])(timeEnd, 'DD.MM.YYYY H:mm').format('H:mm');
        } else if (count === 5) {
          result = timeEnd;
        }

        if (count === 10) {
          result = '';
        }
      }

      return result;
    }),
    EndDate: _ember['default'].computed('DateTimeEnd', function () {
      var timeEnd = this.get('DateTimeEnd');
      var result = '';

      if (timeEnd) {
        var count = timeEnd.length;

        if (count === 16) {
          result = (0, _moment['default'])(timeEnd, 'DD.MM.YYYY H:mm').format('DD.MM.YYYY');
        } else if (count === 10) {
          result = timeEnd;
        }
      }

      return result;
    })
  });

  exports['default'] = Model;
});