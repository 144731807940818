define('deputee-office/controllers/modals/document-history', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    actions: {
      initModel: _ember['default'].on('init', function () {
        this._super();
      }),

      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      }
    }
  });
});