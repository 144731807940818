define('deputee-office/routes/protocol-decisions', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/infinity-route', 'deputee-office/mixins/territory-flag-name'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsInfinityRoute, _deputeeOfficeMixinsTerritoryFlagName) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsInfinityRoute['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], {

    searchText: '',

    modelLoadingWrapper: {
      value: false
    },

    queryParams: {
      protocolState: {
        refreshModel: false
      }
    },

    modelLoading: _ember['default'].computed.alias('modelLoadingWrapper.value'),

    renderTemplate: function renderTemplate() {
      this.render();
      var device = this.get('devicejs');
      if (device.isPhone()) {
        this.render('search-box', {
          into: this.routeName,
          outlet: 'search-box',
          controller: this.controllerFor('protocol-decisions.protocol-decisions-search'),
          model: { searchText: this.get('searchText') }
        });
      } else {
        this.render('search-box', {
          outlet: 'toolbar-right-outlet',
          controller: this.controllerFor('protocol-decisions.protocol-decisions-search'),
          model: { searchText: this.get('searchText') }
        });
      }
    },

    model: function model() {
      var _this2 = this;

      this.set('modelLoading', true);

      var searchText = encodeURI(this.get('searchText'));
      var _this = this;

      this.infinityModel('protocol-decision', {
        perPage: 20,
        startingPage: 1,
        filter: searchText,
        TerritoryId: this.get('territoryService').getTerritoryId(),
        modelPath: 'controller.protocolDecisions',
        projection: 'protocol-decision'
      }).then(function (protocolDecisions) {
        var controller = _this.controller;
        controller.set('protocolDecisions', protocolDecisions);
        _this.set('modelLoading', false);
      })['catch'](function (reason) {
        console.error(reason);
        _this2.set('modelLoading', false);
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var modelLoading = this.get('modelLoadingWrapper');
      controller.set('modelLoading', modelLoading);
    },

    activate: function activate() {
      this.setFlagAndNameBySetting();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
      this.set('searchText', '');
    },

    actions: {

      searchProtocolDecisions: function searchProtocolDecisions(text) {
        this.set('searchText', text);
        this.refresh();
      },

      showExecutorsReplies: function showExecutorsReplies(model) {
        var device = this.get('devicejs');
        if (device.isPhone()) {
          var queryParams = {
            protocolState: 'execution'
          };
          this.get('controller').set('currentDecision', model);
          this.transitionTo(this.routeName, {
            queryParams: queryParams
          });
        } else {
          this.render('modals/executor-replies', {
            into: 'protocol-decisions',
            controller: 'modals/document-decisions',
            outlet: 'modal',
            model: model
          });
        }
      },

      showDecision: function showDecision(model) {
        var device = this.get('devicejs');
        if (device.isPhone()) {
          var queryParams = {
            protocolState: 'solution'
          };
          this.get('controller').set('currentDecision', model);
          this.transitionTo(this.routeName, {
            queryParams: queryParams
          });
        } else {
          this.render('modals/decision', {
            into: 'protocol-decisions',
            controller: 'modals/document-decisions',
            outlet: 'modal',
            model: model
          });
        }
      },

      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'protocol-decisions'
        });
      }
    }
  });
});