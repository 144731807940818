define('deputee-office/controllers/modals/file-select-extension', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    actions: {
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      },
      didShown: function functionName() {
        _ember['default'].$('.scrollable-div').perfectScrollbar('update');
      },
      getPrintAgendaForm: function getPrintAgendaForm(id, ext) {
        var _this = this;

        var settingsService = this.get('settingsservice');
        var currentUserService = this.get('currentuserservice');
        var urlQuery = settingsService.getHost() + 'api/PrintFormAgendas?id=' + id;
        var fileName = '';
        if (ext === 'pdf') {
          urlQuery = urlQuery + '&isPDF=true';
        }

        var authHeader = currentUserService.getBasicAuthHeader();
        _ember['default'].$.ajax({
          url: settingsService.getHost() + 'api/GetReportName/' + id,
          headers: { Authorization: authHeader },
          contentType: 'application/json; charset=utf-8',
          type: 'GET'
        }).then(function (response) {
          fileName = response;
        });
        var headers = new Headers({
          Authorization: authHeader
        });
        var options = {
          headers: headers
        };
        fetch(urlQuery, options).then(function (resp) {
          return resp.blob();
        }).then(function (blob) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = fileName + '.' + ext;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          _this.send('closeModal');
        })['catch'](function () {
          console.log('Ошибка при загрузке печатной формы.');
          _this.send('closeModal');
        });
      }
    }
  });
});