define('deputee-office/models/zs-passport/zs-passport-relationship', ['exports', 'ember-data', 'deputee-office/models/base', 'ember-flexberry-data', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _emberFlexberryData, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    To: _emberData['default'].hasMany('zs-passport/zs-passport-relationship-list', { async: false }),
    From: _emberData['default'].hasMany('zs-passport/zs-passport-relationship-list', { async: false }),
    Extra: _emberData['default'].hasMany('zs-passport/zs-passport-relationship-list', { async: false }),
    ChengedTo: _ember['default'].computed('To', function () {
      var _to = this.get('To');
      if (_to) {
        return _to.filterBy('Type', this.get('ChengeName'));
      }
    }),
    CancelTo: _ember['default'].computed('To', function () {
      var _to = this.get('To');
      if (_to) {
        return _to.filterBy('Type', this.get('CancelName'));
      }
    }),
    ChengedFrom: _ember['default'].computed('From', function () {
      var _from = this.get('From');
      if (_from) {
        return _from.filterBy('Type', this.get('ChengeName'));
      }
    }),
    CancelFrom: _ember['default'].computed('From', function () {
      var _from = this.get('From');
      if (_from) {
        return _from.filterBy('Type', this.get('CancelName'));
      }
    }),
    ExtraTo: _ember['default'].computed('Extra', function () {
      var _extra = this.get('Extra');
      if (_extra) {
        return _extra;
      }
    }),
    CancelName: _ember['default'].computed('property', function () {
      return 'Отмена';
    }),
    ChengeName: _ember['default'].computed('property', function () {
      return 'Изменение';
    })
  });

  Model.defineProjection('zs-passport-relationship-full', 'zs-passport/zs-passport-relationship', {
    To: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-relationship-list', 'To', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      PassportId: _emberFlexberryData.Projection.attr('PassportId'),
      Type: _emberFlexberryData.Projection.attr('Type'),
      Title: _emberFlexberryData.Projection.attr('Title')
    }),
    From: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-relationship-list', 'From', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      PassportId: _emberFlexberryData.Projection.attr('PassportId'),
      Type: _emberFlexberryData.Projection.attr('Type'),
      Title: _emberFlexberryData.Projection.attr('Title')
    }),
    Extra: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-relationship-list', 'Extra', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      PassportId: _emberFlexberryData.Projection.attr('PassportId'),
      Type: _emberFlexberryData.Projection.attr('Type'),
      Title: _emberFlexberryData.Projection.attr('Title')
    })
  });

  exports['default'] = Model;
});