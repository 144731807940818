define('deputee-office/models/law', ['exports', 'ember', 'ember-data', 'deputee-office/models/base', 'moment'], function (exports, _ember, _emberData, _deputeeOfficeModelsBase, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    IntId: _emberData['default'].attr('number'),
    RegDate: _emberData['default'].attr('date'),
    RegNumber: _emberData['default'].attr('string'),
    Type: _emberData['default'].attr('string'),
    Subject: _emberData['default'].attr('string'),
    HeadCommittee: _emberData['default'].attr('string'),
    Name: _emberData['default'].attr('string'),
    IsProject: _emberData['default'].attr('boolean'),
    Reading: _emberData['default'].attr('string'),
    FormattedRegDate: _ember['default'].computed('RegDate', function () {
      return (0, _moment['default'])('' + this.get('RegDate')).format('DD.MM.YYYY');
    }),
    PassportId: _ember['default'].computed.alias('id'),
    BillCode: _emberData['default'].attr('number'),
    Header: _emberData['default'].attr('string')
  });

  exports['default'] = Model;
});