define('deputee-office/components/news-card', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      openNews: function openNews(item) {
        this.sendAction('doOpenNews', item, this.get('newsPers'));
      },
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      }
    }
  });
});