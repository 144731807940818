define('deputee-office/controllers/application', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    session: _ember['default'].inject.service('session'),
    offlineGlobals: _ember['default'].inject.service('offline-globals'),
    fullscreen: _ember['default'].inject.service(),

    currentQuestion: _ember['default'].inject.service('current-question-service'),

    queryParams: ['isGuest'],

    isGuest: null,

    // Для авторизации под гостем без входа.
    // За основу взято: https://medium.com/@barelyknown/authenticate-in-ember-using-a-url-parameter-46f8439a5528
    unsetIsGuest: _ember['default'].observer('isGuest', function () {
      if (this.get('isGuest')) {
        _ember['default'].run.next(this, function () {
          this.set('isGuest', null);
        });
      }
    }),

    onlineStatus: _ember['default'].computed('offlineGlobals.isOnline', function () {
      var isOnline = this.get('offlineGlobals.isOnline');
      return isOnline ? 'online' : 'offline';
    }),

    processingQuery: _ember['default'].computed('offlineGlobals.processingQuery', function () {
      return this.get('offlineGlobals.processingQuery');
    }),

    isMobile: _ember['default'].computed(function () {
      return navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Opera Mini/i) || navigator.userAgent.match(/IEMobile/i);
    }),

    isiOs: _ember['default'].computed(function () {
      if (!window.device) {
        return;
      }

      return window.device.platform.toLowerCase() === 'ios';
    }),

    isPhone: _ember['default'].computed(function () {
      if (!this.get('devicejs')) {
        return;
      }

      return this.get('devicejs').isPhone();
    }),

    actions: {
      rightMenu: function rightMenu() {
        var sidebar = _ember['default'].$('[data-sidebar]#rightMenu');
        var overlay = _ember['default'].$('[data-sidebar-overlay]#rightMenu');
        if (overlay.is(':visible')) {
          sidebar.css('right', sidebar.width() * -1 + 'px');
          overlay.fadeTo('500', 0, function () {
            overlay.hide();
          });
        } else {
          sidebar.css('right', '0px');

          overlay.show(0, function () {
            overlay.fadeTo('500', 0.5);
          });
        }
      },
      goToVizitkaPK: function goToVizitkaPK() {
        window.externalApp.launch(['VizPK://', 'https://itunes.apple.com/us/app/vizitka-permskogo-kraa/id1076923567', 'Приложение "Визитка Пермского края" не установлено. Хотите установить его сейчас?', window.externalApp.alertType.OK_CANCEL]);
      }

    }
  });
});