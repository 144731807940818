define('deputee-office/mixins/grayscale-for-explorer10', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    GrayForExplorer10: function GrayForExplorer10(selector) {
      var getInternetExplorerVersion = function getInternetExplorerVersion() {
        var rv = -1;
        if (navigator.appName === 'Microsoft Internet Explorer') {
          var ua = navigator.userAgent;
          var re = new RegExp('MSIE ([0-9]{1,}[\.0-9]{0,})');
          if (re.exec(ua) != null) {
            rv = parseFloat(RegExp.$1);
          }
        } else if (navigator.appName === 'Netscape') {
          var ua = navigator.userAgent;
          var re = new RegExp('Trident/.*rv:([0-9]{1,}[\.0-9]{0,})');
          if (re.exec(ua) != null) {
            rv = parseFloat(RegExp.$1);
          }
        }

        return rv;
      };

      // Grayscale images only on browsers IE10+ since they removed support for CSS grayscale filter
      if (getInternetExplorerVersion() >= 10) {
        _ember['default'].$(selector).each(function () {
          this.src = grayscaleIE10(this.src);
        });

        var grayscaleIE10 = function grayscaleIE10(src) {
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d');
          var imgObj = new Image();
          imgObj.src = src;
          canvas.width = imgObj.width;
          canvas.height = imgObj.height;
          ctx.drawImage(imgObj, 0, 0);
          var imgPixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
          for (var y = 0; y < imgPixels.height; y++) {
            for (var x = 0; x < imgPixels.width; x++) {
              var i = y * 4 * imgPixels.width + x * 4;
              var avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
              imgPixels.data[i] = avg;
              imgPixels.data[i + 1] = avg;
              imgPixels.data[i + 2] = avg;
            }
          }

          ctx.putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);
          return canvas.toDataURL();
        };
      }
    }

  });
});