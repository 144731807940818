define('deputee-office/mixins/loader/yp-comments-reload', ['exports', 'ember', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Mixin.create({
    /**
     * Получение промиса коментариев/поправок к ппз.
     * @param {string} id
     * @param {string} lastCommentDateTime
     * @returns {Promise}
     */
    getPassportComments: function getPassportComments(id, lastCommentDateTime) {
      return this.get('store').query('yp-passport/yp-passport-comment', {
        PassportId: id,
        lastCommentDateTime: lastCommentDateTime
      });
    },

    commentsName: 'comments',

    /**
     * Запускаем таймер дозагрузки коментарие/поправок.
     * @returns {}
     */
    reloadComments: function reloadComments() {
      if (this.get(this.get('commentsName') + 'length')) {
        var date = this.get(this.get('commentsName')).toArray().sortBy('Time').get('lastObject.Time').toISOString();
      }

      var runLater = _ember['default'].run.later(this, function () {
        this.loadComments(date);
      }, _deputeeOfficeConfigEnvironment['default'].APP.questionCommentsReloadInterval);
      if (this.get('runLater')) {
        _ember['default'].run.cancel(this.get('runLater'));
      }

      if (!this.get('isDestroyed')) {
        this.set('runLater', runLater);
      } else {
        _ember['default'].run.cancel(runLater);
      }
    },

    /**
     * Добавляем новые коментарии/поправки.
     * @param {string} Дата в формате ISO.
     * @returns {}
     */
    loadComments: function loadComments(date) {
      var _this = this;

      if (this.get('AgendaState') === 'Заочное обсуждение') {
        this.getPassportComments(this.get('passportId'), date).then(function (comments) {
          var newComments = comments.filter(function (x) {
            return !_this.get(_this.get('commentsName')).findBy('id', x.get('id')) && !x.get('isMy');
          });
          if (newComments.get('length')) {
            _this.get(_this.get('commentsName')).addObjects(newComments.toArray());
            _this.set('question.UnreadCommentCount', _this.get('question.UnreadCommentCount') + newComments.get('length'));
          }

          _this.reloadComments();
        })['catch'](function () {
          return _this.reloadComments();
        });
      } else {
        this.reloadComments();
      }
    }
  });
});