define('deputee-office/components/custom-popover', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    bodyOverflow: 'auto',
    tagName: 'div',
    idButton: 'infoPopover',
    idContent: 'infoPopupContent',
    buttonText: '',
    animation: 'pop',
    width: '450px',
    afterSpanClass: '',
    backdrop: true,
    dataplacement: 'bottom',
    maxHeight: _ember['default'].computed(function () {
      return _ember['default'].$(document).height() - this.$().offset().top - 70 + 'px';
    }),
    hide: function hide() {
      _ember['default'].$('#superpuper').perfectScrollbar('destroy');
      _ember['default'].$('[id*="opover"]').webuiPopover('hide');
      _ember['default'].$('.webui-popover').remove();
      this.rerender();
    },
    didRender: function didRender() {
      var _this = this;
      this._super.apply(this, arguments);
      _ember['default'].$('#' + this.get('idButton')).webuiPopover({
        url: '#' + this.get('idContent'),
        backdrop: this.get('backdrop'),
        animation: this.get('animation'),
        width: this.get('width'),
        offsetTop: 10,
        onShow: function onShow() {
          _ember['default'].$('.scrollable-div').perfectScrollbar('update');
          var ttt = _this.get('afterSpanClass');
          if (ttt.indexOf('glyphicon-menu-down') !== -1) {
            _this.set('afterSpanClass', 'glyphicon glyphicon-menu-up font-size-small');
          }
        },
        onHide: function onHide() {
          var ttt = _this.get('afterSpanClass');
          if (ttt.indexOf('glyphicon-menu-up') !== -1) {
            _this.set('afterSpanClass', 'glyphicon glyphicon-menu-down font-size-small');
          }
        }
      });
    },

    didInsertElement: function didInsertElement() {
      var _this = this;
      _ember['default'].$(window).on('resize', function () {
        _this.hide();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.hide();
      _ember['default'].$(window).off('resize');
    },
    bindHide: (function () {
      if (window.device && window.device.platform && window.device.platform.toLowerCase() === 'ios') {
        _ember['default'].$('.webui-popover-backdrop').on('tap', function (e) {
          e.stopPropagation();
        });
      }
    }).on('init')
  });
});