define('deputee-office/routes/phone/contacts', ['exports', 'ember', 'deputee-office/mixins/infinity-route'], function (exports, _ember, _deputeeOfficeMixinsInfinityRoute) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsInfinityRoute['default'], {

    modelLoadingWrapper: {
      value: false
    },
    searchText: '',
    modelLoading: _ember['default'].computed.alias('modelLoadingWrapper.value'),

    queryParams: {
      contactSubState: {
        refreshModel: false,
        replace: false
      }
    },

    model: function model(params) {
      var _this2 = this;

      this.set('modelLoading', true);
      this.set('params', params);
      params.perPage = 20;
      params.startingPage = 1;
      var searchText = this.get('searchText');
      if (searchText && searchText.length > 0) {
        //searchText = encodeURI(this.get('searchText'));
        params.filter = searchText;
      }

      var _this = this;
      this.infinityModel('phonecontact', params).then(function (contacts) {
        var controller = _this.controller;
        controller.set('model', contacts);
        _this.set('modelLoading', false);
      })['catch'](function (reason) {
        console.error(reason);
        _this2.set('modelLoading', false);
      });

      //Предзагрузка подразделений
      this.store.findAll('phonedepartment');

      // Возвращается пустой массив, чтобы в контроллере не ломалась группировка.
      return new _ember['default'].A([]);
    },

    setupController: function setupController(controller, model) {
      var _this3 = this;

      this._super(controller, model);

      var params = this.get('params');

      params.perPage = 20;
      params.startingPage = 1;
      var searchText = this.get('searchText');
      if (searchText && searchText.length > 0) {
        params.filter = searchText;
      }

      this.infinityModel('phonecontact', params).then(function (contacts) {
        controller.set('model', contacts);
        _this3.set('modelLoading', false);
      })['catch'](function (reason) {
        console.error(reason);
        _this3.set('modelLoading', false);
      });

      this.set('controller', controller);
      controller.set('modelLoading', this.get('modelLoadingWrapper'));
      controller.set('contactData', null);
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition) {
        controller.set('subString', null);
      }
    },

    /**
     * Отмена выполняемых в рамках роута ajax-запросов.
     *
     * @return {undefined}
     */
    _abortRequests: function _abortRequests() {
      if (_ember['default'].$.xhrPool != null) {
        var routeName = this.get('routeName');

        _ember['default'].$.xhrPool.abort(routeName);
      }

      this.get('store').cancelQuery('phonecontact');
    },

    /**
     * Отмена выполняемых ajax-запросов при выходе из роута.
     *
     * @return {undefined}
     */
    deactivate: function deactivate() {
      this._abortRequests();
    },

    renderTemplate: function renderTemplate() {
      this.render();
      this.render('search-box', {
        into: this.routeName,
        outlet: 'search-box',
        controller: this.controllerFor('contactsphone.contactsphone-search'),
        model: { searchText: this.get('searchText') }
      });
    },

    actions: {

      contactInfo: function contactInfo(contact) {
        this.get('controller').set('currentContact', contact);
        this.transitionTo(this.routeName, {
          queryParams: {
            contactSubState: 'showContact'
          }
        });
      },

      back: function back() {
        window.history.back();
      },

      /**
       * Поиск контактов, имя или должность которых соответствуют заданной строке.
       *
       * @param {string} Text - Текст для поиска.
       */
      doSearch: function doSearch(text) {
        this.set('searchText', text);
        this.refresh();
      }
    }
  });
});