define('deputee-office/routes/yp-agenda-parlament/passport', ['exports', 'deputee-office/routes/base-agenda-route/base-agenda-passport-components'], function (exports, _deputeeOfficeRoutesBaseAgendaRouteBaseAgendaPassportComponents) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRouteBaseAgendaPassportComponents['default'].extend({
    passportNavigate: 'yp-agenda-parlament.passport',
    zsQuestion: false,

    /**
     * Получение истории переименований паспорта.
     */
    getPassportHistoryQuery: function getPassportHistoryQuery(passport) {
      return this.store.query('zs-passport/zs-passport-law-history', {
        PassportId: passport.get('PassportId')
      });
    },

    /**
    * Получение связанных законопроектов (отменяемых/изменяемых/отменяющих/изменяющих).
    */
    getPassportRelationships: function functionName(passport) {
      return this.store.queryRecord('zs-passport/zs-passport-relationship', {
        id: passport.get('PassportId'),
        projection: 'zs-passport-relationship-full'
      });
    },

    /**
    * Получение паспорта вопроса.
    */
    getPassportQuery: function getPassportQuery() {
      return this.store.queryRecord('yp-passport', {
        id: this.get('params.question_id')
      });
    },

    PassportHeaderQuery: function PassportHeaderQuery(passport) {
      return this.store.queryRecord('zs-passport/zs-passport-header', {
        id: passport.get('PassportId'),
        projection: 'zs-passport-header-full'
      });
    }
  });
});