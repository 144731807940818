define('deputee-office/routes/yp-agenda-parlament/passport/index', ['exports', 'ember', 'deputee-office/routes/yp-agenda-comissions/passport/index'], function (exports, _ember, _deputeeOfficeRoutesYpAgendaComissionsPassportIndex) {
  exports['default'] = _deputeeOfficeRoutesYpAgendaComissionsPassportIndex['default'].extend({
    parentControllerName: 'yp-agenda-parlament.passport',
    detailsRouteName: 'yp-agenda-parlament.passport.stage-details',
    notesRouteName: 'yp-agenda-parlament.passport.notes',
    baseControllerName: 'yp-agenda-parlament.index',

    /**
     * Получение промиса комментариев/поправок.
     * @param {string} Идентификатор ппз.
     * @returns {Promise}
     */
    getPassportComments: function getPassportComments(id) {
      return this.store.query('yp-passport/yp-passport-comment', {
        PassportId: id
      });
    },

    /**
     * Получение результатов голосования.
     * @param {string} Идентификатор ппз.
     * @returns {Promise}
     */
    getPassportVotingResult: function getPassportVotingResult(id) {
      return this.store.query('yp-passport/yp-passport-voting-result', {
        PassportId: id,
        projection: 'yp-passport-voting-result-full'
      });
    },

    departmentId: _ember['default'].computed(function () {
      return this.paramsFor('yp-agenda-parlament').departmentId;
    })
  });
});