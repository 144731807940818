define('deputee-office/controllers/base-agendas/base-agenda-search', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    searchPlaceholder: 'Поиск по вопросам',

    sendSearchAction: function sendSearchAction(text) {
      this.get('target').send('search', text);
      _ember['default'].$('.scrollable-div').trigger('updatescroll');
    },

    actions: {
      search: function search(text) {
        _ember['default'].run.debounce(this, this.get('sendSearchAction'), text, 1500);
      }
    }
  });
});