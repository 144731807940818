define('deputee-office/routes/decrees', ['exports', 'ember', 'deputee-office/mixins/infinity-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/territory-flag-name'], function (exports, _ember, _deputeeOfficeMixinsInfinityRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsTerritoryFlagName) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsInfinityRoute['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], {
    queryParams: {
      viewMode: {
        replace: true
      }
    },

    searchText: '',

    // Обертки для свойиств примитивных типов для корректоного биндинга в контроллере.
    descOrderWrapper: {
      value: true
    },

    modelLoadingWrapper: {
      value: false
    },

    modelLoading: _ember['default'].computed.alias('modelLoadingWrapper.value'),

    descOrder: _ember['default'].computed.alias('descOrderWrapper.value'),

    descOrderChanged: _ember['default'].observer('descOrder', function () {
      this.refresh();
    }),

    renderTemplate: function renderTemplate() {
      this.render();
      var device = this.get('devicejs');
      if (device.isPhone()) {
        this.render('search-box', {
          into: 'decrees',
          outlet: 'search-box',
          controller: this.controllerFor('decrees.decrees-search'),
          model: { searchText: this.get('searchText') }
        });
      } else {
        this.render('search-box', {
          outlet: 'toolbar-right-outlet',
          controller: this.controllerFor('decrees.decrees-search'),
          model: { searchText: this.get('searchText') }
        });
      }
    },

    model: function model() {
      var _this2 = this;

      this.set('modelLoading', true);

      var searchText = encodeURI(this.get('searchText'));
      var descOrder = this.get('descOrder');
      var _this = this;

      this.infinityModel('decree', {
        perPage: 20,
        startingPage: 1,
        filter: searchText,
        descOrder: descOrder
      }).then(function (decrees) {
        var controller = _this.controller;

        controller.set('model', decrees);
        _this.set('modelLoading', false);
      })['catch'](function (reason) {
        console.error(reason);
        _this2.set('modelLoading', false);
      });

      // Возвращается пустой массив, чтобы в контроллере не ломалась группировка.
      return new _ember['default'].A([]);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var modelLoading = this.get('modelLoadingWrapper');
      var descOrder = this.get('descOrderWrapper');
      controller.set('modelLoading', modelLoading);
      controller.set('descSortingOrder', descOrder);
    },

    activate: function activate() {
      this.setFlagAndNameForZS();
      this.controllerFor('decrees').set('viewMode', 'list');

      //this.set('viewModeToggleIcon', 'assets/img/decreeviewtiles.png');
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();

      this.set('searchText', '');
      this.set('descOrder', true);
    },

    actions: {
      searchDecrees: function searchDecrees(text) {
        this.set('searchText', text);
        this.refresh();
      }
    }
  });
});