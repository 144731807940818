define('deputee-office/models/work-plan-string', ['exports', 'ember-data', 'deputee-office/models/base', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Month: _emberData['default'].attr('string'),
    Title: _emberData['default'].attr('string'),
    QuestionId: _emberData['default'].attr('guid'),
    activityType: _emberData['default'].attr('string'),
    TerritoryId: _emberData['default'].attr('guid'),
    Date: _emberData['default'].attr('date'),
    OrderNum: _emberData['default'].attr('number'),
    FormattedDate: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])('' + this.get('Date')).format('MMMM YYYY').firstCharToUpper();
    })
  });

  exports['default'] = Model;
});