define('deputee-office/models/territory', ['exports', 'ember-data', 'deputee-office/models/base', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    flagService: _ember['default'].inject.service(),
    Name: _emberData['default'].attr('string'),
    Level: _emberData['default'].attr('string'),
    OrderNum: _emberData['default'].attr('number'),
    Padding: _ember['default'].computed('Level', function () {
      return 'padding-left: ' + this.get('Level') * 30 + 'px;';
    }),
    FlagUrl: _ember['default'].computed(function () {
      return this.get('flagService').getFlagUrlByTerritoryId(this.get('id'));
    })
  });

  exports['default'] = Model;
});