define('deputee-office/routes/yp-agenda-comissions/passport/index', ['exports', 'ember', 'deputee-office/routes/base-agenda-route/passport/index'], function (exports, _ember, _deputeeOfficeRoutesBaseAgendaRoutePassportIndex) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoutePassportIndex['default'].extend({
    agendaStateService: _ember['default'].inject.service('agenda-state'),
    parentControllerName: 'yp-agenda-comissions.passport',
    detailsRouteName: 'yp-agenda-comissions.passport.stage-details',
    notesRouteName: 'yp-agenda-comissions.passport.notes',
    baseControllerName: 'yp-agenda-comissions.index',

    /**
     * Получение промиса комментариев/поправок.
     * @param {string} Идентификатор ппз.
     * @returns {Promise}
     */
    getPassportComments: function getPassportComments(id) {
      return this.store.query('yp-passport/yp-passport-comment', {
        PassportId: id
      });
    },

    /**
     * Получение результатов голосования.
     * @param {string} Идентификатор ппз.
     * @returns {Promise}
     */
    getPassportVotingResult: function getPassportVotingResult(id) {
      return this.store.query('yp-passport/yp-passport-voting-result', {
        PassportId: id,
        projection: 'yp-passport-voting-result-full'
      });
    },

    passportObserver: _ember['default'].observer('parentController.model.id', function () {
      this.updateComments();
    }),

    PassportHeaderObserver: _ember['default'].observer('parentController.model.PassportHeader', function () {
      this.get('controller').set('noteAllowed', this.get('passportExist') && this.get('currentuserservice').isUserFromZS());
    }),

    departmentId: _ember['default'].computed('parentController.model.id', function () {
      return this.paramsFor('yp-agenda-comissions').departmentId;
    }),

    updateComments: function functionName() {
      var _this = this;

      this.get('controller').set('comments', null);
      var passportId = this.get('parentController.model.id');
      if (passportId) {
        this.getPassportComments(passportId).then(function (comments) {
          _this.get('controller').set('comments', comments.toArray());
        });
        this.getPassportVotingResult(passportId).then(function (votingResults) {
          return _this.get('controller').set('votingResults', votingResults);
        });
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('departmentId', this.get('departmentId'));
      controller.set('ypTimer', true);
      this.updateComments();
    },

    resetController: function resetController(controller, isExiting, transition) {
      this._super(controller, isExiting, transition);
      if (isExiting && transition) {
        controller.set('comments', null);
        controller.set('ypTimer', false);
      }
    },

    actions: {
      showCommentsModal: function showCommentsModal(comments) {
        var ass = this.get('agendaStateService');
        comments.set('SendCommentAvailable', ass.isZo(this.get('AgendaState'), this.get('StageOver')) && this.get('IsCommentAvailable'));
        comments.set('SendCommentAvailableForUser', this.get('IsCommentAvailable'));
        comments.set('SendVoteAvailable', ass.isGp(this.get('AgendaState'), this.get('StageOver')));
        comments.set('QuestionId', this.get('QuestionId'));
        comments.set('IsAmendmentAvailable', this.get('controller.passport.IsAmendmentAvailable'));
        if (this.get('devicejs').isPhone()) {
          this.get('controller').set('displayedComments', comments);
          this.send('goToState', 'comments');
          return false;
        } else {
          return true;
        }
      },
      AddVotingResult: function functionName(voteObject) {
        voteObject.set('PassportId', this.get('QuestionId'));
        var ass = this.get('agendaStateService');
        if (ass.isAfterZg(this.get('AgendaState'), this.get('StageOver'))) {
          var passportId = this.get('parentController.model.id');
          voteObject.set('votingResultsPromise', this.getPassportVotingResult(passportId));
          voteObject.set('ShowVoteForm', false);
        } else {
          voteObject.set('votingResultsPromise', null);
          voteObject.set('ShowVoteForm', true);
        }

        voteObject.set('showPersons', false);
        if (this.get('devicejs').isPhone()) {
          this.send('goToState', 'vote', voteObject.get('id'));
          return false;
        } else {
          return true;
        }
      }
    }
  });
});