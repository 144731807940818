define('deputee-office/controllers/modals/document-decisions', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      },
      didShown: function functionName() {
        _ember['default'].$('.scrollable-div').perfectScrollbar('update');
      }
    }
  });
});