define('deputee-office/initializers/local-store', ['exports', 'ember-flexberry-data/initializers/local-store'], function (exports, _emberFlexberryDataInitializersLocalStore) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberFlexberryDataInitializersLocalStore['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberFlexberryDataInitializersLocalStore.initialize;
    }
  });
});