define('deputee-office/controllers/modals/passport-relationships', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    actions: {
      initModel: _ember['default'].on('init', function () {
        this._super();
      }),

      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      },
      goToLaw: function goToLaw() {
        this.send('closeModal');
        _ember['default'].getOwner(this).lookup('controller:application').send('rightMenu');
        return true;
      }
    }
  });
});