define('deputee-office/models/zsdepartment/zs-department-question-passport-document', ['exports', 'ember-data', 'deputee-office/models/base-passport-document', 'ember-flexberry-data'], function (exports, _emberData, _deputeeOfficeModelsBasePassportDocument, _emberFlexberryData) {
  var Model = _deputeeOfficeModelsBasePassportDocument['default'].extend({
    ZsDepartmentAgendaId: _emberData['default'].attr('guid'),
    Date: _emberData['default'].attr('string')
  });

  Model.defineProjection('zs-department-question-passport-document-full', 'zsdepartment/zs-department-question-passport-document', {
    ZsDepartmentAgendaId: _emberFlexberryData.Projection.attr('ZsDepartmentAgendaId'),
    Type: _emberFlexberryData.Projection.attr('Type'),
    Title: _emberFlexberryData.Projection.attr('Title'),
    PassportId: _emberFlexberryData.Projection.attr('PassportId'),
    QuestionId: _emberFlexberryData.Projection.attr('QuestionId'),
    Date: _emberFlexberryData.Projection.attr('Date'),
    OrderNum: _emberFlexberryData.Projection.attr('OrderNum'),
    TabName: _emberFlexberryData.Projection.attr('TabName'),
    Files: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-file', 'Files', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      Url: _emberFlexberryData.Projection.attr('Url')
    })
  });
  exports['default'] = Model;
});