define('deputee-office/routes/pomopk-decisions', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/infinity-route', 'deputee-office/mixins/territory-flag-name'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsInfinityRoute, _deputeeOfficeMixinsTerritoryFlagName) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsInfinityRoute['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], {

    searchText: '',

    modelLoadingWrapper: {
      value: false
    },

    queryParams: {
      pomopkState: {
        refreshModel: false
      }
    },

    modelLoading: _ember['default'].computed.alias('modelLoadingWrapper.value'),

    model: function model() {
      var _this2 = this;

      this.set('modelLoading', true);

      var _this = this;
      var searchText = encodeURI(this.get('searchText'));

      this.infinityModel('pomopk-decision', {
        perPage: 10,
        startingPage: 1,
        filter: searchText,
        TerritoryId: this.get('territoryService').getTerritoryId(),
        modelPath: 'controller.decisions',
        projection: 'pomopk-decision'
      }).then(function (decisions) {
        var controller = _this.controller;
        controller.set('decisions', decisions);
        _this.set('modelLoading', false);
      })['catch'](function (reason) {
        console.error(reason);
        _this2.set('modelLoading', false);
      });
    },

    renderTemplate: function renderTemplate() {
      this.render();
      var device = this.get('devicejs');
      if (device.isPhone) {
        this.render('search-box', {
          into: this.routeName,
          outlet: 'search-box',
          controller: this.controllerFor('pomopk-decisions'),
          model: { searchText: this.get('searchText') }
        });
      } else {
        this.render('search-box', {
          outlet: 'toolbar-right-outlet',
          controller: this.controllerFor('pomopk-decisions'),
          model: { searchText: this.get('searchText') }
        });
      }
    },

    setupController: function setupController(controller) {
      var modelLoading = this.get('modelLoadingWrapper');
      controller.set('modelLoading', modelLoading);
    },

    activate: function activate() {
      this.setFlagAndNameBySetting();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
    },

    actions: {
      searchDecisions: function searchDecisions(text) {
        this.set('searchText', text);
        this.refresh();
      }
    }
  });
});