define('deputee-office/helpers/array-contains', ['exports', 'ember-array-contains-helper/helpers/array-contains'], function (exports, _emberArrayContainsHelperHelpersArrayContains) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberArrayContainsHelperHelpersArrayContains['default'];
    }
  });
  Object.defineProperty(exports, 'arrayContains', {
    enumerable: true,
    get: function get() {
      return _emberArrayContainsHelperHelpersArrayContains.arrayContains;
    }
  });
});