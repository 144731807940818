define('deputee-office/controllers/server-modal', ['exports', 'ember', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      save: function save() {
        var networkSettings = this.get('model');
        if (this.checkServer(networkSettings.get('Host'))) {
          networkSettings.save();
        }
      },
      closeModal: function closeModal() {
        this.get('model').rollbackAttributes();
        this.get('target').send('removeModal');
      }
    },
    checkServer: function checkServer(url) {
      var result = false;
      _ember['default'].$.ajax({
        url: url + 'api/checkserver',
        type: 'GET',
        async: false,
        timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
      }).then(function (data) {
        result = data === 200;
      });
      return result;
    }
  });
});