define('deputee-office/controllers/yp-agenda-comissions/index', ['exports', 'ember', 'deputee-office/controllers/base-agenda-route'], function (exports, _ember, _deputeeOfficeControllersBaseAgendaRoute) {
  exports['default'] = _deputeeOfficeControllersBaseAgendaRoute['default'].extend({
    ypTimer: true,
    agendaStateService: _ember['default'].inject.service('agenda-state'),

    AgendaChanged: _ember['default'].observer('currentAgenda', 'currentAgenda.StageName', 'currentAgenda.StageOver', function () {
      this.timer();
    }),

    timer: function functionName() {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var currentAgenda = this.get('currentAgenda');
        if (currentAgenda && currentAgenda.get('StageName')) {
          if (!this.get('devicejs').isPhone()) {
            this.get('target').send('showTimer', currentAgenda);
          }

          this.get('agendaStateService').set('currentAgenda', currentAgenda);
        } else {
          this.get('agendaStateService').set('currentAgenda', null);
        }
      });
    }
  });
});