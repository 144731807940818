define('deputee-office/mixins/services-mixin', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    settingsservice: _ember['default'].inject.service(),
    cacheservice: _ember['default'].inject.service(),
    networkstatus: _ember['default'].inject.service(),
    currentuserservice: _ember['default'].inject.service(),
    notificationService: _ember['default'].inject.service(),
    flagService: _ember['default'].inject.service(),
    territoryService: _ember['default'].inject.service(),
    preloadService: _ember['default'].inject.service(),
    favoritesService: _ember['default'].inject.service(),
    fotoService: _ember['default'].inject.service(),
    statisticsService: _ember['default'].inject.service()
  });
});