define('deputee-office/routes/favorites', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _emberSimpleAuthMixinsAuthenticatedRouteMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], {

    favoritesService: _ember['default'].inject.service(),

    queryParams: {
      favoriteState: {
        refreshModel: true
      }
    },

    model: function model() {
      var _this = this;

      return this.store.findAll('favorite').then(function (records) {
        records = records.toArray().map(function (record) {
          var params = JSON.parse(record.get('Params'));

          if (params.additionalParams && params.additionalParams.serviceName) {
            return params.additionalParams.serviceName;
          }

          return record.get('Model');
        });

        records = records.filter(function (item, i) {
          return records.indexOf(item) === i;
        });

        return _this.get('favoritesService').getFavServiceInfo(records);
      });
    },

    renderTemplate: function renderTemplate() {
      this.render();
      var device = this.get('devicejs');
      if (device.isPhone()) {
        this.render('search-box', {
          into: 'favorites',
          outlet: 'search-box',
          controller: this.controllerFor('favorites')
        });
      } else {
        this.render('search-box', {
          outlet: 'toolbar-right-outlet',
          controller: this.controllerFor('favorites')
        });
      }
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition) {
        controller.set('searchString.value', null);
      }
    },

    actions: {

      selectType: function selectType(info) {
        var queryParams = {
          favoriteState: info.componentName
        };

        this.transitionTo(this.routeName, {
          queryParams: queryParams
        });
      },

      back: function back() {
        window.history.back();
      },

      contactInfo: function contactInfo(contact) {
        var _this2 = this;

        var device = this.get('devicejs');
        if (!device.isPhone()) {
          (function () {
            var sidebar = _ember['default'].$('[data-sidebar]#rightMenu');
            var overlay = _ember['default'].$('[data-sidebar-overlay]#rightMenu');
            if (overlay.is(':visible')) {
              sidebar.css('right', sidebar.width() * -1 + 'px');
              overlay.fadeTo('500', 0, function () {
                overlay.hide();
              });
            } else {
              sidebar.css('right', '0px');

              overlay.show(0, function () {
                overlay.fadeTo('500', 0.5);
              });
            }

            _this2.render('contact-info', {
              controller: 'contacts.contact-info',
              into: 'application',
              outlet: 'right-menu',
              model: contact
            });
          })();
        } else {
          this.controllerFor('contacts').set('currentContact', contact);
          this.transitionTo('contacts', {
            queryParams: { contactSubState: 'showContact' }
          });
        }
      },

      dataLoadStart: function dataLoadStart() {
        this.get('controller').set('componentDataLoading', true);
        this.get('controller').set('notFound', false);
      },

      dataLoadEnd: function dataLoadEnd() {
        this.get('controller').set('componentDataLoading', false);
      },

      foundNothing: function foundNothing() {
        this.send('dataLoadEnd');
        this.get('controller').set('notFound', true);
      },

      clearCathegory: function clearCathegory(selectedType) {
        var _this3 = this;

        if (selectedType) {
          this.get('favoritesService').clearFavorites(selectedType.model, selectedType.apServiceName).then(function () {
            if (_this3.get('devicejs').isPhone()) {
              window.history.back();
            } else {
              _this3.send('foundNothing');
            }
          })['catch'](function (reason) {
            alert('Не удалось корректно очистить категорию');
            console.error(reason);
          });
        }
      }
    }
  });
});