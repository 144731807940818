define('deputee-office/mixins/infinity-route', ['exports', 'ember', 'ember-infinity/mixins/route'], function (exports, _ember, _emberInfinityMixinsRoute) {

      var RouteMixin = _ember['default'].Mixin.create(_emberInfinityMixinsRoute['default'], {

            _canLoadMore: _ember['default'].computed('_totalPages', 'currentPage', function () {
                  return true;
            }),
            _requestNextPage: function _requestNextPage() {
                  var modelName = this.get('_infinityModelName');
                  var nextPage = this.incrementProperty('currentPage');
                  var params = this._buildParams(nextPage);

                  return this.get('store')[this._storeFindMethod](modelName, params).then(this._afterInfinityModel(this));
            },

            _nextPageLoaded: function _nextPageLoaded(newObjects) {
                  var totalPages = newObjects.get(this.get('totalPagesParam'));
                  this.set('_totalPages', totalPages);

                  var infinityModel = newObjects;

                  if (this.get('_firstPageLoaded')) {
                        if (typeof this.updateInfinityModel === 'function' && this.updateInfinityModel !== _ember['default'].Object.extend(RouteMixin).create().updateInfinityModel) {
                              infinityModel = this.updateInfinityModel(newObjects);
                        } else {
                              infinityModel = this._doUpdate(newObjects);
                        }
                  }

                  this.set('_firstPageLoaded', true);
                  this._notifyInfinityModelUpdated(newObjects);

                  var canLoadMore = this.get('_canLoadMore') && newObjects.content.length > 0;
                  infinityModel.set('reachedInfinity', !canLoadMore);

                  if (!canLoadMore) {
                        this._notifyInfinityModelLoaded();
                  }

                  return infinityModel;
            }

      });

      exports['default'] = RouteMixin;
});