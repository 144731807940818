define('deputee-office/models/omsu-passport/omsu-passport-voting-result', ['exports', 'ember-data', 'deputee-office/models/base', 'ember-flexberry-data', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _emberFlexberryData, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Name: _emberData['default'].attr('string'),

    /**
     * Идентификатор вопроса заседания или поправки.
     */
    PassportId: _emberData['default'].attr('string'),
    VoteForCount: _emberData['default'].attr('number'),
    VoteAgainstCount: _emberData['default'].attr('number'),
    AbstainCount: _emberData['default'].attr('number'),
    NotVoteCount: _emberData['default'].attr('number'),
    Type: _emberData['default'].attr('string'),
    VotePersons: _emberData['default'].hasMany('omsu-passport/omsu-passport-voting-person', { async: false }),
    TerritoryId: _emberData['default'].attr('guid'),
    allCount: _ember['default'].computed('VoteForCount', 'VoteAgainstCount', 'AbstainCount', function () {
      var VoteForCount = this.get('VoteForCount');
      var VoteAgainstCount = this.get('VoteAgainstCount');
      var AbstainCount = this.get('AbstainCount');

      var all = VoteForCount + VoteAgainstCount + AbstainCount;

      return all;
    }),
    VoteForCountString: _ember['default'].computed('VoteForCount', function () {
      var VoteForCount = this.get('VoteForCount');
      var all = this.get('allCount');
      return all !== 0 ? VoteForCount + ' (' + Math.round(VoteForCount / all * 100) + '%)' : '0 (0%)';
    }),
    VoteAgainstCountString: _ember['default'].computed('VoteAgainstCount', function () {
      var VoteAgainstCount = this.get('VoteAgainstCount');
      var all = this.get('allCount');
      return all !== 0 ? VoteAgainstCount + ' (' + Math.round(VoteAgainstCount / all * 100) + '%)' : '0 (0%)';
    }),
    AbstainCountString: _ember['default'].computed('AbstainCount', function () {
      var AbstainCount = this.get('AbstainCount');
      var all = this.get('allCount');
      return all !== 0 ? AbstainCount + ' (' + Math.round(AbstainCount / all * 100) + '%)' : '0 (0%)';
    }),
    NotVoteCountString: _ember['default'].computed('NotVoteCount', function () {
      var NotVoteCount = this.get('NotVoteCount');
      return NotVoteCount;
    }),
    VoteForCountPercent: _ember['default'].computed('VoteForCount', function () {
      var VoteForCount = this.get('VoteForCount');
      var all = this.get('allCount');
      return all !== 0 ? Math.round(VoteForCount / all * 100) : 0;
    }),
    VoteAgainstCountPercent: _ember['default'].computed('VoteAgainstCount', function () {
      var VoteAgainstCount = this.get('VoteAgainstCount');
      var all = this.get('allCount');
      return all !== 0 ? Math.round(VoteAgainstCount / all * 100) : 0;
    }),
    AbstainCountPercent: _ember['default'].computed('AbstainCount', function () {
      var AbstainCount = this.get('AbstainCount');
      var all = this.get('allCount');
      return all !== 0 ? Math.round(AbstainCount / all * 100) : 0;
    })
  });

  Model.defineProjection('omsu-passport-voting-result-full', 'omsu-passport/omsu-passport-voting-result', {
    Name: _emberFlexberryData.Projection.attr('Name'),
    Title: _emberFlexberryData.Projection.attr('Title'),
    VoteForCount: _emberFlexberryData.Projection.attr('VoteForCount'),
    VoteAgainstCount: _emberFlexberryData.Projection.attr('VoteAgainstCount'),
    AbstainCount: _emberFlexberryData.Projection.attr('AbstainCount'),
    NotVoteCount: _emberFlexberryData.Projection.attr('NotVoteCount'),
    TerritoryId: _emberFlexberryData.Projection.attr('TerritoryId'),
    Type: _emberFlexberryData.Projection.attr('Type'),
    VotePersons: _emberFlexberryData.Projection.hasMany('omsu-passport/omsu-passport-voting-person', 'VotePersons', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      Url: _emberFlexberryData.Projection.attr('Url')
    })
  });

  exports['default'] = Model;
});