define('deputee-office/components/favorites/favorites-omsu-agenda-commitees', ['exports', 'ember', 'deputee-office/mixins/favorites-component-base'], function (exports, _ember, _deputeeOfficeMixinsFavoritesComponentBase) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsFavoritesComponentBase['default'], {
    serviceName: 'omsu-agenda-commitees.passport',
    agendaIdParamName: 'AgendaId',

    loadableModels: ['favorite', 'omsu-passport/omsu-passport-document', 'omsuagenda', 'omsuquestion', 'omsudepartment'],

    store: _ember['default'].inject.service(),
    favoritesService: _ember['default'].inject.service(),

    loadData: function loadData() {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        try {
          var store = _this2.get('store');

          return store.query('favorite', {
            Model: 'zs-passport/zs-passport-file'
          }).then(function (favorites) {
            var marked3$0 = [favoritesByTerritoryGenerator].map(regeneratorRuntime.mark);

            var territoryFavorites = {};

            favorites.toArray().forEach(function (favorite) {
              var params = JSON.parse(favorite.get('Params'));

              if (params.additionalParams && params.additionalParams.serviceName === _this2.get('serviceName')) {
                var territoryId = params.additionalParams.territoryId;

                if (territoryId) {
                  if (!territoryFavorites[territoryId]) {
                    territoryFavorites[territoryId] = new _ember['default'].A([]);
                  }

                  territoryFavorites[territoryId].pushObject(favorite);
                }
              }
            });

            var resultsPromises = new _ember['default'].A([]);
            var _this = _this2;

            // Генератор для синхронного выполнения загрузки документов ОМСУ
            // в зависимости от территории.
            function favoritesByTerritoryGenerator() {
              var territoryId;
              return regeneratorRuntime.wrap(function favoritesByTerritoryGenerator$(context$4$0) {
                while (1) switch (context$4$0.prev = context$4$0.next) {
                  case 0:
                    context$4$0.t0 = regeneratorRuntime.keys(territoryFavorites);

                  case 1:
                    if ((context$4$0.t1 = context$4$0.t0()).done) {
                      context$4$0.next = 7;
                      break;
                    }

                    territoryId = context$4$0.t1.value;
                    context$4$0.next = 5;
                    return _this.loadByTerritory(territoryId, territoryFavorites[territoryId]);

                  case 5:
                    context$4$0.next = 1;
                    break;

                  case 7:
                  case 'end':
                    return context$4$0.stop();
                }
              }, marked3$0[0], this);
            }

            var execute = function execute(generator) {
              var next = generator.next();

              if (!next.done) {
                resultsPromises.pushObject(next.value);
                next.value['finally'](function () {
                  return execute(generator);
                });
              } else {
                _ember['default'].RSVP.allSettled(resultsPromises, 'favorites-omsu-agenda-commitees: all for loadByTerritories').then(function (x) {
                  _this2.get('model').pushObjects(_this2.settledHelper(x));
                  resolve();
                })['catch'](function (e) {
                  reject();
                  console.error(e);
                });
              }
            };

            execute(favoritesByTerritoryGenerator());
          })['catch'](function (e) {
            reject();
            console.error(e);
          });
        } catch (e) {
          reject(e);
        }
      }, 'favorites-omsu-agenda-commitees: loadData');
    },

    init: function init() {
      this._super();
      this.set('model', _ember['default'].A([]));
      this.set('displayedModel', _ember['default'].A([]));
    },

    loadByTerritory: function loadByTerritory(territoryId, favorites) {
      var _this3 = this;

      var store = this.get('store');
      var favoritesService = this.get('favoritesService');

      var questionModelName = 'omsuquestion';
      var agendaModelName = 'omsuagenda';
      var questionDocumentName = 'omsu-passport/omsu-passport-document';
      var questionDocumentProjection = 'omsu-passport-document-full';

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].RSVP.Promise.resolve(favorites).then(function (favorites) {

          // Массив идентификаторов документов.
          var docIds = favoritesService.getUnique(favorites, 'docId');

          // Массив идентификаторов вопросов.
          var questionIds = favoritesService.getUnique(favorites, 'additionalParams.questionId');

          // Массив идентификаторов повесток, которые требуются для получения нужных вопрсов.
          var agendaIds = favoritesService.getUnique(favorites, 'additionalParams.agendaId');

          // Массив идентификаторов подразделений.
          var departmentIds = favoritesService.getUnique(favorites, 'additionalParams.departmentId');

          // Обертки для запросов получения документов.
          var wrappersForDocuments = favorites.map(function (favorite) {
            return JSON.parse(favorite.get('Params')).additionalParams;
          });

          var promises = [];

          wrappersForDocuments.forEach(function (item) {
            var promise = store.query(questionDocumentName, {
              PassportId: item.PassportId,
              TabName: item.tab,
              TerritoryId: territoryId,
              projection: questionDocumentProjection
            }).then(function (result) {

              var docsWrapper = {
                questionId: item.questionId,
                agendaId: item.agendaId,
                additionalParams: item,
                documents: result.toArray()
              };

              return docsWrapper;
            });
            promises.push(promise);
          });

          // Возвращает только те модели, которые нужны для избранного.
          var documentsPromise = _ember['default'].RSVP.allSettled(promises, 'favorites-omsu-agenda-commitees: documentsPromise').then(function (result) {
            return favoritesService.getUniqForModels(_this3.settledHelper(result), docIds, 'documents', function (item, source) {
              return new Object({
                agendaId: source.agendaId,
                questionId: source.questionId,
                additionalParams: source.additionalParams,
                documentItem: item
              });
            });
          })['catch'](function (e) {
            reject();
            console.error(e);
          });

          var agendasPromises = [];

          departmentIds.forEach(function (item) {
            agendasPromises.push(store.query(agendaModelName, {
              DepartmentId: item,
              TerritoryId: territoryId
            }));
          });

          // Промис для нахождения всех повесток.
          var agendasPromise = _ember['default'].RSVP.allSettled(agendasPromises, 'favorites-omsu-agenda-commitees: agendasPromise').then(function (result) {
            return favoritesService.getUniqForModels(_this3.settledHelper(result), agendaIds);
          })['catch'](function (e) {
            reject();
            console.error(e);
          });

          var qPromises = [];

          agendaIds.forEach(function (item) {
            var options = {};
            var agendaIdParamName = _this3.get('agendaIdParamName');
            options[agendaIdParamName] = item;
            options.TerritoryId = territoryId;
            qPromises.push(store.query(questionModelName, options));
          });

          var questionsPromise = _ember['default'].RSVP.allSettled(qPromises, 'favorites-omsu-agenda-commitees: questionsPromise').then(function (result) {
            return favoritesService.getUniqForModels(_this3.settledHelper(result), questionIds);
          })['catch'](function (e) {
            reject();
            console.error(e);
          });

          var promisesObject = {
            favorites: _ember['default'].RSVP.Promise.resolve(favorites),
            questions: questionsPromise,
            agendas: agendasPromise,
            documents: documentsPromise,
            departments: store.query('omsudepartment', {
              TerritoryId: territoryId
            })
          };

          _ember['default'].RSVP.hashSettled(promisesObject, 'Hash for all').then(function (_result) {
            var result = new Object({
              favorites: _result.favorites.state === 'fulfilled' ? _result.favorites.value : [],
              questions: _result.questions.state === 'fulfilled' ? _result.questions.value : [],
              agendas: _result.agendas.state === 'fulfilled' ? _result.agendas.value : [],
              documents: _result.documents.state === 'fulfilled' ? _result.documents.value : [],
              departments: _result.departments.state === 'fulfilled' ? _result.departments.value : []
            });

            // Массив идентификаторов файлов.
            var fileIds = _this3.get('favoritesService').getUnique(result.favorites, 'id');

            // Построение групп для вывода в шаблоне.
            var questionGroups = result.questions.map(function (questionItem) {
              var agenda = null;
              var agendaIdParamName = _this3.get('agendaIdParamName');

              result.agendas.forEach(function (agendaItem) {
                if (questionItem.get(agendaIdParamName) === agendaItem.get('id')) {
                  agenda = agendaItem;
                }
              });
              var documents = result.documents.filter(function (documentWrapperItem) {
                return documentWrapperItem.questionId === questionItem.get('id') && documentWrapperItem.agendaId === agenda.get('id');
              }).map(function (documentWrapperItem) {
                var files = documentWrapperItem.documentItem.get('Files').toArray();

                files = files.filter(function (file) {
                  return fileIds.indexOf(file.get('id')) !== -1;
                });

                var docToDisplay = {
                  id: documentWrapperItem.documentItem.get('id'),
                  title: documentWrapperItem.documentItem.get('Title'),
                  additionalParams: documentWrapperItem.additionalParams,
                  files: files,
                  displayFiles: _ember['default'].A([])
                };

                return docToDisplay;
              });

              var department = result.departments.find(function (item) {
                return item.get('id') === agenda.get('DepartmentId');
              });

              var questionGroup = {
                Department: department ? department.get('FullName') : null,
                Date: agenda.get('Date'),
                FormattedDate: agenda.get('FormattedDate'),
                DisplayedDate: agenda.get('DisplayedDate'),
                question: questionItem,
                documents: documents,
                documentsGroups: {}
              };

              return questionGroup;
            });

            resolve(questionGroups);
          })['catch'](function (e) {
            reject();
            console.error(e);
          });
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'loadByTerritory');
    },

    filterModel: function filterModel() {
      var model = this.get('model');
      var displayedModel = this.get('displayedModel');
      var filterText = this.get('searchString');

      displayedModel.clear();

      if (filterText && filterText.value) {
        model.forEach(function (questionGroup) {
          var groupAdded = false;

          _ember['default'].set(questionGroup, 'documentsGroups', {});

          questionGroup.documents.forEach(function (doc) {
            var docAdded = false;

            doc.displayFiles.clear();

            doc.files.forEach(function (file) {
              if (file.get('Name').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1) {
                if (!groupAdded) {
                  displayedModel.pushObject(questionGroup);
                  groupAdded = true;
                }

                if (!docAdded) {
                  if (!questionGroup.documentsGroups[doc.title]) {
                    questionGroup.documentsGroups[doc.title] = new _ember['default'].A([]);
                  }

                  questionGroup.documentsGroups[doc.title].pushObject(doc);

                  docAdded = true;
                }

                doc.displayFiles.pushObject(file);
              }
            });
          });
        });
      } else {
        model.forEach(function (questionGroup) {
          _ember['default'].set(questionGroup, 'documentsGroups', {});

          questionGroup.documents.forEach(function (doc) {
            doc.displayFiles.clear();
            doc.displayFiles.pushObjects(doc.files);

            if (!questionGroup.documentsGroups[doc.title]) {
              questionGroup.documentsGroups[doc.title] = new _ember['default'].A([]);
            }

            questionGroup.documentsGroups[doc.title].pushObject(doc);
          });

          displayedModel.pushObject(questionGroup);
        });
      }

      displayedModel.sortBy('Department', 'Date', 'question.Num');
    }
  });
});