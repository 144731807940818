define('deputee-office/adapters/legal-act-offline', ['exports', 'deputee-office/adapters/application-offline', 'ember-flexberry-data'], function (exports, _deputeeOfficeAdaptersApplicationOffline, _emberFlexberryData) {
  exports['default'] = _deputeeOfficeAdaptersApplicationOffline['default'].extend({
    query: function query(store, modelName, _query) {
      var filter = _query.filter;
      var page = _query.page;
      var perPage = _query.per_page;
      var queryInstance = _query instanceof _emberFlexberryData.Query.QueryObject;

      if (!queryInstance) {
        _query = { TerritoryId: _query.TerritoryId };
      }

      return this._super(store, modelName, _query).then(function (results) {
        results = results.data;
        if (filter) {
          results = results.filter(function (record) {
            if (filter.name && filter.name !== 'Все' && record.Name.toLowerCase().indexOf(filter.name.toLowerCase()) === -1 && record.FullRegNumber.toLowerCase().indexOf(filter.name.toLowerCase()) === -1) {
              return false;
            }

            if (filter.state && filter.state !== 'Все' && record.State !== filter.state) {
              return false;
            }

            if (filter.type && filter.type !== 'Все' && record.ActType !== filter.type) {
              return false;
            }

            if (filter.view && filter.view !== 'Все' && (filter.view === 'Нормативный' && record.Type !== 'Да' || filter.view === 'Ненормативный' && record.Type !== 'Нет')) {
              return false;
            }

            return true;
          });
        }

        // Сортировка по полям RegDate и RegNumber от большего к меньшему.
        function sortByRegDate(a, b) {
          if (a.RegDate < b.RegDate) {
            return 1;
          }

          if (a.RegDate > b.RegDate) {
            return -1;
          }

          if (a.RegDate === b.RegDate) {
            if (a.RegNumber < b.RegNumber) {
              return 1;
            }

            if (a.RegNumber > b.RegNumber) {
              return -1;
            }
          }
        }

        results.sort(sortByRegDate);

        // Оффлайн-пейджинг через slice.
        if (perPage && perPage !== 0) {
          var fromIndex = (page - 1) * perPage;
          var toIndex = page * perPage;

          results = results.slice(fromIndex, toIndex);
        }

        return { data: results };
      });
    }
  });
});