define('deputee-office/models/zs-department-passport', ['exports', 'ember-data', 'deputee-office/models/base-passport', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBasePassport, _ember) {
  exports['default'] = _deputeeOfficeModelsBasePassport['default'].extend({
    ZsDepartmentAgendaId: _emberData['default'].attr('guid'),
    OrderNum: _emberData['default'].attr('number'),
    Time: _emberData['default'].attr('string'),
    Type: _emberData['default'].attr('string'),
    Title: _emberData['default'].attr('string'),
    Decision: _emberData['default'].attr('string'),
    RegNumber: _emberData['default'].attr('string'),

    PassportHeaderPromiseObserver: _ember['default'].observer('PassportHeaderPromise', function () {
      var _this = this;
      this.set('PassportHeaderState', 'loading');
      var promise = this.get('PassportHeaderPromise');
      if (promise) {
        promise.then(function (data) {
          _this.set('PassportHeaderState', 'loaded');
          return data;
        })['catch'](function (reason) {
          _this.set('PassportHeaderState', 'loaded');
          return reason;
        });
      }
    }),

    Info: _ember['default'].computed('Title', function () {
      return this.get('Title');
    }),

    DecisionList: _ember['default'].computed('Decision', function () {
      var decision = this.get('Decision');
      if (decision) {
        return decision.split(';');
      }
    })
  });
});