define('deputee-office/initializers/inject-route-history', ['exports', 'ember-route-history/initializers/inject-route-history'], function (exports, _emberRouteHistoryInitializersInjectRouteHistory) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberRouteHistoryInitializersInjectRouteHistory['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberRouteHistoryInitializersInjectRouteHistory.initialize;
    }
  });
});