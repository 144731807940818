define('deputee-office/components/clock-component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    hours: null,
    minutes: null,
    line: null,
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var date = new Date();
      _ember['default'].run.later(this, function () {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        hours = hours < 10 ? '0' + hours : hours;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        this.set('hours', hours);
        this.set('minutes', minutes);
        this.set('line', this.line === ':' ? ' ' : ':');
      }, 1000);
    }
  });
});