define('deputee-office/routes/omsu-agenda-commitees', ['exports', 'deputee-office/routes/base-agenda-route'], function (exports, _deputeeOfficeRoutesBaseAgendaRoute) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoute['default'].extend({
    targetControllerName: 'omsu-agenda-commitees.index',
    passportLinkName: 'omsu-agenda-commitees.passport',
    searchText: null,
    withDepartment: true,
    selectPopoverId: 'selectPopoverId',

    getAgendaMembers: function getAgendaMembers(agendaId) {
      return this.store.query('omsu-passport/omsu-agenda-member', {
        AgendaId: agendaId,
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
    },

    getDepartmentQuery: function getDepartmentQuery() {
      var model = this.store.query('omsudepartment', {
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
      return model;
    },

    getAgendaQuery: function getAgendaQuery(agendaId) {
      var agendaRec = this.store.queryRecord('omsuagenda', {
        AgendaId: agendaId,
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
      return agendaRec;
    },

    getAgendaByDepartmentQuery: function getAgendaByDepartmentQuery(departmentId) {
      var agendaRec = this.store.query('omsuagenda', {
        DepartmentId: departmentId,
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
      return agendaRec;
    },

    getQuestionsQuery: function getQuestionsQuery(agendaId, territoryId) {
      var model = this.store.query('omsuquestion', {
        AgendaId: agendaId,
        TerritoryId: territoryId
      });
      return model;
    },

    unloadedFileCountUrl: function unloadedFileCountUrl(agendaId) {
      return this.get('settingsservice').getHost() + '/api/omsuquestions/unloadedAllFileCount/' + agendaId + '?TerritoryId=' + this.get('territoryService').getTerritoryId();
    },

    actions: {},

    activate: function activate() {
      this.setFlagAndNameBySetting();
    },
    deactivate: function deactivate() {
      this._super();
      this.setDefaultFlag();
    }
  });
});