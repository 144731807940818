define('deputee-office/controllers/login', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    isSubmitDisabled: true,
    isPromiseResolved: true,
    passwordState: 'password',
    tempId: '',
    windowHeight: _ember['default'].$(window).height() - 40 + 'px',
    rowHeight: (_ember['default'].$(window).height() - 40) / 3 + 'px',
    session: _ember['default'].inject.service('session'),
    content: {},
    RememberMe: false,
    clickedLogin: false,
    iconPassword: 'icon-closed-eye',
    authenticator: 'authenticator:custom',

    fieldsChanged: _ember['default'].observer('identification', 'password', function () {
      var login = this.get('identification');
      var password = this.get('password');
      if (login && login.length > 0 && password && password.length > 0) {
        this.set('isSubmitDisabled', false);
      } else {
        this.set('isSubmitDisabled', true);
      }
    }),

    passwordStateChanged: _ember['default'].observer('passwordState', function () {
      if (this.get('passwordState') === 'text') {
        this.set('iconPassword', 'icon-opened-eye');
      } else {
        this.set('iconPassword', 'icon-closed-eye');
      }
    }),

    handleResize: function handleResize() {
      this.set('windowHeight', _ember['default'].$(window).height() - 40 + 'px');
      this.set('rowHeight', (_ember['default'].$(window).height() - 40) / 3 + 'px');
    },

    errorCleaner: _ember['default'].observer('password', 'identification', function () {
      this.set('errorMessage');
    }),

    bindResizeEvent: (function () {
      _ember['default'].$(window).on('resize', _ember['default'].run.bind(this, this.handleResize));
    }).on('init'),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        if (!this.get('isSubmitDisabled') && this.get('isPromiseResolved')) {
          this.set('isPromiseResolved', false);
          _ember['default'].$('button.login').focus();
          this.set('clickedLogin', true);
          var credentials = this.getProperties('identification', 'password');
          var rememberme = this.get('RememberMe');
          this.get('session').authenticate(this.get('authenticator'), credentials)['catch'](function (reason) {
            _this.set('errorMessage', reason.error || reason);
          })['finally'](function () {
            if (!_this.get('isDestroyed')) {
              _this.set('isPromiseResolved', true);
              _this.set('clickedLogin', false);
            }
          });

          var setting = this.get('settingsservice').getNetworkSettings();

          setting.set('RememberMe', rememberme);

          if (rememberme) {
            setting.set('Login', credentials.identification);
            setting.set('Password', credentials.password);
          } else {
            setting.set('Login', '');
            setting.set('Password', '');
          }

          setting.save();
        }
      },

      authenticateGuest: function authenticateGuest() {
        var _this2 = this;

        if (this.get('isPromiseResolved')) {
          this.set('isPromiseResolved', false);
          this.get('session').authenticate(this.get('authenticator'), {
            identification: 'guest',
            password: 'guest'
          })['catch'](function (reason) {
            _this2.set('errorMessage', reason.error || reason);
          })['finally'](function () {
            if (!_this2.get('isDestroyed')) {
              _this2.set('isPromiseResolved', true);
            }
          });
        }
      },

      showPassword: function showPassword() {
        if (this.get('passwordState') === 'text') {
          this.set('passwordState', 'password');
        } else {
          _ember['default'].$('#passfield').focus();
          this.set('passwordState', 'text');
        }
      },

      passFocus: function passFocus() {
        _ember['default'].$('#passfield').focus();
      },

      focusInPass: function focusInPass() {
        this.set('passwordState', 'password');
      },

      focusedLogin: function focusedLogin() {
        this.set('tempId', this.get('identification'));
      },

      clearPassword: function clearPassword() {
        if (this.get('tempId') !== this.get('identification') && this.get('password').length > 0) {
          this.set('password', '');
        }
      },

      changeRme: function changeRme() {
        this.set('RememberMe', this.get('RememberMe') ? false : true);
      }
    }
  });
});