define('deputee-office/mixins/group-by', ['exports', 'ember'], function (exports, _ember) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _ember['default'].Mixin.create({
    getGrouped: function getGrouped(model, groupBy, groupPropertyName) {
      groupPropertyName = groupPropertyName ? groupPropertyName : 'name';
      groupBy = groupBy ? groupBy : 'Type';

      var result = _ember['default'].A([]);
      var idx = [];
      model.forEach(function (record) {
        var _type = record.get(groupBy);
        if (_type === '') {
          return;
        }

        if (!result.isAny(groupPropertyName, _type)) {
          var _result$pushObject;

          idx[_type] = _ember['default'].A([]);
          result.pushObject((_result$pushObject = {}, _defineProperty(_result$pushObject, groupPropertyName, _type), _defineProperty(_result$pushObject, 'items', idx[_type]), _result$pushObject));
        }

        idx[_type].pushObject(record);
      });
      return result;
    }
  });
});