define('deputee-office/routes/legal-acts', ['exports', 'ember', 'deputee-office/mixins/infinity-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/territory-flag-name'], function (exports, _ember, _deputeeOfficeMixinsInfinityRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsTerritoryFlagName) {

  var filterValues = {
    types: {
      all: 'Все',
      base: 'Базовый',
      change: 'Изменения',
      powerLose: 'Утрата силы',
      cancel: 'Отмена',
      award: 'Награждение',
      firstReading: 'Первое чтение',
      agreement: 'Согласование проекта закона'
    },
    views: {
      all: 'Все',
      normative: 'Нормативный',
      notNormative: 'Ненормативный'
    },
    states: {
      all: 'Все',
      intoForce: 'Вступил в силу',
      changed: 'Изменен',
      repealed: 'Утратил силу',
      suspended: 'Приостановлен',
      project: 'Проект',
      canceled: 'Отменен',
      accepted: 'Принят'
    }
  };

  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsInfinityRoute['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], {

    controllerName: 'legal-acts.index',

    modelLoadingWrapper: {
      value: false
    },

    modelLoading: _ember['default'].computed.alias('modelLoadingWrapper.value'),

    model: function model(params, transition) {
      var _this = this;

      this.set('modelLoading', true);

      if (!this.get('filter')) {
        var _filter2 = {
          name: '',
          type: filterValues.types.all,
          view: filterValues.views.all,
          state: filterValues.states.all
        };
        this.set('filter', _filter2);
      }

      this.set('curentRouteName', transition.targetName);

      var filter = this.get('filter');

      this.infinityModel('legal-act', { perPage: 10, startingPage: 1, TerritoryId: this.get('territoryService').getTerritoryId(), filter: filter }).then(function (legalActs) {

        var controller = _this.controller;
        controller.set('model', legalActs);

        if (!controller.get('filter')) {
          controller.set('filterValues', filterValues);
          controller.set('filter', filter);
        }

        _this.set('modelLoading', false);
      })['catch'](function (reason) {
        console.error(reason);
        _this.set('modelLoading', false);
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var modelLoading = this.get('modelLoadingWrapper');
      controller.set('modelLoading', modelLoading);
    },

    activate: function activate() {
      this.setFlagAndNameBySetting();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition) {
        controller.set('filter', null);
        controller.set('model', null);
        this.set('searchText', null);
        this.set('filter', null);
        this.set('filteredDepartments', false);
      }
    },

    renderTemplate: function renderTemplate() {
      this._super();
      if (this.get('curentRouteName').indexOf('legal-acts.passport') < 0) {
        this.render('search-box', {
          outlet: 'toolbar-right-outlet',
          controller: this.controllerFor('legal-acts.legal-acts-search'),
          model: { searchText: this.get('searchText') }
        });
      }
    },

    actions: {
      typeChanged: function typeChanged(type) {
        this.set('type', type);
      },

      willTransition: function willTransition(transition) {
        if (transition.targetName.indexOf('legal-acts.passport') > -1) {
          this.disconnectOutlet('toolbar-right-outlet');
        } else {
          this.render('search-box', {
            outlet: 'toolbar-right-outlet',
            controller: this.controllerFor('legal-acts.legal-acts-search'),
            model: { searchText: this.get('searchText') }
          });
        }

        return true;
      },
      search: function search(text) {
        var filter = this.get('filter');
        _ember['default'].set(filter, 'name', text);
        this.set('searchText', text);
        this.send('filter', filter);
      },

      filter: function filter(_filter) {
        this.set('filter', _filter);

        this.refresh();
      },

      clear: function clear(filter) {
        if (filter != null && filter !== undefined) {
          _ember['default'].set(filter, 'name', '');
          _ember['default'].set(filter, 'type', filterValues.types.all);
          _ember['default'].set(filter, 'view', filterValues.views.all);
          _ember['default'].set(filter, 'state', filterValues.states.all);
          this.send('filter', filter);
        }
      }
    }
  });
});