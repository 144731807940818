define('deputee-office/initializers/flexberry-enum', ['exports', 'ember-flexberry-data/initializers/flexberry-enum'], function (exports, _emberFlexberryDataInitializersFlexberryEnum) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberFlexberryDataInitializersFlexberryEnum['default'];
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function get() {
      return _emberFlexberryDataInitializersFlexberryEnum.initialize;
    }
  });
});