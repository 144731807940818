define('deputee-office/helpers/longtext-ellipsis', ['exports', 'ember'], function (exports, _ember) {

  var longtextEllipsis = function longtextEllipsis(params /*, hash*/) {
    var text = params[0];
    var textlength = params[1];
    var ellipsisType = params[2] || 'word';
    if (!text) {
      return text;
    }

    var str = text;
    var width = _ember['default'].$(window).width();
    var maxChars = 180;
    var charMultiplier = width / 1920;
    var screenMultiplier = 0.4 * (1 - charMultiplier);
    var countChars = Math.round((1 - screenMultiplier) * (charMultiplier * maxChars));

    if (textlength) {
      if (text.length > textlength) {
        str = text.substr(0, textlength);
        if (ellipsisType === 'word') {
          str = str.substr(0, str.regexLastIndexOf(/\s/)) + '...';
        } else {
          str += '...';
        }
      }
    } else {
      if (text.length > countChars) {
        str = text.substr(0, countChars);
        str = str.substr(0, str.regexLastIndexOf(/\s/)) + '...';
      }
    }

    return str;
  };

  exports['default'] = _ember['default'].Helper.helper(longtextEllipsis);
});