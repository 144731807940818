define('deputee-office/models/omsu-passport', ['exports', 'ember-data', 'deputee-office/models/base-passport', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBasePassport, _ember) {

  var Model = _deputeeOfficeModelsBasePassport['default'].extend({
    AgendaId: _emberData['default'].attr('string'),
    AgendaName: _emberData['default'].attr('string'),
    TerritoryId: _emberData['default'].attr('guid'),
    PassportHeaderPromiseObserver: _ember['default'].observer('PassportHeaderPromise', function () {
      var _this = this;
      this.set('PassportHeaderState', 'loading');
      var promise = this.get('PassportHeaderPromise');
      promise.then(function (data) {
        _this.set('PassportHeaderState', 'loaded');
        return data;
      })['catch'](function (reason) {
        _this.set('PassportHeaderState', 'loaded');
        return reason;
      });
    })
  });

  exports['default'] = Model;
});