define('deputee-office/routes/laws', ['exports', 'ember', 'deputee-office/mixins/infinity-route', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/territory-flag-name'], function (exports, _ember, _deputeeOfficeMixinsInfinityRoute, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsTerritoryFlagName) {

  var filterValues = {
    types: {
      law: 'Закон',
      decree: 'Постановление'
    },
    readings: {
      all: 'Все',
      first: 'Первое чтение',
      second: 'Второе чтение',
      third: 'Третье чтение'
    }
  };

  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsInfinityRoute['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], {

    controllerName: 'laws.index',

    modelLoadingWrapper: {
      value: false
    },

    modelLoading: _ember['default'].computed.alias('modelLoadingWrapper.value'),

    model: function model(params, transition) {
      var _this2 = this;

      this.set('modelLoading', true);

      if (!this.get('filter')) {
        var filter = {
          name: '',
          type: filterValues.types.law,
          readings: filterValues.readings,
          reading: filterValues.readings.all,
          committees: null,
          committee: null
        };
        this.set('type', filter.type);
        this.set('filter', filter);
      }

      var _this = this;

      this.set('curentRouteName', transition.targetName);

      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var filteredDepartments = _this.get('filteredDepartments');

        if (filteredDepartments) {
          resolve();
        } else {
          _this.store.findAll('zs-department').then(function (results) {
            return results.filter(function (department) {
              if (department.get('Type') === 'КОМИТЕТЫ') {
                return true;
              }

              return false;
            }).map(function (committee) {
              return committee.get('Name');
            });
          }).then(function (filteredDepartments) {
            var filter = _this.get('filter');
            filteredDepartments.unshift('Все');
            filter.committees = filteredDepartments;
            filter.committee = filteredDepartments[0];
            _this.set('filteredDepartments', true);
            resolve();
          })['catch'](function (reason) {
            reject(reason);
          });
        }
      });

      promise.then(function () {
        var filter = _this.get('filter');
        return _this.infinityModel('law', {
          perPage: 10, startingPage: 1,
          filter: { committee: filter.committee, reading: filter.reading, name: filter.name, type: filter.type }
        });
      }).then(function (laws) {
        var filter = _this.get('filter');
        var controller = _this.controller;
        controller.set('model', laws);

        if (!controller.get('filter')) {
          controller.set('filterValues', filterValues);
          controller.set('filter', filter);
        }

        var type = _this2.get('type');
        controller.set('type', type);
        _this2.set('modelLoading', false);
      })['catch'](function (reason) {
        console.error(reason);
        _this2.set('modelLoading', false);
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var modelLoading = this.get('modelLoadingWrapper');
      controller.set('modelLoading', modelLoading);
    },

    activate: function activate() {
      this.setFlagAndNameForZS();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition) {
        controller.set('filter', null);
        controller.set('model', null);
        this.set('searchText', null);
        this.set('filter', null);
        this.set('filteredDepartments', false);
      }
    },

    renderTemplate: function renderTemplate() {
      this._super();
      var device = this.get('devicejs');
      if (this.get('curentRouteName').indexOf('laws.passport') < 0) {
        if (!device.isPhone()) {
          this.render('search-box', {
            outlet: 'toolbar-right-outlet',
            controller: this.controllerFor('laws.laws-search'),
            model: { searchText: this.get('searchText') }
          });
        }
      }
    },

    actions: {

      typeChanged: function typeChanged(type) {
        this.set('type', type);
      },

      willTransition: function willTransition(transition) {
        var device = this.get('devicejs');
        if (transition.targetName.indexOf('laws.passport') > -1) {
          if (!device.isPhone()) {
            this.disconnectOutlet('toolbar-right-outlet');
          }
        } else {
          if (!device.isPhone()) {
            this.render('search-box', {
              outlet: 'toolbar-right-outlet',
              controller: this.controllerFor('laws.laws-search'),
              model: { searchText: this.get('searchText') }
            });
          }
        }

        return true;
      },
      search: function search(text) {
        var filter = this.get('filter');
        _ember['default'].set(filter, 'name', text);
        this.set('searchText', text);
        this.send('filter', filter);
      },

      filter: function filter(_filter) {
        this.set('filter', _filter);

        this.refresh();
      },

      clear: function clear(filter) {
        if (filter != null && filter !== undefined) {
          _ember['default'].set(filter, 'name', '');
          _ember['default'].set(filter, 'type', filterValues.types.law);
          _ember['default'].set(filter, 'committee', '');
          this.send('filter', filter);
        }
      }
    }
  });
});