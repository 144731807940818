define('deputee-office/components/full-calendar', ['exports', 'ember', 'deputee-office/templates/components/full-calendar', 'ember-invoke-action', 'moment'], function (exports, _ember, _deputeeOfficeTemplatesComponentsFullCalendar, _emberInvokeAction, _moment) {
  exports['default'] = _ember['default'].Component.extend(_emberInvokeAction.InvokeActionMixin, {

    /////////////////////////////////////
    // PROPERTIES
    /////////////////////////////////////

    layout: _deputeeOfficeTemplatesComponentsFullCalendar['default'],
    classNames: ['full-calendar'],

    /////////////////////////////////////
    // FULLCALENDAR OPTIONS
    /////////////////////////////////////

    // scheduler defaults to non-commercial license
    schedulerLicenseKey: _ember['default'].computed(function () {

      // load the consuming app's config
      var applicationConfig = _ember['default'].getOwner(this).lookupFactory('config:environment');
      var defaultSchedulerLicenseKey = 'CC-Attribution-NonCommercial-NoDerivatives';

      if (applicationConfig && applicationConfig.emberFullCalendar && applicationConfig.emberFullCalendar.schedulerLicenseKey) {
        return applicationConfig.emberFullCalendar.schedulerLicenseKey;
      }

      return defaultSchedulerLicenseKey;
    }),

    fullCalendarOptions: [

    // general display
    'header', 'customButtons', 'buttonIcons', 'theme', 'themeButtonIcons', 'firstDay', 'isRTL', 'weekends', 'hiddenDays', 'fixedWeekCount', 'weekNumbers', 'weekNumberCalculation', 'businessHours', 'height', 'contentHeight', 'aspectRatio', 'handleWindowResize', 'eventLimit',

    // timezone
    'timezone', 'now',

    // views
    'views', 'defaultView',

    // agenda options
    'allDaySlot', 'allDayText', 'slotDuration', 'slotLabelFormat', 'slotLabelInterval', 'snapDuration', 'scrollTime', 'minTime', 'maxTime', 'slotEventOverlap',

    // current date
    'defaultDate',

    // text/time customization
    'lang', 'timeFormat', 'columnFormat', 'titleFormat', 'buttonText', 'monthNames', 'monthNamesShort', 'dayNames', 'dayNamesShort', 'weekNumberTitle', 'displayEventTime', 'displayEventEnd', 'eventLimitText', 'dayPopoverFormat',

    // selection
    'selectable', 'selectHelper', 'unselectAuto', 'unselectCancel', 'selectOverlap', 'selectConstraint',

    // event data
    'events', 'eventSources', 'allDayDefault', 'startParam', 'endParam', 'timezoneParam', 'lazyFetching', 'defaultTimedEventDuration', 'defaultAllDayEventDuration', 'forceEventDuration',

    // event rendering
    'eventColor', 'eventBackgroundColor', 'eventBorderColor', 'eventTextColor', 'nextDayThreshold', 'eventOrder',

    // event dragging & resizing
    'editable', 'eventStartEditable', 'eventDurationEditable', 'dragRevertDuration', 'dragOpacity', 'dragScroll', 'eventOverlap', 'eventConstraint',

    // dropping external elements
    'droppable', 'dropAccept',

    // timeline view
    'resourceAreaWidth', 'resourceLabelText', 'resourceColumns', 'slotWidth', 'slotDuration', 'slotLabelFormat', 'slotLabelInterval', 'snapDuration', 'minTime', 'maxTime', 'scrollTime',

    // resource data
    'resources', 'eventResourceField',

    // resource rendering
    'resourceOrder', 'resourceGroupField', 'resourceGroupText'],

    fullCalendarEvents: [

    // general display
    'viewRender', 'viewDestroy', 'dayRender', 'windowResize',

    // clicking and hovering
    'dayClick', 'eventClick', 'eventMouseover', 'eventMouseout',

    // selection
    'select', 'unselect',

    // event data
    'eventDataTransform', 'loading',

    // event rendering
    'eventRender', 'eventAfterRender', 'eventAfterAllRender', 'eventDestroy',

    // event dragging & resizing
    'eventDragStart', 'eventDragStop', 'eventDrop', 'eventResizeStart', 'eventResizeStop', 'eventResize',

    // dropping external events
    'drop', 'eventReceive',

    // timeline view
    'dayClick',

    // resource data
    'loading',

    // resource rendering
    'resourceText', 'resourceRender'],

    /////////////////////////////////////
    // SETUP/TEARDOWN
    /////////////////////////////////////

    didInsertElement: function didInsertElement() {
      var options = Object.assign(this.get('options'), this.get('actions1'));

      // add the license key for the scheduler
      options.schedulerLicenseKey = this.get('schedulerLicenseKey');

      var fc = _ember['default'].$('.full-calendar').fullCalendar(options);
      this.setUpMessаge();

      // Замена иконок кнопок "назад" и "вперед" на корректные версии из bootstrap.
      // Сделано через jQuery, а не через options, т.к. при передаче класса через
      // опцию fullCalendar добавляет строку к названию класса.
      _ember['default'].$('.fc-prev-button>span').removeClass('ui-icon ui-icon-circle-triangle-w');
      _ember['default'].$('.fc-prev-button>span').addClass('glyphicon glyphicon-menu-left');
      _ember['default'].$('.fc-next-button>span').removeClass('ui-icon ui-icon-circle-triangle-e');
      _ember['default'].$('.fc-next-button>span').addClass('glyphicon glyphicon-menu-right');

      if (this.get('dateParam')) {
        fc.fullCalendar('gotoDate', (0, _moment['default'])(this.get('dateParam'), 'MM.YYYY'));
      }

      if (this.get('devicejs').isPhone()) {
        var height = _ember['default'].$(window).height() - this.$().offset().top;
        height = Math.round(height);
        fc.fullCalendar('changeView', 'listMonth');
        fc.fullCalendar('option', 'height', height);
      }

      return fc;
    },

    addButtonHandlers: function addButtonHandlers() {
      var _this = this;
      _ember['default'].$('.fc-prev-button').click(function () {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
          args[_key] = arguments[_key];
        }

        _ember['default'].run.schedule('actions', _this, function () {
          _this.invokeAction.apply(_this, ['onPrev'].concat(args));
        });
      });

      _ember['default'].$('.fc-next-button').click(function () {
        for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
          args[_key2] = arguments[_key2];
        }

        _ember['default'].run.schedule('actions', _this, function () {
          _this.invokeAction.apply(_this, ['onNext'].concat(args));
        });
      });

      _ember['default'].$('.fc-today-button').click(function () {
        for (var _len3 = arguments.length, args = Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
          args[_key3] = arguments[_key3];
        }

        _ember['default'].run.schedule('actions', _this, function () {
          _this.invokeAction.apply(_this, ['onToday'].concat(args));
        });
      });
    },

    setUpMessаge: function setUpMessGe() {
      var loading = 'Загрузка...';
      var notFoung = 'По вашему запросу ничего не нашлось';
      if (this.get('isLoading')) {
        _ember['default'].$('.full-calendar').fullCalendar('option', 'noEventsMessage', loading);
        this.addButtonHandlers();
      } else {
        _ember['default'].$('.full-calendar').fullCalendar('option', 'noEventsMessage', notFoung);
        this.addButtonHandlers();
      }
    },

    isLoadingObserver: _ember['default'].observer('isLoading', function () {
      this.setUpMessаge();
    }),

    /////////////////////////////////////
    // COMPUTED PROPERTIES
    /////////////////////////////////////

    /**
     * Returns all of the valid Fullcalendar options that
     * were passed into the component.
     */
    options: _ember['default'].computed(function () {
      var _this2 = this;

      var fullCalendarOptions = this.get('fullCalendarOptions');
      var options = {};

      fullCalendarOptions.forEach(function (optionName) {
        if (_this2.get(optionName) !== undefined) {
          options[optionName] = _this2.get(optionName);
        }
      });

      return options;
    }),

    /**
     * Returns all of the valid Fullcalendar callback event
     * names that were passed into the component.
     */
    usedEvents: _ember['default'].computed('fullCalendarEvents', function () {
      var _this3 = this;

      return this.get('fullCalendarEvents').filter(function (eventName) {
        var methodName = '_' + eventName;
        return _this3.get(methodName) !== undefined || _this3.get(eventName) !== undefined;
      });
    }),

    /**
     * Returns an object that contains a function for each action passed
     * into the component. This object is passed into Fullcalendar.
     */
    actions1: _ember['default'].computed(function () {
      var _this4 = this;

      var actions = {};

      this.get('usedEvents').forEach(function (eventName) {

        // create an event handler that runs the function inside an event loop.
        actions[eventName] = function () {
          for (var _len4 = arguments.length, args = Array(_len4), _key4 = 0; _key4 < _len4; _key4++) {
            args[_key4] = arguments[_key4];
          }

          _ember['default'].run.schedule('actions', _this4, function () {
            _this4.invokeAction.apply(_this4, [eventName].concat(args));
          });
        };
      });

      return actions;
    })

  });
});