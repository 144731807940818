define('deputee-office/models/omsu-passport/omsu-passport-correction', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    Num: _emberData['default'].attr('number'),
    Location: _emberData['default'].attr('string'),
    Text: _emberData['default'].attr('string'),
    Initiator: _emberData['default'].attr('string'),
    WorkGroupDesicion: _emberData['default'].attr('string'),
    OmsuDesicion: _emberData['default'].attr('string'),
    FromConclision: _emberData['default'].attr('boolean'),
    VoteResult: _emberData['default'].attr('string'),
    FromConclisionString: _ember['default'].computed('FromConclision', function () {
      return this.get('FromConclision') ? 'Да' : 'Нет';
    })
  });
});