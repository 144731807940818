define('deputee-office/components/main-services-view', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    classNames: ['servicesContainer'],
    tile: '186',
    cellsCount: _ember['default'].computed(function () {
      var wWidth = _ember['default'].$(window).width();
      var columnsCount = 6;
      var twidth = this.get('tile');
      columnsCount = Math.floor(wWidth / twidth);
      return columnsCount;
    }),
    customPadding: _ember['default'].computed(function () {
      var wWidth = _ember['default'].$(window).width();
      var twidth = this.get('tile');
      var columnsCount = this.get('cellsCount');
      return Math.round((wWidth - twidth * columnsCount) / 2);
    }),
    visibleItems: _ember['default'].computed.filter('items', function (item) {
      return item.IsVisible;
    }),
    itemsByGroups: _ember['default'].computed('items.[]', function () {
      var itemsArray = this.get('items');
      var tempA = _ember['default'].A([]);
      var countElemntInGroups = this.get('cellsCount');
      var countGroups = Math.ceil(itemsArray.length / countElemntInGroups);
      for (var i = 0; i < countGroups; i++) {
        var endIndex = (i + 1) * countElemntInGroups > itemsArray ? -1 : (i + 1) * countElemntInGroups;
        tempA[i] = itemsArray.slice(i * countElemntInGroups, endIndex);
      }

      return tempA;
    }),
    firstGroup: _ember['default'].computed('itemsByGroups', function () {
      return this.get('itemsByGroups').slice(0, 2);
    }),
    secondGroup: _ember['default'].computed('itemsByGroups', function () {
      return this.get('itemsByGroups').slice(2);
    }),
    hideRow: true,
    actions: {
      sendStat: function sendStat(item) {
        this.get('statisticsService').sendStatEvent('OpenService', item.id);
      },
      showHiddenServices: function showHiddenServices() {
        if (this.get('hideRow')) {
          _ember['default'].$('.row-eq-height#hiddenGroup').show('slow');
        } else {
          _ember['default'].$('.row-eq-height#hiddenGroup').hide('slow');
        }

        this.set('hideRow', this.get('hideRow') ? false : true);
      }
    }
  });
});