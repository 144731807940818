define('deputee-office/models/zs-department', ['exports', 'ember-data', 'deputee-office/models/base', 'deputee-office/mixins/department-short-name', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _deputeeOfficeMixinsDepartmentShortName, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend(_deputeeOfficeMixinsDepartmentShortName['default'], {
    Name: _emberData['default'].attr('string'),
    FullName: _emberData['default'].attr('string'),
    Type: _emberData['default'].attr('string'),
    modelType: _ember['default'].computed('Type', function () {
      var modelType = 'committee';

      return modelType;
    })
  });

  exports['default'] = Model;
});