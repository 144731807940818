define('deputee-office/components/agenda-refresh', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    editTime: null,
    agendaStateService: _ember['default'].inject.service('agenda-state'),
    agendaDeepObservationService: _ember['default'].inject.service(),

    onAgendaUpdate: function onAgendaUpdate(agendaId) {
      if (this.get('agenda.id') === agendaId) {
        this.sendAction('updateAgenda');
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.get('agendaDeepObservationService').off('agendaUpdate', this, this.onAgendaUpdate);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var id = this.get('agenda.id');
      if (id) {
        this.get('agendaDeepObservationService').off('agendaUpdate', this, this.onAgendaUpdate);
        this.get('agendaDeepObservationService').on('agendaUpdate', this, this.onAgendaUpdate);
      }
    }
  });
});