define('deputee-office/models/yp-question', ['exports', 'ember-data', 'deputee-office/models/base-question', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBaseQuestion, _ember) {

  var Model = _deputeeOfficeModelsBaseQuestion['default'].extend({
    ShortInfo: _emberData['default'].attr('string'),
    Reading: _emberData['default'].attr('number'),
    ReadingString: _ember['default'].computed('Reading', function () {
      var reading = this.get('Reading');
      if (reading === 1) {
        return 'Первое';
      }

      if (reading === 2) {
        return 'Второе';
      }

      if (reading === 3) {
        return 'Третье';
      }
    }),
    SpeakerString: _ember['default'].computed('Speaker', function () {
      return 'Докладчик: ' + this.get('Speaker');
    }),
    CoSpeakerString: _ember['default'].computed('CoSpeaker', function () {

      var coSpeaker = this.get('CoSpeaker');
      var prefix;
      if (coSpeaker.split(',').length > 1) {
        prefix = 'Содокладчики: ';
      } else {
        prefix = 'Содокладчик: ';
      }

      return prefix + this.get('CoSpeaker');
    })

  });

  exports['default'] = Model;
});