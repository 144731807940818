define('deputee-office/components/search-box', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    searchText: null,
    searchPlaceholder: 'Поиск',
    visibleSearchField: false,
    actions: {
      clear: function clear() {
        this.set('searchText', '');
        this.sendAction('search', '');
      },
      showSearchField: function showSearchField() {
        this.toggleProperty('visibleSearchField');
        this.update();
      }
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.$('.searchInput').on('clearSearchText', function () {
        _this2.send('clear');
      });
    },

    didRender: function didRender() {
      if (this.get('searchText')) {
        this.set('visibleSearchField', true);
        this.update();
      }
    },

    getToolbarHeight: function getToolbarHeight() {
      if (_ember['default'].$('.toolBar').outerHeight()) {
        return _ember['default'].$('.toolBar').outerHeight();
      } else {
        _ember['default'].$('.toolBar').removeClass('dNone');
        var h = _ember['default'].$('.toolBar').outerHeight();
        _ember['default'].$('.toolBar').addClass('dNone');
        return h;
      }
    },

    update: function update() {
      var _this3 = this;

      var isVisible = this.get('visibleSearchField');
      var _this = this;
      if (isVisible) {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          _this.$('input').focus();
          var tempH = this.getToolbarHeight();
          _ember['default'].$('.row.searchField').parent().css('padding-top', tempH);
          _ember['default'].$('.row.searchField').parents('.scrollable-div').height(function (i, h) {
            return h + tempH;
          });
          _ember['default'].$('.toolBar').addClass('dNone');
          _ember['default'].run.later(this, function () {
            if (_this.get('searchText')) {
              _this.$('.searchField').css('background-color', 'white');
              _this.$('.searchFade').css('display', 'none');
            }
          });
        });

        this.one('keyUp', _this.$('input'), function () {
          _this.$('.searchField').css('background-color', 'white');
          _this.$('.searchFade').css('display', 'none');
        });
      } else {
        (function () {
          _ember['default'].$('.toolBar').removeClass('dNone');
          var tempH = _ember['default'].$('.toolBar').outerHeight();
          _ember['default'].$('.row.searchField').parent().css('padding-top', 0);
          _ember['default'].$('.row.searchField').parents('.scrollable-div').height(function (i, h) {
            return h - tempH;
          });
          if (_this3.get('searchText')) {
            _this3.send('clear');
          }

          _this3.off('keyUp', _this.$('input'));
        })();
      }
    }
  });
});