define('deputee-office/components/session-timer', ['exports', 'ember', 'deputee-office/utils/count-text'], function (exports, _ember, _deputeeOfficeUtilsCountText) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    didInsertElement: function didInsertElement() {
      var _reset = this.reset.bind(this);
      this.set('_reset', _reset);
      document.addEventListener('resume', _reset, false);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this.reset();
    },

    reset: function reset() {
      _ember['default'].run.cancel(this.get('lastRun'));
      this.run();
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      document.removeEventListener('resume', this.get('_reset'), false);
      _ember['default'].run.cancel(this.get('lastRun'));
    },

    run: function run() {
      var _this = this;

      var timeString = this.get('timeString');
      var timeTicks = this.get('timeTicks');
      var diffMs = 0;

      var second = 1000;
      var minute = 60 * second;
      var hour = 60 * minute;
      var day = 24 * hour;
      var today = new Date();

      if (timeString) {
        var sessionDate = new Date(timeString);

        var sessionDateWithoutOffcet = sessionDate.getTime() + sessionDate.getTimezoneOffset() * minute;
        var todayWithoutOffcet = today.getTime() + today.getTimezoneOffset() * minute;

        diffMs = sessionDateWithoutOffcet - todayWithoutOffcet;
      } else {
        diffMs = timeTicks;
      }

      if (diffMs > 0) {
        var diffDays;
        var diffHrs;
        var diffMins;
        var diffSec;

        (function () {
          _this.sendAction('timerIsRun');
          _this.set('Running', true);

          diffDays = Math.floor(diffMs / day);
          diffHrs = Math.floor(diffMs % day / hour);
          diffMins = Math.floor(diffMs % day % hour / minute);
          diffSec = Math.floor(diffMs % day % hour % minute / second);

          var everyMinute = function everyMinute(minSkip) {

            if (timeString) {
              today = new Date();

              sessionDateWithoutOffcet = sessionDate.getTime() + sessionDate.getTimezoneOffset() * minute;
              todayWithoutOffcet = today.getTime() + today.getTimezoneOffset() * minute;

              diffMs = sessionDateWithoutOffcet - todayWithoutOffcet;
            } else {
              diffMs = timeTicks - minSkip * minute;
            }

            diffDays = Math.floor(diffMs / day);
            diffHrs = Math.floor(diffMs % day / hour);
            diffMins = Math.floor(diffMs % day % hour / minute);
            diffSec = Math.floor(diffMs % day % hour % minute / second);

            if (diffMins < 2) {

              // Условие, при котором происходит запуск секундного таймера,
              // и выход из текущего (минутного).
              if (diffHrs === 0 && diffDays === 0 && diffMins < 2) {
                var everySecond;

                var _ret2 = (function () {
                  var remainingSeconds = diffSec + diffMins * 60;

                  everySecond = function everySecond() {
                    if (remainingSeconds > 0) {
                      if (remainingSeconds >= 60) {
                        _this.set('timeStringFormatted', diffMins + ' ' + _this.getMinutesName(diffMins));
                      } else {
                        _this.set('timeStringFormatted', remainingSeconds + ' ' + _this.getSecondsName(remainingSeconds));
                      }

                      remainingSeconds--;

                      var lastRun = _ember['default'].run.later(_this, everySecond, second);
                      _this.set('lastRun', lastRun);
                    } else {
                      _this.sendAction('timerIsOver');
                      _this.set('Running', false);
                      return;
                    }
                  };

                  everySecond();

                  return {
                    v: undefined
                  };
                })();

                if (typeof _ret2 === 'object') return _ret2.v;
              }
            }

            var result = undefined;

            if (diffDays > 0) {
              result = diffDays + ' ' + _this.getDaysName(diffDays) + ' ' + (diffHrs ? diffHrs + ' ' + _this.getHoursName(diffHrs) : '');
            } else if (diffHrs > 0) {
              result = diffHrs + ' ' + _this.getHoursName(diffHrs) + ' ' + (diffMins ? diffMins + ' ' + _this.getMinutesName(diffMins) : '');
            } else if (diffMins > 0) {
              result = diffMins + ' ' + _this.getMinutesName(diffMins);
            }

            if (result) {
              _this.set('timeStringFormatted', result);

              diffMins--;

              var lastRun = _ember['default'].run.later(_this, everyMinute, minSkip + 1, minute);
              _this.set('lastRun', lastRun);
            } else {
              _ember['default'].run.scheduleOnce('afterRender', this, function () {
                _this.sendAction('timerIsOver');
                _this.set('Running', false);
              });
            }
          };

          everyMinute(0);
        })();
      } else {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          _this.sendAction('timerIsOver');
          _this.set('Running', false);
        });
      }
    },

    getDaysName: function getDaysName(daysCount) {
      return (0, _deputeeOfficeUtilsCountText['default'])(daysCount, 'дней', 'день', 'дня');
    },

    getHoursName: function getHoursName(hoursCount) {
      return (0, _deputeeOfficeUtilsCountText['default'])(hoursCount, 'часов', 'час', 'часа');
    },
    getMinutesName: function getMinutesName(minutesCount) {
      return (0, _deputeeOfficeUtilsCountText['default'])(minutesCount, 'минут', 'минута', 'минуты');
    },
    getSecondsName: function getSecondsName(secondsCount) {
      return (0, _deputeeOfficeUtilsCountText['default'])(secondsCount, 'секунд', 'секунда', 'секунды');
    }
  });
});