define('deputee-office/components/group-selector', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    templateName: 'components/group-selector/default',

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var model = this.get('model');
      if (model) {
        var grouped = this.getGrouped(model);
        this.set('groups', grouped);
      }
    },

    getGrouped: function getGrouped(model) {
      var result = _ember['default'].A([]);
      var idx = [];
      model.forEach(function (record) {
        var _type = record.get('Type');
        if (_type === '') {
          return;
        }

        if (!result.isAny('name', _type)) {
          idx[_type] = _ember['default'].A([]);
          result.pushObject({ name: _type, items: idx[_type] });
        }

        idx[_type].pushObject(record);
      });
      return result;
    },

    actions: {
      selectItem: function selectItem(item) {
        this.sendAction('onSelect', item);
        _ember['default'].$('.scrollable-div').trigger('updatescroll');
      }
    }
  });
});