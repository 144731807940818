define('deputee-office/controllers/legal-acts/legal-acts-search', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    searchPlaceholder: 'Поиск по актам',

    sendSearchString: function sendSearchString(text) {
      this.get('target').send('search', text);
      _ember['default'].$('.scrollable-div').trigger('updatescroll');
    },

    actions: {
      search: function search(text) {
        _ember['default'].run.debounce(this, this.get('sendSearchString'), text, 1500);
      }
    }
  });
});