define('deputee-office/models/omsu-passport/omsu-passport-relationship', ['exports', 'ember-data', 'deputee-office/models/zs-passport/zs-passport-relationship', 'ember-flexberry-data', 'ember'], function (exports, _emberData, _deputeeOfficeModelsZsPassportZsPassportRelationship, _emberFlexberryData, _ember) {

  var Model = _deputeeOfficeModelsZsPassportZsPassportRelationship['default'].extend({
    TerritoryId: _emberData['default'].attr('guid'),
    CancelName: _ember['default'].computed('property', function () {
      return 'Отмена ПА';
    }),
    ChengeName: _ember['default'].computed('property', function () {
      return 'Изменение ПА';
    })
  });

  Model.defineProjection('omsu-passport-relationship-full', 'omsu-passport/zs-passport-relationship', {
    To: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-relationship-list', 'To', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      PassportId: _emberFlexberryData.Projection.attr('PassportId'),
      Type: _emberFlexberryData.Projection.attr('Type'),
      Title: _emberFlexberryData.Projection.attr('Title')
    }),
    From: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-relationship-list', 'From', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      PassportId: _emberFlexberryData.Projection.attr('PassportId'),
      Type: _emberFlexberryData.Projection.attr('Type'),
      Title: _emberFlexberryData.Projection.attr('Title')
    }),
    Extra: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-relationship-list', 'Extra', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      PassportId: _emberFlexberryData.Projection.attr('PassportId'),
      Type: _emberFlexberryData.Projection.attr('Type'),
      Title: _emberFlexberryData.Projection.attr('Title')
    })
  });

  exports['default'] = Model;
});