define('deputee-office/components/omsuschedule-filter-popover', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    popoverId: 'popoverFilterOmsuscheduleId',

    isFilterNotDefault: _ember['default'].computed('selectedType', 'selectedMy', function () {
      if (this.get('selectedType') === '' && this.get('selectedMy') === '') {
        return true;
      } else {
        return false;
      }
    }),

    typePropertyChanged: function typePropertyChanged() {
      var newType = this.get('selectedType');

      this.sendAction('typeChanged', newType);
    },

    myPropertyChanged: function myPropertyChanged() {
      var newMy = this.get('selectedMy');

      this.sendAction('myChanged', newMy);
    },

    init: function init() {
      this._super();
      this.set('selectedType', '');
      this.set('selectedMy', '');
      this.addObserver('selectedType', this.typePropertyChanged);
      this.addObserver('selectedMy', this.myPropertyChanged);
    },

    actions: {
      changeType: function changeType(newType) {
        this.set('selectedType', newType);
      },

      changeMy: function changeMy(newMy) {
        this.set('selectedMy', newMy);
      }
    }
  });
});