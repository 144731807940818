define('deputee-office/components/contact-department-tree-node', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    expanded: false,

    isSelectedNode: _ember['default'].computed('selectedNode', function () {
      return this.get('selectedNode.id') === this.get('model.id');
    }),

    actions: {
      nodeClick: function nodeClick(model) {
        this.sendAction('onNodeClickAction', model);
        var expanded = this.get('expanded');
        this.set('expanded', !expanded);
        _ember['default'].$('.contactListScroll #scrollable-div').trigger('updatescroll');
      }
    }
  });
});