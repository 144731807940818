define('deputee-office/services/agenda-state', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Service.extend({
    stages: ['заочное обсуждение', 'голосование по поправкам', 'заочное голосование', 'очное заседание', 'утверждение решения'],

    /**
     * isZo - Это этап заочного обсужденрия или нет.
     *
     * @param  {string} stageName Имя этапа
     * @return {boolean}           Это этап заочного обсужденрия или нет.
     */
    isZo: function isZo(stageName, stageOver) {
      var finished = false;
      if (stageOver) {
        finished = (0, _moment['default'])(stageOver).isBefore(new Date());
      }

      if (stageName) {
        return stageName.toLowerCase() === this.get('stages')[0] && !finished;
      }
    },

    /**
     * isAfterZo - Этап после заочного обсужденрия или нет.
     *
     * @param  {string} stageName Имя этапа
     * @return {boolean}           Этап после заочного обсужденрия или нет.
     */
    isAfterZo: function isAfterZo(stageName, stageOver) {
      var finished = false;
      if (stageOver) {
        finished = (0, _moment['default'])(stageOver).isBefore(new Date());
      }

      if (stageName) {
        return this.get('stages').indexOf(stageName.toLowerCase()) > 0 || stageName.toLowerCase() === this.get('stages')[0] && finished;
      }
    },

    /**
     * isGp - Это этап голосования по поправкам или нет.
     *
     * @param  {string} stageName Имя этапа
     * @return {boolean}           Это голосования по поправкам  или нет.
     */
    isGp: function isGp(stageName, stageOver) {
      var finished = false;
      if (stageOver) {
        finished = (0, _moment['default'])(stageOver).isBefore(new Date());
      }

      if (stageName) {
        return stageName.toLowerCase() === this.get('stages')[1] && !finished;
      }
    },

    /**
     * isAfterGp - Этап после голосования по поправкам или нет.
     *
     * @param  {string} stageName Имя этапа
     * @return {boolean}           Этап после голосования по поправкам или нет.
     */
    isAfterGp: function isAfterGp(stageName, stageOver) {
      var finished = false;
      if (stageOver) {
        finished = (0, _moment['default'])(stageOver).isBefore(new Date());
      }

      if (stageName) {
        return this.get('stages').indexOf(stageName.toLowerCase()) > 1 || stageName.toLowerCase() === this.get('stages')[1] && finished;
      }
    },

    /**
     * isZg - Это этап заочного голосования или нет.
     *
     * @param  {string} stageName Имя этапа
     * @return {boolean}           Это голосования по поправкам  или нет.
     */
    isZg: function isZg(stageName, stageOver) {
      var finished = false;
      if (stageOver) {
        finished = (0, _moment['default'])(stageOver).isBefore(new Date());
      }

      if (stageName) {
        return stageName.toLowerCase() === this.get('stages')[2] && !finished;
      }
    },

    /**
     * isAfterZg - Этап после заочного голосования или нет.
     *
     * @param  {string} stageName Имя этапа
     * @return {boolean}           Этап после заочного голосования или нет.
     */
    isAfterZg: function isAfterZg(stageName, stageOver) {
      var finished = false;
      if (stageOver) {
        finished = (0, _moment['default'])(stageOver).isBefore(new Date());
      }

      if (stageName) {
        return this.get('stages').indexOf(stageName.toLowerCase()) > 2 || stageName.toLowerCase() === this.get('stages')[2] && finished;
      }
    }
  });
});