define('deputee-office/components/favorites/favorites-laws', ['exports', 'ember', 'deputee-office/mixins/favorites-component-base'], function (exports, _ember, _deputeeOfficeMixinsFavoritesComponentBase) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsFavoritesComponentBase['default'], {
    templateName: 'components/favorites/favorites-zs-agenda-departments',
    store: _ember['default'].inject.service(),
    favoritesService: _ember['default'].inject.service(),

    loadableModels: ['favorite', 'zs-passport/zs-passport-document', 'law'],

    init: function init() {
      this._super();
      this.set('model', _ember['default'].A([]));
      this.set('displayedModel', _ember['default'].A([]));
    },

    loadData: function loadData() {
      var _this = this;

      var store = this.get('store');
      var favoritesService = this.get('favoritesService');
      var model = this.get('model');

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        store.query('favorite', { Model: 'zs-passport/zs-passport-file' }).then(function (favorites) {
          return favorites.toArray().filter(function (favorite) {
            var params = JSON.parse(favorite.get('Params'));
            return params.additionalParams && params.additionalParams.serviceName === 'laws.passport';
          });
        }).then(function (favorites) {

          // Массив идентификаторов документов.
          var docIds = favoritesService.getUnique(favorites, 'docId');

          // Массив идентификаторов законов.
          var lawIds = favoritesService.getUnique(favorites, 'additionalParams.lawId');

          // Обертки для запросов получения документов.
          var wrappersForDocuments = favorites.map(function (favorite) {
            return JSON.parse(favorite.get('Params')).additionalParams;
          });

          var promises = [];

          wrappersForDocuments.forEach(function (item) {
            var promise = store.query('zs-passport/zs-passport-document', {
              PassportId: item.PassportId,
              TabName: item.tab
            }).then(function (result) {

              var docsWrapper = {
                lawId: item.lawId,
                additionalParams: item,
                documents: result.toArray()
              };

              return docsWrapper;
            });

            promises.push(promise);
          });

          var documentsPromise = _ember['default'].RSVP.allSettled(promises).then(function (result) {
            result = _this.settledHelper(result);

            var docItemWrappers = _ember['default'].A([]);

            for (var i = 0; i < result.length; i++) {
              for (var j = 0; j < result[i].documents.length; j++) {
                if (docIds.indexOf(result[i].documents[j].get('id')) !== -1) {
                  var docItemWrapper = {
                    lawId: result[i].lawId,
                    additionalParams: result[i].additionalParams,
                    documentItem: result[i].documents[j]
                  };

                  docItemWrappers.pushObject(docItemWrapper);
                }
              }
            }

            return docItemWrappers;
          })['catch'](function (e) {
            reject();
            console.error(e);
          });

          var lawPromises = lawIds.map(function (record) {
            return store.findRecord('law', record);
          });

          var lawPromisesAll = _ember['default'].RSVP.allSettled(lawPromises, 'favorites-laws: lawPromisesAll').then(function (laws) {
            return _this.settledHelper(laws);
          })['catch'](function (e) {
            reject();
            console.error(e);
          });

          var promisesObject = {
            favorites: _ember['default'].RSVP.Promise.resolve(favorites),
            questions: lawPromisesAll.then(function (result) {
              return result.filter(function (law) {
                return lawIds.indexOf(law.get('id')) !== -1;
              });
            }),
            documents: documentsPromise
          };

          return _ember['default'].RSVP.hashSettled(promisesObject, 'favorites-laws: hash for all').then(function (_result) {

            var result = new Object({
              favorites: _result.favorites.state === 'fulfilled' ? _result.favorites.value : [],
              questions: _result.questions.state === 'fulfilled' ? _result.questions.value : [],
              documents: _result.documents.state === 'fulfilled' ? _result.documents.value : []
            });

            // Массив идентификаторов файлов.
            var fileIds = _this.get('favoritesService').getUnique(result.favorites, 'id');

            // Построение групп для вывода в шаблоне.
            var lawGroups = result.questions.map(function (lawItem) {

              var documents = result.documents.filter(function (documentWrapperItem) {
                return documentWrapperItem.lawId === lawItem.get('id');
              }).map(function (documentWrapperItem) {
                var files = documentWrapperItem.documentItem.get('Files').toArray();

                files = files.filter(function (file) {
                  return fileIds.indexOf(file.get('id')) !== -1;
                });

                var docToDisplay = {
                  id: documentWrapperItem.documentItem.get('id'),
                  title: documentWrapperItem.documentItem.get('Title'),
                  additionalParams: documentWrapperItem.additionalParams,
                  files: files,
                  displayFiles: _ember['default'].A([])
                };

                return docToDisplay;
              });

              var lawGroup = {
                law: lawItem,
                documents: documents,
                displayedDocuments: _ember['default'].A([])
              };

              return lawGroup;
            });

            model.pushObjects(lawGroups);

            _this.filterModel();

            // Резолвится главный промис.
            resolve();
          });
        });
      }, 'favorites-laws: loadData');
    },

    filterModel: function filterModel() {
      var model = this.get('model');
      var displayedModel = this.get('displayedModel');
      var filterText = this.get('searchString');

      displayedModel.clear();

      if (filterText && filterText.value) {
        model.forEach(function (lawGroup) {
          var groupAdded = false;

          lawGroup.displayedDocuments.clear();

          lawGroup.documents.forEach(function (doc) {
            var docAdded = false;

            doc.displayFiles.clear();

            doc.files.forEach(function (file) {
              if (file.get('Name').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1) {
                if (!groupAdded) {
                  displayedModel.pushObject(lawGroup);
                  groupAdded = true;
                }

                if (!docAdded) {
                  lawGroup.displayedDocuments.pushObject(doc);
                  docAdded = true;
                }

                doc.displayFiles.pushObject(file);
              }
            });
          });
        });
      } else {
        model.forEach(function (lawGroup) {
          lawGroup.displayedDocuments.clear();

          lawGroup.documents.forEach(function (doc) {
            doc.displayFiles.clear();

            doc.displayFiles.pushObjects(doc.files);
            lawGroup.displayedDocuments.pushObject(doc);
          });

          displayedModel.pushObject(lawGroup);
        });
      }

      displayedModel.sortBy('law.Name');
    }

  });
});