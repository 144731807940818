define('deputee-office/adapters/decree-offline', ['exports', 'deputee-office/adapters/application-offline', 'ember-flexberry-data'], function (exports, _deputeeOfficeAdaptersApplicationOffline, _emberFlexberryData) {
  exports['default'] = _deputeeOfficeAdaptersApplicationOffline['default'].extend({
    caching: 'none',
    namespace: 'ember-flexberry-offline:store',
    query: function query(store, modelName, _query) {
      var filter = _query.filter;
      var descOrder = _query.descOrder;
      var page = _query.page;
      var perPage = _query.per_page;

      var queryInstance = _query instanceof _emberFlexberryData.Query.QueryObject;
      if (!queryInstance) {
        _query = {};
      }

      return this._super(store, modelName, _query).then(function (results) {
        results = results.data;
        if (filter && filter != 'null' && !!filter.length) {
          filter = decodeURI(filter).toUpperCase();
          results = results.filter(function (record) {
            if (record.Title.toUpperCase().indexOf(filter) > -1 || record.RegNumber.toUpperCase().indexOf(filter) > -1) {

              return true;
            }

            return false;
          });
        }

        var sortByRegDate = null;

        if (descOrder) {
          sortByRegDate = function (a, b) {
            if (a.RegDate < b.RegDate) {
              return 1;
            }

            if (a.RegDate > b.RegDate) {
              return -1;
            }
          };
        } else {
          sortByRegDate = function (a, b) {
            if (a.RegDate > b.RegDate) {
              return 1;
            }

            if (a.RegDate < b.RegDate) {
              return -1;
            }
          };
        }

        results.sort(sortByRegDate);

        // Оффлайн-пейджинг через slice.
        if (perPage && perPage !== 0) {
          var fromIndex = (page - 1) * perPage;
          var toIndex = page * perPage;

          results = results.slice(fromIndex, toIndex);
        }

        return { data: results };
      });
    }
  });
});