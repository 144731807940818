define('deputee-office/components/horizontal-scrolling-block', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        bindDrag: (function () {
            //Ember.$()
            _ember['default'].$('.hsrow').on('mousedown', function () {});
            _ember['default'].$('.hsrow').on('mousemove', function () {});

            _ember['default'].$('.hsrow').on('mouseup', function () {});
        }).on('init')
    });
});