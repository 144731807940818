define('deputee-office/routes/external-resources', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/territory-flag-name'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsTerritoryFlagName) {

  var ZSPortalName = 'Портал ЗС';

  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], {
    session: _ember['default'].inject.service('session'),
    model: function model() {
      var modelArray = _ember['default'].A([]);
      var _this = this;

      function getFilledModel(records) {
        modelArray.pushObjects(records.toArray());

        return modelArray;
      }

      function getRecords(useOnlineStore) {
        return _this.store.findAll('external-resource', { useOnlineStore: useOnlineStore }).then(function (records) {
          _this.set('modelFromOnline', useOnlineStore);

          if (!useOnlineStore && records.get('length') === 0) {
            return getRecords(true);
          }

          return getFilledModel(records);
        });
      }

      return getRecords(false);
    },
    activate: function activate() {
      this.setFlagAndNameForZS();
      if (!this.get('devicejs').isPhone()) {
        _ember['default'].$('body').addClass('whiteBckgrnd');
      }
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
      if (!this.get('devicejs').isPhone()) {
        _ember['default'].$('body').removeClass('whiteBckgrnd');
      }
    },

    setupController: function setupController(controller, model) {
      if (!this.get('modelFromOnline')) {
        this.store.findAll('external-resource', { useOnlineStore: true }).then(function (records) {
          controller.get('model').clear();
          controller.get('model').pushObjects(records.toArray());
        });
      }

      this._super(controller, model);
      this.set('controller', controller);

      var device = this.get('devicejs');
      controller.set('desktop', device.type() === 'desktop');
    },
    actions: {
      openResource: function openResource(resource) {
        var controller = this.get('controller');

        var openType = undefined;
        if (controller.get('desktop')) {
          if (resource.get('Name') === ZSPortalName) {

            var postFormToZS = function postFormToZS(path, params, method) {
              method = method || 'post';

              var form = document.createElement('form');
              form.setAttribute('method', method);
              form.setAttribute('action', path);
              form.setAttribute('target', 'extResFrame');

              for (var key in params) {
                if (params.hasOwnProperty(key)) {
                  var hiddenField = document.createElement('input');
                  hiddenField.setAttribute('type', 'hidden');
                  hiddenField.setAttribute('name', key);
                  hiddenField.setAttribute('value', params[key]);

                  form.appendChild(hiddenField);
                }
              }

              document.body.appendChild(form);
              form.submit();
            };

            var _login = this.get('currentuserservice').get('login');
            var _password = this.get('currentuserservice').get('password');

            postFormToZS('https://portal.zsperm.ru/CookieAuth.dll?Logon', {
              curl: 'Z2F',
              flags: 0,
              forcedownlevel: 0,
              formdir: 3,
              trusted: 0,
              username: _login,
              password: _password,
              SubmitCreds: 'Вход в систему'
            });

            return;
          }

          openType = '_system';
        } else {
          openType = '_blank';
        }

        var loadStopCallBack = function loadStopCallBack() {
          if (browser !== undefined && firstCall) {
            browser.executeScript({ code: 'var userLogin = "' + login + '";' }, null);
            browser.executeScript({ code: 'var userPassword = "' + password + '";' }, null);
            _ember['default'].$.ajax({
              url: 'assets/js/inAppBrowserZSPortalInject.js',
              type: 'get',
              async: false,
              dataType: 'html',
              success: function success(js) {
                browser.executeScript({ code: js }, null);
                firstCall = false;
              }
            });
          }
        };

        var browser = window.cordova.InAppBrowser.open(resource.get('Url'), openType, 'location=yes,closebuttoncaption=Закрыть,enableviewportscale=yes');
        if (resource.get('Name') === ZSPortalName) {
          var login = this.get('currentuserservice').get('login');
          var password = this.get('currentuserservice').get('password');
          browser.addEventListener('loadstop', loadStopCallBack);
          var firstCall = true;
        }
      }
    }
  });
});