define('deputee-office/controllers/base-agenda-route/passport/stage-details', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    stageDetailsStateName: 'base-agenda-route/stage-details/index',
    defaultStageDetailsStateName: 'base-agenda-route/stage-details/index',

    queryParams: ['stageDetailsState', 'stageDetailsStateParam'],
    stageDetailsState: null,
    stageDetailsStateParam: null,

    OmsuCorrections: _ember['default'].computed('modelList', 'stageDetailsStateParam', function () {
      var _this = this;

      if (this.get('modelList')) {
        return this.get('modelList').find(function (x) {
          return x.get('id') === decodeURI(_this.get('stageDetailsStateParam'));
        });
      }
    }),

    CurrentVote: _ember['default'].computed('model.details.votingResult', 'stageDetailsStateParam', function () {
      var _this2 = this;

      if (this.get('model.details.votingResult')) {
        return this.get('model.details.votingResult').find(function (x) {
          return x.get('id') === decodeURI(_this2.get('stageDetailsStateParam'));
        });
      }
    }),

    OmsuResult: _ember['default'].computed('modelList', 'stageDetailsStateParam', function () {
      if (this.get('modelList')) {
        return this.get('modelList').filter(function (x) {
          return x.get('TypeName') === 'Письмо о согласовании';
        });
      }
    }),

    AcceptCount: _ember['default'].computed('OmsuResult', function () {
      var model = this.get('OmsuResult');
      if (model) {
        return model.filterBy('OmsuAccept', true).length;
      }
    }),

    DisacceptCount: _ember['default'].computed('OmsuResult', function () {
      var model = this.get('OmsuResult');
      if (model) {
        return model.filterBy('OmsuAccept', false).length;
      }
    }),

    OfferCount: _ember['default'].computed('OmsuResult', function () {
      var model = this.get('OmsuResult');
      if (model) {
        return model.mapBy('OmsuOffer').reduce(function (x, y) {
          return x + y;
        });
      }
    }),

    Title: _ember['default'].computed('stageDetailsStateParam', function () {
      return decodeURI(this.get('stageDetailsStateParam'));
    }),

    stageDetailsStateChanged: _ember['default'].observer('stageDetailsState', 'model', function () {
      if (this.get('model')) {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          switch (this.get('stageDetailsState')) {
            case 'details':
              this.send('documentInfo', this.get('modelList').findBy('id', this.get('stageDetailsStateParam')));
              this.set('stageDetailsStateName', 'base-agenda-route/stage-details/details');
              break;
            case 'detail-vote':
              this.set('stageDetailsStateName', 'base-agenda-route/passport-state/detail/vote');
              if (!this.get('model.detail')) {
                this.send('documentInfo', this.get('modelList').findBy('id', this.get('stageDetailsStateParam')));
              }

              break;
            case 'workGroup':
              this.set('stageDetailsStateName', 'base-agenda-route/stage-details/work-group');
              if (!this.get('model.detail')) {
                this.send('documentInfo', this.get('modelList').findBy('id', this.get('stageDetailsStateParam')));
              }

              break;
            case 'omsuCorrections':
              this.set('stageDetailsStateName', 'base-agenda-route/stage-details/omsu-corrections');
              if (!this.get('model.detail')) {
                this.send('documentInfo', this.get('modelList').findBy('id', this.get('stageDetailsStateParam')));
              }

              break;
            case 'omsuResult':
              this.set('stageDetailsStateName', 'base-agenda-route/stage-details/omsu-decision');
              if (!this.get('model.detail')) {
                this.send('documentInfo', this.get('modelList').findBy('id', this.get('stageDetailsStateParam')));
              }

              break;
            default:
              this.set('stageDetailsStateName', this.get('defaultStageDetailsStateName'));
          }
        });
      }
    })
  });
});