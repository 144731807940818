define('deputee-office/transforms/guid', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Transform.extend({
    serialize: function serialize(value) {
      if (value) {
        return value.toLowerCase();
      }

      return null;
    },
    deserialize: function deserialize(value) {
      if (value) {
        return value.toLowerCase();
      }

      return null;
    }
  });
});