define('deputee-office/components/vote-mobile', ['exports', 'ember', 'deputee-office/mixins/vote'], function (exports, _ember, _deputeeOfficeMixinsVote) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsVote['default'], {
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (typeof this.get('model.ShowVoteForm') === 'boolean' || !_ember['default'].isNone(this.get('safeMode'))) {
        this.set('isShowDiagram', true);
      } else {
        window.history.back();
      }
    }
  });
});