define('deputee-office/routes/yp-agenda-parlament/passport/notes', ['exports', 'deputee-office/routes/base-agenda-route/passport/notes'], function (exports, _deputeeOfficeRoutesBaseAgendaRoutePassportNotes) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoutePassportNotes['default'].extend({
    parentControllerName: 'yp-agenda-parlament.passport',

    getPassportPersonalDocumentQuery: function getPassportPersonalDocumentQuery(passportId) {
      return this.getBasePassportPersonalDocumentQuery(passportId);
    },
    getQuestionPersonalDocumentQuery: function getQuestionPersonalDocumentQuery(questionId) {
      return this.getBaseQuestionPersonalDocumentQuery(questionId);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('ypTimer', true);
    }
  });
});