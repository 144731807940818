define('deputee-office/routes/omsu-agenda-assembly/passport/stage-details', ['exports', 'ember', 'deputee-office/routes/base-agenda-route/passport/stage-details'], function (exports, _ember, _deputeeOfficeRoutesBaseAgendaRoutePassportStageDetails) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoutePassportStageDetails['default'].extend({
    parentControllerName: 'omsu-agenda-assembly.passport',

    beforeModel: function beforeModel(transition) {
      this._super(transition);
      this.set('transition', transition);
    },

    afterModel: function afterModel() {
      this._super();

      if (this.controller && this.controller.get('additionalParams')) {
        this.controller.get('additionalParams').tab = decodeURI(this.get('params.current_tab'));
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var transition = this.get('transition');
      var additionalParams = {
        questionId: transition.params['omsu-agenda-assembly.passport'].question_id,
        agendaId: transition.queryParams.agendaId,
        departmentId: transition.queryParams.departmentId,
        territoryId: this.get('territoryService').getTerritoryId(),
        tab: decodeURI(this.get('params.current_tab')),
        PassportId: this.get('parentController.model.PassportId'),
        AddInfo: this.get('parentController.model.AddInfo.Name'),

        //departmentType: this.paramsFor('omsu-agenda-assembly').departmentType,
        serviceName: 'omsu-agenda-assembly.passport'
      };

      controller.set('additionalParams', additionalParams);
    },

    additionalParams: _ember['default'].observer('parentController.model', 'controller.additionalParams', function () {
      if (this.get('parentController.model') && this.controller && this.controller.get('additionalParams')) {
        this.controller.set('additionalParams.PassportId', this.get('parentController.model.PassportId'));
        this.controller.set('additionalParams.AddInfo', this.get('parentController.model.AddInfo.Name'));
      }
    }),

    resetController: function resetController(controller, isExiting, transition) {
      this._super(controller, isExiting, transition);

      if (isExiting && transition) {
        controller.set('additionalParams', null);
        this.set('transition', null);
      }
    },

    /**
     * Получение документов этапа, связанных с паспортом вопроса.
     * @param {any} passportId Идентификатор паспорта вопроса.
     * @param {any} currentTab Имя вкладки, для которой получаются документы.
     * @returns Промис с документами этапа, связанными с паспортом вопроса.
     */
    getDocumentQuery: function getDocumentQuery(passportId, currentTab) {
      return this.store.query('omsu-passport/omsu-passport-document', {
        PassportId: passportId,
        TabName: currentTab,
        TerritoryId: this.get('territoryService').getTerritoryId(),
        projection: 'omsu-passport-document-full'
      });
    },

    getVotingResult: function getVotingResult(id) {
      return this.store.query('omsu-passport/omsu-passport-voting-result', {
        PassportId: id,
        projection: 'omsu-passport-voting-result-full',
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
    }
  });
});