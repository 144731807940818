define('deputee-office/controllers/newquestions/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    searchPlaceholder: 'Поиск по поступившим вопросам',

    modelObserver: _ember['default'].observer('model', function () {
      this.set('newQuestions', this.get('model'));
    }),

    filterNewQuestions: function filterNewQuestions(text) {
      var filteredNewQuestions = this.get('model').filter(function (newQuestion) {
        return newQuestion.get('Name').toLowerCase().indexOf(text.toLowerCase()) !== -1;
      });
      this.set('newQuestions', filteredNewQuestions);
      _ember['default'].$('#scrollable-div').trigger('updatescroll');
    },

    actions: {
      search: function search(text) {
        _ember['default'].run.debounce(this, this.get('filterNewQuestions'), text, 1000);
      }
    }
  });
});