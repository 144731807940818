define('deputee-office/models/pomopk-decision', ['exports', 'ember-data', 'deputee-office/models/base', 'ember-flexberry-data', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _emberFlexberryData, _ember, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Date: _emberData['default'].attr('date'),
    Name: _emberData['default'].attr('string'),
    Theme: _emberData['default'].attr('string'),
    RegNumber: _emberData['default'].attr('number'),
    DecisionFileUrl: _emberData['default'].attr('string'),
    LegalActFiles: _emberData['default'].hasMany('LegalActFile'),
    TerritoryId: _emberData['default'].attr('guid'),
    IsDone: _emberData['default'].attr('boolean'),
    Normative: _emberData['default'].attr('boolean'),

    Length: _ember['default'].computed('Topic', 'Executor', 'Decision', function () {
      var ThemeLength = 0;

      if (this.get('Theme')) {
        ThemeLength = this.get('Theme').length;
      }

      var NameLength = 0;

      if (this.get('Name')) {
        NameLength = this.get('Name').length;
      }

      if (ThemeLength > 10 || NameLength > 10) {
        return true;
      }
    }),

    Extended: false,

    IsDoneClass: _ember['default'].computed('IsDone', function () {
      if (this.get('IsDone')) {
        return 'ispolneno';
      }
    }),

    ExpandButtonClass: _ember['default'].computed('Extended', function () {
      if (this.get('Extended')) {
        return 'btn-row-expanded device-btn-expand';
      } else {
        return 'device-btn-expand';
      }
    }),

    DayMonth: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])('' + this.get('Date')).format('LL').slice(0, -8);
    }),

    Year: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])('' + this.get('Date')).format('YYYY');
    })
  });

  Model.defineProjection('pomopk-decision', 'pomopk-decision', {
    Date: _emberFlexberryData.Projection.attr('Date'),
    Name: _emberFlexberryData.Projection.attr('Name'),
    Theme: _emberFlexberryData.Projection.attr('Theme'),
    RegNumber: _emberFlexberryData.Projection.attr('RegNumber'),
    DecisionFileUrl: _emberFlexberryData.Projection.attr('DecisionFileUrl'),
    LegalActFiles: _emberFlexberryData.Projection.hasMany('LegalActFile', 'LegalActFiles', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      Url: _emberFlexberryData.Projection.attr('Url')
    }),
    TerritoryId: _emberFlexberryData.Projection.attr('TerritoryId'),
    IsDone: _emberFlexberryData.Projection.attr('IsDone'),
    Normative: _emberFlexberryData.Projection.attr('Normative'),
    Extended: _emberFlexberryData.Projection.attr('Extended')
  });

  exports['default'] = Model;
});