define('deputee-office/adapters/law-offline', ['exports', 'moment', 'deputee-office/adapters/application-offline', 'ember-flexberry-data'], function (exports, _moment, _deputeeOfficeAdaptersApplicationOffline, _emberFlexberryData) {
  exports['default'] = _deputeeOfficeAdaptersApplicationOffline['default'].extend({
    query: function query(store, modelName, _query) {
      var filter = _query.filter;
      var page = _query.page;
      var perPage = _query.per_page;

      var queryInstance = _query instanceof _emberFlexberryData.Query.QueryObject;
      if (!queryInstance) {
        _query = {};
      }

      return this._super(store, modelName, _query).then(function (results) {
        results = results.data;
        if (filter) {
          results = results.filter(function (record) {
            if (filter.name && record.Name.indexOf(filter.name) === -1 && (0, _moment['default'])('' + record.RegDate).format('DD.MM.YYYY').indexOf(filter.name) === -1 && record.RegNumber.indexOf(filter.name) === -1) {
              return false;
            }

            if (filter.type && record.Type !== filter.type) {
              return false;
            }

            if (filter.committee && filter.committee !== 'Все' && record.HeadCommittee !== filter.committee) {
              return false;
            }

            if (filter.reading && filter.reading !== 'Все' && record.Reading !== filter.reading) {
              return false;
            }

            return true;
          });
        }

        var propertyName = 'BillCode';
        var direction = 'desc';

        results = results.toArray().sort(function (a, b) {
          if (direction === 'asc') {
            return a[propertyName] - b[propertyName];
          } else if (direction === 'desc') {
            return b[propertyName] - a[propertyName];
          }
        });

        // Оффлайн-пейджинг через slice.
        if (perPage && perPage !== 0) {
          var fromIndex = (page - 1) * perPage;
          var toIndex = page * perPage;

          results = results.slice(fromIndex, toIndex);
        }

        return { data: results };
      });
    }
  });
});