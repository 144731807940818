define("deputee-office/utils/count-text", ["exports"], function (exports) {
  exports["default"] = countText;

  function countText(count, zeroString, oneString, twoString) {
    count = Math.abs(count);
    var lastOne = Math.floor(count % 10);
    var lastTwo = Math.floor(count % 100);
    var result;

    if (lastTwo >= 11 && lastTwo <= 19) {
      result = zeroString;
    } else if (lastOne === 1) {
      result = oneString;
    } else if (lastOne >= 2 && lastOne <= 4) {
      result = twoString;
    } else {
      result = zeroString;
    }

    return result;
  }
});