define('deputee-office/models/service-setting', ['exports', 'deputee-office/models/base', 'ember', 'ember-data'], function (exports, _deputeeOfficeModelsBase, _ember, _emberData) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    settingsService: _ember['default'].inject.service('settingsservice'),
    OrderNum: _emberData['default'].attr('number'),
    Title: _emberData['default'].attr('string'),
    IconName: _emberData['default'].attr('string'),
    SubsystemName: _emberData['default'].attr('string'),
    IsVisible: _emberData['default'].attr('boolean'),
    RelativeRootUrl: _emberData['default'].attr('string'),
    IconUrl: _ember['default'].computed('IconName', 'IsVisible', function () {
      //return `${this.get('settingsService').getHost()}/Icons/ServiceIcons/${this.get('IconName')}`;

      if (this.get('IsVisible')) {
        return 'assets/img/ServiceIcons/' + this.get('IconName');
      } else {
        return 'assets/img/ServiceIcons/grayscale/' + this.get('IconName');
      }
    }),
    IconClearName: _ember['default'].computed('IconName', function () {
      return this.get('IconName').substr(0, this.get('IconName').indexOf('.'));
    }),
    SubsystemTitle: _ember['default'].computed('SubsystemName', function () {
      switch (this.get('SubsystemName')) {
        case 'ZSPK':
          return 'Мурманская областная Дума';
        case 'OMSU':
          return 'ОМСУ';
        case 'YouthParlaiment':
          return 'Молодежный парламент';
        case 'PAMS':
          return 'Мурманская областная Дума';
        default:
          return 'Другие';
      }
    })
  });

  exports['default'] = Model;
});