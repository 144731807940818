define('deputee-office/utils/cache', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Object.extend({

    data: _ember['default'].Map.create(),

    clear: function clear() {
      this.data.clear();
    },

    get: function get(namespace) {
      if (this.data.get(namespace)) {
        return _ember['default'].copy(_ember['default'].A(this.data.get(namespace)), true);
      } else {
        return null;
      }
    },

    set: function set(namespace, objects) {
      this.data.set(namespace, _ember['default'].copy(_ember['default'].A(objects), true));
    },

    replace: function replace(data) {
      this.clear();
      for (var index in data) {
        this.set(index, data[index]);
      }
    }

  });
});