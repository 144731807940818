define('deputee-office/routes/login', ['exports', 'ember', 'ember-simple-auth/mixins/unauthenticated-route-mixin', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _emberSimpleAuthMixinsUnauthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsUnauthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], {
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var setting = this.get('settingsservice').getNetworkSettings();

      controller.set('identification', setting.get('Login'));
      controller.set('password', setting.get('Password'));
      controller.set('RememberMe', setting.get('RememberMe'));
    },

    activate: function activate() {
      _ember['default'].$('body').addClass('whiteBckgrnd');
      var device = this.get('devicejs');
      if (device.isPhone()) {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          _ember['default'].$('.loginOverallContainer').parent().addClass('h100');
        });
      }

      this.controllerFor('login').set('clickedLogin', false);
      this.controllerFor('login').set('passwordState', 'password');
    },

    deactivate: function deactivate() {
      _ember['default'].$('body').removeClass('whiteBckgrnd');
      _ember['default'].$('.loginOverallContainer').parent().removeClass('h100');
    }
  });
});