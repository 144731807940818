define('deputee-office/routes/yp-agenda-parlament', ['exports', 'ember', 'deputee-office/routes/yp-agenda-comissions'], function (exports, _ember, _deputeeOfficeRoutesYpAgendaComissions) {

  var agencies = ['Молодежный парламент'];

  exports['default'] = _deputeeOfficeRoutesYpAgendaComissions['default'].extend({
    targetControllerName: 'yp-agenda-parlament.index',
    passportLinkName: 'yp-agenda-parlament.passport',
    searchText: null,
    withDepartment: false,

    getDepartmentQuery: function getDepartmentQuery() {
      var model = this.store.findAll('yp-department');
      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          model.then(function functionName(departments) {
            var resolveData = departments.filter(function (department) {
              return agencies.indexOf(department.get('Name')) !== -1;
            });
            resolve(resolveData);
          })['catch'](function (reason) {
            reject(reason);
          });
        });
      });

      return promise;
    }
  });
});