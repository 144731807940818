define('deputee-office/routes/budgets', ['exports', 'ember', 'deputee-office/mixins/territory-flag-name', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsTerritoryFlagName, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsTerritoryFlagName['default'], _deputeeOfficeMixinsServicesMixin['default'], {
    model: function model() {
      var modelArray = _ember['default'].A([]);
      var _this = this;

      function getFilledModel(records) {
        modelArray.pushObjects(records.toArray());

        return modelArray;
      }

      function getRecords(useOnlineStore) {
        return _this.store.findAll('budget', { useOnlineStore: useOnlineStore }).then(function (records) {
          _this.set('modelFromOnline', useOnlineStore);

          if (!useOnlineStore && records.get('length') === 0) {
            return getRecords(true);
          }

          return getFilledModel(records);
        })['catch'](function functionName() {
          if (!useOnlineStore) {
            return getRecords(true);
          }
        });
      }

      return getRecords(false);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      if (!this.get('modelFromOnline')) {
        this.store.findAll('budget', { useOnlineStore: true }).then(function (records) {
          controller.get('model').clear();
          controller.get('model').pushObjects(records.toArray());
        });
      }
    },

    activate: function activate() {
      this.setFlagAndNameForZS();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
    }
  });
});