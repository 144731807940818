define('deputee-office/routes/yp-agenda-parlament/passport/file-view', ['exports', 'ember', 'deputee-office/routes/download-file'], function (exports, _ember, _deputeeOfficeRoutesDownloadFile) {
  exports['default'] = _deputeeOfficeRoutesDownloadFile['default'].extend({
    controllerName: 'download-file',
    templateName: 'download-file',
    actions: {
      fileDownloadComplete: function functionName() {
        _ember['default'].$('.timerModal').addClass('displayNone');
      }
    },
    deactivate: function deactivate() {
      this._super();
      _ember['default'].run.schedule('afterRender', this, function () {
        _ember['default'].$('.timerModal').removeClass('displayNone');
      });
    }
  });
});