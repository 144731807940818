define('deputee-office/mixins/territory-flag-name', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    setFlagAndNameBySetting: function setFlagAndNameBySetting() {
      var territoryService = this.get('territoryService');
      var territoryName = territoryService.getTerritoryName();
      var appController = _ember['default'].getOwner(this).lookup('controller:application');

      this.set('appController', appController);

      if (territoryName != null) {
        var territoryId = territoryService.getTerritoryId();
        var flagService = this.get('flagService');
        var flag = flagService.getFlagUrlByTerritoryId(territoryId);

        appController.set('flag', flag);
        appController.set('territoryName', territoryName);
      }
    },

    setFlagAndNameForMP: function setFlagAndNameForMP() {
      var territoryService = this.get('territoryService');
      var territoryName = territoryService.getMPName();
      var flagService = this.get('flagService');
      var flag = flagService.getFlagUrlByTerritoryId('permskiy_krai');
      var appController = _ember['default'].getOwner(this).lookup('controller:application');
      this.set('appController', appController);
      appController.set('flag', flag);
      appController.set('territoryName', territoryName);
    },

    setFlagAndNameForZS: function setFlagAndNameForZS() {
      var territoryService = this.get('territoryService');
      var territoryName = territoryService.getZSName();
      var flagService = this.get('flagService');
      var flag = flagService.getFlagUrlByTerritoryId('permskiy_krai');
      var appController = _ember['default'].getOwner(this).lookup('controller:application');
      this.set('appController', appController);
      appController.set('flag', flag);
      appController.set('territoryName', territoryName);
    },

    setFlagAndNameForPAMS: function setFlagAndNameForPAMS() {
      var territoryService = this.get('territoryService');
      var territoryName = territoryService.getPAMSName();
      var flagService = this.get('flagService');
      var flag = flagService.getFlagUrlByTerritoryId();
      var appController = _ember['default'].getOwner(this).lookup('controller:application');
      this.set('appController', appController);
      appController.set('flag', flag);
      appController.set('territoryName', territoryName);
    },

    setDefaultFlag: function setDefaultFlag() {
      var appRoute = _ember['default'].getOwner(this).lookup('route:application');
      var appController = this.get('appController');

      appRoute.setCurrentUserFlag();
      appController.set('territoryName', null);
    }

  });
});