define('deputee-office/components/favorites/favorites-budgets', ['exports', 'ember', 'deputee-office/mixins/favorites-component-base'], function (exports, _ember, _deputeeOfficeMixinsFavoritesComponentBase) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsFavoritesComponentBase['default'], {
    store: _ember['default'].inject.service(),

    loadableModels: ['favorite', 'budget'],

    init: function init() {
      this._super();
      this.set('model', _ember['default'].A([]));
      this.set('displayedModel', _ember['default'].A([]));
    },

    grouped: _ember['default'].computed('displayedModel.[]', function () {
      var budgets = this.get('displayedModel');
      if (budgets) {
        var result = _ember['default'].A([]);
        var idx = [];
        budgets.toArray().sortByProperty('OrderNum', 'asc').forEach(function (record) {
          var _budget = record.get('year');
          if (!result.isAny('key', _budget)) {
            idx[_budget + '_'] = _ember['default'].A([]);
            result.pushObject({ key: _budget, items: idx[_budget + '_'] });
          }

          idx[_budget + '_'].pushObject(record);
        });
        return result;
      }
    }),

    loadData: function loadData() {
      var store = this.get('store');
      var model = this.get('model');

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        store.query('favorite', { Model: 'budget' }).then(function (favorites) {

          var ids = favorites.map(function (favorite) {
            return JSON.parse(favorite.get('Params')).id;
          });

          store.findAll('budget').then(function (budgets) {
            budgets.forEach(function (budget) {
              var id = budget.get('id');

              if (ids.indexOf(id) !== -1) {
                model.pushObject(budget);
              }
            });

            resolve();
          })['catch'](function (e) {
            reject();
            console.error(e);
          });
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'favorites-budgets: loadData');
    },

    filterModel: function filterModel() {
      var model = this.get('model');
      var displayedModel = this.get('displayedModel');
      var filterText = this.get('searchString');

      displayedModel.clear();

      if (filterText && filterText.value) {
        model.forEach(function (budget) {
          if (budget.get('Title').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1) {
            displayedModel.pushObject(budget);
          }
        });
      } else {
        displayedModel.pushObjects(model);
      }
    }

  });
});