define('deputee-office/objects/network-setting', ['exports', 'ember', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeConfigEnvironment) {

  // Константы свойств, необходимы для корректной сериализации.
  // (Т.к. при инстанцировании через контейнер в объект добавляются внутренние свойства,
  // которые непонятно как исключать из сериализации другими способами).
  var customProps = ['WiFiOnly', 'Host', 'Login', 'Password', 'RememberMe'];

  exports['default'] = _ember['default'].Object.extend({
    settingsService: _ember['default'].inject.service('settingsservice'),

    defaultSettings: {
      WiFiOnly: false,
      Host: _deputeeOfficeConfigEnvironment['default'].APP.servicesHost,
      Login: '',
      Password: '',
      RememberMe: false
    },

    init: function init() {
      this.setProperties(this.defaultSettings);
    },
    /**
    * Создание объекта, содержащего необходимые свойства для сериализации.
    * @return {Object} объект, содержащий только нужные для сохранения настроек поля.
    */
    serialize: function serialize() {
      var result = {};

      for (var key in _ember['default'].$.extend(true, {}, this)) {
        if (_ember['default'].$.inArray(key, customProps) !== -1) {
          result[key] = this[key];
        }
      }

      return result;
    },

    /**
    * Сброс настроек.
    * @return {undefined}
    */
    setDefault: function setDefault() {
      this.setProperties(this.defaultSettings);
      this.save();
    },

    /**
    * Сохранение настроек в локальном хранилище.
    * @return {undefined}
    */
    save: function save() {
      var settingsservice = this.get('settingsService');
      var data = this.serialize();

      settingsservice.putSettings('network-setting', new Date(), data);
    },

    /**
    * Отменяет внесенные локально изменения в объект, приводя его к виду настроек,
     * находящихся в локальном хранилище.
    * @return {undefined}
    */
    rollbackAttributes: function rollbackAttributes() {
      var settingsservice = this.get('settingsService');
      var settings = settingsservice.getSettings('network-setting', false);

      this.setProperties(settings);
    }
  });
});