define('deputee-office/components/download-button-all-document', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    /**
     * Путь по которому находится компонент.
     */
    pathQuery: '',

    /**
     * Отображать кнопку загрузки архива.
     */
    isDownLoad: true,

    /**
     * Возвращает id паспорта закона или пункта повестки в зависимости от значения pathQuery
     */
    id: _ember['default'].computed('pathQuery', function () {
      if (this.pathQuery === 'laws.passport.index') {
        return this.get('parentView.model.Passport.PassportId');
      }

      if (this.pathQuery === 'zs-agenda.passport.index' || this.pathQuery === 'zs-agenda-departments.passport.index') {
        return this.get('parentView.passportModel.id');
      }
    }),

    /**
     * Возвращает url до zip архива паспорта/ппз.
     */
    pathQueryDocument: _ember['default'].computed('pathQuery', function () {
      if (this.pathQuery === 'laws.passport.index') {
        return 'api/zsGetUrlZipPassportDocuments/';
      }

      if (this.pathQuery === 'zs-agenda.passport.index' || this.pathQuery === 'zs-agenda-departments.passport.index') {
        return 'api/zsGetUrlZipPPZDocuments/';
      }
    }),

    /**
     * Наименование файла архива без пути.
     */
    fileName: _ember['default'].computed('pathQuery', function () {
      if (this.pathQuery === 'laws.passport.index') {
        return this.get('parentView.model.Title');
      }

      if (this.pathQuery === 'zs-agenda.passport.index' || this.pathQuery === 'zs-agenda-departments.passport.index') {
        return this.get('parentView.passportModel.PassportHeader.Title');
      }
    }),

    actions: {
      /**
       * Возращает zip архив файлов паспорта/ппз пользователю для сохранения и удаляет этот архив на сервере.
       */
      download: function download() {
        var _this = this;

        this.set('isDownLoad', false);
        this.set('pathQuery', this.get('parentView.target.currentRouteName'));
        var id = this.get('id');
        var settingsService = this.get('settingsservice');
        var currentUserService = this.get('currentuserservice');
        var urlQuery = settingsService.getHost() + this.get('pathQueryDocument') + id;
        var fileName = this.get('fileName');
        var authHeader = currentUserService.getBasicAuthHeader();
        var headers = new Headers({ Authorization: authHeader });
        var options = {
          headers: headers
        };

        fetch(urlQuery, options).then(function (resp) {
          return resp.blob();
        }).then(function (blob) {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          a.download = fileName + '.zip';
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          _this.set('isDownLoad', true);
        })['catch'](function () {
          return console.log('Ошибка при загрузке архива.');
        });
      }
    }
  });
});