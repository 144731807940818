define('deputee-office/models/external-resource', ['exports', 'ember-data', 'deputee-office/models/base', 'deputee-office/mixins/services-mixin', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _deputeeOfficeMixinsServicesMixin, _ember) {

  var Model = _deputeeOfficeModelsBase['default'].extend(_deputeeOfficeMixinsServicesMixin['default'], {
    fileService: _ember['default'].inject.service(),

    Name: _emberData['default'].attr('string'),
    Url: _emberData['default'].attr('string'),
    ImageUrl: _emberData['default'].attr('string'),
    ImageUrlServer: _ember['default'].computed('ImageUrl', function () {
      var _this = this;
      var fileService = this.get('fileService');
      var platform = fileService.get('platform');
      var host = this.get('settingsservice').getHost();
      var fileName = this.get('ImageUrl');
      var url = host + fileName;
      var fileDirectory = platform === 'android' ? window.cordova.file.externalDataDirectory : window.cordova.file.dataDirectory;
      var fullFileName = fileDirectory + fileName;
      if (platform === 'ios') {
        fullFileName = fullFileName.replace(/\\/g, '/');
      }

      if (platform === 'android' || platform === 'ios') {
        var setupImageUrlServer = function functionName() {
          window.resolveLocalFileSystemURL(fullFileName, function functionName(fileEntry) {
            console.log('Файл доступен');
            fileEntry.file(function (file) {
              var reader = new FileReader();

              reader.onloadend = function () {
                console.log('Результат получен');
                _this.set('ImageUrlServer', reader.result);
              };

              reader.onerror = function () {
                console.log('Ошибка чтения');
              };

              reader.readAsDataURL(file);
            }, function functionName() {
              console.log('Ошибка чтения');
            });
          }, function functionName() {
            console.log('Файл не доступен');
          });
        };

        var error = function functionName() {
          console.log('Загурузка не удалась');
        };

        if (this.get('networkstatus').isConnectionAccess()) {
          var ft = new window.FileTransfer();
          ft.download(encodeURI(url), fullFileName, setupImageUrlServer, error);
        } else {
          setupImageUrlServer();
        }
      } else {
        return url;
      }
    })
  });

  exports['default'] = Model;
});