define('deputee-office/models/yp-passport/yp-passport-document', ['exports', 'ember', 'ember-data', 'deputee-office/models/base-passport-document', 'ember-flexberry-data'], function (exports, _ember, _emberData, _deputeeOfficeModelsBasePassportDocument, _emberFlexberryData) {

  var Model = _deputeeOfficeModelsBasePassportDocument['default'].extend({
    Date: _emberData['default'].attr('string'),
    OmsuOffer: _emberData['default'].attr('number'),
    OmsuAccept: _emberData['default'].attr('boolean'),
    IsOmsuAccept: _ember['default'].computed('property', function () {
      return this.get('OmsuAccept') ? 'Да' : 'Нет';
    })
  });

  Model.defineProjection('yp-passport-document-full', 'yp-passport/yp-passport-document', {
    Type: _emberFlexberryData.Projection.attr('Type'),
    Title: _emberFlexberryData.Projection.attr('Title'),
    PassportId: _emberFlexberryData.Projection.attr('PassportId'),
    QuestionId: _emberFlexberryData.Projection.attr('QuestionId'),
    Date: _emberFlexberryData.Projection.attr('Date'),
    OrderNum: _emberFlexberryData.Projection.attr('OrderNum'),
    TabName: _emberFlexberryData.Projection.attr('TabName'),
    OmsuOffer: _emberFlexberryData.Projection.attr('OmsuOffer'),
    OmsuAccept: _emberFlexberryData.Projection.attr('OmsuAccept'),
    TypeName: _emberFlexberryData.Projection.attr('TypeName'),
    Files: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-file', 'Files', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      Url: _emberFlexberryData.Projection.attr('Url')
    })
  });

  exports['default'] = Model;
});