define('deputee-office/components/year-select-popover', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    dateFormat: 'D MMMM',
    yearMaxHeight: _ember['default'].computed(function () {
      return _ember['default'].$(document).height() - this.$().offset().top - 120 + 'px';
    }),
    popoverId: 'popoverYearsSelectorId',
    monthYearFormat: 'MMMM YYYY',

    years: _ember['default'].computed('model', function () {
      var dateFieldName = this.get('dateFieldName');
      return this.get('model').map(function (item) {
        return (0, _moment['default'])(item.get(dateFieldName)).year();
      }).uniq().sort();
    }),
    selectedYear: _ember['default'].computed('model', function () {
      var dateFieldName = this.get('dateFieldName');
      return (0, _moment['default'])(this.get('selected').get(dateFieldName)).year();
    }),
    didRender: function didRender() {
      if (_ember['default'].$('.webui-popover-content #scrollable-div') !== undefined) {
        _ember['default'].$('.webui-popover-content #scrollable-div').trigger('updatescroll');
      }
    },

    filter: function filter(items) {
      var _this = this;
      var dateFieldName = _this.get('dateFieldName');
      var curentDate = _this.get('curentDate');
      var dateFormat = this.get('dateFormat');
      var monthYearFormat = this.get('monthYearFormat');

      return items.filter(function (item) {
        if (curentDate) {
          var itemDate = (0, _moment['default'])(item.get(dateFieldName));
          var result = curentDate.year() === itemDate.year();
          if (result && !item.popoverFormattedDate) {
            if (dateFormat === monthYearFormat) {
              item.popoverFormattedDate = itemDate.format(dateFormat).firstCharToUpper();
            } else {
              item.popoverFormattedDate = itemDate.format(dateFormat);
            }
          }

          return result;
        } else {
          return true;
        }
      }).sort(function (a, b) {
        return b.get(dateFieldName).getTime() - a.get(dateFieldName).getTime();
      });
    },
    actions: {
      selectItem: function selectItem(item) {
        _ember['default'].$('#' + this.get('popoverId')).webuiPopover('hide');
        _ember['default'].run.later(this, function () {
          this.sendAction('onSelect', item);
        }, 50);
        _ember['default'].$('.filterContainer').trigger('clearFilter');
        _ember['default'].$('.scrollable-div').trigger('updatescroll');
      },
      onYearChange: function onYearChange(date) {
        this.set('curentDate', date);
        if (this.get('model')) {
          var items = this.filter(this.get('model'));
          this.set('items', items);
        }
      }
    }
  });
});