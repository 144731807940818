define('deputee-office/routes/zs-agenda', ['exports', 'ember', 'deputee-office/routes/base-agenda-route'], function (exports, _ember, _deputeeOfficeRoutesBaseAgendaRoute) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoute['default'].extend({
    offlineGlobals: _ember['default'].inject.service(),
    targetControllerName: 'zs-agenda.index',
    zsQuestion: true,
    passportLinkName: 'zs-agenda.passport',
    title: 'Вопросы повестки ',

    /**
     * Получение участников заседания ЗС.
     */
    getAgendaMembers: function getAgendaMembers(agendaId) {
      var membersPromise = this.store.query('zs-passport/zs-passport-agenda-member', {
        AgendaId: agendaId,
        projection: 'zs-passport-agenda-member-full'
      });
      var periodsPromise = this.store.query('zs-passport/zs-passport-agenda-period', {
        AgendaId: agendaId
      });

      return _ember['default'].RSVP.hashSettled({
        members: membersPromise,
        periods: periodsPromise
      }, 'zs agenda members').then(function (x) {
        var result = x.members.value ? x.members.value : [];
        result.set('periods', x.periods.value);
        return result;
      });
    },

    getDepartmentQuery: function getDepartmentQuery() {
      var promise = new _ember['default'].RSVP.Promise(function (resolve) {
        _ember['default'].run.next(this, function () {
          var department = _ember['default'].Object.create({
            id: 'zs'
          });
          var departments = [department];
          resolve(departments);
        });
      }, 'getDepartmentQuery: create fake department');

      return promise;
    },

    getAgendaQuery: function getAgendaQuery(agendaId) {
      var _this = this;
      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.store.findAll('zsagenda').then(function (agendas) {
          resolve(agendas.findBy('id', agendaId));
        })['catch'](function (e) {
          reject(e);
        });
      });

      return promise;
    },

    getAgendaByDepartmentQuery: function getAgendaByDepartmentQuery() {
      return this.store.findAll('zsagenda');
    },

    getQuestionsQuery: function getQuestionsQuery(agendaId) {
      return this.store.query('zsquestion', {
        AgendaId: agendaId
      });
    },

    unloadedFileCountUrl: function unloadedFileCountUrl(agendaId) {
      return this.get('settingsservice').getHost() + '/api/zsquestions/unloadedAllFileCount/' + agendaId;
    },

    activate: function activate() {
      this.setFlagAndNameForPAMS();
    },

    deactivate: function deactivate() {
      this._super();
      this.setDefaultFlag();
    },

    actions: {
      refreshAgenda: function refreshAgenda() {
        this.refresh();
      }
    }
  });
});