define('deputee-office/models/budget', ['exports', 'ember-data', 'deputee-office/models/base', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    DocId: _emberData['default'].attr('string'),
    Title: _emberData['default'].attr('string'),
    Date: _emberData['default'].attr('string'),
    title: _ember['default'].computed('Title', function () {
      return this.get('Title');
    }),
    date: _ember['default'].computed('Title', function () {
      return (0, _moment['default'])(this.get('Title'), 'MMMM YYYY');
    }),
    year: _ember['default'].computed('Title', function () {
      return (0, _moment['default'])(this.get('Title'), 'YYYY').year();
    }),
    OrderNum: _emberData['default'].attr('number')
  });

  exports['default'] = Model;
});