define('deputee-office/components/contact-department-tree-node-check', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    expanded: false,

    nextLevel: _ember['default'].computed('level', function () {
      return this.get('level') + 1;
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('level') < this.get('showLevel')) {
        this.set('expanded', true);
      }
    },

    actions: {
      nodeClick: function nodeClick(model) {
        if (model) {
          var IsHidden = model.get('IsHidden');
          model.set('IsHidden', !IsHidden);
          this.sendAction('onNodeClickAction', model);
        } else {
          var expanded = this.get('expanded');
          this.set('expanded', !expanded);
        }
      }
    }
  });
});