define('deputee-office/controllers/newslist', ['exports', 'ember'], function (exports, _ember) {
  var notFoundMsg = 'По вашему запросу ничего не найдено.';
  exports['default'] = _ember['default'].Controller.extend({
    newslistTemplateName: 'newslist-main',
    queryParams: ['category', 'personal', 'sourceType', 'newsSubState', 'itemId'],
    sourceList: ['Все', 'Радио-ТВ', 'Интернет', 'Пресса'],
    selectedSource: 'Все',
    filterIcon: _ember['default'].computed('selectedSource', function () {
      if (this.get('selectedSource') !== 'Все') {
        return 'glyphicon icon-filter-selected';
      }

      return 'glyphicon icon-filter';
    }),
    newsTitle: _ember['default'].computed('category', 'personal', function () {
      var name = 'Новости о Законодательном Собрании';
      if (this.get('category') === 'RssKPRF') {
        name = 'Новости КПРФ';
      }

      if (this.get('category') === 'RssER') {
        name = 'Новости Единой России';
      }

      if (this.get('category') === 'RssLDPR') {
        name = 'Новости ЛДПР';
      }

      if (this.get('personal') === 'true') {
        name = 'Новости обо мне';
      }

      return name;
    }),

    grouped: _ember['default'].computed('model.[]', function () {
      var idx = {};
      this.get('model').forEach(function (record) {
        var _month = record.get('Month');
        if (!idx[_month]) {
          idx[_month] = _ember['default'].A([]);
        }

        idx[_month].pushObject(record);
      });
      return idx;
    }),
    errorMsg: _ember['default'].observer('grouped', function () {
      if (this.get('grouped') && this.get('grouped').length > 0) {
        this.set('errorMessage', null);
      } else {
        this.set('errorMessage', notFoundMsg);
      }
    }),
    newsStateChanged: _ember['default'].observer('newsSubState', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var _this = this;

        switch (this.get('newsSubState')) {
          case 'showNews':
            if (this.get('itemId') && !this.get('currentNews')) {
              this.store.findRecord('newsitem', this.get('itemId')).then(function (news) {
                _this.set('currentNews', news);
              });
            }

            this.set('newslistTemplateName', 'news-substate/newsitem');
            break;
          default:
            this.set('newslistTemplateName', 'newslist-main');
        }
      });
    }),
    actions: {
      updateDate: function updateDate(newDate) {
        this.get('target').transitionTo({ queryParams: { date: newDate.format('DD.MM.YYYY') } });
      },
      selectSource: function selectSource(selectedSource) {
        this.set('selectedSource', selectedSource);
        if (selectedSource === 'Все') {
          this.get('target').transitionTo({ queryParams: { sourceType: null } });
          return;
        }

        if (selectedSource === 'Радио-ТВ') {
          this.get('target').transitionTo({ queryParams: { sourceType: 'TvRadio' } });
          return;
        }

        if (selectedSource === 'Интернет') {
          this.get('target').transitionTo({ queryParams: { sourceType: 'Internet' } });
          return;
        }

        if (selectedSource === 'Пресса') {
          this.get('target').transitionTo({ queryParams: { sourceType: 'Pressa' } });
          return;
        }
      },
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      }
    }
  });
});