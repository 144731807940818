define('deputee-office/helpers/short-committee', ['exports', 'ember'], function (exports, _ember) {
  exports.shortCommittee = shortCommittee;

  function shortCommittee(params) {
    var committee = params[0];

    try {
      if (committee.startsWith('Комитет ') || committee.startsWith('Комиссия ')) {
        committee = committee.substr(committee.indexOf(' ') + 1).firstCharToUpper();
      }
    } catch (e) {
      console.error(e);
    } finally {

      return committee;
    }
  }

  exports['default'] = _ember['default'].Helper.helper(shortCommittee);
});