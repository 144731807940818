define('deputee-office/models/zs-passport/zs-voting-result', ['exports', 'ember-data', 'deputee-office/models/base', 'ember-flexberry-data', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _emberFlexberryData, _ember, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Name: _emberData['default'].attr('string'),

    /**
     * Идентификатор паспорта закона.
     */
    PassportId: _emberData['default'].attr('string'),

    /**
     * Идентификатор вопроса заседания или поправки.
     */
    QuestionId: _emberData['default'].attr('string'),
    VoteForCount: _emberData['default'].attr('number'),
    VoteAgainstCount: _emberData['default'].attr('number'),
    AbstainCount: _emberData['default'].attr('number'),
    NotVoteCount: _emberData['default'].attr('number'),
    Type: _emberData['default'].attr('string'),
    Date: _emberData['default'].attr('string'),
    Result: _emberData['default'].attr('string'),
    Reading: _emberData['default'].attr('string'),
    QuestionNumber: _emberData['default'].attr('string'),
    VotePersons: _emberData['default'].hasMany('zs-passport/zs-voting-person', { async: false }),
    allCount: _ember['default'].computed('VoteForCount', 'VoteAgainstCount', 'AbstainCount', function () {
      var VoteForCount = this.get('VoteForCount');
      var VoteAgainstCount = this.get('VoteAgainstCount');
      var AbstainCount = this.get('AbstainCount');

      var all = VoteForCount + VoteAgainstCount + AbstainCount;

      return all;
    }),

    VoteForCountString: _ember['default'].computed('VoteForCount', function () {
      var VoteForCount = this.get('VoteForCount');
      var all = this.get('allCount');
      return all !== 0 ? VoteForCount + ' (' + Math.round(VoteForCount / all * 100) + '%)' : '0 (0%)';
    }),

    FullZsVoteResultName: _ember['default'].computed('Date', 'Name', 'QuestionNumber', function () {
      var _date = this.get('Date');
      var date = _date && new Date(_date);
      var dateFormated = '';
      if (date && date.getTime() > 0 && (date.getHours() > 0 || date.getMinutes() > 0)) {
        dateFormated = (0, _moment['default'])(date.getTime() + (_moment['default'].parseZone(_date).utcOffset() - _moment['default'].parseZone(new Date()).utcOffset()) * 60000).format('HH:mm');
      }

      var name = this.get('Name') || '';
      var number = this.get('QuestionNumber');
      if (dateFormated || name) {
        return number ? dateFormated + ' ' + name + ' (вопрос ' + number + ')' : dateFormated + ' ' + name;
      } else {
        return number ? 'Вопрос ' + number : 'Голосование';
      }
    }),

    GroupName: _ember['default'].computed('Reading', 'Date', function () {
      var reading = this.get('Reading');

      var _date = this.get('Date');
      var date = _date && new Date(_date);
      var dateFormated = '';
      if (date && date.getTime() > 0) {
        dateFormated = (0, _moment['default'])(date.getTime() + (_moment['default'].parseZone(_date).utcOffset() - _moment['default'].parseZone(new Date()).utcOffset()) * 60000).format('DD.MM.YYYY');
      }

      reading = reading !== 'Пусто' && reading || '';

      if (reading && dateFormated) {
        return reading + ' (' + dateFormated + ')';
      }

      return '' + reading + dateFormated;
    }),

    VoteAgainstCountString: _ember['default'].computed('VoteAgainstCount', function () {
      var VoteAgainstCount = this.get('VoteAgainstCount');
      var all = this.get('allCount');
      return all !== 0 ? VoteAgainstCount + ' (' + Math.round(VoteAgainstCount / all * 100) + '%)' : '0 (0%)';
    }),
    AbstainCountString: _ember['default'].computed('AbstainCount', function () {
      var AbstainCount = this.get('AbstainCount');
      var all = this.get('allCount');
      return all !== 0 ? AbstainCount + ' (' + Math.round(AbstainCount / all * 100) + '%)' : '0 (0%)';
    }),
    NotVoteCountString: _ember['default'].computed('NotVoteCount', function () {
      var NotVoteCount = this.get('NotVoteCount');
      return NotVoteCount;
    }),
    VoteForCountPercent: _ember['default'].computed('VoteForCount', function () {
      var VoteForCount = this.get('VoteForCount');
      var all = this.get('allCount');
      return all !== 0 ? Math.round(VoteForCount / all * 100) : 0;
    }),
    VoteAgainstCountPercent: _ember['default'].computed('VoteAgainstCount', function () {
      var VoteAgainstCount = this.get('VoteAgainstCount');
      var all = this.get('allCount');
      return all !== 0 ? Math.round(VoteAgainstCount / all * 100) : 0;
    }),
    AbstainCountPercent: _ember['default'].computed('AbstainCount', function () {
      var AbstainCount = this.get('AbstainCount');
      var all = this.get('allCount');
      return all !== 0 ? Math.round(AbstainCount / all * 100) : 0;
    })
  });

  Model.defineProjection('zs-voting-result-full', 'zs-passport/zs-voting-result', {
    Name: _emberFlexberryData.Projection.attr('Name'),
    Title: _emberFlexberryData.Projection.attr('Title'),
    VoteForCount: _emberFlexberryData.Projection.attr('VoteForCount'),
    VoteAgainstCount: _emberFlexberryData.Projection.attr('VoteAgainstCount'),
    AbstainCount: _emberFlexberryData.Projection.attr('AbstainCount'),
    NotVoteCount: _emberFlexberryData.Projection.attr('NotVoteCount'),
    TerritoryId: _emberFlexberryData.Projection.attr('TerritoryId'),
    Type: _emberFlexberryData.Projection.attr('Type'),
    VotePersons: _emberFlexberryData.Projection.hasMany('zs-passport/zs-voting-person', 'VotePersons', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      Url: _emberFlexberryData.Projection.attr('Url')
    })
  });

  exports['default'] = Model;
});