define('deputee-office/controllers/modals/yp-comments', ['exports', 'ember', 'deputee-office/mixins/group-by', 'deputee-office/mixins/yp-comments'], function (exports, _ember, _deputeeOfficeMixinsGroupBy, _deputeeOfficeMixinsYpComments) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsGroupBy['default'], _deputeeOfficeMixinsYpComments['default'], {

    commentsName: 'model',

    updateContainerHeight: function updateContainerHeight() {
      var _this = _ember['default'].$('.ypCommentsModal');
      var hH = _this.find('.modal-header').first().outerHeight();
      var fH = _this.find('.modal-footer').first().outerHeight();
      var cH = _this.find('.modal-content').first().height();
      this.set('childCommHeight', cH - fH - hH + 'px');
      _this.find('#scrollable-divcomments').first().children('.container-fluid').css('max-height', cH - fH - hH + 'px');
    },

    actions: {
      votingResult: function votingResult() {
        this._super.apply(this, arguments);
        return false;
      },

      didShown: function functionName() {
        this.set('isShowDiagram', true);
        this.updateScroll(true);
        this.updateContainerHeight();
      }
    }
  });
});