define('deputee-office/services/file-service', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Service.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    store: _ember['default'].inject.service(),
    networkstatus: _ember['default'].inject.service(),
    notificationService: _ember['default'].inject.service(),
    /**
     * platform - Вычислимое свойство получения названия платформы.
     *
     * @param  {type} function( description
     * @return {string}           Название платформы.
     */
    platform: _ember['default'].computed(function () {
      if (window.device && window.device.platform) {
        var platform = window.device.platform.toLowerCase();
        return platform;
      }
    }),

    /**
     * fileDirectory - Получение диретории сохранения файлов для устройств.
     *
     * @param  {type} function(  description
     * @return {string}            Путь к файлам.
     */
    fileDirectory: _ember['default'].computed(function () {
      var platform = this.get('platform');
      var fileDirectory = platform === 'android' ? window.cordova.file.externalDataDirectory : window.cordova.file.dataDirectory;
      return fileDirectory;
    }),
    saveModel: function functionName(model) {
      var _this = this;
      model.save()['catch'](function (e) {
        if (model.get('isNew')) {
          model.deleteRecord();
        }

        model.rollbackAttributes();
        if (e.status === 413) {
          _this.get('notificationService').alert('Размер прикрепляемого файла превышает допустимый предел.', null, 'Внимание!');
        } else {
          _this.get('notificationService').alert('При выполнении операции произошла ошибка.', null, 'Внимание!');
        }

        console.error('Save Error: ');
        console.error(e);
      });
    },
    /**
     * sendNewVersion - отправка версии с примечаниями на сервер.
     *
     * @param  {type} _this         Контекст.
     * @param  {type} file          Информация о файле.
     * @param  {string} newFilepath   Путь до новой версии файла.
     */
    sendNewVersion: function functionName(_this, file, newFilepath) {
      window.resolveLocalFileSystemURL(newFilepath, function functionName(fileEntry) {
        var newFile = _this.get('store').createRecord('file', {
          FileInfoId: file.get('FileInfoId'),
          FileVersion: file.get('FileVersion'),
          UserFileVersion: file.get('UserFileVersion'),
          SubsystemFileId: file.get('SubsystemFileId'),
          DocType: file.get('DocType'),
          SubsystemKey: file.get('SubsystemKey')
        });
        fileEntry.file(function (file) {
          var reader = new FileReader();
          reader.onloadend = function () {
            var imgBlob = new Blob([this.result], { type: 'application/pdf' });
            imgBlob.name = file.name;
            newFile.set('Name', file.name);
            newFile.set('file', imgBlob);
            _this.saveModel(newFile);
          };

          reader.readAsArrayBuffer(file);
        }, function functionName(e) {
          console.error(e);
        });
      }, function functionName(e) {
        console.error(e);
      });
    },

    deviceNativeOpen: function androidNativeOpen() {
      var path = this.get('originalPath');
      if (this.get('platform') === 'android') {
        path = this.get('nativeURL');
      }

      window.cordova.plugins.fileOpener2.open(path, this.get('lastFile.MediaType'), {
        error: function error(e) {
          console.log('Error status: ' + e.status + ' - Error message: ' + e.message);
        },
        success: function success() {
          console.log('file opened successfully');
        }
      });
    },

    /**
     * deviceOpenFile - Функция открытия файла на устройстве.
     *
     * @param  {type} _this   Контекст.
     * @param  {string} file  Модель файла.
     * @param  {function} success Функция успеха.
     * @param  {function} error   Функция провала.
     * @return {undefined}         description
     */
    deviceOpenFile: function deviceOpenFile(_this, file, fileList, success, error, forcePDFObject, callback) {
      var options = {
        pdfOpenParams: {
          navpanes: 0,
          toolbar: 0,
          statusbar: 0,
          view: 'FitV',
          pagemode: 'thumbs'
        },
        forcePDFJS: true,
        PDFJS_URL: './pdfjs/web/viewer.html'
      };
      var platform = this.get('platform');
      var originalPath = encodeURI(_this.getFullFileName(file));
      _this.set('originalPath', originalPath);
      window.resolveLocalFileSystemURL(originalPath, function functionName(fileEntry) {

        var path = fileEntry.nativeURL;
        _this.set('nativeURL', path);
        if (platform === 'android') {
          path = '../../../../' + path.substring(8);
        }

        if (platform === 'ios' && file.get('MediaType') === 'application/pdf') {
          path = path.substring(7);
          if (forcePDFObject === undefined || forcePDFObject === false) {
            var versions = [];
            fileList.forEach(function (item) {
              versions.push(item.id);
              versions.push(item.get('FileVersion') + '.' + item.get('UserFileVersion'));
            });
            window.PSPDFKitView.viewLocal([originalPath, _this.get('networkstatus').isInternetConnection(), versions], function (result) {
              if (result.filePath) {
                _this.sendNewVersion(_this, file, result.filePath);
              }

              if (result.versionToOpen) {
                var itemToOpen;
                fileList.forEach(function (item) {
                  if (item.id === result.versionToOpen) {
                    itemToOpen = item;
                  }
                });
                _this.crossplatformFileDownloadAndOpen(itemToOpen, fileList, callback, forcePDFObject, error, error);
              } else {
                window.history.back();
              }
            });
            callback();
            return;
          }
        }

        window.PDFObject.embed(path, '#pdfViewContent', options);
        callback();
      }, function functionName() {});
    },

    /**
     * getFullFileName - Фукция получения полного пути к файлу на устройстве.
     *
     * @param  {string} fileId Идентификатор файла.
     * @return {string}        Полный путь к файлу.
     */
    getFullFileName: function getFullFileName(file) {
      var localFileName = file.get('id') + file.get('Extension');
      var fileDirectory = this.get('fileDirectory');
      var fullFileName = fileDirectory + localFileName;
      return fullFileName;
    },

    /**
     * deviceDownloadFile - Функция загрузки файла на устройстве.
     *
     * @param  {type} _this   Контекст.
     * @param  {string} fileId  Идентификатор файла.
     * @param  {function} success Функция успеха.
     * @param  {function} error   Функция провала.
     * @return {undefined}         description
     */
    deviceDownloadFile: function deviceDownloadFile(_this, file, success, error) {
      var host = _this.get('settingsservice').getHost();
      var url = host + 'api/file/' + file.get('id');
      var ft = new window.FileTransfer();
      ft.download(encodeURI(url), _this.getFullFileName(file), success, error);
    },

    /**
     * browserDownloadFile - Загрузка файла в браузере.
     *
     * @param  {string} fileId Идентификатор файла.
     * @return {undefined}        description
     */
    browserDownloadFile: function browserDownloadFile(file) {
      var host = this.get('settingsservice').getHost();

      var options = {
        pdfOpenParams: {
          navpanes: 0,
          toolbar: 0,
          statusbar: 0,
          view: 'FitV',
          pagemode: 'thumbs'
        },
        forcePDFJS: true,
        PDFJS_URL: '/pdfjs/web/viewer.html'
      };

      window.PDFObject.embed(host + 'api/file/' + file.get('id'), '#pdfViewContent', options);
    },

    /**
     * deviceIsFileExist - Проверка существования файла на устройстве.
     *
     * @param  {string} fileId  Идентификатор файла.
     * @param  {function} success Функция успеха.
     * @param  {function} error   Функция провала.
     * @return {undefined}         description
     */
    deviceIsFileExist: function deviceIsFileExist(file, success, error) {
      var fullFileName = this.getFullFileName(file);
      window.resolveLocalFileSystemURL(fullFileName, success, error);
    },

    /**
     * crossplatformFileDownloadAndOpen - Универсальная функция для загрузки и открытия файла.
     *
     * @param  {string} fileId   Идентификатор файла.
     * @param  {function} callback Функция вызываемыя после зывершения.
     * @return {type}          description
     */
    crossplatformFileDownloadAndOpen: function crossplatformFileDownloadAndOpen(file, fileList, callback, forcePDFObject, errOpen, errDownload) {
      var platform = this.get('platform');
      var _this = this;
      _this.set('lastFile', file);
      if (platform === 'android' || platform === 'ios') {
        _this.deviceIsFileExist(file, function functionName() {
          console.log('Файл найден');
          _this.deviceOpenFile(_this, file, fileList, null, errOpen, forcePDFObject, callback);
        }, function functionName() {
          console.log('Файл не найден');
          _this.deviceDownloadFile(_this, file, function () {
            _this.deviceOpenFile(_this, file, fileList, null, errOpen, forcePDFObject, callback);
          }, errDownload);
        });
      } else {
        this.browserDownloadFile(file);
        callback();
      }
    },

    crossplatformFileDownload: function crossplatformFileDownloadAndOpen(file, fileList, successDownload, errDownload) {
      var platform = this.get('platform');
      var _this = this;
      var fileId = file.get('id');
      _this.set('lastFile', file);
      if (platform === 'android' || platform === 'ios') {
        _this.deviceIsFileExist(file, function functionName() {
          _ember['default'].Logger.debug('Файл ' + fileId + ' найден');
          successDownload(fileId);
        }, function functionName() {
          _ember['default'].Logger.debug('Файл ' + fileId + ' не найден');
          _this.deviceDownloadFile(_this, file, function () {
            successDownload(fileId);
          }, errDownload);
        });
      } else {
        successDownload();
      }
    }
  });
});