define('deputee-office/services/statistics-service', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeConfigEnvironment) {
  /**
   * Сервис для сбора статистики.
   */
  exports['default'] = _ember['default'].Service.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    /**
     * Метод логирования перехода пользователя в сервис.
     *
     * @param  {String} eventName     Идентификатор события.
     * @param  {String} eventParam    Параметр события.
     * @return {undefined}
     */
    sendStatEvent: function sendStatEvent(eventName, eventParam) {
      var _this = this;

      try {
        (function () {
          var isConnected = _this.get('networkstatus').isInternetConnection();
          var settingsService = _this.get('settingsservice');
          var authHeader = _this.get('currentuserservice').getBasicAuthHeader();

          // Статистика отправляется при наличии сети.
          if (isConnected) {
            (function () {
              var data = {
                eventName: eventName,
                eventParam: eventParam,
                deviceId: window.device.uuid,
                deviceModel: window.device.model,
                deviceManufacturer: window.device.manufacturer,
                OSName: window.device.platform,
                OSVersion: window.device.version
              };

              _ember['default'].run(function () {
                _ember['default'].$.ajax({
                  url: settingsService.getHost() + '/api/logServiceEntry',
                  headers: { Authorization: authHeader },
                  type: 'POST',
                  contentType: 'application/json',
                  dataType: 'json',
                  data: JSON.stringify(data),
                  async: true,
                  timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
                });
              });
            })();
          }
        })();
      } catch (e) {
        console.log('SendStat error: ' + e);
      }
    }
  });
});