define('deputee-office/routes/application', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'ember-simple-auth/mixins/application-route-mixin', 'deputee-office/config/environment', 'deputee-office/utils/version-compare'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _emberSimpleAuthMixinsApplicationRouteMixin, _deputeeOfficeConfigEnvironment, _deputeeOfficeUtilsVersionCompare) {

  var CUSTOM_SWIPE_MIN = 450;

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsApplicationRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], {
    flagService: _ember['default'].inject.service(),
    defaultPhoto: 'assets/img/no-photo.jpg',
    offlineGlobals: _ember['default'].inject.service(),
    cacheservice: _ember['default'].inject.service(),
    session: _ember['default'].inject.service('session'),
    fullscreen: _ember['default'].inject.service(),

    queryParams: {
      isGuest: {
        refreshModel: false,
        replace: true
      }
    },

    isGuestAuthenticating: null,

    model: function model(params) {
      var isGuest = params.isGuest;

      if (isGuest === 'true') {
        this.set('isGuestAuthenticating', true);
        if (!this.get('session.isAuthenticated')) {
          return this.get('session').authenticate('authenticator:custom', {
            identification: 'guest',
            password: 'guest'
          });
        }
      }

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        // Для ускорения открытия модального окна выбора территорий
        // this.store.findAll('territory')
        //   .then(function functionName(territories) {
        //     var img = (new Image());
        //     territories.forEach(function(item) {
        //       // Запрашиваем свойство чтобы оно вычислилось заранее и
        //       // флаг загрузился в из ассетов.
        //       img.src = item.get('FlagUrl');
        //     });
        //   });
        this.store.findAll('favorite');

        // this.store.query('omsudepartment', {
        //   TerritoryId: `${this.get('territoryService').getTerritoryId()}`
        // });
        // this.store.findAll('zs-department');
        // this.store.findAll('yp-department');
        // this.store.findAll('material-ksp');
        // this.store.findAll('eventsplan');
        // this.store.findAll('omsu-activity-type');
      });
      /*images preload*/
      var imgLib = ['datesorting', 'datesorting_desc', 'decreeviewlist', 'decreeviewtiles', 'backArrowCircle', 'filter_selected', 'menu', 'moreinfo', 'navbar-back', 'nextArrowCircle'];
      imgLib.forEach(function (item) {
        new Image().src = 'assets/img/' + item + '.png';
        new Image().src = 'assets/img/' + item + '_active.png';
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('controller', controller);

      var _this = this;
      var session = this.get('session');

      var onPause = function onPause() {
        if (window.device && window.device.platform.toLowerCase() === 'android') {
          _this.get('cacheservice').putCache('savedState', new Date(), window.location.hash.replace('#', ''));
        }
      };

      // Подготовка бокового меню после инициализации DOM.
      _ember['default'].run.schedule('afterRender', this, function () {
        var sidebarLeft = _ember['default'].$('[data-sidebar]#leftMenu');

        if (sidebarLeft.width()) {
          sidebarLeft.css('margin-left', sidebarLeft.width() * -1 + 'px');
        } else {
          sidebarLeft.css('margin-left', '-100%');
        }

        sidebarLeft.show(0, function () {
          sidebarLeft.css('transition', 'all 300ms linear');
          sidebarLeft.css('transform', 'translate3d(0, 0, 0)');
        });

        var sidebarRight = _ember['default'].$('[data-sidebar]#rightMenu');

        if (sidebarRight.width()) {
          sidebarRight.css('right', sidebarRight.width() * -1 + 'px');
        } else {
          sidebarRight.css('right', '-100%');
        }

        sidebarRight.show(0, function () {
          sidebarRight.css('transition', 'all 300ms linear');
          sidebarRight.css('transform', 'translate3d(0, 0, 0)');
        });
      });

      // Убирем среднюю кномку мыши, обрабатываем в ручную спкциальным компонентом.
      _ember['default'].run.schedule('afterRender', this, function () {

        var _this = this;

        _ember['default'].$('[data-sidebar-overlay]#leftMenu').on('click touchend', function () {
          _this.controllerFor('application').send('toggleMenu');
        });

        _ember['default'].$('[data-sidebar-overlay]#rightMenu').on('click touchend', function () {
          _this.controllerFor('application').send('rightMenu');
        });

        document.addEventListener('click', function (event) {
          if (event.which === 2) {
            event.preventDefault();
            event.stopPropagation();
          }
        }, true);

        document.addEventListener('touchstart', handleTouchStart, true);
        document.addEventListener('touchend', handleTouchEnd, true);

        document.addEventListener('pause', onPause, false);

        var xDown = null;
        var yDown = null;

        function handleTouchStart(evt) {
          xDown = evt.touches[0].clientX;
          yDown = evt.touches[0].clientY;
        }

        function handleTouchEnd(evt) {
          if (!xDown || !yDown) {
            return;
          }

          var xUp = evt.changedTouches[0].clientX;
          var yUp = evt.changedTouches[0].clientY;

          var xDiff = xDown - xUp;
          var yDiff = yDown - yUp;

          var loverlay = _ember['default'].$('[data-sidebar-overlay]#leftMenu');
          var roverlay = _ember['default'].$('[data-sidebar-overlay]#rightMenu');

          if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /*most significant*/
            if (xDiff > 0) {
              /* left swipe */
              if (Math.abs(xDiff) > 50 && session.get('isAuthenticated') === true) {

                if (Math.abs(xDiff) > CUSTOM_SWIPE_MIN && !loverlay.is(':visible') && !roverlay.is(':visible')) {
                  _ember['default'].$(window).trigger('customswipeleft');
                }

                if (loverlay.is(':visible')) {
                  _this.controllerFor('application').send('toggleMenu');
                }
              }
            } else {
              /* right swipe */
              if (Math.abs(xDiff) > 50 && session.get('isAuthenticated') === true) {
                var triggerSwipe = false;

                if (!loverlay.is(':visible') && !roverlay.is(':visible')) {
                  triggerSwipe = true;
                }

                if (roverlay.is(':visible')) {
                  _this.controllerFor('application').send('rightMenu');
                }

                if (xDown < _ember['default'].$(window).width() / 10) {
                  triggerSwipe = false;
                  if (!loverlay.is(':visible')) {
                    _this.controllerFor('application').send('toggleMenu');
                  }
                }

                if (Math.abs(xDiff) > CUSTOM_SWIPE_MIN && triggerSwipe) {
                  _ember['default'].$(window).trigger('customswiperight');
                }
              }
            }
          }
          /* reset values */
          xDown = null;
          yDown = null;
        }
      });

      // Получение флага и герба для аутентифицированного пользователя.
      var getFlag = function getFlag() {
        if (session.get('isAuthenticated') === true) {
          _this.setFlagAndPhoto(controller);
        }
      };

      getFlag();

      document.addEventListener('deviceready', function () {
        // Проверка обновления версии.
        window.cordova.getAppVersion.getVersionNumber(function (currentVer) {
          var lastVer = localStorage.getItem('lastVersion');
          if (currentVer !== lastVer) {
            var cacheService = _this.get('cacheservice');
            cacheService.clearCache();
            _this.store.unloadAll();
            _this.store.init();
            _this.store.adapterFor('aplication', false)['delete']();

            // Вернем информацию о последнем пользователе
            cacheService.putCache('userInfo', null, _this.get('currentuserservice').get('user'));

            // Сбрасываем хост
            var owner = _ember['default'].getOwner(_this);
            var networkSetting = owner.lookup('object:network-setting');
            networkSetting.setDefault();

            localStorage.setItem('lastVersion', currentVer);
            _this.refresh();
            console.log('Cleaning history due version updated to: ' + currentVer);
          }

          var checkVersion = function checkVersion() {
            var platform = String.prototype.toLowerCase.call(window.device && window.device.platform || '');

            if (platform && platform !== 'browser') {
              _this.set('checkVersionInProcess', true);
              _ember['default'].$.ajax({
                url: _deputeeOfficeConfigEnvironment['default'].APP.servicesHost + 'api/version',
                type: 'GET'
              }).then(function (version) {
                var serverVersion = version;
                if ((0, _deputeeOfficeUtilsVersionCompare['default'])(serverVersion, currentVer) > 0) {

                  var confirmCallback = function functionName(num) {
                    if (num === 1) {
                      var appId = platform === 'ios' ? 'itms-apps://itunes.apple.com/app/евкд-пк/id1155486679' : 'market://details?id=ru.perm.ics.DeputeeOffice';
                      window.cordova.InAppBrowser.open(appId, '_system');
                    }

                    _this.get('cacheservice').putCache('lastUpdateMsg', null, new Date().getTime());

                    var lastRun = _ember['default'].run.later(_this, checkVersion, _deputeeOfficeConfigEnvironment['default'].APP.updateMsgOffset);
                    _this.set('lastRun', lastRun);
                    _this.set('checkVersionInProcess', false);
                  };

                  _this.get('notificationService').confirm('Перейти в магазин для обновления версии?', confirmCallback, 'Используется устаревшая версия!', ['Да', 'Нет']);
                }
              }, function () {
                _ember['default'].Logger.error('Ошибка проверки актуальности версии');
                var lastRun = _ember['default'].run.later(_this, checkVersion, _deputeeOfficeConfigEnvironment['default'].APP.updateMsgOffset);
                _this.set('lastRun', lastRun);
                _this.set('checkVersionInProcess', false);
              });
            }
          };

          checkVersion();
          var reset = function reset() {
            if (!_this.get('checkVersionInProcess')) {

              var lastUpdateMsg = _this.get('cacheservice').getCache('lastUpdateMsg', false);
              _ember['default'].run.cancel(_this.get('lastRun'));

              var offset = new Date().getTime() - lastUpdateMsg;
              var lastRun;
              if (_deputeeOfficeConfigEnvironment['default'].APP.updateMsgOffset - offset < 0) {
                lastRun = _ember['default'].run.next(_this, checkVersion);
                _this.set('lastRun', lastRun);
              } else {
                lastRun = _ember['default'].run.later(_this, checkVersion, _deputeeOfficeConfigEnvironment['default'].APP.updateMsgOffset - offset);
                _this.set('lastRun', lastRun);
              }
            }
          };

          var _reset = reset.bind(_this);
          document.addEventListener('resume', _reset, false);
        });

        window.windowSoftManager.setMode('adjustResize');

        navigator.splashscreen.hide();
        var state = _this.get('cacheservice').getCache('savedState', false);
        if (state) {
          _this.get('cacheservice').putCache('savedState', new Date(), null);
          _this.transitionTo(state);
        }

        session.addObserver('isAuthenticated', _this, function () {
          getFlag();
        });

        if (_this.get('devicejs').isPhone()) {
          window.screen.orientation.lock('portrait');
        } else if (_this.get('devicejs').isTablet()) {
          window.screen.orientation.lock('landscape');
        } else {
          screen.orientation.unlock();
        }
      }, false);

      document.addEventListener('offline', function () {
        _this.get('offlineGlobals').setOnlineAvailable(false);
      }, false);

      document.addEventListener('online', function () {
        _this.get('offlineGlobals').setOnlineAvailable(true);
      }, false);
    },

    setFlagAndPhoto: function setFlagAndPhoto(controller) {
      try {
        var storage = this.get('cacheservice');
        var currentuser = this.get('currentuserservice');

        var user = currentuser.get('user');

        var photoImg = this.get('fotoService').getFotoUrlByName(this.get('session.data.authenticated.displayName'));

        if (!this.get('networkstatus').isInternetConnection()) {
          var photoUrl = currentuser.get('photoUrl');

          if (photoUrl != null) {
            photoImg = photoUrl;
            user.photoUrl = photoImg;
          } else {
            user.photoUrl = null;
          }

          storage.putCache('userInfo', null, user);
        } else {
          if (user) {
            var img = user.photoUrl;
            if (img) {
              photoImg = img;
            }
          }
        }

        this.setCurrentUserFlag();

        controller.set('photoImg', photoImg);
        controller.set('notGuest', !currentuser.get('isGuest'));
      } catch (e) {
        console.log(e);
      }
    },

    setCurrentUserFlag: function setCurrentUserFlag() {
      var user = this.get('currentuserservice').get('user');
      if (!user) {
        return;
      }

      var territoryId = user.territoryId;
      var flagService = this.get('flagService');
      var flag = flagService.getFlagUrlByTerritoryId(territoryId);

      this.get('controller').set('flag', flag);
    },

    actions: {
      invalidateSession: function invalidateSession() {
        _ember['default'].$.ajaxSetup({
          header: null
        });
        this.get('currentuserservice').set('user', null);
        this.get('session').invalidate();
      },

      toggleFullscreen: function toggleFullscreen() {
        var screen = this.get('fullscreen');

        if (screen.isEnabled) {
          screen.disable();
          window.open('https://ya.ru', '_system', 'fullscreen=no,location=yes');
        } else {
          screen.enable();
        }
      },

      sendStatAndRoute: function sendStatAndRoute(serviceId, target) {
        this.transitionTo(target);
        this.get('statisticsService').sendStatEvent('OpenService', serviceId);
      },
      toggleMenu: function toggleMenu() {
        var sidebar = _ember['default'].$('[data-sidebar]#leftMenu');
        var overlay = _ember['default'].$('[data-sidebar-overlay]#leftMenu');

        if (overlay.is(':visible')) {
          sidebar.css('margin-left', sidebar.width() * -1 + 'px');

          overlay.fadeTo('500', 0, function () {
            overlay.hide();
          });
        } else {
          sidebar.css('margin-left', '0');
          overlay.show(0, function () {
            overlay.fadeTo('500', 0.5);
          });
        }
      }
    },

    sessionInvalidated: function sessionInvalidated() {
      var sidebar = _ember['default'].$('[data-sidebar]#leftMenu');
      var overlay = _ember['default'].$('[data-sidebar-overlay]#leftMenu');

      if (overlay.is(':visible')) {
        sidebar.css('margin-left', sidebar.width() * -1 + 'px');
        overlay.fadeTo('500', 0, function () {
          overlay.hide();
        });
      }

      if (!this.get('isGuestAuthenticating')) {
        this.transitionTo('/login');
      }
    },

    sessionAuthenticated: function sessionAuthenticated() {
      var currentUser = this.get('currentuserservice');
      if (currentUser.newUserLoggedIn(this.store)) {
        return;
      }

      this.get('statisticsService').sendStatEvent('Login', '');

      if (!this.get('isGuestAuthenticating')) {
        this.transitionTo('/');
      } else {
        this.set('isGuestAuthenticating', false);
      }
    }

  });
});