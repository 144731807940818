define('deputee-office/controllers/modals/territory-modal', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    store: _ember['default'].inject.service(),
    actions: {
      didShown: function functionName() {
        _ember['default'].$('.needPF').scrollTop(0);
        _ember['default'].$('.needPF').perfectScrollbar('update');
      },
      save: function save() {
        var selectedTerritory = this.get('selectedTerritory');

        if (selectedTerritory && this.get('territoryService').getTerritory().id !== selectedTerritory.id) {
          var store = this.get('store');
          var data = store.serialize(selectedTerritory, { includeId: true });

          this.get('territoryService').setTerritory(data, true);
        }
      },
      closeModal: function closeModal() {
        this.get('target').send('removeModalTerritory');
        this.get('target').send('updateTerritoryName');
      },
      initModel: _ember['default'].on('init', function () {
        this._super();

        var store = this.get('store');
        var selectedTerritory = this.get('territoryService').getTerritory();

        this.set('selectedTerritory', selectedTerritory);

        var _this = this;

        store.findAll('territory').then(function (result) {
          _this.set('territories', result);
        });
      })
    }
  });
});