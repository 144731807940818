define('deputee-office/models/yp-agenda', ['exports', 'ember-data', 'deputee-office/models/base-agenda', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBaseAgenda, _ember) {

  var Model = _deputeeOfficeModelsBaseAgenda['default'].extend({
    DateFinish: _emberData['default'].attr('date'),
    AgendaSubType: _emberData['default'].attr('string'),
    DepartmentId: _emberData['default'].attr('guid'),
    StageOver: _emberData['default'].attr('date'),
    StageOverTicks: _emberData['default'].attr('number'),
    StageName: _emberData['default'].attr('string'),
    StageNameGenitive: _emberData['default'].attr('string'),
    Type: _emberData['default'].attr('string'),
    StageOverString: _ember['default'].computed('StageOver', function () {
      return this.get('StageOver').toString();
    }),
    StageNameGenitiveLower: _ember['default'].computed('StageNameGenitive', function () {
      return this.get('StageNameGenitive').toLowerCase();
    })
  });

  exports['default'] = Model;
});