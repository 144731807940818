define('deputee-office/routes/legal-acts/passport', ['exports', 'ember', 'deputee-office/routes/base-agenda-route/base-agenda-passport-components'], function (exports, _ember, _deputeeOfficeRoutesBaseAgendaRouteBaseAgendaPassportComponents) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRouteBaseAgendaPassportComponents['default'].extend({
    passportNavigate: 'legal-acts.passport',
    passportViewName: 'passport-views.default-simple-view',
    relationshipsPath: 'legal-acts.passport',

    getPassportQuery: function getPassportQuery() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve) {

        var shim = _ember['default'].Object.extend({}).create({
          PassportId: _this.get('params.legalact_id')
        });

        _ember['default'].run.scheduleOnce('afterRender', _this, function () {
          resolve(shim);
        });
      });
    },

    PassportHeaderQuery: function PassportHeaderQuery(passport) {
      return this.store.queryRecord('omsu-passport/omsu-passport-header', {
        id: passport.get('PassportId'),
        TerritoryId: this.get('territoryService').getTerritoryId(),
        projection: 'omsu-passport-header-full'
      });
    },

    getPassportRelationships: function functionName(passport) {
      return this.store.queryRecord('omsu-passport/omsu-passport-relationship', {
        id: passport.get('PassportId'),
        projection: 'omsu-passport-relationship-full',
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
    },

    getQuestionHistory: function getQuestionHistory(passport) {
      return this.store.query('omsu-passport/omsu-question-history', {
        QuestionId: passport.get('PassportId'),
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
    },

    getLegalActPublication: function getLegalActPublication(passport) {
      return this.store.query('omsu-passport/omsu-legal-act-publication', {
        PassportId: passport.get('PassportId'),
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
    }
  });
});