define('deputee-office/mixins/department-short-name', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    ShortName: _ember['default'].computed('Name', function () {
      var name = this.get('Name');

      try {
        if (name.startsWith('Комитет ') || name.startsWith('Комиссия ')) {
          name = name.substr(name.indexOf(' ') + 1).firstCharToUpper();
        }
      } catch (e) {
        console.error(e);
      } finally {

        return name;
      }
    })
  });
});