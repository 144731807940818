define('deputee-office/routes/omsu-agenda-pomopk', ['exports', 'ember', 'deputee-office/routes/base-agenda-route'], function (exports, _ember, _deputeeOfficeRoutesBaseAgendaRoute) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoute['default'].extend({
    targetControllerName: 'omsu-agenda-pomopk.index',
    passportLinkName: 'omsu-agenda-pomopk.passport',
    searchText: null,

    getAgendaMembers: function getAgendaMembers(agendaId) {
      return this.store.query('omsu-passport/omsu-agenda-member', {
        AgendaId: agendaId,
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
    },

    getDepartmentQuery: function getDepartmentQuery() {
      var promise = new _ember['default'].RSVP.Promise(function (resolve) {
        _ember['default'].run.next(this, function () {
          var department = _ember['default'].Object.extend({}).create({
            id: 'pomopk'
          });
          var departments = [department];
          resolve(departments);
        });
      }, 'fake pomopk department');

      return promise;
    },

    getAgendaQuery: function getAgendaQuery(agendaId) {
      var _this = this;
      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.store.findAll('pomopk-agenda').then(function (agendas) {
          resolve(agendas.findBy('id', agendaId));
        })['catch'](function (e) {
          reject(e);
        });
      });

      return promise;
    },

    getAgendaByDepartmentQuery: function getAgendaByDepartmentQuery() {
      return this.store.findAll('pomopk-agenda');
    },

    getQuestionsQuery: function getQuestionsQuery(agendaId) {
      return this.store.query('pomopk-question', {
        AgendaId: agendaId
      });
    },

    unloadedFileCountUrl: function unloadedFileCountUrl(agendaId) {
      return this.get('settingsservice').getHost() + '/api/omsuquestions/unloadedAllFileCount/' + agendaId + '?TerritoryId=' + this.get('territoryService').getTerritoryId();
    },

    activate: function activate() {
      this.setFlagAndNameBySetting();
    },

    deactivate: function deactivate() {
      this._super();
      this.setDefaultFlag();
    },

    actions: {}
  });
});