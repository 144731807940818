define('deputee-office/components/note-editor', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    notificationService: _ember['default'].inject.service(),
    networkstatus: _ember['default'].inject.service(),

    tagName: '',
    actions: {
      save: function save() {

        if (!this.get('networkstatus').isInternetConnection() && this.model.get('hasDirtyAttributes')) {
          this.get('notificationService').alert('Для сохранения заметки необходимо подключение к сети интернет.', null, 'Внимание!');
          return;
        }

        this.get('target').send('saveModel', this.model);
      },
      cancel: function cancel() {
        if (this.model.get('isNew') && !this.model.get('isSaving')) {
          this.model.deleteRecord();
        } else {
          this.model.rollbackAttributes();
        }

        this.get('target').send('removeNoteModal');
      }
    }
  });
});