define('deputee-office/models/yp-passport', ['exports', 'ember-data', 'deputee-office/models/base-passport', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBasePassport, _ember) {
  exports['default'] = _deputeeOfficeModelsBasePassport['default'].extend({
    IsCommentAvailable: _emberData['default'].attr('boolean'),
    NeedOfflineDiscussion: _emberData['default'].attr('boolean'),
    IsAmendmentAvailable: _emberData['default'].attr('boolean'),
    IsVoteAvailable: _emberData['default'].attr('boolean'),
    ShortInfo: _emberData['default'].attr('string'),
    ToolBarInfo: _ember['default'].computed('ShortInfo', function () {
      if (this.get('ShortInfo')) {
        return this.get('Info') + '(<i>' + this.get('ShortInfo') + '</i>)';
      } else {
        return this._super();
      }
    }),
    isMultiCoSpeaker: _ember['default'].computed('CoSpeaker', function () {
      var coSpeaker = this.get('CoSpeaker');
      if (coSpeaker.split(',').length > 1) {
        return true;
      } else {
        return false;
      }
    })
  });
});