define('deputee-office/controllers/protocol-decisions', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {

  var notFoundMsg = 'По вашему запросу ничего не найдено.';

  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {

    templateName: 'protocol-substate/protocol-decisions',

    protocolStateNameChanged: _ember['default'].observer('protocolState', 'model', function () {
      switch (this.get('protocolState')) {
        case 'solution':
          this.set('templateName', 'protocol-substate/protocol-decisions-solution');
          break;
        case 'execution':
          this.set('templateName', 'protocol-substate/protocol-decisions-execution');
          break;
        default:
          this.set('templateName', 'protocol-substate/protocol-decisions');
      }
    }),

    grouped: _ember['default'].computed('protocolDecisions.[]', function () {
      var result = _ember['default'].A([]);
      var idx = [];
      this.get('protocolDecisions').forEach(function (record) {
        var _month = record.get('Month');
        if (!result.isAny('month', _month)) {
          idx[_month] = _ember['default'].A([]);
          result.pushObject({ month: _month, items: idx[_month] });
        }

        idx[_month].pushObject(record);
      });
      return result;
    }),

    errorMsg: _ember['default'].observer('grouped', function () {
      if (this.get('grouped') && this.get('grouped').length > 0) {
        this.set('errorMessage', null);
      } else {
        this.set('errorMessage', notFoundMsg);
      }
    })
  });
});