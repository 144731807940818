define('deputee-office/services/currentuserservice', ['exports', 'ember', 'npm:js-base64'], function (exports, _ember, _npmJsBase64) {
  exports['default'] = _ember['default'].Service.extend({
    session: _ember['default'].inject.service(),
    cacheservice: _ember['default'].inject.service(),
    settingsService: _ember['default'].inject.service('settingsservice'),
    territoryService: _ember['default'].inject.service(),

    _user: null,
    init: function init() {
      this._super();
      this.set('_user', this.get('session.data.authenticated'));
    },
    user: _ember['default'].computed('_user', {
      get: function get() {
        return this.get('_user');
      },

      set: function set(key, value) {
        this.set('_user', value);
        this.set('session.data.authenticated', value);
        return value;
      }
    }),
    login: _ember['default'].computed('user', function () {
      var user = this.get('user');
      if (user) {
        return user.login;
      }

      return null;
    }),
    password: _ember['default'].computed('user', function () {
      var user = this.get('user');
      if (user) {
        return user.password;
      }

      return null;
    }),
    photoUrl: _ember['default'].computed('user', function () {
      var user = this.get('user');
      if (user) {
        return user.photoUrl;
      }

      return null;
    }),
    department: _ember['default'].computed('user', function () {
      var user = this.get('user');
      if (user) {
        return user.department;
      }

      return null;
    }),
    party: _ember['default'].computed('user', function () {
      var user = this.get('user');
      if (user) {
        return user.party;
      }

      return null;
    }),
    displayName: _ember['default'].computed('user', function () {
      var user = this.get('user');
      if (user) {
        return user.displayName;
      }

      return null;
    }),
    TerritoryId: _ember['default'].computed('user', function () {
      var user = this.get('user');
      if (user) {
        return user.TerritoryId;
      }

      return null;
    }),
    SubsystemId: _ember['default'].computed('user', function () {
      var user = this.get('user');
      if (user) {
        return user.subsystemId;
      }

      return null;
    }),
    isGuest: _ember['default'].computed('user', function () {
      return this.get('login') === 'guest';
    }),
    isMPUser: _ember['default'].computed('department', function () {
      return this.get('department') === 'Молодежный парламент';
    }),
    newUserLoggedIn: function newUserLoggedIn(store) {
      var settingsservice = this.get('settingsService');
      var currentLogin = this.get('login');
      var cacheService = this.get('cacheservice');
      var userInfo = cacheService.getCache('userInfo', false);
      var lastLogin = userInfo ? userInfo.login : null;
      if (lastLogin !== currentLogin) {
        store.unloadAll();
        cacheService.clearCache();
        settingsservice.clearSettings();
        store.adapterFor('aplication', false)['delete']();
        window.App.buildRegistry();
        window.App.reset();
        var session = _ember['default'].getOwner(this).lookup('session:main');
        var oldUserAuthData = this.get('cacheservice').get('ember_simple_auth:session');
        session.store.persist(oldUserAuthData);
        return true;
      }

      cacheService.putCache('userInfo', null, this.get('user'));
    },
    /**
     * Получение заголовка базовой авторизации.
     * @return {string} заголовок базовой авторизации.
     */
    getBasicAuthHeader: function getBasicAuthHeader() {
      var login = this.get('login');
      var password = this.get('password');

      if (login && password) {

        return 'Basic ' + _npmJsBase64['default'].Base64.encode(login + ':' + password);
      }

      return null;
    },

    /**
     * isUserFromPermKray - Метод вычисления пользователя с территорией Пермский край.
     *
     * @return {bool}
     */
    isUserFromPermKray: function isUserFromPermKray() {
      return this.get('user').territoryId === this.get('territoryService').getPkTerritory() || this.get('user').territoryId === '';
    },

    isUserFromZS: function isUserFromZS() {
      return this.get('user').subsystemId === this.get('territoryService').getZsSubsystemId();
    }
  });
});