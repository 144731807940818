define('deputee-office/routes/zs-agenda-departments', ['exports', 'ember', 'deputee-office/routes/base-agenda-route'], function (exports, _ember, _deputeeOfficeRoutesBaseAgendaRoute) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoute['default'].extend({
    targetControllerName: 'zs-agenda-departments.index',
    passportLinkName: 'zs-agenda-departments.passport',
    withDepartment: true,
    zsQuestion: true,
    selectPopoverId: 'selectPopoverId',

    getDepartmentQuery: function getDepartmentQuery() {
      return this.store.findAll('zs-department', { reload: true });
    },

    getAgendaQuery: function getAgendaQuery(agendaId) {
      return this.store.queryRecord('zsdepartmentagenda', {
        id: agendaId
      });
    },

    getAgendaByDepartmentQuery: function getAgendaByDepartmentQuery(departmentId) {
      return this.store.query('zsdepartmentagenda', {
        departmentId: departmentId
      });
    },

    getQuestionsQuery: function getQuestionsQuery(agendaId) {
      return this.store.query('zsquestion', {
        AgendaId: agendaId
      });
    },

    unloadedFileCountUrl: function unloadedFileCountUrl(agendaId) {
      return this.get('settingsservice').getHost() + '/api/zsquestions/unloadedAllFileCount/' + agendaId;
    },

    loadAgendas: function loadAgendas(departmentId) {
      var _this2 = this;

      if (this.get('notXmlLoad')) {
        return this.store.query(this.get('agendaModelName'), {
          departmentId: departmentId
        });
      } else {
        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          var agendas = _this2.store.peekAll(_this2.get('agendaModelName'));
          if (agendas.content.length) {
            resolve(agendas);
          } else {
            agendas = _this2.store.findAll(_this2.get('agendaModelName'), { reload: true }).then(function (agendas) {
              resolve(agendas);
            })['catch'](function (e) {
              reject(e);
            });
          }
        });
      }
    },

    /**
     * Получение заседания по id.
     */
    loadAgenda: function loadAgenda(id) {
      var _this = this;
      if (this.get('notXmlLoad')) {
        var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _this.store.query(_this.get('agendaModelName'), {
            departmentId: _this.get('controller.departmentId')
          }).then(function (agendas) {
            resolve(agendas.findBy('id', id));
          })['catch'](function (e) {
            reject(e);
          });
        });

        return promise;
      } else {
        return new _ember['default'].RSVP.Promise(function (resolve, reject) {
          _this.loadAgendas().then(function functionName(agendas) {
            resolve(agendas.findBy('id', id));
          })['catch'](function (e) {
            reject(e);
          });
        });
      }
    },

    //
    /**
       * Получение списка вопросов к заседанию.
       */
    loadAgendaQuestions: function loadAgendaQuestions(agendaId) {
      if (this.get('notXmlLoad')) {
        return this.store.query('zsquestion', {
          AgendaId: agendaId
        });
      } else {
        return this.store.query(this.get('questionModelName'), {
          ZsDepartmentAgendaId: agendaId
        });
      }
    },

    activate: function activate() {
      this.setFlagAndNameForZS();
    },

    deactivate: function deactivate() {
      this._super();
      this.setDefaultFlag();
    },

    /**
     * Получение участников заседания комитетов/комиссий.
     */
    getAgendaMembers: function getAgendaMembers(agendaId) {
      var membersPromise = this.store.query('zs-passport/zs-passport-agenda-member', {
        AgendaId: agendaId,
        projection: 'zs-passport-agenda-member-full'
      });
      var periodsPromise = this.store.query('zs-passport/zs-passport-agenda-period', {
        AgendaId: agendaId
      });

      return _ember['default'].RSVP.hashSettled({
        members: membersPromise,
        periods: periodsPromise
      }, 'zs agenda department members').then(function (x) {
        var result = x.members.value ? x.members.value : [];
        result.set('periods', x.periods.value);
        return result;
      });
    },

    actions: {
      refreshAgenda: function refreshAgenda() {
        this.refresh();
      }
    }
  });
});