define('deputee-office/routes/news', ['exports', 'ember', 'deputee-office/mixins/territory-flag-name', 'deputee-office/mixins/services-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeMixinsTerritoryFlagName, _deputeeOfficeMixinsServicesMixin, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], _deputeeOfficeMixinsServicesMixin['default'], {

    perPage: 3,
    personalNews: [],
    news: [],
    queryParams: {
      itemId: {
        refreshModel: false,
        replace: false
      },
      newsSubState: {
        refreshModel: false,
        replace: false
      }
    },

    model: function model() {
      var news = this.getNews(false, this.get('perPage'), 1);
      var personalNews = this.getNews(true, this.get('perPage'), 1);

      // let blogs = this.getBlogs();
      this.__getUnreadCount();

      return {
        news: news,
        pers: personalNews

      };
    },
    // blogs: blogs
    /**
     * Получение количества непрочитанных новостей.
     * @return {undefined}
     */
    __getUnreadCount: function __getUnreadCount() {
      var isConnected = this.get('networkstatus').isInternetConnection();
      var authHeader = this.get('currentuserservice').getBasicAuthHeader();
      var _this = this;
      var url = this.get('settingsservice').getHost() + '/api/newscount';
      if (isConnected && url) {
        _ember['default'].run.schedule('destroy', _this, function () {
          _ember['default'].$.ajax({
            url: url,
            headers: { Authorization: authHeader },
            type: 'GET',
            xhrPool: _this.get('routeName'),
            timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
          }).then(function (data) {
            _this.controller.set('counters', data);
          }, function functionName(e) {
            console.error(e);
          });
        });
      } else {
        if (_this.controller) {
          _this.controller.set('counters', null);
        }
      }
    },
    /**
    * Отмена выполняемых в рамках роута ajax-запросов.
    *
    * @return {undefined}
    */
    abortRequests: function abortRequests() {
      if (_ember['default'].$.xhrPool != null) {
        var routeName = this.get('routeName');
        _ember['default'].$.xhrPool.abort(routeName);
      }
    },
    getNews: function getNews(pers, perPage, page) {
      var modelArray = _ember['default'].A([]);
      var _this = this;
      function getFilledModel(records) {
        modelArray.pushObjects(records.toArray());
        return modelArray;
      }

      function getRecords(useOnlineStore) {
        return _this.store.query('newsitem', { useOnlineStore: useOnlineStore, per_page: perPage, page: page, personal: pers, category: 'ZuicNews'
        }).then(function (records) {
          _this.set('modelFromOnline', useOnlineStore);
          if (!useOnlineStore && records.get('length') === 0) {
            return getRecords(true);
          }

          if (pers) {
            _this.get('personalNews').addObjects(records);
          } else {
            _this.get('news').addObjects(records);
          }

          return getFilledModel(records);
        })['catch'](function functionName() {
          if (!useOnlineStore) {
            return getRecords(true);
          }
        });
      }

      var items = getRecords(true);
      return items;
    },

    getBlogs: function getBlogs() {
      var modelArray = _ember['default'].A([]);
      var _this = this;
      function getFilledModel(records) {
        modelArray.pushObjects(records.toArray());
        return modelArray;
      }

      function getRecords(useOnlineStore) {
        return _this.store.findAll('bloguser', { useOnlineStore: useOnlineStore }).then(function (records) {
          _this.set('modelFromOnline', useOnlineStore);
          if (!useOnlineStore && records.get('length') === 0) {
            return getRecords(true);
          }

          return getFilledModel(records);
        })['catch'](function functionName() {
          if (!useOnlineStore) {
            return getRecords(true);
          }
        });
      }

      var items = getRecords(true);
      return items;
    },
    showUrl: function showUrl(url) {
      if (!window.device) {
        return;
      }

      var platform = window.device.platform.toLowerCase();
      var mode = '_system';
      if (platform === 'ios' || platform === 'android') {
        mode = '_blank';
      }

      var _this = this;
      var inAppBrowserRef = window.cordova.InAppBrowser.open(url, mode, 'location=yes,,hidden=yes,closebuttoncaption=Закрыть,enableviewportscale=yes');
      inAppBrowserRef.addEventListener('exit', function () {
        _this.__getUnreadCount();
      });
      inAppBrowserRef.show();
    },
    getNextNews: function getNextNews(news, newsPers) {
      var _this2 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        try {
          var newsArray;

          if (newsPers) {
            newsArray = _this2.get('personalNews');
          } else {
            newsArray = _this2.get('news');
          }

          var index = newsArray.indexOf(news);
          if (newsArray[index + 1]) {
            resolve(newsArray[index + 1]);
          } else {
            _this2.getNews(newsPers, _this2.get('perPage'), newsArray.length / _this2.get('perPage') + 1).then(function (x) {
              if (x) {
                resolve(x.get('firstObject'));
              } else {
                resolve();
              }
            });
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    getPrevNews: function functionName(news, newsPers) {
      var _this3 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        try {
          var newsArray;

          if (newsPers) {
            newsArray = _this3.get('personalNews');
          } else {
            newsArray = _this3.get('news');
          }

          var index = newsArray.indexOf(news);
          if (index - 1 >= 0) {
            resolve(newsArray[index - 1]);
          } else {
            resolve();
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    actions: {
      nextNews: function functionName() {
        var _this4 = this;

        this.get('CurrentNews.nextNews').then(function (x) {
          _this4.set('CurrentNews.news', x);
          _this4.set('CurrentNews.prevNews', _this4.getPrevNews(x, _this4.get('CurrentNews.newsPers')));
          _this4.set('CurrentNews.nextNews', _this4.getNextNews(x, _this4.get('CurrentNews.newsPers')));
          return x;
        });
      },
      prevNews: function functionName() {
        var _this5 = this;

        this.get('CurrentNews.prevNews').then(function (x) {
          _this5.set('CurrentNews.news', x);
          _this5.set('CurrentNews.prevNews', _this5.getPrevNews(x, _this5.get('CurrentNews.newsPers')));
          _this5.set('CurrentNews.nextNews', _this5.getNextNews(x, _this5.get('CurrentNews.newsPers')));
          return x;
        });
      },
      doOpenNews: function doOpenNews(item, newsPers) {
        var device = this.get('devicejs');
        if (device.isPhone()) {
          this.get('controller').set('currentNews', item);
          this.transitionTo(this.routeName, {
            queryParams: {
              newsSubState: 'showNews',
              itemId: item.id
            }
          });
        } else {
          var url = item.get('Url');
          if (item.get('Type') === 'HTML' || !url) {

            var model = _ember['default'].Object.extend({}).create({
              prevNews: this.getPrevNews(item, newsPers),
              nextNews: this.getNextNews(item, newsPers),
              news: item,
              newsPers: newsPers
            });
            this.render('modals/news', {
              into: 'news',
              outlet: 'modal',
              model: model
            });
            this.set('CurrentNews', model);
          } else {
            this.showUrl(url);
          }
        }
      },
      doOpenUrl: function doOpenUrl(url) {
        this.showUrl(url);
      },

      showUrl: function showUrl(url) {
        this.showUrl(url);
      },

      removeModal: function removeModal() {
        this.__getUnreadCount();
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'news'
        });
      },
      didTransition: function didTransition() {
        this.controller.send('createPopover');
        return true;
      }
    },
    activate: function activate() {
      this.setFlagAndNameForZS();
    },
    deactivate: function deactivate() {
      this.get('personalNews').clear();
      this.get('news').clear();
      this.setDefaultFlag();
      this.abortRequests();
    }
  });
});