define('deputee-office/components/timer-modal-dialog', ['exports', 'ember', 'deputee-office/components/base-modal-dialog', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeComponentsBaseModalDialog, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _deputeeOfficeComponentsBaseModalDialog['default'].extend({
    store: _ember['default'].inject.service(),
    hide: false,
    isOverObserver: _ember['default'].observer('isOver', function () {
      if (this.get('agenda') && this.get('isOver') && (this.get('agenda.Status') === 'Идет заседание' || this.get('agenda.Status') === 'Формирование повестки')) {
        this.reloadAgenda();
      } else {
        if (this.get('runLater')) {
          _ember['default'].run.cancel(this.get('runLater'));
        }
      }
    }),

    agendaIdObserver: _ember['default'].observer('agenda.id', function () {
      if (this.get('agenda.id')) {
        this.loadAgenda();
      }
    }),

    didInsertElement: function didInsertElement() {
      var _loadAgenda = this.loadAgenda.bind(this);
      this.set('_loadAgenda', _loadAgenda);
      document.addEventListener('resume', _loadAgenda, false);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      document.removeEventListener('resume', this.get('_loadAgenda'), false);
    },

    reloadAgenda: function reloadAgenda() {
      var runLater = _ember['default'].run.later(this, function () {
        var _this = this;

        this.loadAgenda().then(function () {
          _this.reloadAgenda();
        })['catch'](function () {
          return _this.reloadAgenda();
        });
      }, _deputeeOfficeConfigEnvironment['default'].APP.agendaStageReloadInterval);
      if (this.get('runLater')) {
        _ember['default'].run.cancel(this.get('runLater'));
      }

      if (!this.get('isDestroyed')) {
        this.set('runLater', runLater);
      } else {
        _ember['default'].run.cancel(runLater);
      }
    },

    loadAgenda: function loadAgenda() {
      return this.get('store').queryRecord('yp-agenda', {
        id: this.get('agenda.id')
      });
    },

    show: (function () {
      this.$('.modal').modal({
        backdrop: false,
        keyboard: false
      }).on('hidden.bs.modal', (function () {
        this.sendAction('close');
      }).bind(this));
      this.sendAction('initModel');
    }).on('didInsertElement'),
    removeSLayer: (function () {
      // this.$('.modal').modal('hide');
    }).on('willDestroyElement'),

    actions: {
      hideTimer: function functionName() {
        this.set('hide', !this.get('hide'));
      }
    }
  });
});