define('deputee-office/components/favorites/favorites-omsu-agenda-pomopk', ['exports', 'ember', 'deputee-office/components/favorites/favorites-files-zs-mp-base'], function (exports, _ember, _deputeeOfficeComponentsFavoritesFavoritesFilesZsMpBase) {
  exports['default'] = _deputeeOfficeComponentsFavoritesFavoritesFilesZsMpBase['default'].extend({
    templateName: 'components/favorites/favorites-files-zs-mp-base',
    serviceName: 'omsu-agenda-pomopk.passport',
    agendaIdParamName: 'AgendaId',

    loadData: function loadData() {
      var _this = this;

      var model = this.get('model');

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.loadByDepartmentType('pomopk').then(function (result) {
          model.pushObjects(result);

          _this.filterModel();
          resolve();
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'favorites-omsu-agenda-pomopk: loadData');
    }
  });
});