define('deputee-office/controllers/modals/news', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    CurrentNewsChanged: _ember['default'].observer('model.news', function () {
      var _this2 = this;

      if (this.get('model.news.id')) {
        try {
          (function () {
            var isConnected = _this2.get('networkstatus').isInternetConnection();
            var settingsService = _this2.get('settingsservice');
            var authHeader = _this2.get('currentuserservice').getBasicAuthHeader();
            var _this = _this2;
            var url = settingsService.getHost() + '/api/newsread?id=' + _this2.get('model.news.id');

            if (isConnected && url) {
              _ember['default'].run.schedule('destroy', _this, function () {
                _ember['default'].$.ajax({
                  url: url,
                  headers: {
                    Authorization: authHeader
                  },
                  type: 'POST',
                  timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
                });
              });
            }
          })();
        } catch (e) {
          console.error(e);
        }
      }
    }),
    actions: {
      next: function functionName() {
        this.send('nextNews');
      },
      prev: function functionName() {
        this.send('prevNews');
      },
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
      },
      showUrl: function showUrl() {
        return true;
      }
    }
  });
});