define('deputee-office/routes/newquestions', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/territory-flag-name'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsTerritoryFlagName) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], {
    controllerName: 'newquestions.index',

    model: function model() {
      var r = this.store.query('newquestion', {
        TerritoryId: this.get('territoryService').getTerritoryId()
      });

      return r;
    },

    activate: function activate() {
      this.setFlagAndNameBySetting();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
    },

    renderTemplate: function renderTemplate() {
      this.render();
      if (!this.get('devicejs').isPhone()) {
        this.render('search-box', {
          outlet: 'toolbar-right-outlet',
          controller: this.controllerFor('newquestions')
        });
      }
    }
  });
});