define('deputee-office/components/favorites/favorites-legal-acts', ['exports', 'ember', 'deputee-office/mixins/favorites-component-base'], function (exports, _ember, _deputeeOfficeMixinsFavoritesComponentBase) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsFavoritesComponentBase['default'], {
    store: _ember['default'].inject.service(),
    favoritesService: _ember['default'].inject.service(),

    loadableModels: ['favorite', 'omsu-passport/omsu-passport-document', 'legal-act'],

    serviceName: 'legal-acts.passport',
    modelName: 'legal-act',
    entityIdName: 'legalActId',

    init: function init() {
      this._super();
      this.set('model', _ember['default'].A([]));
      this.set('displayedModel', _ember['default'].A([]));
    },

    loadData: function loadData() {
      var _this2 = this;

      var store = this.get('store');

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {

        store.query('favorite', {
          Model: 'zs-passport/zs-passport-file'
        }).then(function (favorites) {
          var marked3$0 = [favoritesByTerritoryGenerator].map(regeneratorRuntime.mark);

          var territoryFavorites = {};

          favorites.toArray().forEach(function (favorite) {
            var params = JSON.parse(favorite.get('Params'));

            if (params.additionalParams && params.additionalParams.serviceName === _this2.get('serviceName')) {
              var territoryId = params.additionalParams.territoryId;

              if (territoryId) {
                if (!territoryFavorites[territoryId]) {
                  territoryFavorites[territoryId] = new _ember['default'].A([]);
                }

                territoryFavorites[territoryId].pushObject(favorite);
              }
            }
          });

          var resultsPromises = new _ember['default'].A([]);
          var _this = _this2;

          // Генератор для синхронного выполнения загрузки документов ОМСУ
          // в зависимости от территории.
          function favoritesByTerritoryGenerator() {
            var territoryId;
            return regeneratorRuntime.wrap(function favoritesByTerritoryGenerator$(context$4$0) {
              while (1) switch (context$4$0.prev = context$4$0.next) {
                case 0:
                  context$4$0.t0 = regeneratorRuntime.keys(territoryFavorites);

                case 1:
                  if ((context$4$0.t1 = context$4$0.t0()).done) {
                    context$4$0.next = 7;
                    break;
                  }

                  territoryId = context$4$0.t1.value;
                  context$4$0.next = 5;
                  return _this.loadByTerritory(territoryId, territoryFavorites[territoryId]);

                case 5:
                  context$4$0.next = 1;
                  break;

                case 7:
                case 'end':
                  return context$4$0.stop();
              }
            }, marked3$0[0], this);
          }

          var execute = function execute(generator) {
            var next = generator.next();

            if (!next.done) {
              resultsPromises.pushObject(next.value);
              next.value['finally'](function () {
                return execute(generator);
              });
            } else {
              _ember['default'].RSVP.allSettled(resultsPromises, 'favorite-legal-acts: all for loadByTerritories').then(function (x) {
                _this2.get('model').pushObjects(_this2.settledHelper(x));
                resolve();
              })['catch'](function (e) {
                reject();
                console.error(e);
              });
            }
          };

          execute(favoritesByTerritoryGenerator());
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'favorite-legal-acts: loadData');
    },

    loadByTerritory: function loadByTerritory(territoryId, favorites) {
      var _this3 = this;

      var store = this.get('store');
      var favoritesService = this.get('favoritesService');
      var entityIdName = this.get('entityIdName');

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _ember['default'].RSVP.Promise.resolve(favorites).then(function (favorites) {

          // Массив идентификаторов документов.
          var docIds = favoritesService.getUnique(favorites, 'docId');

          // Массив идентификаторов законов.
          var legalActIds = favoritesService.getUnique(favorites, 'additionalParams.' + entityIdName);

          // Обертки для запросов получения документов.
          var wrappersForDocuments = favorites.map(function (favorite) {
            return JSON.parse(favorite.get('Params')).additionalParams;
          });

          var promises = [];

          wrappersForDocuments.forEach(function (item) {
            var promise = store.query('omsu-passport/omsu-passport-document', {
              PassportId: item.PassportId,
              TabName: item.tab,
              TerritoryId: territoryId
            }).then(function (result) {

              var docsWrapper = {
                legalActId: item[entityIdName],
                additionalParams: item,
                documents: result.toArray()
              };

              return docsWrapper;
            });
            promises.push(promise);
          });

          var documentsPromise = _ember['default'].RSVP.allSettled(promises, 'favorite-legal-acts: documentsPromise').then(function (result) {
            result = _this3.settledHelper(result);

            var docItemWrappers = _ember['default'].A([]);

            for (var i = 0; i < result.length; i++) {
              for (var j = 0; j < result[i].documents.length; j++) {
                if (docIds.indexOf(result[i].documents[j].get('id')) !== -1) {
                  var docItemWrapper = {
                    legalActId: result[i].legalActId,
                    additionalParams: result[i].additionalParams,
                    documentItem: result[i].documents[j]
                  };

                  docItemWrappers.pushObject(docItemWrapper);
                }
              }
            }

            return docItemWrappers;
          });

          var legalActPromises = legalActIds.map(function (record) {
            return store.findRecord(_this3.get('modelName'), record);
          });

          var legalActPromisesAll = _ember['default'].RSVP.allSettled(legalActPromises, 'favorite-legal-acts: legalActPromisesAll').then(function (result) {
            return result.filter(function (x) {
              return x.state === 'fulfilled';
            }).map(function (legalAct) {
              return legalAct.value;
            }).filter(function (legalAct) {
              return legalActIds.indexOf(legalAct.get('id')) !== -1;
            });
          })['catch'](function (e) {
            reject();
            console.error(e);
          });

          var promisesObject = {
            favorites: _ember['default'].RSVP.Promise.resolve(favorites),
            questions: legalActPromisesAll,
            documents: documentsPromise
          };

          return _ember['default'].RSVP.hashSettled(promisesObject, 'favorite-legal-acts: hash for all').then(function (_result) {
            var result = new Object({
              favorites: _result.favorites.state === 'fulfilled' ? _result.favorites.value : [],
              questions: _result.questions.state === 'fulfilled' ? _result.questions.value : [],
              documents: _result.documents.state === 'fulfilled' ? _result.documents.value : []
            });

            // Массив идентификаторов файлов.
            var fileIds = _this3.get('favoritesService').getUnique(result.favorites, 'id');

            // Построение групп для вывода в шаблоне.
            var legalActGroups = result.questions.map(function (legalActItem) {

              var documents = result.documents.filter(function (documentWrapperItem) {
                return documentWrapperItem.legalActId === legalActItem.get('id');
              }).map(function (documentWrapperItem) {

                var files = documentWrapperItem.documentItem.get('Files').toArray();

                files = files.filter(function (file) {
                  return fileIds.indexOf(file.get('id')) !== -1;
                });

                var docToDisplay = {
                  id: documentWrapperItem.documentItem.get('id'),
                  title: documentWrapperItem.documentItem.get('Title'),
                  additionalParams: documentWrapperItem.additionalParams,
                  files: files,
                  displayFiles: _ember['default'].A([])
                };

                return docToDisplay;
              });

              var legalActGroup = {
                legalAct: legalActItem,
                documents: documents,
                documentsGroups: {}
              };

              return legalActGroup;
            });

            // Резолвится главный промис.
            resolve(legalActGroups);
          })['catch'](function (e) {
            reject();
            console.error(e);
          });
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'favorite-legal-acts: loadByTerritory');
    },

    filterModel: function filterModel() {
      var model = this.get('model');
      var displayedModel = this.get('displayedModel');
      var filterText = this.get('searchString');

      displayedModel.clear();

      if (filterText && filterText.value) {
        model.forEach(function (legalActGroup) {
          var groupAdded = false;

          _ember['default'].set(legalActGroup, 'documentsGroups', {});

          legalActGroup.documents.forEach(function (doc) {
            var docAdded = false;

            doc.displayFiles.clear();

            doc.files.forEach(function (file) {
              if (file.get('Name').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1) {
                if (!groupAdded) {
                  displayedModel.pushObject(legalActGroup);
                  groupAdded = true;
                }

                if (!docAdded) {
                  if (!legalActGroup.documentsGroups[doc.title]) {
                    legalActGroup.documentsGroups[doc.title] = new _ember['default'].A([]);
                  }

                  legalActGroup.documentsGroups[doc.title].pushObject(doc);
                  docAdded = true;
                }

                doc.displayFiles.pushObject(file);
              }
            });
          });
        });
      } else {
        model.forEach(function (legalActGroup) {
          _ember['default'].set(legalActGroup, 'documentsGroups', {});

          legalActGroup.documents.forEach(function (doc) {
            doc.displayFiles.clear();

            doc.displayFiles.pushObjects(doc.files);

            if (!legalActGroup.documentsGroups[doc.title]) {
              legalActGroup.documentsGroups[doc.title] = new _ember['default'].A([]);
            }

            legalActGroup.documentsGroups[doc.title].pushObject(doc);
          });

          displayedModel.pushObject(legalActGroup);
        });
      }

      displayedModel.sortBy('legalAct.Name');
    }

  });
});