define('deputee-office/initializers/global-app', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    window.App = application;
  }

  exports['default'] = {
    name: 'global',
    initialize: initialize
  };
});