define('deputee-office/controllers/base-agenda-route/passport/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    defaultPassportStateName: 'base-agenda-route/passport-state/index',
    defaultPassportStateNameOmsu: 'base-agenda-route/passport-state/index-omsu',

    queryParams: ['passportState', 'passportStateParam'],
    passportState: null,
    passportStateParam: null,

    zsQuestion: _ember['default'].computed('parentController.zsQuestion', function () {
      return this.get('parentController.zsQuestion');
    }),

    voteObject: _ember['default'].computed('votingResults', function () {
      if (this.get('votingResults.length')) {
        return this.get('votingResults.firstObject');
      }

      return _ember['default'].Object.create({});
    }),

    CurrentVote: _ember['default'].computed('passport.detail.votingResult', 'passport.detail.votingResultGroup', 'passportStateParam', 'votingResults', 'voteObject', function () {
      var _this = this;

      if (this.get('passportState') === 'vote') {
        if (this.get('votingResults')) {
          var findedVote = this.get('votingResults').findBy('id', this.get('passportStateParam'));
          return findedVote ? findedVote : this.get('voteObject');
        } else {
          return this.get('voteObject');
        }
      } else {
        if (this.get('passport.detail.votingResult')) {
          return this.get('passport.detail.votingResult').find(function (x) {
            return x.get('id') === decodeURI(_this.get('passportStateParam'));
          });
        }

        if (this.get('passport.detail.votingResultGroup')) {
          var result = undefined;
          this.get('passport.detail.votingResultGroup').forEach(function (group) {
            var findedVoteResilt = group.items.find(function (x) {
              return x.get('id') === decodeURI(_this.get('passportStateParam'));
            });
            if (findedVoteResilt) {
              result = findedVoteResilt;
            }
          });
          return result;
        }
      }
    }),

    Title: _ember['default'].computed('passportStateParam', function () {
      return decodeURI(this.get('passportStateParam'));
    }),

    passportStateChanged: _ember['default'].observer('passportState', 'passport', 'parentController.zsQuestion', function () {
      if (this.get('passport')) {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          switch (this.get('passportState')) {
            case 'about':
              this.set('passportStateName', 'base-agenda-route/passport-state/about');
              break;
            case 'comments':
              this.set('passportStateName', 'base-agenda-route/passport-state/comments');
              break;
            case 'vote':
              this.set('passportStateName', 'base-agenda-route/passport-state/vote');
              break;
            case 'detail':
              this.set('passportStateName', 'base-agenda-route/passport-state/detail');
              this.send('renderRigthMenu', {});
              break;
            case 'detail-vote':
              this.set('passportStateName', 'base-agenda-route/passport-state/detail/vote');
              if (!this.get('passport.detail')) {
                this.send('renderRigthMenu', {});
              }

              break;
            case 'questionHistory':
              this.set('passportStateName', 'base-agenda-route/passport-state/detail/question-history');
              if (!this.get('passport.detail')) {
                this.send('renderRigthMenu', {});
              }

              break;
            case 'documentDecisions':
              this.set('passportStateName', 'base-agenda-route/passport-state/detail/document-decisions');
              if (!this.get('passport.detail')) {
                this.send('renderRigthMenu', {});
              }

              break;
            case 'documentHistory':
              this.set('passportStateName', 'base-agenda-route/passport-state/detail/document-history');
              if (!this.get('passport.detail')) {
                this.send('renderRigthMenu', {});
              }

              break;
            case 'legalActPublication':
              this.set('passportStateName', 'base-agenda-route/passport-state/detail/legal-act-publication');
              if (!this.get('passport.detail')) {
                this.send('renderRigthMenu', {});
              }

              break;
            default:
              this.set('passportStateName', this.get('defaultPassportStateName'));
          }
        });
      }
    })
  });
});