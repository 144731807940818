define('deputee-office/routes/omsu-agenda-assembly', ['exports', 'deputee-office/routes/base-agenda-route'], function (exports, _deputeeOfficeRoutesBaseAgendaRoute) {

  var agencies = ['Земское Собрание', 'Дума городского округа', 'Дума городского поселения', 'Городская Дума'];

  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoute['default'].extend({
    targetControllerName: 'omsu-agenda-assembly.index',
    passportLinkName: 'omsu-agenda-assembly.passport',
    searchText: null,

    getAgendaMembers: function getAgendaMembers(agendaId) {
      return this.store.query('omsu-passport/omsu-agenda-member', {
        AgendaId: agendaId,
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
    },

    getDepartmentQuery: function getDepartmentQuery() {
      var model = this.store.query('omsudepartment', {
        TerritoryId: this.get('territoryService').getTerritoryId()
      }).then(function functionName(departments) {
        var resolveData = departments.filter(function (department) {
          return agencies.indexOf(department.get('Name')) !== -1;
        });
        return resolveData;
      });
      return model;
    },

    getAgendaQuery: function getAgendaQuery(agendaId) {
      var agendaRec = this.store.queryRecord('omsuagenda', {
        AgendaId: agendaId,
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
      return agendaRec;
    },

    getAgendaByDepartmentQuery: function getAgendaByDepartmentQuery(departmentId) {
      var agendaRec = this.store.query('omsuagenda', {
        DepartmentId: departmentId,
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
      return agendaRec;
    },

    getQuestionsQuery: function getQuestionsQuery(agendaId, territoryId) {
      var model = this.store.query('omsuquestion', {
        AgendaId: agendaId,
        TerritoryId: territoryId });
      return model;
    },

    unloadedFileCountUrl: function unloadedFileCountUrl(agendaId) {
      return this.get('settingsservice').getHost() + '/api/omsuquestions/unloadedAllFileCount/' + agendaId + '?TerritoryId=' + this.get('territoryService').getTerritoryId();
    },

    activate: function activate() {
      this.setFlagAndNameBySetting();
    },

    deactivate: function deactivate() {
      this._super();
      this.setDefaultFlag();
    },

    actions: {}

  });
});