define('deputee-office/models/material-ksp', ['exports', 'ember-data', 'deputee-office/models/base'], function (exports, _emberData, _deputeeOfficeModelsBase) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    DocId: _emberData['default'].attr('string'),
    Title: _emberData['default'].attr('string'),
    Date: _emberData['default'].attr('string'),
    MaterialType: _emberData['default'].attr('string'),
    OrderNum: _emberData['default'].attr('number')
  });

  exports['default'] = Model;
});