define('deputee-office/helpers/count-text', ['exports', 'ember'], function (exports, _ember) {

  var getCountText = function getCountText(count, zeroString, oneString, twoString) {
    count = Math.abs(count);
    var lastOne = Math.floor(count % 10);
    var lastTwo = Math.floor(count % 100);
    var result;

    if (lastTwo >= 11 && lastTwo <= 19) {
      result = zeroString;
    } else if (lastOne === 1) {
      result = oneString;
    } else if (lastOne >= 2 && lastOne <= 4) {
      result = twoString;
    } else {
      result = zeroString;
    }

    return result;
  };

  var countText = function countText(params /*, hash*/) {
    return getCountText(params[0], params[1], params[2], params[3]);
  };

  exports['default'] = _ember['default'].Helper.helper(countText);
});