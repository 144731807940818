define('deputee-office/routes/application-error', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller, error) {
      // Подготовка бокового меню после инициализации DOM.
      _ember['default'].run.schedule('afterRender', this, function () {
        var sidebarLeft = _ember['default'].$('[data-sidebar]#leftMenu');

        if (sidebarLeft.width()) {
          sidebarLeft.css('margin-left', sidebarLeft.width() * -1 + 'px');
        } else {
          sidebarLeft.css('margin-left', '-100%');
        }

        sidebarLeft.show(0, function () {
          sidebarLeft.css('transition', 'all 300ms linear');
          sidebarLeft.css('transform', 'translate3d(0, 0, 0)');
        });

        var sidebarRight = _ember['default'].$('[data-sidebar]#rightMenu');

        if (sidebarRight.width()) {
          sidebarRight.css('right', sidebarRight.width() * -1 + 'px');
        } else {
          sidebarRight.css('right', '-100%');
        }

        sidebarRight.show(0, function () {
          sidebarRight.css('transition', 'all 300ms linear');
          sidebarRight.css('transform', 'translate3d(0, 0, 0)');
        });
      });

      _ember['default'].Logger.debug(error.message);
      this._super.apply(this, arguments);
    },
    renderTemplate: function renderTemplate() {
      this.render('application');
      this.render('application-error', {
        into: 'application',
        controller: 'application'
      });
    },
    actions: {
      back: function back() {
        window.history.back();
      }
    }
  });
});