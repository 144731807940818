define('deputee-office/components/favorites/favorites-k-s-materials', ['exports', 'ember', 'deputee-office/mixins/favorites-component-base'], function (exports, _ember, _deputeeOfficeMixinsFavoritesComponentBase) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsFavoritesComponentBase['default'], {
    store: _ember['default'].inject.service(),

    loadableModels: ['favorite', 'material-ksm'],

    init: function init() {
      this._super();
      this.set('model', _ember['default'].A([]));
      this.set('displayedModel', _ember['default'].A([]));
    },

    // Группируем по повеске
    grouped: _ember['default'].computed('displayedModel.[]', function () {
      var materials = this.get('displayedModel');
      if (materials) {
        var _ret = (function () {
          var result = _ember['default'].A([]);
          var idx = [];
          materials.toArray().sortByProperty('OrderNum', 'asc').forEach(function (record) {
            var _agenda = record.get('Agenda');
            if (!result.isAny('agenda', _agenda)) {
              idx[_agenda] = _ember['default'].A([]);
              result.pushObject({ agenda: _agenda, items: idx[_agenda] });
            }

            idx[_agenda].pushObject(record);
          });
          return {
            v: result
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
    }),

    loadData: function loadData() {
      var store = this.get('store');
      var model = this.get('model');

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        store.query('favorite', { Model: 'material-ksm' }).then(function (favorites) {

          var ids = favorites.map(function (favorite) {
            return JSON.parse(favorite.get('Params')).id;
          });

          store.findAll('material-ksm').then(function (materials) {
            materials.forEach(function (material) {
              var id = material.get('id');

              if (ids.indexOf(id) !== -1) {
                model.pushObject(material);
              }
            });

            resolve();
          })['catch'](function (e) {
            reject();
            console.error(e);
          });
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'favorites-k-s-materials: loadData');
    },

    filterModel: function filterModel() {
      var model = this.get('model');
      var displayedModel = this.get('displayedModel');
      var filterText = this.get('searchString');

      displayedModel.clear();

      if (filterText && filterText.value) {
        model.forEach(function (material) {
          if (material.get('Title').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1 || material.get('Date').indexOf(filterText.value) > -1) {
            displayedModel.pushObject(material);
          }
        });
      } else {
        displayedModel.pushObjects(model);
      }
    }

  });
});