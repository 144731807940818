define('deputee-office/controllers/modals/eventsplan-modal', ['exports', 'ember', 'deputee-office/mixins/loader/events-plan'], function (exports, _ember, _deputeeOfficeMixinsLoaderEventsPlan) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsLoaderEventsPlan['default'], {
    actions: {
      initModel: _ember['default'].on('init', function () {
        var _this2 = this;

        try {
          (function () {
            _this2._super();
            var _this = _this2;
            var transition = _this2.get('model').transition;
            _this2.loadEventsPlans().then(function () {
              _this.set('redirect', false);
              _this.set('transition', transition);
            });
          })();
        } catch (e) {
          console.error(e);
          this.get('target').send('removeModal');
        }
      }),

      closeModal: function closeModal() {
        this.set('eventsplans', null);
        if (!this.get('redirect')) {
          this.get('target').send('removeModal');
        }
      },

      selectEventsPlan: function selectEventsPlan(eventsPlan) {
        this.set('redirect', true);
        this.get('target').send('selectEventsPlan', eventsPlan);
      }
    }
  });
});