define('deputee-office/services/networkstatus', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    settingsservice: _ember['default'].inject.service('settingsservice'),

    isConnectionAccess: function isConnectionAccess() {
      var settingsservice = this.get('settingsservice');
      var WiFiOnly = settingsservice.getNetworkSettings().get('WiFiOnly');

      if (WiFiOnly) {

        return this.isFastConnection();
      } else {

        return this.isInternetConnection();
      }
    },

    isInternetConnection: function isInternetConnection() {

      try {
        var networkState = navigator.connection.type;

        if (networkState === window.Connection.NONE) {

          return false;
        }
      } catch (e) {
        console.log(e);
      }

      return true;
    },

    isFastConnection: function isFastConnection() {
      try {

        var networkState = navigator.connection.type;

        if (networkState === window.Connection.WIFI || networkState === window.Connection.ETHERNET || networkState === window.Connection.UNKNOWN) {

          return true;
        }
      } catch (e) {
        console.log(e);
      }

      return false;
    }
  });
});