define('deputee-office/transforms/json-data', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Transform.extend({
    serialize: function serialize(value) {
      return JSON.stringify(value);
    },
    deserialize: function deserialize(value) {
      var dv = JSON.parse(value);
      if (Array.isArray(dv)) {
        return dv ? dv.map(function (x) {
          return _ember['default'].Object.extend({}).create(x);
        }) : dv;
      } else {
        return dv ? _ember['default'].Object.extend({}).create(dv) : dv;
      }
    }
  });
});