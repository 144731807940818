define('deputee-office/mixins/offline-schema', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    init: function init() {
      this.set('offlineSchema', {
        'ember-flexberry-data': {
          1: {
            blogitem: 'id,Type,Url,Order,EditTime',
            bloguser: 'id,Author,PhotoBase64,Subtitle,Order,*Items,EditTime',
            'calendar-event': 'id,Description,Location,Summary,DtStart,DtEnd,Subsystem,EditTime',
            newsitem: 'id,Title,Content,TaglessContent,Url,Type,sourceType,Source,Date,category,Author,personal,EditTime',
            budget: 'id,DocId,Title,Date,OrderNum,EditTime',
            contact: 'id,Name,PhoneNumber,Email,Post,Photo,department,OrderNum,departmentid,departmentName,departmentFullPath,EditTime',
            phonecontact: 'id,Name,PhoneNumber,Email,Post,Photo,department,OrderNum,departmentid,departmentName,EditTime',
            decree: 'id,Title,RegNumber,RegDate,FileDownloadUrl,EditTime',
            department: 'id,Name,Level,Parent,*Departments,TerritoryId,IsZspk,EditTime',
            phonedepartment: 'id,Name,Level,Parent,*Departments,IsHidden,IsZspk,OrderNum,EditTime',
            'document-version': 'id,FileInfoId,EditTime',
            'executor-reply': 'id,ExecutorFileName,ExecutorFileUrl,POMOPKDecisionFileName,POMOPKDecisionFileUrl,Note,EditTime',
            'external-resource': 'id,Name,Url,ImageUrl,EditTime',
            favorite: 'id,Model,Params,CreateTime,EditTime',
            file: 'id,FileInfoId,FileVersion,UserFileVersion,SubsystemFileId,DocType,SubsystemKey,MediaType,Extension,EditTime',
            'gos-program': 'id,DocId,Title,OrderNum,EditTime',
            'governor-decree': 'id,Title,RegNumber,RegDate,FileDownloadUrl,EditTime',
            'law-view': 'id,View,EditTime',
            law: 'id,IntId,RegDate,RegNumber,Type,Subject,HeadCommittee,Name,IsProject,Reading,BillCode,Header,EditTime',
            'legal-act-file': 'id,Name,Url,EditTime',
            'legal-act-view': 'id,View,TerritoryId,EditTime',
            'legal-act': 'id,IntId,RegDate,FullRegNumber,RegNumber,Type,Subject,HeadCommittee,Name,Category,ActType,Agenda,' + 'State,TerritoryId,AgendaSessionPointId,EditTime',
            'material-ksm': 'id,DocId,Title,Date,Agenda,OrderNum,EditTime',
            'material-ksp': 'id,DocId,Title,Date,MaterialType,OrderNum,EditTime',
            'newquestion-view': 'id,View,TerritoryId,EditTime',
            newquestion: 'id,IntId,RegDate,RegNum,Name,Subject,HeadComm,TerritoryId,EditTime',
            'omsu-activity-type': 'id,Name,EditTime',
            'omsu-event': 'id,Title,Description,EventType,AllDay,Color,EventUrl,Start,End,IsMy,EventObjectUID,Location,Date,TerritoryId,EditTime',
            'omsu-passport': 'id,Info,TerritoryId,Num,Speaker,CoSpeaker,PreSpeak,Next,Prev, AgendaName, AgendaId,DecisionProjectName,EditTime',
            omsuagenda: 'id,IntId,Name,Status,Members,Date,DepartmentId,TerritoryId,ProtocolFileUrl,Location,EditTime',
            omsudepartment: 'id,Name,FullName,Type,Order,TerritoryId,EditTime',
            omsuquestion: 'id,IntId,Title,Num,Type,Reading,Decision,DraftDecision,DraftDecisionSecondReading,Speaker,' + 'CoSpeaker,HeadCommittee,UnreadFileCount,AgendaId,QuestionId,TerritoryId,OrderNum,RegNumber,EditTime',
            'pomopk-agenda': 'id,IntId,Name,Status,Date,EditTime',
            'pomopk-decision': 'id,Date,Name,Theme,RegNumber,DecisionFileUrl,*LegalActFiles,TerritoryId,IsDone,Normative,Extended,EditTime',
            'pomopk-passport': 'id,Info,TerritoryId,Num,Speaker,CoSpeaker,PreSpeak,Next,Prev,DecisionProjectName,EditTime',
            'pomopk-question': 'id,IntId,Title,Num,Type,Reading,Decision,Speaker,CoSpeaker,HeadCommittee,' + 'UnreadFileCount,AgendaId,QuestionId,OrderNum,RegNumber,EditTime',
            'protocol-decision': 'id,Topic,IsDone,Date,Author,PeriodOfExecution,Executor,File,Decision,TerritoryId,*ExecutorReplies,EditTime',
            'question-comment': 'id,QuestionId,AuthorId,AuthorFIO,TimeStamp,Place,OriginalText,NewText,Ticks,Editable,Extended,EditTime',
            report: 'id,DocId,Title,OrderNum,EditTime',
            'service-setting': 'id,OrderNum,Title,IconName,SubsystemName,IsVisible,RelativeRootUrl,EditTime',
            territory: 'id,Name,Level,OrderNum,EditTime',
            'uppc-report': 'id,DocId,DocUrl,Title,*children,*parent,EditTime',
            'work-plan': 'id,Division,Title,TerritoryId,EditTime',
            'work-plan-string': 'id,Month,Title,QuestionId,TerritoryId,Date,OrderNum,EditTime',
            'yp-agenda': 'id,IntId,Name,Date,DateFinish,Status,AgendaSubType,DepartmentId,Location,StageOver,StageName,StageNameGenitive,Type,EditTime',
            'yp-department': 'id,Name,Type,IntId,EditTime',
            'yp-passport': 'id,Info,Num,Speaker,CoSpeaker,PreSpeak,Next,Prev,PassportHeader,DecisionProjectFile,' + 'IsCommentAvailable, IsVoteAvailable, IsAmendmentAvailable,NeedOfflineDiscussion,DecisionProjectName,EditTime',
            'yp-passport/yp-passport-voting-result': 'id,Name,PassportId,CurrentUserVote, Type,VoteForCount,' + 'VoteAgainstCount,AbstainCount,NotVoteCount,*VotePersons,TerritoryId,EditTime',
            'yp-passport/yp-passport-document': 'id,Type,Title,PassportId,Date,OrderNum,*Files,TabName,OmsuOffer,OmsuAccept,TypeName,NeedDetailButton,EditTime',
            'yp-passport/yp-passport-comment': 'id,Name,Text,PassportId,Time,isAmendment,IsVoteAvailable,isMy,EditTime',
            'yp-passport/yp-amendment-voting-result': 'id,Name,PassportId, CurrentUserVote,Type,VoteForCount,' + 'VoteAgainstCount,AbstainCount,NotVoteCount,*VotePersons,TerritoryId,EditTime',
            'yp-question': 'id,IndId,Title,Num,Type,Reading,Decision,Speaker,UnreadFileCount,Status,AgendaId,OrderNum, CoSpeaker,EditTime',
            'yp-question-view': 'id,View,EditTime',
            'zs-department': 'id,Name,FullName,Type,EditTime',
            'zs-passport': 'id,Info,Num,Speaker,CoSpeaker,PreSpeak,Next,Prev,PassportHeader,Decision,DecisionProjectName,EditTime',
            zsagenda: 'id,IndId,Name,Status,Date,EditTime',
            zsquestion: 'id,IntId,Title,Num,Type,Reading,Decision,Speaker,HeadCommittee,UnreadFileCount,AgendaId,OrderNum,Status,RegNumber,EditTime',
            'zsquestion-view': 'id,View,EditTime',
            'omsu-passport/omsu-passport-header': 'id,IsAccepted,Title,Name,Subject,HeadCommittee,*ProjectLegalActFiles,' + 'TerritoryId,Tabs,ZsLawToApprovId,EditTime',
            'omsu-passport/omsu-passport-voting-result': 'id,Name,PassportId,Type, VoteForCount, VoteAgainstCount,' + 'AbstainCount,NotVoteCount,*VotePersons,TerritoryId,EditTime',
            'omsu-passport/omsu-passport-voting-person': 'id,Name,Vote,EditTime',
            'omsu-passport/omsu-question-history': 'id,Date,Status,Department,QuestionId,Agenda,TerritoryId,EditTime',
            'omsu-passport/omsu-agenda-member': 'id,Name,Role,Post,AgendaId,IsAttended,DepartmentName,TerritoryId,EditTime',
            'omsu-passport/omsu-passport-correction': 'id,Num,Location,Text,Initiator,WorkGroupDesicion,OmsuDesicion,FromConclision,VoteResult,EditTime',
            'omsu-passport/omsu-passport-document': 'id,Type,Title,PassportId,Date,OrderNum,*Files,TabName,OmsuOffer,NeedDetailButton,' + 'OmsuAccept,TypeName, TerritoryId,VoteResult,DecisionType,EventName,EventId,QuestionName,' + 'QuestionId,ProtocolFile,DeadlineChenges,*Corrections,EditTime',
            'omsu-passport/omsu-passport-relationship': 'id,*To,*From,*Extra, TerritoryId,EditTime',
            'omsu-passport/omsu-legal-act-publication': 'id,Name,Number,Date, Volume, Part, PassportId, TerritoryId,EditTime',
            'zscommission/zscommissionagenda': 'id,Name,Date,Path,Status,DepartmentName,EditTime',
            'zscommission/zscommissionquestion': 'id,Num,OrderNum,Type,Title,Status,Reading,Speaker,CoSpeaker,HeadCommittee,' + 'ZsDepartmentAgendaId,AddInfo,EditTime',
            'zs-commission-passport': 'id,ZsDepartmentAgendaId,OrderNum,Time,Type,Title,Decision,RegNumber,Info,Num,' + 'Speaker,CoSpeaker,PreSpeak,Next,Prev,PassportHeader,DecisionProjectName,EditTime',
            'zscommission/zs-commission-question-passport-document': 'id,ZsDepartmentAgendaId,Type,Title,PassportId,QuestionId,' + 'Date,OrderNum,TabName,*Files,NeedDetailButton,EditTime',
            'zscommittee/zscommitteeagenda': 'id,Name,Date,Path,Status,DepartmentName,EditTime',
            'zscommittee/zscommitteequestion': 'id,Num,OrderNum,Type,Title,Status,Reading,Speaker,CoSpeaker,HeadCommittee,ZsDepartmentAgendaId,AddInfo,EditTime',
            'zs-committee-passport': 'id,ZsDepartmentAgendaId,OrderNum,Time,Type,Title,Decision,RegNumber,Info,Num,' + 'Speaker,CoSpeaker,PreSpeak,Next,Prev,PassportHeader,DecisionProjectName,EditTime',
            'zscommittee/zs-committee-question-passport-document': 'id,ZsDepartmentAgendaId,Type,Title,PassportId,' + 'QuestionId,Date,OrderNum,TabName,*Files,NeedDetailButton,EditTime',
            'zs-passport/zs-passport-law-history': 'id,Name,Date,PassportId,EditTime',
            'zs-passport/zs-passport-file': 'id,Name,Url,OrderNum,EditTime',
            'zs-passport/zs-passport-agenda-member': 'id,Name,Role,AgendaId,*Missing,EditTime',
            'zs-passport/zs-passport-agenda-period': 'id,Date,AgendaId,Morning,EditTime',
            'zs-passport/zs-passport-header': 'id,IsAccepted,Title,Name,Subject,HeadCommittee,*ProjectLegalActFiles,Tabs,CurrentTab,EditTime',
            'zs-passport/zs-passport-relationship': 'id,*To,*From,*Extra,EditTime',
            'zs-passport/zs-passport-relationship-list': 'id,Name,PassportId,Type,Title,EditTime',
            'zs-passport/zs-passport-document': 'id,Type,Title,PassportId,QuestionId,Date,OrderNum,*Files,TabName,OmsuOffer,' + 'OmsuAccept,TypeName,CanonicalAgendaName,CanonicalAgendaId,CanonicalQuestionName,CanonicalQuestionId,' + 'CanonicalDepartmentType,CanonicalDepartmentId,IsMP,NeedDetailButton,EditTime',
            'zs-passport/zs-passport-document-work-group': 'id,WorkGroupMember,EditTime',
            'zs-passport/zs-passport-personal-document': 'id,Name,QuestionId,QuestionNum,QuestionName,' + 'PassportId,PassportName,PassportTitle,Type,Text,Date,Url,EditTime',
            'zs-passport/zs-voting-result': 'id,Name,PassportId,Type, VoteForCount, VoteAgainstCount,' + 'AbstainCount,NotVoteCount,*VotePersons,Date,Reading,QuestionNumber,QuestionId,Result,EditTime',
            'zs-passport/zs-voting-person': 'id,Name,Vote,EditTime',
            'zsfraction/zsfractionagenda': 'id,Name,Date,Path,Status,DepartmentName,EditTime',
            'zsfraction/zsfractionquestion': 'id,Num,OrderNum,Type,Title,Status,Reading,Speaker,CoSpeaker,HeadCommittee,ZsDepartmentAgendaId,EditTime',
            'zs-fraction-passport': 'id,ZsDepartmentAgendaId,OrderNum,Time,Type,Title,Decision,RegNumber,Info,Num,' + 'Speaker,CoSpeaker,PreSpeak,Next,Prev,PassportHeader,DecisionProjectName,EditTime',
            'ik-report': 'id,DocId,DocUrl,Title,EditTime',
            eventsplan: 'id,DocId,DocUrl,Title,OrderNum,EditTime',
            'zsdepartment/zsdepartmentagenda': 'id,Name,Date,Path,Status,DepartmentName,EditTime',
            'zsdepartment/zsdepartmentquestion': 'id,ZsDepartmentAgendaId,Num,OrderNum,Time,Type,Title,Status,Decision,' + 'Reading,Speaker,CoSpeaker,HeadCommittee,PassportId,Next,Prev,RegNumber,EditTime'
          }
        }
      });
      return this._super.apply(this, arguments);
    }
  });
});