define('deputee-office/components/yp-components/yp-discussion', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/loader/yp-comments-reload'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsLoaderYpCommentsReload) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsLoaderYpCommentsReload['default'], {
    store: _ember['default'].inject.service(),
    ypCommentService: _ember['default'].inject.service(),
    tagName: 'a',
    unreadCount: _ember['default'].computed('passportId', 'question.UnreadCommentCount', function () {
      var question = this.get('store').peekRecord('yp-question', this.get('passportId'));
      this.set('question', question);
      if (question && question.get('UnreadCommentCount')) {
        return question.get('UnreadCommentCount');
      }

      return 0;
    }),

    correctStatus: _ember['default'].computed('AgendaStatus', function () {
      if (this.get('AgendaStatus') === 'Идет заседание' || this.get('AgendaStatus') === 'Формирование повестки') {
        return true;
      }
    }),

    /**
     * Вызываем модальное окно с коментариями/поправками при клике.
     * @returns {}
     */
    click: function click() {
      if (this.get('comments')) {
        this.sendAction('openModal', this.get('comments'));
        this.set('question.UnreadCommentCount', 0);

        // отправка запроса о прочтении
        this.get('ypCommentService').setCommentsAsRead(this.get('passportId'));
      }
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var passportId = this.get('passportId');
      if (passportId) {
        this.set('comments', this.get('comments').toArray());
        this.reloadComments();
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].run.cancel(this.get('runLater'));
    }
  });
});