define('deputee-office/routes/zs-agenda-departments/passport', ['exports', 'ember', 'deputee-office/routes/base-agenda-route/base-agenda-passport-components'], function (exports, _ember, _deputeeOfficeRoutesBaseAgendaRouteBaseAgendaPassportComponents) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRouteBaseAgendaPassportComponents['default'].extend({
    passportNavigate: 'zs-agenda-departments.passport',
    zsQuestion: true,

    /**
     * Получение решений комитета/комиссии/фракции.
     */
    getPassportDecisions: function getPassportDecisions() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        try {
          var decisions = _this.get('controller.model.Decision');
          resolve(decisions && decisions.split(';') || null);
        } catch (e) {
          reject(e);
        }
      });
    },
    /**
     * Получение истории переименований паспорта.
     */
    getPassportHistoryQuery: function getPassportHistoryQuery(passport) {
      return this.store.query('zs-passport/zs-passport-law-history', {
        PassportId: passport.get('PassportId')
      });
    },

    /**
    * Получение связанных законопроектов (отменяемых/изменяемых/отменяющих/изменяющих).
    */
    getPassportRelationships: function functionName(passport) {
      return this.store.queryRecord('zs-passport/zs-passport-relationship', {
        id: passport.get('PassportId'),
        projection: 'zs-passport-relationship-full'
      });
    },

    notXmlLoad: _ember['default'].computed('departmentType', function () {
      var departmentType = this.paramsFor('zs-agenda-departments').departmentType;
      return departmentType === 'committee' || departmentType === 'commission';
    }),

    /**
      * Получение паспорта вопроса.
      */
    getPassportQuery: function getPassportQuery() {
      var agendaId = this.paramsFor('zs-agenda-departments').agendaId;
      return this.store.queryRecord('zs-passport', {
        id: this.get('params.question_id'),
        agendaId: agendaId
      });
    },

    PassportHeaderQuery: function PassportHeaderQuery(passport) {
      return this.store.queryRecord('zs-passport/zs-passport-header', {
        id: passport.get('PassportId'),
        projection: 'zs-passport-header-full'
      });
    },

    getVotingResult: function getVotingResult(passport) {
      return this.store.query('zs-passport/zs-voting-result', {
        QuestionId: passport.get('id'),
        projection: 'zs-voting-result-full'
      });
    }
  });
});