define('deputee-office/components/yp-components/yp-comment-info-updater', ['exports', 'ember', 'deputee-office/config/environment', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeConfigEnvironment, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    agendaStateService: _ember['default'].inject.service('agenda-state'),
    tagName: '',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var questions = this.get('questions');
      if (questions) {
        this.reloadCommentsInfo(questions);
      }
    },

    unreadCommentCountUrl: function unreadCommentCountUrl(questions) {
      var agendaStateService = this.get('agendaStateService');
      if ((this.get('currentAgenda.Status') === 'Идет заседание' || this.get('currentAgenda.Status') === 'Формирование повестки') && agendaStateService.isZo(this.get('currentAgenda.StageName'))) {
        return this.get('settingsservice').getHost() + '/api/ypquestions/unreadCommentCount/?passportId=' + questions.mapBy('id').join('&passportId=');
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      _ember['default'].run.cancel(this.get('runLater'));
    },

    reloadCommentsInfo: function reloadCommentsInfo(questions) {
      var runLater = _ember['default'].run.later(this, function () {
        this.loadCommentsInfo(questions);
      }, _deputeeOfficeConfigEnvironment['default'].APP.commentInfoReloadInterval);
      if (this.get('runLater')) {
        _ember['default'].run.cancel(this.get('runLater'));
      }

      if (!this.get('isDestroyed')) {
        this.set('runLater', runLater);
      } else {
        _ember['default'].run.cancel(runLater);
      }
    },

    loadCommentsInfo: function loadCommentsInfo(questions) {
      var isConnected = this.get('networkstatus').isInternetConnection();
      var authHeader = this.get('currentuserservice').getBasicAuthHeader();
      var _this = this;
      if (!questions) {
        questions = _this.get('questions');
      }

      var url = _this.unreadCommentCountUrl(questions);

      if (isConnected && url) {
        _ember['default'].run.schedule('destroy', this, function () {
          _ember['default'].$.ajax({
            url: url,
            headers: { Authorization: authHeader },
            type: 'GET',
            xhrPool: _this.get('routeName'),
            timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
          }).done(function (data) {
            questions.forEach(function (item, index) {
              _ember['default'].run.later(item.get('id'), function () {
                _ember['default'].run.schedule('destroy', this, function () {
                  if (data && data !== '') {
                    item.set('UnreadCommentCount', data[item.id].newCommentCount);
                    item.set('AmendmentsExist', data[item.id].amendmentsExist);
                  } else {
                    item.set('UnreadCommentCount', 0);
                    item.set('AmendmentsExist', false);
                  }
                });
              }, 350 * (index / 2));
            });
          }).then(function () {
            _this.reloadCommentsInfo(questions);
          }, function () {
            _this.reloadCommentsInfo(questions);
          });
        });
      }
    }

  });
});