define('deputee-office/models/current-question', ['exports', 'ember-data', 'deputee-office/models/base', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    numAgenda: _emberData['default'].attr('string'),
    dateFrom: _emberData['default'].attr('date'),
    questionId: _emberData['default'].attr('string'),
    questionNum: _emberData['default'].attr('string'),
    departmentId: _emberData['default'].attr('string'),
    questionType: _emberData['default'].attr('string'),
    departmentType: _emberData['default'].attr('string'),
    questionName: _emberData['default'].attr('string'),
    departmentName: _emberData['default'].attr('string'),
    date: _ember['default'].computed('dateFrom', function () {
      if (this.get('dateFrom')) {
        return (0, _moment['default'])('' + this.get('dateFrom')).format('DD.MM.YYYY');
      }
    }),
    isService: _ember['default'].computed('questionType', function () {
      return this.get('questionType') === 'Служебный';
    })
  });
  exports['default'] = Model;
});