define('deputee-office/components/base-view', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    setup: (function () {
      if (this.get('tagName') !== '') {
        var _model = this.get('model');
        if (_model) {
          var _classNames = this.get('classNames');
          _classNames.addObject(_model.CssClass);
        }
      }
    }).on('init')
  });
});