define('deputee-office/services/territory-service', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {

  /**
   * Сервис для работы с выбранной территорией.
   */
  exports['default'] = _ember['default'].Service.extend(_deputeeOfficeMixinsServicesMixin['default'], {

    /**
     * getTerritoryId - Метод получения идентификатора выбранной территории.
     *
     * @return {Sring}  Идентификатор выбранной территории.
     */
    getTerritoryId: function getTerritoryId() {
      return this.getTerritory().id;
    },

    /**
     * getTerritoryName - Метод получения названия выбранной территории.
     *
     * @return {Sring}  Название выбранной территории.
     */
    getTerritoryName: function getTerritoryName() {
      return this.getTerritory().Name;
    },
    /**
     * getZSName - Метод получения названия ЗСПК.
     *
     * @return {Sring} Мурманская областная Дума.
    */
    getZSName: function getZSName() {
      return 'Мурманская областная Дума';
    },

    /**
     * getMPName - Метод получения названия МП.
     *
     * @return {Sring} Молодежный парламент.
    */
    getMPName: function getMPName() {
      return 'Молодежный парламент';
    },

    /**
     * getPAMSName - Метод получения названия ВОД.
     *
     * @return {Sring} Мурманская областная Дума.
    */
    getPAMSName: function getPAMSName() {
      return 'Мурманская областная Дума';
    },

    /**
     * getTerritory - Метод получения выбранной территории как объекта.
     *
     * @return {Object}  Выбранная территория.
     */
    getTerritory: function getTerritory() {
      var terrytory = this.get('settingsservice').getSettings('selectedTerritory', false);
      if (!terrytory && terrytory !== '') {
        this.setDefaultTerritory();
      }

      return this.get('settingsservice').getSettings('selectedTerritory', false);
    },

    /**
     * setTerritory - Метод установки выбранной территории.
     *
     * @param  {Object} data    Выбранная территория.
     * @param  {bool} preload Флаг предварительной загрузки объетов для новой выбранной территории.
     * @return {undefined}
     */
    setTerritory: function setTerritory(data, preload) {
      if (this.getTerritoryId() !== data.id) {
        this.get('settingsservice').putSettings('selectedTerritory', null, data);
        if (preload) {
          this.get('preloadService').preloadAgenda();
        }
      }
    },

    /**
     * setDefaultTerritory - Метод установки территории по умолчанию.
     *
     * @return {undefined}
     */
    setDefaultTerritory: function setDefaultTerritory() {
      var userSrvice = this.get('currentuserservice');
      var user = userSrvice.get('user');
      var territoryId = user.territoryId;
      var TerritoryName = user.territoryName;
      if (this.get('currentuserservice').isUserFromPermKray() || this.get('currentuserservice').get('isGuest')) {
        territoryId = 'dd056a2f-111d-4d5b-a753-343804507918';
        TerritoryName = 'Пермский р-н';
      }

      var data = {
        id: territoryId,
        Name: TerritoryName
      };
      this.get('settingsservice').putSettings('selectedTerritory', null, data);
    },
    getPkTerritory: function getPkTerritory() {
      return '4112247f-f2e2-4aa6-a06c-9f7a384c73d3';
    },
    getZsSubsystemId: function getZsSubsystemId() {
      return 'd01f73d0-0625-4c66-9edb-905b18ac3653';
    }
  });
});