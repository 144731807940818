define('deputee-office/routes/omsuschedule', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/territory-flag-name', 'moment'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsTerritoryFlagName, _moment) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], {
    queryParams: {
      dateParam: {
        refreshModel: false,
        replace: true
      }
    },

    model: function model(params) {
      var dateParam = (0, _moment['default'])().format('MM.YYYY');
      if (params.dateParam) {
        dateParam = params.dateParam;
      }

      var result = this.store.query('omsu-event', {
        Date: dateParam,
        TerritoryId: this.get('territoryService').getTerritoryId()
      });
      var _model = {
        items: result
      };
      return _model;
    },

    setupController: function setupController(controller, model) {
      controller.set('isLoading', true);
      model.items.then(function (items) {
        controller.set('model.items', items);
        controller.set('isLoading', false);
        controller.send('doGetData');
      });

      var func = function func() {
        var modelToEvent = controller.modelToEvent;
        var items = controller.get('model.items');
        if (items && items.forEach) {
          var filters;
          var events;

          (function () {
            filters = {
              eventType: controller.get('model.eventType'),
              showMyEvents: controller.get('model.showMyEvents')
            };

            var fc = _ember['default'].$('#omsueventscalc');
            var _calcFilter = controller.calcFilter;
            events = items.filter(function (record) {
              return _calcFilter(record, filters) && !fc.fullCalendar('clientEvents').mapBy('id').contains(record.get('id'));
            }).map(function (record) {
              return modelToEvent.call(controller, record);
            });

            fc.fullCalendar('addEventSource', events);
            controller.send('removeFiltered');
          })();
        }
      };

      controller.addObserver('model.items', model, func);

      this._super(controller, model);
    },

    resetController: function resetController(controller) {
      controller.set('dateParam', undefined);
    },

    activate: function activate() {
      this.setFlagAndNameBySetting();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
    },

    actions: {
      filterEvents: function filterEvents(selectedType, selectedMy) {
        this.get('controller').send('typeChanged', selectedType);
        this.get('controller').send('myChanged', selectedMy);
      },

      showFilter: function showFilter() {
        this.render('modals/omsu-schedule-filter', {
          into: this.get('routeName'),
          outlet: 'modal',
          model: this.get('controller.model')
        });
      },

      showEvent: function showEvent(event) {
        this.render('modals/omsu-schedule-event', {
          controller: 'modals/document-decisions',
          into: this.get('routeName'),
          outlet: 'modal',
          model: event
        });
      },

      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: this.get('routeName')
        });
      }
    }

  });
});