define('deputee-office/instance-initializers/device', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(applicationInstance) {
    ['component', 'controller', 'model', 'route', 'router:main', 'view'].forEach(function (type) {
      applicationInstance.application.inject(type, 'devicejs', 'service:device');
    });

    var deviceService = applicationInstance.lookup('service:device');
    applicationInstance.application.Resolver.reopen({
      device: deviceService
    });

    // For some reason reopen will take an effect only after new instantiation.
    // Without this call to 'Rersolver.create' device detection service won't be injected into existing resolver instance
    // (stored in application__registry__.resolver).
    applicationInstance.application.Resolver.create();
  }

  exports['default'] = {
    name: 'devicejs',
    initialize: initialize
  };
});