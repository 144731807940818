define('deputee-office/components/laws-filter-popover', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    popoverId: 'popoverFilterLawId',
    filterIcon: _ember['default'].computed('selectedCommittee', 'selectedReading', function () {
      var sc = this.get('selectedCommittee');
      var sr = this.get('selectedReading');
      if (sc !== 'Все' || sr.Name !== 'Все') {
        return 'glyphicon icon-filter-selected';
      }

      return 'glyphicon icon-filter';
    }),
    typePropertyChanged: function typePropertyChanged() {
      var filter = this.get('filterModel');
      var newType = this.get('selectedType');

      filter.type = newType;
      this.sendAction('typeChanged', newType);
      this.sendAction('onFilter', filter);
    },

    committeePropertyChanged: function committeePropertyChanged() {
      var filter = this.get('filterModel');
      filter.committee = this.get('selectedCommittee');

      this.sendAction('onFilter', filter);
    },

    readingPropertyChanged: function readingPropertyChanged() {
      var filter = this.get('filterModel');
      filter.reading = this.get('selectedReading');

      this.sendAction('onFilter', filter);
    },

    filterChanged: function filterChanged() {
      var filter = this.get('filterModel');
      if (!filter) {
        return;
      }

      this.set('selectedType', filter.type);
      this.set('committees', filter.committees);
      this.set('selectedCommittee', filter.committee);
      this.set('readings', filter.readings);
      this.set('selectedReading', filter.reading);
      this.addObserver('selectedType', this.typePropertyChanged);
      this.addObserver('selectedCommittee', this.committeePropertyChanged);
      this.addObserver('selectedReading', this.readingPropertyChanged);

      if (this.get('filterObserverAdded')) {
        this.removeObserver('filterModel', this.filterChanged);
        this.set('filterObserverAdded', false);
      }
    },

    init: function init() {
      this._super();
      var filter = this.get('filterModel');

      if (!filter) {
        this.addObserver('filterModel', this.filterChanged);
        this.set('filterObserverAdded', true);
      } else {
        this.filterChanged();
      }
    },

    actions: {
      changeReading: function changeReading(newreading) {
        this.set('selectedReading', newreading);
      },
      changeType: function changeType(newType) {
        this.set('selectedType', newType);
      },

      changeCommittee: function changeCommittee(newCommittee) {
        this.set('selectedCommittee', newCommittee);
      }
    }
  });
});