define('deputee-office/controllers/k-s-p-materials', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    viewMode: 'list',
    searchPlaceholder: 'Поиск по материалам',
    queryParams: ['materialType'],
    materialType: null,

    // Фильтруем по типу материала и поиску
    displayedMaterials: _ember['default'].computed('materialType', 'searchText', 'model', function () {
      var _this = this;
      var text = this.get('searchText');
      var materials = this.get('model');
      if (materials) {
        var filteredMaterials = materials.filter(function (material) {
          if (text) {
            return material.get('MaterialType') === _this.get('materialType') && material.get('Title').toLowerCase().indexOf(text.toLowerCase()) !== -1;
          } else {
            return material.get('MaterialType') === _this.get('materialType');
          }
        });
        return filteredMaterials;
      }
    }),

    // Группируем по годам
    grouped: _ember['default'].computed('displayedMaterials.[]', function () {
      var materials = this.get('displayedMaterials');
      if (materials) {
        var result = _ember['default'].A([]);
        var idx = [];
        materials.toArray().sortByProperty('OrderNum', 'asc').forEach(function (material) {
          var year = material.get('Date');
          if (!result.isAny('date', year)) {
            idx[year + '_'] = _ember['default'].A([]);
            result.pushObject({ date: year, items: idx[year + '_'] });
          }

          idx[year + '_'].pushObject(material);
        });
        return result.sort(function (a, b) {
          return b.date - a.date;
        });
      }
    }),

    materialTypesSetUp: _ember['default'].observer('model', function () {
      if (this.get('model')) {
        var materialTypes = this.getMaterialTypes(this.get('model'));
        if (!this.get('materialType')) {
          this.send('selectMaterialType', materialTypes[0]);
        }
      }
    }),

    materialTypes: _ember['default'].computed('model', function () {
      if (this.get('model')) {
        var materialTypes = this.getMaterialTypes(this.get('model'));
        return materialTypes;
      }
    }),

    getMaterialTypes: function getMaterialTypes(materials) {
      var result = materials.map(function (material) {
        return material.get('MaterialType');
      }, this).uniq().sort();
      return result;
    },

    actions: {
      selectMaterialType: function selectMaterialType(materialType) {
        this.transitionToRoute('k-s-p-materials', { queryParams: { materialType: materialType } });
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      },

      search: function search(text) {
        this.set('searchText', text);
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      }
    }
  });
});