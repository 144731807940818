define('deputee-office/controllers/modals/timer-mp', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isOver: false,
    actions: {
      timerOver: function timerOver() {
        _ember['default'].run.schedule('afterRender', this, function () {
          this.set('isOver', true);
        });
      },
      timerRun: function timerRun() {
        _ember['default'].run.schedule('afterRender', this, function () {
          this.set('isOver', false);
        });
      }
    }
  });
});