define('deputee-office/routes/k-s-p-materials', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/territory-flag-name'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsTerritoryFlagName) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], {

    queryParams: {
      materialType: {
        refreshModel: false,
        replace: true
      }
    },

    model: function model(params) {

      var modelArray = _ember['default'].A([]);
      var materialType = params.materialType;

      this.set('materialType', materialType);

      return this.getRecords(modelArray, false);
    },

    getFilledModel: function getFilledModel(modelArray, records) {
      modelArray.pushObjects(records.toArray());

      return modelArray;
    },

    getRecords: function getRecords(modelArray, useOnlineStore) {
      var _this = this;

      return this.store.findAll('material-ksp', { useOnlineStore: useOnlineStore }).then(function (records) {
        _this.set('modelFromOnline', useOnlineStore);

        if (!useOnlineStore && records.get('length') === 0) {
          return _this.getRecords(modelArray, true);
        }

        return _this.getFilledModel(modelArray, records);
      })['catch'](function functionName() {
        if (!useOnlineStore) {
          return this.getRecords(modelArray, true);
        }
      });
    },

    setupController: function setupController(controller, model) {

      this._super(controller, model);

      if (!this.get('modelFromOnline')) {
        this.store.findAll('material-ksp', { useOnlineStore: true }).then(function (records) {
          controller.get('model').clear();
          controller.get('model').pushObjects(records.toArray());
        });
      }
    },

    resetController: function resetController(controller, isExiting, transition) {
      controller.set('selectedMaterialType', undefined);
      controller.set('materialType', undefined);
      if (transition.targetName.indexOf('download-file') === -1) {
        controller.set('searchText', undefined);
      }
    },

    renderTemplate: function renderTemplate() {
      this.render();
      if (this.get('devicejs').isPhone()) {
        this.render('search-box', {
          into: this.get('routeName'),
          outlet: 'search-box',
          controller: this.controllerFor('k-s-p-materials')
        });
        this.controllerFor('k-s-p-materials').set('model.searchText', this.get('controller.searchText'));
      } else {
        this.render('search-box', {
          outlet: 'toolbar-right-outlet',
          controller: this.controllerFor('k-s-p-materials')
        });
      }
    },

    activate: function activate() {
      this.setFlagAndNameForZS();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
    }
  });
});