define('deputee-office/models/base-agenda', ['exports', 'ember-data', 'deputee-office/models/base', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember, _moment) {
  exports['default'] = _deputeeOfficeModelsBase['default'].extend({
    IntId: _emberData['default'].attr('number'),
    Name: _emberData['default'].attr('string'),
    Date: _emberData['default'].attr('date'),
    Status: _emberData['default'].attr('string'),
    FormattedDate: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])('' + this.get('Date')).format('DD.MM.YYYY HH.mm');
    }),
    DisplayedDate: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])('' + this.get('Date')).format('D MMMM YYYY года');
    }),
    Time: _ember['default'].computed('Date', function () {
      return (0, _moment['default'])('' + this.get('Date')).format('HH:mm');
    }),
    Location: _emberData['default'].attr('string'),
    LocationURI: _ember['default'].computed('Location', function () {
      return encodeURIComponent(this.get('Location'));
    })
  });
});