define('deputee-office/models/omsudepartment', ['exports', 'ember-data', 'deputee-office/models/base', 'deputee-office/mixins/department-short-name'], function (exports, _emberData, _deputeeOfficeModelsBase, _deputeeOfficeMixinsDepartmentShortName) {

  var Model = _deputeeOfficeModelsBase['default'].extend(_deputeeOfficeMixinsDepartmentShortName['default'], {
    Name: _emberData['default'].attr('string'),
    FullName: _emberData['default'].attr('string'),
    Type: _emberData['default'].attr('string'),
    Order: _emberData['default'].attr('number'),
    TerritoryId: _emberData['default'].attr('guid')
  });

  exports['default'] = Model;
});