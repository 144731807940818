define('deputee-office/resolver', ['exports', 'ember-resolver', 'ember'], function (exports, _emberResolver, _ember) {
  exports['default'] = _emberResolver['default'].extend({
    deviceRelatedTypes: ['template', 'route', 'controller'],

    resolve: function resolve(fullName) {
      var device = this.get('device');
      if (_ember['default'].isNone(device)) {
        return this._super(fullName);
      }

      var fullNamePartsArray = fullName.split(':');
      var _resolvingType$resolvingPath = {
        resolvingType: fullNamePartsArray[0],
        resolvingPath: fullNamePartsArray[1]
      };
      var resolvingType = _resolvingType$resolvingPath.resolvingType;
      var resolvingPath = _resolvingType$resolvingPath.resolvingPath;

      if (this.get('deviceRelatedTypes').indexOf(resolvingType) > -1) {
        var newFullName = resolvingType + ':' + device.getType() + '/' + resolvingPath;
        return this._super(newFullName) || this._super(fullName);
      } else {
        return this._super(fullName);
      }
    }
  });
});