define('deputee-office/components/favorites/favorites-decrees', ['exports', 'ember', 'deputee-office/mixins/favorites-component-base'], function (exports, _ember, _deputeeOfficeMixinsFavoritesComponentBase) {

  var defaultModelName = 'decree';

  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsFavoritesComponentBase['default'], {

    store: _ember['default'].inject.service(),

    loadableModels: ['favorite', 'decree'],

    grouped: _ember['default'].computed('displayedModel.[]', function () {
      var result = _ember['default'].A([]);
      var idx = [];
      this.get('displayedModel').forEach(function (record) {
        var _month = record.get('Month');
        if (!result.isAny('month', _month)) {
          idx[_month] = _ember['default'].A([]);
          result.pushObject({
            month: _month,
            items: idx[_month]
          });
        }

        idx[_month].pushObject(record);
      });

      return result;
    }),

    init: function init() {
      this._super();
      this.set('model', _ember['default'].A([]));
      this.set('displayedModel', _ember['default'].A([]));
    },

    loadData: function loadData() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var store = _this.get('store');
        var model = _this.get('model');

        var modelName = _this.get('tModelName');

        if (!modelName) {
          modelName = defaultModelName;
        }

        store.query('favorite', {
          Model: modelName
        }).then(function (favorites) {

          var requests = favorites.map(function (favorite) {
            var id = JSON.parse(favorite.get('Params')).id;

            return store.findRecord(modelName, id).then(function (decree) {
              model.sort(function (a, b) {
                if (a.get('RegDate') < b.get('RegDate')) {
                  return 1;
                }

                if (a.get('RegDate') > b.get('RegDate')) {
                  return -1;
                }
              });

              model.pushObject(decree);
            });
          });

          _ember['default'].RSVP.allSettled(requests, 'favorites-decrees: allSettled requests').then(function () {
            resolve();
          })['catch'](function (e) {
            reject();
            console.error(e);
          });
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'favorites-decrees: loadData');
    },

    filterModel: function filterModel() {
      var model = this.get('model');
      var displayedModel = this.get('displayedModel');
      var filterText = this.get('searchString');

      displayedModel.clear();

      if (filterText && filterText.value) {
        model.forEach(function (decree) {
          if (decree.get('Title').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1) {
            displayedModel.pushObject(decree);
          }
        });
      } else {
        displayedModel.pushObjects(model);
      }
    }

  });
});