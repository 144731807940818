define('deputee-office/components/tree-nodes/passport-stage-node', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    isSelectedNode: _ember['default'].computed('selectedNode', function () {
      return this.get('selectedNode') === this.get('model.Name');
    }),
    actions: {
      nodeClick: function nodeClick(model) {
        this.sendAction('onNodeClickAction', model.Name);
      }
    }
  });
});