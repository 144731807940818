define('deputee-office/routes/index', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin) {

  var meetingRoutes = ['omsu-agenda-commitees.index', 'zs-agenda-departments.index', 'yp-agenda-comissions.index'];
  var materialRoutes = ['k-s-p-materials'];
  var eventsplan = 'eventsplan';
  var workplan = 'work-plan.index';

  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], {

    actionsToRun: [],

    queryParams: {
      indexState: {
        refreshModel: false,
        replace: false
      },
      indexStateTransition: {
        refreshModel: false,
        replace: false
      }
    },

    statisticsService: _ember['default'].inject.service(),
    preloadService: _ember['default'].inject.service(),

    defaultPhoto: 'assets/img/no-photo.jpg',
    defaultFlag: 'assets/img/permskiy_krai.png',

    model: function model() {

      if (this.actionsToRun.length) {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          var _this = this;
          this.actionsToRun.forEach(function (action) {
            _this.send(action);
          });
          this.actionsToRun = [];
        });
      }

      return this.store.findAll('service-setting', {
        useOnlineStore: true
      }).then(function functionName(records) {
        var img = new Image();
        records.forEach(function (item) {
          img.src = item.get('IconUrl');
        });
        return records;
      });
    },

    timer: function functionName() {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        var _this2 = this;

        var currentuserservice = this.get('currentuserservice');
        if (!this.get('isTimerClosed') && currentuserservice.isUserFromZS()) {
          var agendas = this.store.findAll('zsagenda');
          agendas.then(function (agendaRecords) {
            var agenda = _this2.get('preloadService')._getClosestAgenda(agendaRecords);
            if (agenda) {
              _this2.send('showTimer', agenda.get('Date'));
            }
          });
        }
      });
    },

    activate: function activate() {
      this.timer();
    },

    actions: {
      willTransition: function willTransition(transition) {

        if (meetingRoutes.indexOf(transition.targetName) !== -1 && !transition.queryParams.departmentId) {
          transition.abort();

          this.send('showModalDepartments', transition);
        } else if (materialRoutes.indexOf(transition.targetName) !== -1 && !transition.queryParams.materialType) {
          transition.abort();

          this.send('showModalMaterialTypes', transition);
        } else if (transition.targetName === eventsplan) {
          transition.abort();

          this.send('showModalEventsPlan', transition);
        } else if (transition.targetName === workplan && !transition.queryParams.activityTypeId) {
          transition.abort();

          this.send('showModalWorkPlan', transition);
        }
      },

      timerOver: function functionName() {
        this.send('removeTimer', true);
      },

      removeTimer: function functionName(safe) {
        if (!safe) {
          this.set('isTimerClosed', true);
        }

        this.disconnectOutlet({
          outlet: 'timer',
          parentView: 'index'
        });
      },

      showTimer: function showTimer(date) {
        var device = this.get('devicejs');
        if (device.isPhone()) {
          this.controllerFor('index').set('closestAgendaDate', date);
        } else {
          this.render('modals/timer', {
            into: 'index',
            outlet: 'timer',
            model: date
          });
        }
      },

      showModalDepartments: function showModalDepartments(transition) {
        var wrapper = {
          transition: transition
        };

        if (this.get('devicejs').isPhone()) {
          this.transitionTo(this.routeName, {
            queryParams: {
              indexState: 'comissions',
              indexStateTransition: transition.targetName
            }
          });
        } else {

          this.render('modals/department-modal', {
            into: 'index',
            outlet: 'modal',
            model: wrapper
          });
        }
      },

      showModalMaterialTypes: function showModalMaterialTypes(transition) {
        var wrapper = {
          transition: transition
        };

        if (this.get('devicejs').isPhone()) {
          this.transitionTo(this.routeName, {
            queryParams: {
              indexState: 'material',
              indexStateTransition: transition.targetName
            }
          });
        } else {

          this.render('modals/materialtype-modal', {
            into: 'index',
            outlet: 'modal',
            model: wrapper
          });
        }
      },

      showModalEventsPlan: function showModalEventsPlan(transition) {
        var wrapper = {
          transition: transition
        };
        if (this.get('devicejs').isPhone()) {
          this.transitionTo(this.routeName, {
            queryParams: {
              indexState: 'events-plan',
              indexStateTransition: transition.targetName
            }
          });
        } else {
          this.render('modals/eventsplan-modal', {
            into: 'index',
            outlet: 'modal',
            model: wrapper
          });
        }
      },

      showModalWorkPlan: function showModalWorkPlan(transition) {
        var wrapper = {
          transition: transition
        };

        if (this.get('devicejs').isPhone()) {
          this.transitionTo(this.routeName, {
            queryParams: {
              indexState: 'work-plan',
              indexStateTransition: transition.targetName
            }
          });
        } else {
          this.render('modals/omsuactivitytype-modal', {
            into: 'index',
            outlet: 'modal',
            model: wrapper
          });
        }
      },

      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'index'
        });
      },

      selectDepartmentForTransition: function selectDepartmentForTransition(departmentId, transition) {
        this.transitionTo(transition.targetName, {
          queryParams: {
            departmentId: departmentId
          }
        });
      },

      selectMaterialType: function selectMaterialType(materialType, transition) {
        this.transitionTo(transition.targetName, {
          queryParams: {
            materialType: materialType
          }
        });
      },

      selectActivityType: function selectActivityType(activityTypeId, transition) {
        this.transitionTo(transition.targetName, {
          queryParams: {
            activityTypeId: encodeURI(activityTypeId)
          }
        });
      },

      selectEventsPlan: function selectEventsPlan(eventsPlan) {
        if (!this.get('devicejs').isPhone()) {
          this.actionsToRun.push('showModalEventsPlan');
        }

        this.transitionTo(eventsPlan.get('DocId').replace('#', ''));
      },

      showModal: function showModal(name, model) {
        console.log('Ahtung!');
        this.render(name, {
          into: 'index',
          outlet: 'modal',
          model: model
        });
      },

      removeModalTerritory: function removeModalTerritory() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'index'
        });
        this.get('controller').set('selectedTerritory', this.get('territoryService').getTerritory());
      },

      updateTerritoryName: function updateTerritoryName() {
        this.get('controller').set('selectedTerritory', this.get('territoryService').getTerritory());
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('controller', controller);
      controller.set('selectedTerritory', this.get('territoryService').getTerritory());
    }

  });
});