define('deputee-office/controllers/modals/simple-selector', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actionName: 'select',

    actions: {
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
        this.set('yearsListState', false);
        this.set('_selectedObject', null);
      },
      select: function functionName() {
        var actionName = this.get('model.actionName') ? this.get('model.actionName') : this.get('actionName');
        if (this.get('model.target')) {
          this.get('model.target').send(actionName, this.get('model.selected'));
        } else {
          this.get('target').send(actionName, this.get('model.selected'));
        }
      }
    }
  });
});