define('deputee-office/components/vote-donut-diagramm', ['exports', 'ember', 'deputee-office/utils/count-text'], function (exports, _ember, _deputeeOfficeUtilsCountText) {
  exports['default'] = _ember['default'].Component.extend({
    registerPlugin: function functionName() {
      // write text plugin
      window.Chart.pluginService.register({
        afterUpdate: function afterUpdate(chart) {
          var helpers = window.Chart.helpers;
          var globalConfig = window.Chart.defaults.global;
          if (chart.config.options.elements.center) {
            var ctx = chart.chart.ctx;
            var centerConfig = chart.config.options.elements.center;
            var fontStyle = helpers.getValueOrDefault(centerConfig.fontStyle, globalConfig.defaultFontStyle);
            var fontFamily = helpers.getValueOrDefault(centerConfig.fontFamily, globalConfig.defaultFontFamily);

            var fontSize = undefined;

            if (centerConfig.fontSize) {
              fontSize = centerConfig.fontSize;
            }

            // figure out the best font size, if one is not specified
            else {
                ctx.save();
                fontSize = helpers.getValueOrDefault(centerConfig.minFontSize, 1);
                var maxFontSize = helpers.getValueOrDefault(centerConfig.maxFontSize, 256);
                var maxText = helpers.getValueOrDefault(centerConfig.maxText, centerConfig.text);

                do {
                  ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
                  var textWidth = ctx.measureText(maxText).width;

                  // check if it fits, is within configured limits and that we are not simply toggling back and forth
                  if (textWidth < chart.innerRadius * 2 && fontSize < maxFontSize) {
                    fontSize += 1;
                  } else {
                    // reverse last step
                    fontSize -= 1;
                    break;
                  }
                } while (true);
                ctx.restore();
              }

            // save properties
            chart.center = {
              font: helpers.fontString(fontSize, fontStyle, fontFamily),
              fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor)
            };
          }

          if (chart.config.options.elements.centerDown) {
            var ctx = chart.chart.ctx;
            var centerConfig = chart.config.options.elements.centerDown;
            var fontStyle = helpers.getValueOrDefault(centerConfig.fontStyle, globalConfig.defaultFontStyle);
            var fontFamily = helpers.getValueOrDefault(centerConfig.fontFamily, globalConfig.defaultFontFamily);

            var fontSize = undefined;

            if (centerConfig.fontSize) {
              fontSize = centerConfig.fontSize;
            }

            // figure out the best font size, if one is not specified
            else {
                ctx.save();
                fontSize = helpers.getValueOrDefault(centerConfig.minFontSize, 1);
                var maxFontSize = helpers.getValueOrDefault(centerConfig.maxFontSize, 256);
                var maxText = helpers.getValueOrDefault(centerConfig.maxText, centerConfig.text);

                do {
                  ctx.font = helpers.fontString(fontSize, fontStyle, fontFamily);
                  var textWidth = ctx.measureText(maxText).width;

                  // check if it fits, is within configured limits and that we are not simply toggling back and forth
                  if (textWidth < chart.innerRadius * 2 && fontSize < maxFontSize) {
                    fontSize += 1;
                  } else {
                    // reverse last step
                    fontSize -= 1;
                    break;
                  }
                } while (true);
                ctx.restore();
              }

            // save properties
            chart.centerDown = {
              font: helpers.fontString(fontSize, fontStyle, fontFamily),
              fillStyle: helpers.getValueOrDefault(centerConfig.fontColor, globalConfig.defaultFontColor)
            };
          }
        },
        afterDraw: function afterDraw(chart) {
          if (chart.center) {
            var centerConfig = chart.config.options.elements.center;
            var ctx = chart.chart.ctx;

            ctx.save();
            ctx.font = chart.center.font;
            ctx.fillStyle = chart.center.fillStyle;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.fillText(centerConfig.text, centerX, centerY);

            if (chart.centerDown) {
              var centerDownConfig = chart.config.options.elements.centerDown;

              ctx.save();
              ctx.font = chart.centerDown.font;
              ctx.fillStyle = chart.centerDown.fillStyle;
              ctx.textAlign = 'center';
              ctx.textBaseline = 'middle';
              ctx.fillText(centerDownConfig.text, centerX, centerY + 15);
            }

            ctx.restore();
          }
        }
      });
    },
    optionsForChart: _ember['default'].computed('model.allCount', 'model.Result', function () {
      var allCount = this.get('model.allCount');
      this.registerPlugin();
      var people = (0, _deputeeOfficeUtilsCountText['default'])(allCount, 'человек', 'человек', 'человека');
      var centerDown;
      if (this.get('model.Result') && this.get('model.Result') !== 'Пусто') {
        var color;

        switch (this.get('model.Result')) {
          case 'Решение принято':
            color = 'green';
            break;
          case 'Решение не принято':
            color = 'red';
            break;
          default:
            color = 'black';
        }

        centerDown = {
          // the longest text that could appear in the center
          maxText: '100%',
          text: this.get('model.Result'),
          fontColor: color,
          fontFamily: "'Lato'",

          fontSize: 18,

          // if a fontSize is NOT specified, we will scale (within the below limits) maxText to take up the maximum space in the center
          // if these are not specified either, we default to 1 and 256
          minFontSize: 1,
          maxFontSize: 256
        };
      }

      return new Object({
        cutoutPercentage: 70,
        events: [],
        maintainAspectRatio: false,
        segmentShowStroke: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        elements: {
          arc: {
            roundedCornersFor: 0,
            borderWidth: 0
          },
          center: {
            // the longest text that could appear in the center
            maxText: '100%',
            text: allCount + ' ' + people + ' (100%)',
            fontColor: 'black',
            fontFamily: "'Lato'",

            fontSize: 18,

            // if a fontSize is NOT specified, we will scale (within the below limits) maxText to take up the maximum space in the center
            // if these are not specified either, we default to 1 and 256
            minFontSize: 1,
            maxFontSize: 256
          },
          centerDown: centerDown
        }

      });
    }),
    dataForChart: _ember['default'].computed('model.VoteForCount', 'model.VoteAgainstCount', 'model.AbstainCount', function () {
      var VoteForCount = this.get('model.VoteForCount');
      var VoteAgainstCount = this.get('model.VoteAgainstCount');
      var AbstainCount = this.get('model.AbstainCount');
      var data = {
        labels: [],
        datasets: [{
          data: [VoteAgainstCount, VoteForCount, AbstainCount],
          backgroundColor: ['#FF1744', '#48A151', '#E0E0E0'],
          hoverBackgroundColor: ['#FF1744', '#48A151', '#E0E0E0']
        }]
      };
      return data;
    })
  });
});