define('deputee-office/components/agenda-info-popover', ['exports', 'ember'], function (exports, _ember) {
  var _slice = Array.prototype.slice;
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      showAgendaMembers: function showAgendaMembers() {
        _ember['default'].$('#' + this.get('popoverId')).webuiPopover('hide');
        this.sendAction.apply(this, ['showAgendaMembers'].concat(_slice.call(arguments)));
      },

      selectExtendedFile: function selectExtendedFile(idAgenda) {
        _ember['default'].$('#' + this.get('popoverId')).webuiPopover('hide');
        this.selectExtendedFile = 'selectExtendedFile';
        this.idAgenda = idAgenda;
        this.sendAction('selectExtendedFile', this.idAgenda);
      }
    }
  });
});