define('deputee-office/controllers/modals/agenda-preload', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    store: _ember['default'].inject.service(),
    fileService: _ember['default'].inject.service(),
    agendaDeepObservationService: _ember['default'].inject.service(),

    downloadMeta: _ember['default'].A(),

    questionsAll: _ember['default'].computed('downloadMeta.length', function () {
      return this.get('downloadMeta.length');
    }),

    questionsDownloaded: _ember['default'].computed('downloadMeta.@each.isDownloadComplete', function () {
      return this.get('downloadMeta').filter(function (x) {
        return x.get('isDownloadComplete');
      }).length;
    }),

    actions: {
      closeModal: function closeModal() {
        this.get('target').send('removeModal');
        this.get('downloadMeta').clear();

        // this.get('store').cancelQuery('zs-passport');
        // this.get('store').cancelQuery('zs-passport/zs-passport-header');
        // this.get('store').cancelQuery('zs-passport/zs-passport-document');
        // this.get('store').cancelQuery('file');
      },

      initModel: function initModel() {
        var downloadMeta = this.get('agendaDeepObservationService').agendaDeepReloadOnce(this.get('model.currentAgenda.id'));
        this.set('downloadMeta', downloadMeta);
      }
    }
  });
});