define('deputee-office/helpers/is-pending', ['exports', 'ember-promise-helpers/helpers/is-pending'], function (exports, _emberPromiseHelpersHelpersIsPending) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersIsPending['default'];
    }
  });
  Object.defineProperty(exports, 'isPending', {
    enumerable: true,
    get: function get() {
      return _emberPromiseHelpersHelpersIsPending.isPending;
    }
  });
});