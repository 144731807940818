define('deputee-office/models/bloguser', ['exports', 'ember-data', 'deputee-office/models/base', 'ember-flexberry-data'], function (exports, _emberData, _deputeeOfficeModelsBase, _emberFlexberryData) {
  var Model = _deputeeOfficeModelsBase['default'].extend({
    Author: _emberData['default'].attr('string'),
    PhotoBase64: _emberData['default'].attr('string'),
    Subtitle: _emberData['default'].attr('string'),
    Order: _emberData['default'].attr('number'),
    Items: _emberData['default'].hasMany('blogitem', { async: false })
  });

  Model.defineProjection('blog-user-full', 'bloguser', {
    Author: _emberFlexberryData.Projection.attr('Author'),
    PhotoBase64: _emberFlexberryData.Projection.attr('PhotoBase64'),
    Subtitle: _emberFlexberryData.Projection.attr('Subtitle'),
    Order: _emberFlexberryData.Projection.attr('Order'),
    Items: _emberFlexberryData.Projection.hasMany('blogitem', 'Items', {
      Url: _emberFlexberryData.Projection.attr('Url'),
      Name: _emberFlexberryData.Projection.attr('Name'),
      Order: _emberFlexberryData.Projection.attr('Orders')
    })
  });
  exports['default'] = Model;
});