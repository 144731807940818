define('deputee-office/mixins/loader/events-plan', ['exports', 'ember', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeConfigEnvironment) {
  exports['default'] = _ember['default'].Mixin.create({
    territoryService: _ember['default'].inject.service(),
    currentuserservice: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    networkstatus: _ember['default'].inject.service(),

    /**
     * platform - Вычислимое свойство получения названия платформы.
     *
     * @param  {type} function( description
     * @return {string}           Название платформы.
     */
    platform: _ember['default'].computed(function () {
      if (window.device) {
        var platform = window.device.platform.toLowerCase();
        return platform;
      }
    }),

    loadEventsPlans: function loadEventsPlans() {
      var _this = this;
      var eventsplans = this.store.findAll('eventsplan');
      return eventsplans.then(function (items) {
        _this.set('eventsplans', items.toArray().sortByProperty('OrderNum', 'asc'));
        return items;
      });
    },

    actions: {
      goToZsCalendar: function goToZsCalendar() {
        var _this2 = this;

        var _this = this;
        var platform = this.get('platform');
        if (platform === 'ios') {
          window.plugins.calendar.listCalendars(function (cals) {
            var cal = cals.find(function (cal) {
              return cal.type === 'Subscription' && cal.name === 'Календарь';
            });
            if (cal) {
              window.externalApp.launch(['calshow://', '', '', window.externalApp.alertType.None]);
            } else {
              // Если необходимо качать пароль под конкретным пользователем let url = 'http://'+_this.get('currentuserservice').get('login')+':'+_this.get('currentuserservice').get('password')+'@mail.zsperm.ru/owa/calendar/3dde0031ddc94ac7ab8a3cb38468778e@zsperm.ru/0875b18a90224d81bea6716ca8beafc25203880817408794212/calendar.ics';
              var url = 'http://permkrai:permkrai@mail.zsperm.ru/owa/calendar/' + '3dde0031ddc94ac7ab8a3cb38468778e@zsperm.ru/0875b18a90224d81bea6716ca8beafc25203880817408794212/calendar.ics';
              window.externalApp.launch([url, '', '', window.externalApp.alertType.None]);
            }
          }, null);
        } else if (platform === 'android') {
          var networkstatus = this.get('networkstatus');
          if (!networkstatus.isConnectionAccess()) {
            window.plugins.calendar.openCalendar();
            return;
          }

          this.set('calendarLoading', true);
          this.get('store').query('calendar-event', {
            Subsystem: this.get('territoryService').getZsSubsystemId()
          }).then(function (events) {
            var error = function error(message) {
              console.log('Error: ' + message);
              this.set('calendarLoading', false);
              this.set('calendarLoadingError', true);
            };

            var calendarName = _deputeeOfficeConfigEnvironment['default'].APP.ZsCalendarName;
            var calendarColor = _deputeeOfficeConfigEnvironment['default'].APP.ZsCalendarColor;
            window.plugins.calendar.deleteCalendar(calendarName, function functionName() {
              var createCalOptions = window.plugins.calendar.getCreateCalendarOptions();
              createCalOptions.calendarName = calendarName;
              createCalOptions.calendarColor = calendarColor;

              window.plugins.calendar.createCalendar(createCalOptions, function functionName(id) {
                var calOptions = window.plugins.calendar.getCalendarOptions();
                calOptions.calendarId = id;
                events.forEach(function (item, i) {
                  window.plugins.calendar.createEventWithOptions(item.get('Summary'), item.get('Location'), item.get('Description'), item.get('DtStart'), item.get('DtEnd'), calOptions, null, null);
                  if (i === events.get('length') - 1) {
                    _this.set('calendarLoading', false);
                    window.plugins.calendar.openCalendar();
                  }
                }, null);
              }, error);
            }, error);
          })['catch'](function (e) {
            _this2.error(e);
          });
        } else if (platform === 'browser') {
          var postFormToZS = function postFormToZS(path, params, method) {
            method = method || 'post';

            var form = document.createElement('form');
            form.setAttribute('method', method);
            form.setAttribute('action', path);
            form.setAttribute('target', 'extResFrame');

            for (var key in params) {
              if (params.hasOwnProperty(key)) {
                var hiddenField = document.createElement('input');
                hiddenField.setAttribute('type', 'hidden');
                hiddenField.setAttribute('name', key);
                hiddenField.setAttribute('value', params[key]);

                form.appendChild(hiddenField);
              }
            }

            document.body.appendChild(form);
            form.submit();
          };

          var login = this.get('currentuserservice').get('login');
          var password = this.get('currentuserservice').get('password');

          postFormToZS('https://mail.zsperm.ru/CookieAuth.dll?Logon', {
            curl: 'Z2FowaZ2FplanzsZ2540zsperm.ruZ2FZ3FcmdZ3DcontentsZ26partZ3D1Z26moduleZ3DcalendarZ26viewZ3DWeekly',
            flags: 0,
            forcedownlevel: 0,
            formdir: 3,
            trusted: 0,
            username: login,
            password: password,
            SubmitCreds: 'Вход в систему'
          });

          return;
        }
      }
    }
  });
});