define('deputee-office/controllers/my-documents', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var guidEmpty = '00000000-0000-0000-0000-000000000000';

  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    typeParamNames: {
      'zs-passport': 'PassportId',
      'zs-question': 'QuestionId'
    },

    currentNote: _ember['default'].computed('myDocParam', 'documents', function () {
      return this.get('documents').findBy('id', this.get('myDocParam'));
    }),

    selectedTypeChanged: _ember['default'].observer('selectedType', 'myDocParam', 'model', function () {
      if (this.get('devicejs').isPhone()) {
        switch (this.get('selectedType')) {
          case 'zs-passport':
          case 'zs-question':
            if (this.get('myDocParam')) {
              this.set('myDocumentsStateName', 'my-documents-substate/edit');
            } else {
              this.set('myDocumentsStateName', 'my-documents-substate/documents');
            }

            break;
          default:
            this.set('myDocumentsStateName', 'my-documents-substate/types');
        }
      } else if (!this.get('selectedType')) {
        this.set('selectedType', 'zs-passport');
        return;
      }

      this.loadDocuments();
    }),

    actions: {
      selectType: function selectType(type) {
        this.set('selectedType', type);
      },

      refreshDocuments: function refreshDocuments() {
        this.loadDocuments();
      }
    },

    personalDocuments: _ember['default'].computed('documents', function () {
      var selectedType = this.get('selectedType');
      if (this.get('documents')) {
        switch (selectedType) {
          case 'zs-passport':
            return this.getGroupedByPassport(this.get('documents'));
          case 'zs-question':
            return this.getGroupedByQuestion(this.get('documents'));
        }
      }
    }),

    loadDocuments: function loadDocuments() {
      var _this2 = this;

      var _this = this;
      var selectedType = this.get('selectedType');
      this.set('errorMessage', false);
      if (selectedType) {
        this.store.query('zs-passport/zs-passport-personal-document', _this.getParams(selectedType)).then(function (records) {
          _this2.set('documents', records);
          if (!(records && records.get('length') > 0)) {
            throw 'not found';
          }

          return records;
        })['catch'](function () {
          _this.set('errorMessage', true);
        });
      }
    },

    getParams: function getParams(selectedType) {
      var params = {};

      if (selectedType) {
        var paramName = this.typeParamNames[selectedType];
        if (paramName) {
          params[paramName] = guidEmpty;
        }
      }

      return params;
    },

    getGrouped: function getGrouped(model, groupBy, groupPropertyName) {
      groupPropertyName = groupPropertyName ? groupPropertyName : 'name';
      groupBy = groupBy ? groupBy : 'Type';

      var result = _ember['default'].A([]);
      var idx = [];
      model.forEach(function (record) {
        var _type = record.get(groupBy);
        if (_type === '') {
          return;
        }

        if (!result.isAny(groupPropertyName, _type)) {
          var _result$pushObject;

          idx[_type] = _ember['default'].A([]);
          result.pushObject((_result$pushObject = {}, _defineProperty(_result$pushObject, groupPropertyName, _type), _defineProperty(_result$pushObject, 'items', idx[_type]), _defineProperty(_result$pushObject, 'groupRecord', record), _result$pushObject));
        }

        idx[_type].pushObject(record);
      });
      return result;
    },

    getGroupedByPassport: function getGroupedByPassport(records) {
      var _this = this;
      return _this.getGrouped(records.toArray().sortByProperty('Date', 'desc'), 'DisplayedDate', 'date').map(function (record) {
        record.items = _this.getGrouped(record.items.sortBy('PassportName'), 'PassportName', 'passportName').map(function (record) {
          record.items = record.items = _this.getGroupedByType(record);
          return record;
        });
        return record;
      });
    },

    getGroupedByQuestion: function getGroupedByQuestion(records) {
      var _this = this;
      return _this.getGrouped(records.toArray().sortByProperty('Date', 'desc'), 'DisplayedDate', 'date').map(function (record) {
        record.items = _this.getGrouped(record.items.sortBy('QuestionName'), 'QuestionName', 'passportName').map(function (record) {
          record.items = _this.getGroupedByType(record);
          return record;
        });
        return record;
      });
    },

    getGroupedByType: function getGroupedByType(record) {
      var _this = this;
      return _this.getGrouped(record.items.sortBy('Type'), 'Type', 'type').map(function (record) {
        switch (record.type) {
          case 'note':
            record.type = 'Заметки';
            record.items.sortBy('Text');
            break;
          case 'file':
            record.type = 'Файлы';
            record.items.sortBy('Name');
            break;
        }
        return record;
      });
    }
  });
});