define('deputee-office/components/year-selector', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  exports['default'] = _ember['default'].Component.extend({
    formatMomentString: 'YYYY год',

    initNewYears: function initNewYears() {
      var years = this.get('years');
      var selected = this.get('selected');
      var selectedIndex = years.indexOf(selected);

      var now = (0, _moment['default'])(selected, 'YYYY');
      this.set('_current', now);

      var nextYear = years[selectedIndex + 1];
      var next = nextYear ? (0, _moment['default'])(nextYear, 'YYYY') : nextYear;
      this.set('_next', next);

      var prevYear = years[selectedIndex - 1];
      var prev = prevYear ? (0, _moment['default'])(prevYear, 'YYYY') : prevYear;
      this.set('_prev', prev);

      this.send('update');
    },

    didInsertElement: function didInsertElement() {
      this.initNewYears();
    },

    yearsChanged: _ember['default'].observer('years', function () {
      this.initNewYears();
    }),

    actions: {
      next: function next() {
        var years = this.get('years');
        var selected = this.get('_current').year();
        var selectedIndex = years.indexOf(selected);

        var currentYear = years[selectedIndex + 1];
        var current = currentYear ? (0, _moment['default'])(currentYear, 'YYYY') : currentYear;
        this.set('_current', current);

        var nextYear = years[selectedIndex + 2];
        var _next = nextYear ? (0, _moment['default'])(nextYear, 'YYYY') : nextYear;
        this.set('_next', _next);

        var prevYear = years[selectedIndex];
        var _prev = prevYear ? (0, _moment['default'])(prevYear, 'YYYY') : prevYear;
        this.set('_prev', _prev);

        this.send('update');
      },
      prev: function prev() {
        var years = this.get('years');
        var selected = this.get('_current').year();
        var selectedIndex = years.indexOf(selected);

        var currentYear = years[selectedIndex - 1];
        var current = currentYear ? (0, _moment['default'])(currentYear, 'YYYY') : currentYear;
        this.set('_current', current);

        var nextYear = years[selectedIndex];
        var _next = nextYear ? (0, _moment['default'])(nextYear, 'YYYY') : nextYear;
        this.set('_next', _next);

        var prevYear = years[selectedIndex - 2];
        var _prev = prevYear ? (0, _moment['default'])(prevYear, 'YYYY') : prevYear;
        this.set('_prev', _prev);
        this.send('update');
      },
      update: function update() {
        if (this.get('_prev')) {
          this.set('prevData', this.get('_prev').format(this.formatMomentString));
        } else {
          this.set('prevData', null);
        }

        if (this.get('_next')) {
          this.set('nextData', this.get('_next').format(this.formatMomentString));
        } else {
          this.set('nextData', null);
        }

        if (this.get('_current')) {
          this.set('currentData', this.get('_current').format(this.formatMomentString));
        } else {
          this.set('currentData', null);
        }

        this.sendAction('onChange', this.get('_current'));
      }
    }
  });
});