define('deputee-office/routes/omsu-agenda-pomopk/passport', ['exports', 'deputee-office/routes/base-agenda-route/base-agenda-passport-components'], function (exports, _deputeeOfficeRoutesBaseAgendaRouteBaseAgendaPassportComponents) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRouteBaseAgendaPassportComponents['default'].extend({
    passportNavigate: 'omsu-agenda-pomopk.passport',
    relationshipsPath: 'legal-acts.passport',

    getPassportQuery: function getPassportQuery() {
      return this.store.queryRecord('pomopk-passport', {
        questionId: this.get('params.question_id')
      });
    },
    PassportHeaderQuery: function PassportHeaderQuery(passport) {
      return this.store.queryRecord('omsu-passport/omsu-passport-header', {
        id: passport.get('PassportId'),
        TerritoryId: this.get('territoryService').getPkTerritory(),
        projection: 'omsu-passport-header-full'
      });
    },
    getPassportRelationships: function functionName(passport) {
      return this.store.queryRecord('omsu-passport/omsu-passport-relationship', {
        id: passport.get('PassportId'),
        projection: 'omsu-passport-relationship-full',
        TerritoryId: this.get('territoryService').getPkTerritory()
      });
    },

    getQuestionHistory: function getQuestionHistory(passport) {
      return this.store.query('omsu-passport/omsu-question-history', {
        QuestionId: passport.get('PassportId'),
        TerritoryId: this.get('territoryService').getPkTerritory()
      });
    },

    getVotingResult: function getVotingResult(passport) {
      var id = passport.get('id');

      return this.store.query('omsu-passport/omsu-passport-voting-result', {
        PassportId: id,
        projection: 'omsu-passport-voting-result-full',
        TerritoryId: this.get('territoryService').getPkTerritory()
      });
    }
  });
});