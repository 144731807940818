define('deputee-office/controllers/modals/note', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    notificationService: _ember['default'].inject.service(),
    networkstatus: _ember['default'].inject.service(),
    actions: {
      save: function save(modalDlg) {

        if (!this.get('networkstatus').isInternetConnection() && this.model.get('hasDirtyAttributes')) {
          this.get('notificationService').alert('Для сохранения заметки необходимо подключение к сети интернет.', null, 'Внимание!');
          return;
        }

        modalDlg.$('.modal').modal('hide');
        this.get('target').send('saveModel', this.model);
      },
      closeModal: function closeModal() {
        this.get('target').send('removeNoteModal');
        this.model.rollbackAttributes();
        if (this.model.get('isNew') && !this.model.get('isSaving')) {
          this.model.deleteRecord();
        }
      }
    }
  });
});