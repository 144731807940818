define('deputee-office/routes/laws/passport/index', ['exports', 'deputee-office/routes/base-agenda-route/passport/index'], function (exports, _deputeeOfficeRoutesBaseAgendaRoutePassportIndex) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoutePassportIndex['default'].extend({
    parentControllerName: 'laws.passport',
    templateName: 'laws.header',
    notesRouteName: 'laws.passport.notes',
    detailsRouteName: 'laws.passport.stage-details',
    withoutNavButtons: true,
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('withoutNavButtons', this.get('withoutNavButtons'));
    },
    resetController: function resetController(controller) {
      this._super.apply(this, arguments);
      controller.set('withoutNavButtons', undefined);
    }
  });
});