define('deputee-office/adapters/phonecontact-offline', ['exports', 'deputee-office/adapters/application-offline', 'ember-flexberry-data'], function (exports, _deputeeOfficeAdaptersApplicationOffline, _emberFlexberryData) {
  exports['default'] = _deputeeOfficeAdaptersApplicationOffline['default'].extend({
    query: function query(store, modelName, _query) {
      var filter = _query.filter;
      var page = _query.page;
      var perPage = _query.per_page;

      var queryInstance = _query instanceof _emberFlexberryData.Query.QueryObject;
      if (!queryInstance) {
        _query = {};
      }

      var visibleDepsId = this.store.findAll('phonedepartment').then(function (deps) {
        return deps.filter(function (record) {
          if (record.IsHidden) {
            return false;
          }
        }).map(function (record) {
          return record.id;
        });
      });

      return this._super(store, modelName, _query).then(function (results) {
        results = results.data;
        if (filter) {
          results = results.filter(function (record) {
            if (visibleDepsId.indexOf(record.departmentid) === -1) {
              return false;
            }

            return !filter || record.departmentName && record.departmentName.indexOf(filter) !== -1 || record.Post && record.Post.indexOf(filter) !== -1 || record.Name && record.Name.indexOf(filter) !== -1;
          });
        }

        // Оффлайн-пейджинг через slice.
        if (perPage && perPage !== 0) {
          var fromIndex = (page - 1) * perPage;
          var toIndex = page * perPage;
          results = results.slice(fromIndex, toIndex);
        }

        return { data: results };
      });
    }
  });
});