define('deputee-office/components/middle-button-mouse-clickable', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    'default': false,

    mouseUp: function mouseUp(e) {
      if (e.which === 2) {
        if (!this.get('default')) {
          this.sendAction('action', this.get('data'));
          return false;
        } else {
          var target = e.target || e.srcElement;
          var href = target.getAttribute('href') || window.location.pathname;
          window.open(href, '_system');
          return false;
        }
      }

      return true;
    }
  });
});