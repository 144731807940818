define('deputee-office/mixins/right-menu-collapser', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    rightMenu: function rightMenu(closeOnly) {
      var sidebar = _ember['default'].$('[data-sidebar]#rightMenu');
      var overlay = _ember['default'].$('[data-sidebar-overlay]#rightMenu');
      if (overlay.is(':visible')) {
        sidebar.css('right', sidebar.width() * -1 + 'px');
        overlay.fadeTo('500', 0, function () {
          overlay.hide();
        });
      } else {
        if (!closeOnly) {
          sidebar.css('right', '0px');

          overlay.show(0, function () {
            overlay.fadeTo('500', 0.5);
          });
        }
      }
    }
  });
});