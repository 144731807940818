define('deputee-office/components/favorites/favorites-k-s-p-materials', ['exports', 'ember', 'deputee-office/mixins/favorites-component-base'], function (exports, _ember, _deputeeOfficeMixinsFavoritesComponentBase) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsFavoritesComponentBase['default'], {
    store: _ember['default'].inject.service(),

    loadableModels: ['favorite', 'material-ksp'],

    init: function init() {
      this._super();
      this.set('model', _ember['default'].A([]));
      this.set('displayedModel', _ember['default'].A([]));
    },

    grouped: _ember['default'].computed('displayedModel.[]', function () {

      var materials = this.get('displayedModel');
      if (materials) {
        var _ret = (function () {
          var result = _ember['default'].A([]);
          var idx = [];
          materials.toArray().sortByProperty('OrderNum', 'asc').forEach(function (material) {
            var year = material.get('Date');
            var materialType = material.get('MaterialType');

            if (!result.isAny('type', materialType)) {
              idx[materialType + '_'] = _ember['default'].A([]);
              result.pushObject({ type: materialType, years: idx[materialType + '_'] });
            }

            if (!idx[materialType + '_'].isAny('date', year)) {
              idx[materialType + '_' + year + '_'] = _ember['default'].A([]);
              idx[materialType + '_'].pushObject({ date: year, items: idx[materialType + '_' + year + '_'] });
            }

            idx[materialType + '_' + year + '_'].pushObject(material);
          });
          return {
            v: result.sort(function (a, b) {
              return b.date - a.date;
            })
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
    }),

    loadData: function loadData() {
      var store = this.get('store');
      var model = this.get('model');

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        store.query('favorite', { Model: 'material-ksp' }).then(function (favorites) {

          var ids = favorites.map(function (favorite) {
            return JSON.parse(favorite.get('Params')).id;
          });

          store.findAll('material-ksp').then(function (materials) {
            materials.forEach(function (material) {
              var id = material.get('id');

              if (ids.indexOf(id) !== -1) {
                model.pushObject(material);
              }
            });

            resolve();
          })['catch'](function (e) {
            reject();
            console.error(e);
          });
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'favorites-k-s-p-materials: loadData');
    },

    filterModel: function filterModel() {
      var model = this.get('model');
      var displayedModel = this.get('displayedModel');
      var filterText = this.get('searchString');

      displayedModel.clear();

      if (filterText && filterText.value) {
        model.forEach(function (material) {
          if (material.get('Title').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1 || material.get('MaterialType').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1) {
            displayedModel.pushObject(material);
          }
        });
      } else {
        displayedModel.pushObjects(model);
      }
    }

  });
});