define('deputee-office/controllers/settings', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    currentuserservice: _ember['default'].inject.service(),

    version: _ember['default'].computed(function () {
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        document.addEventListener('deviceready', function functionName() {
          window.cordova.getAppVersion.getVersionNumber(function (currentVer) {
            resolve(currentVer);
          }, function functionName() {
            reject();
          });
        });
      }, 'version');
    }),

    groupedServices: _ember['default'].computed('model.[]', function () {
      var result = _ember['default'].A([]);
      var idx = [];
      var isUserFromZS = this.get('currentuserservice').isUserFromZS();
      var isGuest = this.get('currentuserservice').get('isGuest');
      var isUserFromMP = this.get('currentuserservice').get('department') === 'Молодежный парламент';

      // Инициализируем известные подсистемы для соблюдения порядка.

      var zspkId = 'Мурманская областная Дума';
      idx[zspkId] = _ember['default'].A([]);
      var omsuId = 'ОМСУ';
      idx[omsuId] = _ember['default'].A([]);
      var mpId = 'Молодежный парламент';
      idx[mpId] = _ember['default'].A([]);

      if (!isUserFromZS && !isGuest) {
        result.pushObject({ name: omsuId, items: idx[omsuId] });
        result.pushObject({ name: zspkId, items: idx[zspkId] });
        result.pushObject({ name: mpId, items: idx[mpId] });
      } else if (isUserFromMP) {
        result.pushObject({ name: mpId, items: idx[mpId] });
        result.pushObject({ name: zspkId, items: idx[zspkId] });
        result.pushObject({ name: omsuId, items: idx[omsuId] });
      } else {
        result.pushObject({ name: zspkId, items: idx[zspkId] });
        result.pushObject({ name: omsuId, items: idx[omsuId] });
        result.pushObject({ name: mpId, items: idx[mpId] });
      }

      this.get('model').forEach(function (record) {
        var _type = record.get('SubsystemTitle');
        if (!result.isAny('name', _type)) {
          idx[_type] = _ember['default'].A([]);
          result.pushObject({ name: _type, items: idx[_type] });
        }

        idx[_type].pushObject(record);
      });
      result.forEach(function (item) {
        item.items.sort(function (a, b) {
          if (a.get('OrderNum') > b.get('OrderNum')) {
            return 1;
          }

          if (a.get('OrderNum') < b.get('OrderNum')) {
            return -1;
          }

          return 0;
        });
      });
      return result;
    })
  });
});