define('deputee-office/controllers/modals/omsuactivitytype-modal', ['exports', 'ember', 'deputee-office/mixins/loader/work-plan'], function (exports, _ember, _deputeeOfficeMixinsLoaderWorkPlan) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsLoaderWorkPlan['default'], {
    territoryService: _ember['default'].inject.service(),
    currentuserservice: _ember['default'].inject.service(),

    actions: {

      initModel: _ember['default'].on('init', function () {
        var _this2 = this;

        try {
          (function () {
            _this2._super();

            var transition = _this2.get('model').transition;
            var _this = _this2;
            _this2.loadWorkPlans().then(function () {
              _this.set('redirect', false);
              _this.set('transition', transition);
            });
          })();
        } catch (e) {
          console.error(e);
          this.get('target').send('removeModal');
        }
      }),

      closeModal: function closeModal() {
        this.set('omsuActivityTypes', null);
        if (!this.get('redirect')) {
          this.get('target').send('removeModal');
        }
      },

      selectActivityType: function selectActivityType(activityType) {
        var transition = this.get('transition');
        this.set('redirect', true);
        this.get('target').send('selectActivityType', activityType.get('id'), transition);
      }
    }
  });
});