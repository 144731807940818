define('deputee-office/models/zs-passport/zs-passport-agenda-member', ['exports', 'ember-data', 'deputee-office/models/base', 'ember-flexberry-data'], function (exports, _emberData, _deputeeOfficeModelsBase, _emberFlexberryData) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Name: _emberData['default'].attr('string'),
    Role: _emberData['default'].attr('string'),
    AgendaId: _emberData['default'].attr('string'),
    Missing: _emberData['default'].hasMany('zs-passport/zs-passport-agenda-period', { async: false }),
    DepartmentName: 'Мурамаская областная Дума'
  });

  Model.defineProjection('zs-passport-agenda-member-full', 'zs-passport/zs-passport-agenda-member', {
    Name: _emberFlexberryData.Projection.attr('Name'),
    Role: _emberFlexberryData.Projection.attr('Role'),
    AgendaId: _emberFlexberryData.Projection.attr('AgendaId'),
    Missing: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-agenda-period', 'Missing', {})
  });

  exports['default'] = Model;
});