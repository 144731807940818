define('deputee-office/models/omsu-passport/omsu-passport-document', ['exports', 'ember-data', 'deputee-office/models/base-passport-document', 'ember-flexberry-data', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBasePassportDocument, _emberFlexberryData, _ember, _moment) {

  var Model = _deputeeOfficeModelsBasePassportDocument['default'].extend({
    Date: _emberData['default'].attr('date'),

    TerritoryId: _emberData['default'].attr('guid'),
    VoteResult: _emberData['default'].attr('string'),
    DecisionType: _emberData['default'].attr('string'),
    EventName: _emberData['default'].attr('string'),
    EventId: _emberData['default'].attr('string'),
    QuestionName: _emberData['default'].attr('string'),
    DeadlineChenges: _emberData['default'].attr('date'),
    ProtocolFile: _emberData['default'].belongsTo('zs-passport/zs-passport-file', { async: false }),
    Corrections: _emberData['default'].hasMany('omsu-passport/omsu-passport-correction', { async: false }),

    CanonicalAgendaName: _ember['default'].computed('EventName', function () {
      return this.get('EventName');
    }),
    CanonicalAgendaId: _ember['default'].computed('EventId', function () {
      return this.get('EventId');
    }),
    CanonicalQuestionName: _ember['default'].computed('QuestionName', function () {
      return this.get('QuestionName');
    }),
    CanonicalQuestionId: _ember['default'].computed('QuestionId', function () {
      return this.get('QuestionId');
    }),

    DisplayedDate: _ember['default'].computed('Date', function () {
      if (this.get('Date') && this.get('Date').getTime() > 0) {
        return (0, _moment['default'])('' + this.get('Date')).format('D MMMM YYYY года');
      }
    }),
    DisplayedDeadlineChenges: _ember['default'].computed('DeadlineChenges', function () {
      if (this.get('DeadlineChenges') && this.get('DeadlineChenges').getTime() > 0) {
        return (0, _moment['default'])('' + this.get('DeadlineChenges')).format('D MMMM YYYY года');
      }
    })
  });

  Model.defineProjection('omsu-passport-document-full', 'omsu-passport/zs-passport-document', {
    Type: _emberFlexberryData.Projection.attr('Type'),
    Title: _emberFlexberryData.Projection.attr('Title'),
    PassportId: _emberFlexberryData.Projection.attr('PassportId'),
    QuestionId: _emberFlexberryData.Projection.attr('QuestionId'),
    Date: _emberFlexberryData.Projection.attr('Date'),
    OrderNum: _emberFlexberryData.Projection.attr('OrderNum'),
    TabName: _emberFlexberryData.Projection.attr('TabName'),
    TypeName: _emberFlexberryData.Projection.attr('TypeName'),
    VoteResult: _emberFlexberryData.Projection.attr('VoteResult'),
    DecisionType: _emberFlexberryData.Projection.attr('DecisionType'),
    EventName: _emberFlexberryData.Projection.attr('EventName'),
    EventId: _emberFlexberryData.Projection.attr('EventId'),
    QuestionName: _emberFlexberryData.Projection.attr('QuestionName'),
    DeadlineChenges: _emberFlexberryData.Projection.attr('DeadlineChenges'),
    Files: _emberFlexberryData.Projection.hasMany('zs-passport/zs-passport-file', 'Files', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      Url: _emberFlexberryData.Projection.attr('Url'),
      OrderNum: _emberFlexberryData.Projection.attr('OrderNum')
    }),
    Corrections: _emberFlexberryData.Projection.hasMany('omsu-passport/omsu-passport-correction', 'Corrections', {
      Num: _emberFlexberryData.Projection.attr('Num'),
      Location: _emberFlexberryData.Projection.attr('Location'),
      Text: _emberFlexberryData.Projection.attr('Text'),
      Initiator: _emberFlexberryData.Projection.attr('Initiator'),
      WorkGroupDesicion: _emberFlexberryData.Projection.attr('WorkGroupDesicion'),
      OmsuDesicion: _emberFlexberryData.Projection.attr('OmsuDesicion'),
      FromConclision: _emberFlexberryData.Projection.attr('FromConclision'),
      VoteResult: _emberFlexberryData.Projection.attr('VoteResult')
    }),
    ProtocolFile: _emberFlexberryData.Projection.belongsTo('zs-passport/zs-passport-file', 'ProtocolFile', {
      Name: _emberFlexberryData.Projection.attr('Name'),
      Url: _emberFlexberryData.Projection.attr('Url'),
      OrderNum: _emberFlexberryData.Projection.attr('OrderNum')
    })
  });

  exports['default'] = Model;
});