define('deputee-office/models/yp-department', ['exports', 'ember-data', 'deputee-office/models/base', 'deputee-office/mixins/department-short-name'], function (exports, _emberData, _deputeeOfficeModelsBase, _deputeeOfficeMixinsDepartmentShortName) {

  var Model = _deputeeOfficeModelsBase['default'].extend(_deputeeOfficeMixinsDepartmentShortName['default'], {
    Name: _emberData['default'].attr('string'),
    NameGenitive: _emberData['default'].attr('string'),
    Type: _emberData['default'].attr('string'),
    IntId: _emberData['default'].attr('number')
  });

  exports['default'] = Model;
});