define('deputee-office/controllers/gos-programs', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    viewMode: 'list',
    viewModeToggleIcon: 'assets/img/decreeviewtiles.png',
    searchPlaceholder: 'Поиск по программам',

    modelObserver: _ember['default'].observer('model', function () {
      var programms = this.get('model').toArray().sortByProperty('OrderNum', 'asc');
      this.set('programms', programms);
    }),

    didInsertElement: function didInsertElement() {
      /* iPad/iPhone hover problem causes the user to double click a link */
      this.$('a').on('click touchend', function () {
        var el = _ember['default'].$(this);
        var link = el.attr('href');
        window.location = link;
      });
    },

    actions: {
      toggleViewMode: function toggleViewMode() {
        var currentMode = this.get('viewMode');
        if (currentMode === 'list') {
          this.set('viewMode', 'tiles');
          this.set('viewModeToggleIcon', 'assets/img/decreeviewlist.png');
        } else {
          this.set('viewMode', 'list');
          this.set('viewModeToggleIcon', 'assets/img/decreeviewtiles.png');
        }
      },

      search: function search(text) {
        var programms = this.get('model').filter(function (programm) {
          return programm.get('Title').toLowerCase().indexOf(text.toLowerCase()) !== -1;
        });
        this.set('programms', programms);
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      }
    }
  });
});