define('deputee-office/controllers/modals/department-modal', ['exports', 'ember', 'deputee-office/mixins/loader/department'], function (exports, _ember, _deputeeOfficeMixinsLoaderDepartment) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsLoaderDepartment['default'], {
    territoryService: _ember['default'].inject.service(),
    currentuserservice: _ember['default'].inject.service(),
    actions: {
      initModel: _ember['default'].on('init', function () {
        var _this = this;

        try {
          (function () {
            _this._super();
            var transition = _this.get('model').transition;
            _this.set('transition', transition);
            _this.loadDepartments(transition.targetName).then(function () {
              _this.set('redirect', false);
              _this.set('transition', transition);
            });
          })();
        } catch (e) {
          console.error(e);

          this.get('target').send('removeModal');
        }
      }),

      closeModal: function closeModal() {
        this.set('departments', null);

        if (!this.get('redirect')) {
          this.get('target').send('removeModal');
        }
      },

      selectDepartment: function selectDepartment(department) {

        var transition = this.get('transition');

        this.set('redirect', true);
        this.get('target').send('selectDepartmentForTransition', department.get('id'), transition);
      }
    }
  });
});