define('deputee-office/app', ['exports', 'ember', 'deputee-office/resolver', 'ember-load-initializers', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeResolver, _emberLoadInitializers, _deputeeOfficeConfigEnvironment) {

  var App;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _deputeeOfficeConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _deputeeOfficeConfigEnvironment['default'].podModulePrefix,
    Resolver: _deputeeOfficeResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _deputeeOfficeConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});