define('deputee-office/helpers/order-by', ['exports', 'ember'], function (exports, _ember) {
  exports.orderBy = orderBy;

  function orderBy(params) {
    if (params[0]) {
      return params[0].sortBy(params[1]);
    }
  }

  exports['default'] = _ember['default'].Helper.helper(orderBy);
});