define('deputee-office/components/agenda-select-popover', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {

  var statuses = {
    active: 'Активные',
    archive: 'Архив'
  };

  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    popoverId: 'popoverAgendaId',

    archiveFilter: true,

    selectedStatusChanged: _ember['default'].observer('selectedStatus', function () {
      this.send('updateVisibleAgendas');
    }),

    didInsertElement: function didInsertElement() {
      this.set('statuses', statuses);
      this.send('updateVisibleAgendas');
      _ember['default'].$('#superpuper').perfectScrollbar();
    },

    initAgendas: _ember['default'].observer('agendas', function () {
      this.send('updateVisibleAgendas');
    }),

    actions: {

      selectStatus: function selectStatus(st, event) {
        var q = _ember['default'].$(event.target);
        q.parents("div[name='status']").first().find('input').prop('checked', true);
        this.set('selectedStatus', st);
      },

      selectAgenda: function selectAgenda(agenda) {
        _ember['default'].$('#' + agenda.id).find('input').prop('checked', true);
        this.sendAction('onSelect', agenda);
        _ember['default'].$('#' + this.get('popoverId')).webuiPopover('hide');
        _ember['default'].$('.scrollable-div').trigger('updatescroll');
      },

      changeDateActionS: function changeDateActionS(date) {
        this.set('startDate', date);
        this.send('updateVisibleAgendas');
      },

      changeDateActionPo: function changeDateActionPo(date) {
        this.set('endDate', date);
        this.send('updateVisibleAgendas');
      },

      updateVisibleAgendas: function updateVisibleAgendas() {
        var agendas = this.get('agendas');
        var _this = this;
        var selectedStatus = this.get('selectedStatus');
        if (agendas) {
          var visibleAgendas = agendas.filter(function (agenda) {
            var startDate;
            var endDate;
            if (_this.get('startDate')) {
              startDate = (0, _moment['default'])(_this.get('startDate'));
            } else {
              startDate = (0, _moment['default'])(agenda.get('Date'));
            }

            if (_this.get('endDate')) {
              endDate = (0, _moment['default'])(_this.get('endDate')).add(1, 'months').subtract(1, 'days');
            } else {
              endDate = (0, _moment['default'])(agenda.get('Date'));
            }

            var agendaDate = (0, _moment['default'])(agenda.get('Date'));
            return agendaDate.isBetween(startDate, endDate, null, '[]');
          }).filter(function (agenda) {
            var status = agenda.get('Status');

            // Условие фильтрации по состоянию (статусу) повестки.
            // Фильтрация из двух пунктов - 'Архив' и 'Активные'.
            // Активными считаются все повестки, у которых состояние != 'Архив'.
            var statusCondition = !status || status === statuses.archive && selectedStatus === statuses.archive || selectedStatus !== statuses.archive && status !== statuses.archive;
            return statusCondition;
          });

          this.set('visibleAgendas', visibleAgendas);
        }
      }
    }
  });
});