define('deputee-office/components/agenda-filter-popover', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    popoverId: 'popoverFilterAgendaId',
    filterIcon: _ember['default'].computed('selectedStatus', 'selectedCommittee', 'selectedReading', function () {
      var ss = this.get('selectedStatus') ? this.get('selectedStatus') : 'Все';
      var sc = this.get('selectedCommittee') ? this.get('selectedCommittee') : 'Все';
      var sr = this.get('selectedReading') ? this.get('selectedReading') : 'Все';
      if (ss !== 'Все' || sc !== 'Все' || sr.Name !== 'Все' && this.get('readings.length') > 1) {
        return 'glyphicon icon-filter-selected';
      }

      return 'glyphicon icon-filter';
    }),
    sendFilterAction: function sendFilterAction() {
      var filter = this.get('filterModel').filter;
      filter.status = this.get('selectedStatus');
      filter.reading = this.get('selectedReading');
      filter.committee = this.get('selectedCommittee');
      this.sendAction('onFilter', filter);
    },

    filterPropertyChanged: _ember['default'].observer('selectedStatus', 'selectedCommittee', 'selectedReading', function () {
      _ember['default'].run.once(this, 'sendFilterAction');
    }),

    initModel: _ember['default'].observer('filterModel', function () {
      if (this.get('filterModel')) {
        var filter = this.get('filterModel').filter;
        if (!filter) {
          return;
        }

        var readings = this.get('filterModel').readings;
        var committees = this.get('filterModel').committees;
        var statuses = this.get('filterModel').statuses;
        var counts = this.get('filterModel').counts;

        this.set('statuses', statuses);
        this.set('readings', readings);
        this.set('committees', committees);
        this.set('counts', counts);

        this.setProperties({
          selectedCommittee: filter.committee,
          selectedReading: filter.reading,
          selectedStatus: filter.status
        });
      }
    }).on('didRender'),

    actions: {

      changeStatus: function changeStatus(newstatus) {
        this.set('selectedStatus', newstatus);
      },

      changeReading: function changeReading(newreading) {
        this.set('selectedReading', newreading);
      },

      changeCommittee: function changeCommittee(newcommittee) {
        this.set('selectedCommittee', newcommittee);
      }

    }
  });
});