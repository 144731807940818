define('deputee-office/components/tree/tree-node', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['list-group-item'],
    tagName: 'li',
    expanded: false,
    actions: {
      expand: function functionName() {
        if (this.get('model.children')) {
          this.set('expanded', !this.get('expanded'));
        }
      },
      openResource: function openResource(resource) {
        this.sendAction('openResource', resource);
      }
    }
  });
});