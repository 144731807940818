define('deputee-office/initializers/network-setting', ['exports', 'deputee-office/objects/network-setting'], function (exports, _deputeeOfficeObjectsNetworkSetting) {
  exports.initialize = initialize;

  function initialize(application) {
    application.register('object:network-setting', _deputeeOfficeObjectsNetworkSetting['default']);
  }

  exports['default'] = {
    name: 'network-setting',
    initialize: initialize
  };
});