define('deputee-office/routes/base-agenda-route/passport/index', ['exports', 'ember', 'deputee-office/utils/handler-infos'], function (exports, _ember, _deputeeOfficeUtilsHandlerInfos) {
  exports['default'] = _ember['default'].Route.extend({

    queryParams: {
      passportState: {
        refreshModel: false,
        replace: false
      },
      passportStateParam: {
        refreshModel: false,
        replace: false
      }
    },

    fileService: _ember['default'].inject.service(),
    currentuserservice: _ember['default'].inject.service(),

    templateName: 'base-agenda-route.header',
    controllerName: 'base-agenda-route.passport.index',
    parentControllerObserver: _ember['default'].observer('parentController.model', function () {
      this.get('controller').set('passport', this.get('parentController.model'));
    }),

    PassportHeaderObserver: _ember['default'].observer('parentController.model.PassportHeader', function () {
      this.get('controller').set('noteAllowed', (this.get('passportExist') || this.get('questionExist')) && this.get('currentuserservice').isUserFromZS());
    }),

    passportExist: _ember['default'].computed('parentController.model.PassportHeader', function () {
      return this.get('parentController.model.PassportHeader.id');
    }),

    questionExist: _ember['default'].computed('parentController.model', function () {
      return this.get('parentController.model.id');
    }),

    IsCommentAvailable: _ember['default'].computed('parentController.model', function () {
      return this.get('parentController.model.IsCommentAvailable');
    }),

    QuestionId: _ember['default'].computed('parentController.model', function () {
      return this.paramsFor(this.get('parentControllerName')).question_id;
    }),

    StageOver: _ember['default'].computed('baseController.currentAgenda.StageOver', function () {
      return this.get('baseController.currentAgenda.StageOver');
    }),

    AgendaStatus: _ember['default'].computed('baseController.currentAgenda.Status', function () {
      return this.get('baseController.currentAgenda.Status');
    }),

    AgendaState: _ember['default'].computed('baseController.currentAgenda.StageName', function () {
      return this.get('baseController.currentAgenda.StageName');
    }),

    AgendaStateObserver: _ember['default'].observer('baseController.currentAgenda.StageName', 'baseController.currentAgenda.StageOver', 'baseController.currentAgenda.Status', function () {
      this.get('controller').set('AgendaState', this.get('AgendaState'));
      this.get('controller').set('StageOver', this.get('StageOver'));
      this.get('controller').set('AgendaStatus', this.get('AgendaStatus'));
      if ((0, _deputeeOfficeUtilsHandlerInfos['default'])(this).some(function (x) {
        return x.name.includes('passport');
      })) {
        this.send('updatePassport');
      }
    }),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var owner = _ember['default'].getOwner(this);
      var parentController = owner.lookup('controller:' + this.get('parentControllerName'));
      this.set('controller', controller);
      this.set('parentController', parentController);
      controller.set('detailsRoute', this.get('detailsRouteName'));
      controller.set('notesRoute', this.get('notesRouteName'));
      controller.set('baseControllerName', this.get('baseControllerName'));
      var baseController = _ember['default'].getOwner(this).lookup('controller:' + this.get('baseControllerName'));
      this.set('baseController', baseController);
      controller.set('parentController', parentController);
    },

    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('passportState', null);
        controller.set('passportStateParam', null);
      }
    },

    activate: function activate() {
      var device = this.get('devicejs');
      if (!device.isPhone()) {
        _ember['default'].$('body').addClass('whiteBckgrnd');
      }
    },
    deactivate: function deactivate() {
      var device = this.get('devicejs');
      if (!device.isPhone()) {
        _ember['default'].$('body').removeClass('whiteBckgrnd');
      }
    },
    actions: {
      goToState: function goToState(passportState, param) {
        var queryParams = {
          passportState: passportState
        };
        if (param) {
          queryParams.passportStateParam = param;
        }

        this.transitionTo(this.routeName, {
          queryParams: queryParams
        });
      },

      goBack: function goBack() {
        if (history.length > 1 && history.back() !== undefined) {
          history.back();
        } else {
          this.replaceWith(this.routeName.split('.')[0], { queryParams: this.paramsFor });
        }
      },

      showVideo: function showVideo(url) {
        var platform = this.get('fileService.platform');
        if (platform === 'android' || platform === 'ios') {
          window.cordova.InAppBrowser.open(url, '_blank', 'location=no,enableViewportScale=yes,closebuttoncaption=Закрыть');
        } else {
          this.render('modals/video', {
            into: 'application',
            outlet: 'modal',
            model: url
          });
        }
      }
    }
  });
});