define('deputee-office/models/base-question', ['exports', 'ember-data', 'deputee-office/models/base', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember) {
  exports['default'] = _deputeeOfficeModelsBase['default'].extend({
    IntId: _emberData['default'].attr('number'),
    Title: _emberData['default'].attr('string'),
    Num: _emberData['default'].attr('string'),
    Type: _emberData['default'].attr('string'),
    Reading: _emberData['default'].attr('string'),
    Decision: _emberData['default'].attr('string'),
    Speaker: _emberData['default'].attr('string'),
    DateTimeBegin: _emberData['default'].attr('string'),
    DateTimeEnd: _emberData['default'].attr('string'),
    CoSpeaker: _emberData['default'].attr('string'),
    UnreadFileCount: _emberData['default'].attr('number'),
    Status: _emberData['default'].attr('string'),
    AgendaId: _emberData['default'].attr('guid'),
    OrderNum: _emberData['default'].attr('number'),
    RegNumber: _emberData['default'].attr('string'),
    HasUnreadFiles: _ember['default'].computed('UnreadFileCount', function () {
      return this.get('UnreadFileCount') !== 0;
    })
  });
});