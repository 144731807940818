define('deputee-office/controllers/base-agenda-route', ['exports', 'ember', 'deputee-office/mixins/members-info', 'deputee-office/mixins/group-by'], function (exports, _ember, _deputeeOfficeMixinsMembersInfo, _deputeeOfficeMixinsGroupBy) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsMembersInfo['default'], _deputeeOfficeMixinsGroupBy['default'], {
    settingsService: _ember['default'].inject.service('settingsservice'),
    currentQuestions: _ember['default'].inject.service('current-question-service'),
    currentQuestionsModel: _ember['default'].computed.alias('currentQuestions.model'),
    currentQuestion: null,
    currentPosition: 0,
    readingsAll: {
      Name: 'Все',
      IntValue: 0
    },
    hiddenQuestions: {},
    committeesAll: 'Все',
    statusAll: 'Все',
    statusApproved: 'Утвержден',
    statuses: _ember['default'].computed('statusAll', 'statusApproved', function () {
      return [this.get('statusAll'), this.get('statusApproved'), 'Дополнительный'];
    }),

    currentAgenda: null,
    statusFilter: false,
    emptyFilterResult: false,

    queryParams: ['agendaSubState'],
    agendaSubState: null,

    defaultAgendaSubStateName: 'base-agenda-route/index/index',
    defaultWithDepStateName: 'base-agenda-route/index/index-with-departments',
    agendaSubStateName: null,

    filterIcon: 'glyphicon icon-filter',

    changeFilterIcon: function changeFilterIcon() {
      var ss = this.get('filter.status') ? this.get('filter.status') : 'Все';
      var sc = this.get('filter.committee') ? this.get('filter.committee') : 'Все';
      var sr = this.get('filter.reading') ? this.get('filter.reading') : 'Все';
      if (ss !== 'Все' || sc !== 'Все' || sr.Name !== 'Все' && this.get('filterModel.readings.length') > 1) {
        this.set('filterIcon', 'glyphicon icon-filter-selected');
      } else {
        this.set('filterIcon', 'glyphicon icon-filter');
      }
    },

    Members: _ember['default'].computed('currentAgenda.AgendaMembers.[]', function () {
      return this.get('currentAgenda.AgendaMembers');
    }),

    Periods: _ember['default'].computed('Members.periods', function () {
      return this.get('Members.periods');
    }),

    agendaSubStateChanged: _ember['default'].observer('agendaSubState', 'currentAgenda', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        switch (this.get('agendaSubState')) {
          case 'about':
            this.set('agendaSubStateName', 'base-agenda-route/index/about');
            break;
          case 'members':
            this.set('agendaSubStateName', 'base-agenda-route/index/members');
            break;
          default:
            this.set('agendaSubStateName', this.get('defaultAgendaSubStateName'));
        }
      });
    }),

    cqObserver: _ember['default'].observer('currentQuestionsModel', 'questions', function () {
      var questions = this.get('questions');
      var cqmodel = this.get('currentQuestionsModel');
      if (questions) {
        this.set('currentQuestion', questions.mapBy('id').find(function (item) {
          return cqmodel.mapBy('questionId').includes(item);
        }));
      }
    }),

    /**
     * Обзервер на вопросы.
     */
    dynamicFilter: _ember['default'].observer('questions', function () {
      var _this2 = this;

      var questions = this.get('questions');

      if (questions) {
        var readings = this.getReadings(questions);
        var committees = this.getCommittees(questions);
        var readingsForFilter = readings.length === 1 && !readings.get('firstObject') ? null : readings;
        var committeesForFilter = committees.length === 1 && !committees.get('firstObject') ? null : committees;

        var status = this.get('statusAll');

        var filter = {
          status: status,
          reading: readings.length > 1 ? this.get('readingsAll') : readings.get('firstObject'),
          committee: committees.length > 1 ? this.get('committeesAll') : committees.get('firstObject')
        };

        var statusCounts = {};

        this.get('statuses').forEach(function (status) {
          statusCounts[status] = _this2.get('questions').filter(function (x) {
            return _this2.statusFilterFunc(x.get('Status'), x.get('Type'), status);
          }).filter(function (x) {
            return x.get('Type') !== 'Dummy';
          }).length;
        });

        var filterModel = {
          filter: filter,
          statuses: this.get('statuses'),
          readings: readingsForFilter,
          committees: committeesForFilter,
          searchText: this.get('searchText'),
          counts: {
            statuses: statusCounts
          }
        };

        this.set('filterModel', filterModel);
      }
    }),

    /**
     * Получение списка чтений из вопросов.
     *
     * @param  {Object} вопросы повестки.
     * @return {Array} массив с чтениями.
     */
    getReadings: function getReadings(questions) {

      var _this = this;

      var readings = questions.map(function (question) {
        return question.get('Reading');
      }).uniq();

      var readingsWithValues = readings.map(function (reading) {
        return _this.getUnifiedReading(reading);
      }).filter(function (x) {
        return x;
      });

      //UniqBy
      readingsWithValues.forEach(function (item) {
        if (item && readingsWithValues.filterBy('IntValue', item.IntValue).length > 1) {
          readingsWithValues.removeObject(item);
        }
      });

      var result = readingsWithValues.sortBy('IntValue');

      if (readings.length > 1) {
        result.unshift(this.get('readingsAll'));
      }

      return result;
    },

    /**
     * Получение списка комитетов.
     *
     * @param  {Object} вопросы повестки.
     * @return {Array} массив с наименованиями комитетов.
     */
    getCommittees: function getCommittees(questions) {
      var committeeOptions = [];

      questions.forEach(function (item) {
        var committee = item.get('HeadCommittee');
        if (_ember['default'].$.inArray(committee, committeeOptions) === -1) {
          committeeOptions.push(committee);
        }
      });

      var result = committeeOptions.sort();

      if (result.length > 1) {
        result.unshift(this.get('committeesAll'));
      }

      return result;
    },

    getUnifiedReading: function getUnifiedReading(reading) {
      if (reading) {
        reading = reading.toString();
        return reading === '1' || reading === 'Первое' || reading === 'Первое чтение' || reading === 'Одно' ? { Name: 'Первое', IntValue: 1 } : reading === '2' || reading === 'Второе' || reading === 'Второе чтение' ? { Name: 'Второе', IntValue: 2 } : reading === '3' || reading === 'Третье' || reading === 'Третье чтение' ? { Name: 'Третье', IntValue: 3 } : reading === 'Повторное рассмотрение' ? { Name: 'Повторное', IntValue: 4 } : undefined;
      }
    },

    questionsComparsions: function questionsComparsions(DQ, FQ) {
      if (!DQ || !FQ) {
        return false;
      }

      if (DQ.length !== FQ.length) {
        return true;
      }

      for (var i = 0; i < DQ.length; i++) {
        if (DQ[i].id !== FQ[i].id) {
          return true;
        }
      }

      return false;
    },

    statusFilterFunc: function statusFilterFunc(questionStatus, questionType, filterStatus) {
      return filterStatus === this.get('statusAll') || filterStatus === this.get('statusApproved') && (questionType === 'Question' || questionType === 'Dummy') && questionStatus === this.get('statusApproved') || filterStatus === 'Дополнительный' && questionType === 'AddQuestion';
    },

    /**
     * Получить статус видимости обобщённого вопроса.
     * @param {string} ИД обобщённого вопроса.
     * @return {boolean} Статус видимости обобщённого вопроса.
     */
    getHiddenState: function getHiddenState(questionId) {
      var _this = this;

      if (_this.hiddenQuestions[questionId] == null) {
        _this.hiddenQuestions[questionId] = false;
      } else {
        _this.hiddenQuestions[questionId] = !_this.hiddenQuestions[questionId];
      }

      return _this.hiddenQuestions[questionId];
    },

    /**
     * Отфильтровать список вопросов по видимости связанных с обобщённым.
     * @param {Array} Список вопросов.
     * @param {string} ИД обобщённого вопроса.
     * @return {Array} Отфильтрованный список вопросов.
     */
    filteredChildQuestion: function filteredChildQuestion(questions, questionId) {
      var _this = this;
      var filteredQuestions = null;
      var currentQuestionParent = null;
      var currentRowIdParent = null;

      if (_this.currentQuestion != null) {
        var currentRow = questions.filter(function (item) {
          return item.get('id') === _this.currentQuestion;
        });
        currentRowIdParent = currentRow[0].get('IdParent');

        if (currentRowIdParent != null) {
          _this.hiddenQuestions[currentRowIdParent] = false;
        }
      }

      if (questions) {
        filteredQuestions = questions.filter(function (question) {
          var isVis = true;
          var id = question.get('id');
          var idParent = question.get('IdParent');
          var cntChild = question.get('ChildCount');

          if (idParent) {
            var state = _this.hiddenQuestions[idParent];
            isVis = state == null ? false : !state;

            // Если связанный вопрос текущий, показываем его и всех связанных с обобщённым.
            if (questionId == null) {
              if (_this.currentQuestion != null && _this.currentQuestion === id || currentQuestionParent != null && currentQuestionParent === idParent || _this.hiddenQuestions[idParent] === false) {
                isVis = true;
                _this.hiddenQuestions[idParent] = false;
                currentQuestionParent = idParent;
              } else {
                _this.hiddenQuestions[idParent] = true;
              }
            }
          } else if (questionId == null) {
            if (cntChild !== 0 && _this.currentQuestion != null && (_this.currentQuestion === id || id === currentRowIdParent)) {
              _this.hiddenQuestions[id] = false;
            }
          }

          return isVis;
        });

        // Установим состояние свернуть/развернуь связанные для всех обобщенных.
        for (var i in _this.hiddenQuestions) {
          _this.setQuestionExpand(filteredQuestions, i);
        }

        _this.set('displayedQuestions', filteredQuestions.sortBy('OrderNum'));
        _this.set('loading', false);
      }

      return filteredQuestions;
    },

    /**
     * Установить состояние свернуть/развернуь связанные для обобщённого вопроса.
     * @param {Array} Список вопросов.
     * @param {string} ИД обобщённого вопроса.
     */
    setQuestionExpand: function setQuestionExpand(filteredQuestions, questionId) {
      var stateParentQuestion = this.hiddenQuestions[questionId];
      var parentQuestion = filteredQuestions.find(function (x) {
        return x.get('id') === questionId;
      });

      if (parentQuestion) {
        parentQuestion.set('IsExpand', !stateParentQuestion);
      }
    },

    actions: {
      clearData: function clearData() {
        this.set('currentAgenda', null);
        this.set('department', null);
        this.set('questions', null);
        this.set('displayedQuestions', null);
        this.set('departmentType', undefined);
        this.set('filterModel', undefined);
        this.set('searchText', undefined);
        this.set('currentPosition', 0);
      },

      saveSPos: function saveSPos() {
        var SS = this.get('settingsService');
        var currentRoute = this.get('currentRouteName');
        var curId = SS.getSettings('servicePosition.' + currentRoute, false);
        if (curId !== undefined) {
          this.set('currentPosition', curId);
        }
      },

      saveGPos: function saveGPos(guid) {
        var SS = this.get('settingsService');
        var currentRoute = this.get('currentRouteName');
        SS.putSettings('servicePosition.' + currentRoute, null, guid);
      },
      /**
       * Фильтрация списка вопросов.
       *
       * @param  {Object} filter       Объект, содержащий значения фильтров.
       * @return {undefined}
       */
      filterQuestions: function filterQuestions(filter) {

        this.set('filter', filter);
        this.changeFilterIcon();
        var questions = this.get('questions');
        var _this = this;

        if (questions) {
          var filteredQuestions = questions.filter(function (question) {
            var reading = _this.getUnifiedReading(question.get('Reading'));
            var readingName = reading ? reading.Name : '';
            var name = typeof question.get('Title') === 'string' ? question.get('Title').toLowerCase() : '';
            var regNumber = question.get('RegNumber');
            var searchText = _this.get('searchText');
            var headCommittee = question.get('HeadCommittee');
            var status = question.get('Status');
            var type = question.get('Type');

            var readingCondition = filter.reading ? filter.reading.Name === _this.get('readingsAll').Name || readingName === filter.reading.Name || filter.reading.IntValue === 1 && question.get('RegNumber') && question.get('Reading') === 'Пусто' : true;

            var nameCondition = !searchText || name.indexOf(searchText.toLowerCase()) > -1;

            var regNumberCondition = !searchText || (regNumber ? regNumber.indexOf(searchText.toLowerCase()) > -1 : false);

            var headCommitteeCondition = filter.committee === _this.get('committeesAll') || headCommittee === filter.committee;

            var statusCondition = _this.statusFilterFunc(status, type, filter.status);

            return readingCondition && (nameCondition || regNumberCondition) && headCommitteeCondition && statusCondition;
          });

          filteredQuestions = _this.filteredChildQuestion(filteredQuestions);

          if (this.questionsComparsions(this.get('displayedQuestions'), filteredQuestions.sortBy('OrderNum'))) {
            _ember['default'].$('.scrollable-div').trigger('updatescroll');
            this.set('currentPosition', 0);
          }

          _this.set('displayedQuestions', filteredQuestions.sortBy('OrderNum'));

          this.set('emptyFilterResult', !filteredQuestions.length);

          this.set('loading', false);
        }
      },

      search: function search(text) {
        var filter = this.get('filter');
        this.set('searchText', text);
        this.send('filterQuestions', filter);
      },

      refreshAgenda: function refreshAgenda() {
        this.get('target').send('refreshAgenda');
      },

      /**
       * Свернуть/развернуь вопросы, связанные с обобщённым.
       * @param {string} ИД обобщённого вопроса.
       */
      toogleQuestion: function toogleQuestion(questionId) {
        var _this = this;
        _this.getHiddenState(questionId);
        var filteredQuestions = _this.filteredChildQuestion(this.get('questions'), questionId);

        if (filteredQuestions) {
          _this.set('displayedQuestions', filteredQuestions.sortBy('OrderNum'));
          this.set('loading', false);
        }
      }
    }
  });
});