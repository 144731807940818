define('deputee-office/routes/zs-agenda/passport', ['exports', 'deputee-office/routes/base-agenda-route/base-agenda-passport-components', 'ember'], function (exports, _deputeeOfficeRoutesBaseAgendaRouteBaseAgendaPassportComponents, _ember) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRouteBaseAgendaPassportComponents['default'].extend({
    passportNavigate: 'zs-agenda.passport',
    zsQuestion: true,
    agendaDeepObservationService: _ember['default'].inject.service(),

    init: function init() {
      var _this = this;

      this.get('agendaDeepObservationService').on('ppzUpdate', function (questionsId) {
        if (questionsId && questionsId.contains(_this.get('params.question_id')) && window.location.href.indexOf('file-view') < 0) {
          _this.refresh();
        }
      });
    },

    /**
     * Получение истории переименований паспорта.
     */
    getPassportHistoryQuery: function getPassportHistoryQuery(passport) {
      return this.store.query('zs-passport/zs-passport-law-history', {
        PassportId: passport.get('PassportId')
      });
    },

    /**
     * Получение связанных законопроектов (отменяемых/изменяемых/отменяющих/изменяющих).
     */
    getPassportRelationships: function functionName(passport) {
      return this.store.queryRecord('zs-passport/zs-passport-relationship', {
        id: passport.get('PassportId'),
        projection: 'zs-passport-relationship-full'
      });
    },

    /**
     * Получение паспорта вопроса.
     */
    getPassportQuery: function getPassportQuery() {
      return this.store.queryRecord('zs-passport', {
        id: this.get('params.question_id'),
        agendaId: this.get('queryParams.agendaId')
      });
    },

    PassportHeaderQuery: function PassportHeaderQuery(passport) {
      return this.store.queryRecord('zs-passport/zs-passport-header', {
        id: passport.get('PassportId'),
        projection: 'zs-passport-header-full'
      });
    },

    getVotingResult: function getVotingResult(passport) {
      return this.store.query('zs-passport/zs-voting-result', {
        QuestionId: passport.get('id'),
        projection: 'zs-voting-result-full'
      });
    }
  });
});