define('deputee-office/components/question-panel', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    settingsService: _ember['default'].inject.service('settingsservice'),
    agendaStateService: _ember['default'].inject.service('agenda-state'),
    zsQuestion: false,
    isDummy: false,
    classNames: ['question-panel'],
    classNameBindings: ['isDummy:dummyQuestion', 'isCurrent:current'],
    _isDummy: _ember['default'].on('init', _ember['default'].observer('question.Type', function () {
      var question = this.get('question');
      var type = question.get('Type');

      if (type === 'Dummy') {
        this.set('isDummy', true);
      }
    })),
    isCurrent: _ember['default'].computed('question', 'currentQuestion', function () {
      return this.get('question.id') === this.get('currentQuestion');
    }),
    isAfterGp: _ember['default'].computed('currentAgenda.StageName', 'currentAgenda.StageOver', function () {
      var ass = this.get('agendaStateService');
      return ass.isAfterGp(this.get('currentAgenda.StageName'), this.get('currentAgenda.StageOver'));
    }),
    actions: {
      saveServiceLastGuid: function saveServiceLastGuid(guid) {
        var SS = this.get('settingsService');
        var curRouteName = this.get('currentRouteName');
        SS.putSettings('servicePosition.' + curRouteName, null, guid);
      },
      toogleQuestion: function toogleQuestion(questionId) {
        this.sendAction('toogleQuestion', questionId);
      }
    }
  });
});