define('deputee-office/components/passport/passport-navigator', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['passportNavBar'],
    prevUrl: _ember['default'].computed('model', function () {
      var _this2 = this;

      var controller = _ember['default'].getOwner(this).lookup('controller:' + this.get('baseControllerName'));
      var displayedQuestions = controller.get('displayedQuestions');
      if (displayedQuestions) {
        var prev;
        displayedQuestions.filter(function (x) {
          return x.get('Type') !== 'Dummy';
        }).forEach(function (item, i, arr) {
          if (item.get('id') === _this2.get('model.id') && arr[i - 1]) {
            prev = arr[i - 1].get('id');
          }
        });
        return prev;
      } else {
        var model = this.get('model');
        return model.get('Prev');
      }
    }),
    nextUrl: _ember['default'].computed('model', function () {
      var _this3 = this;

      var controller = _ember['default'].getOwner(this).lookup('controller:' + this.get('baseControllerName'));
      var displayedQuestions = controller.get('displayedQuestions');
      if (displayedQuestions) {
        var next;
        displayedQuestions.filter(function (x) {
          return x.get('Type') !== 'Dummy';
        }).forEach(function (item, i, arr) {
          if (item.get('id') === _this3.get('model.id') && arr[i + 1]) {
            next = arr[i + 1].get('id');
          }
        });
        return next;
      } else {
        var model = this.get('model');
        return model.get('Next');
      }
    }),
    navUrl: _ember['default'].computed('model', function () {
      var model = this.get('model');
      return model.get('navigateUrl');
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;
      this.$(window).on('customswipeleft', function () {
        if (_this.get('nextUrl')) {
          _this.go(_this.get('nextUrl'));
        }
      });
      this.$(window).on('customswiperight', function () {
        if (_this.get('prevUrl')) {
          _this.go(_this.get('prevUrl'));
        }
      });
    },

    go: function go(id) {
      this.get('target').replaceWith(this.get('model.navigateUrl'), id);
    },
    willDestroyElement: function willDestroyElement() {
      this.$(window).off('customswipeleft customswiperight');
    }
  });
});