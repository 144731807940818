define('deputee-office/components/favorites/favorites-gos-programs', ['exports', 'ember', 'deputee-office/mixins/favorites-component-base'], function (exports, _ember, _deputeeOfficeMixinsFavoritesComponentBase) {
  exports['default'] = _ember['default'].Component.extend(_deputeeOfficeMixinsFavoritesComponentBase['default'], {
    store: _ember['default'].inject.service(),
    isLoaded: false,
    isEmpty: false,
    model: null,

    loadableModels: ['favorite', 'gos-program'],

    init: function init() {
      this._super();
      this.set('model', _ember['default'].A([]));
      this.set('displayedModel', _ember['default'].A([]));
    },

    loadData: function loadData() {
      var store = this.get('store');
      var model = this.get('model');

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        store.query('favorite', { Model: 'gos-program' }).then(function (favorites) {

          var ids = favorites.map(function (favorite) {
            return JSON.parse(favorite.get('Params')).id;
          });

          store.findAll('gos-program').then(function (programs) {
            programs.forEach(function (program) {
              var id = program.get('id');

              if (ids.indexOf(id) !== -1) {
                model.pushObject(program);
              }
            });

            resolve();
          })['catch'](function (e) {
            reject();
            console.error(e);
          });
        })['catch'](function (e) {
          reject();
          console.error(e);
        });
      }, 'favorites-gos-programs: loadData');
    },

    filterModel: function filterModel() {
      var model = this.get('model');
      var displayedModel = this.get('displayedModel');
      var filterText = this.get('searchString');

      displayedModel.clear();

      if (filterText && filterText.value) {
        model.forEach(function (program) {
          if (program.get('Title').toLowerCase().indexOf(filterText.value.toLowerCase()) > -1) {
            displayedModel.pushObject(program);
          }
        });
      } else {
        displayedModel.pushObjects(model);
      }
    }

  });
});