define('deputee-office/routes/base-agenda-route/base-agenda-passport-components', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/territory-flag-name', 'deputee-office/mixins/group-by'], function (exports, _ember, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsTerritoryFlagName, _deputeeOfficeMixinsGroupBy) {
  exports['default'] = _ember['default'].Route.extend(_emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], _deputeeOfficeMixinsServicesMixin['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], _deputeeOfficeMixinsGroupBy['default'], {
    relationshipsPath: 'laws.passport',
    passportViewName: 'passport-views.default-view',

    model: function model(params, transition) {
      this.set('params', params);
      this.set('queryParams', transition.queryParams);
      this.updatePassport(params);
    },

    renderTemplate: function renderTemplate() {
      this._super();
      this.render(this.get('passportViewName'));
    },

    updatePassport: function updatePassport(params, notClearModel) {
      var _this = this;
      var controller = _this.get('controller');
      if (controller && !notClearModel) {
        controller.set('model', null);
      }

      this.getPassportQuery().then(function functionName(passport) {
        if (passport) {
          if (passport.get('PassportId')) {
            var php = _this.PassportHeaderQuery(passport).then(function (header) {
              passport.set('PassportHeader', header);
              header.set('Passport', passport);
              return header;
            })['catch'](function (e) {
              console.error(e);
            });
            passport.set('PassportHeaderPromise', php);
          } else {
            var TabListObject;
            if (passport.get('AddInfo')) {
              TabListObject = [passport.get('AddInfo')];
            }

            var passportName = passport.get('NamePPZ');
            var PassportHeader = _ember['default'].Object.extend({ Title: passportName }).create({
              TabListObject: TabListObject
            });
            passport.set('PassportHeader', PassportHeader);
          }

          passport.set('navigateUrl', _this.get('passportNavigate'));
          _this.get('controller').set('model', passport);
        } else {
          var controller = _this.get('controller');
          if (controller) {
            controller.set('error', true);
          } else {
            _this.set('error', true);
          }
        }
      })['catch'](function (e) {
        var controller = _this.get('controller');
        if (controller) {
          controller.set('error', true);
        } else {
          _this.set('error', true);
        }

        console.error(e);
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('zsQuestion', this.get('zsQuestion'));
      controller.set('error', this.get('error'));
    },

    getRenderTarget: function getRenderTarget(model) {
      var _this;

      if (model.get('route')) {
        _this = model.get('route');
      } else {
        _this = this;
      }

      return _this;
    },

    actions: {
      updatePassport: function functionName() {
        this.updatePassport(undefined, true);
      },

      willTransition: function willTransition(transition) {
        this.send('removeModal');
        if (transition.targetName === 'download-file') {
          transition.abort();
          this.transitionTo(this.get('passportNavigate') + '.file-view', {
            queryParams: transition.queryParams
          });
        } else {
          return true;
        }
      },

      renderRigthMenu: function renderRigthMenu(target) {
        var _this2 = this;

        var model = this.get('controller.model.detail') ? this.get('controller.model.detail') : _ember['default'].Object.create({});
        if (this.getPassportHistoryQuery) {
          this.getPassportHistoryQuery(this.get('controller.model')).then(function (history) {
            model.set('documentHistory', history);
          });
        }

        if (this.getPassportRelationships) {
          this.getPassportRelationships(this.get('controller.model')).then(function (relationships) {
            model.set('relationships', relationships);
          });
        }

        if (this.getPassportDecisions) {
          this.getPassportDecisions().then(function (decisions) {
            model.set('decisions', decisions);
          });
        }

        if (this.getQuestionHistory) {
          this.getQuestionHistory(this.get('controller.model')).then(function (history) {
            model.set('questionHistory', history);
          });
        }

        if (this.getLegalActPublication && this.get('controller.model.PassportHeader.IsAccepted')) {
          this.getLegalActPublication(this.get('controller.model')).then(function (history) {
            model.set('legalActPublication', history);
          });
        }

        if (this.getVotingResult) {
          this.getVotingResult(this.get('controller.model')).then(function (result) {
            if (result.any(function (x) {
              return x.get('FullZsVoteResultName');
            })) {
              var sorted = result.toArray().sortByProperty('Date', 'desc');
              var groups = _this2.getGrouped(sorted, 'GroupName', 'groupName');

              groups.forEach(function (x) {
                x.items = x.items.sortByProperty('Date', 'asc');
              });

              model.set('votingResultGroup', groups);
            } else {
              model.set('votingResult', result.toArray().sort(function functionName(a) {
                if (a.get('Name') === 'Основное') {
                  return -1;
                } else {
                  return 1;
                }
              }));
            }
          });
        }

        model.set('ZsLawToApprovId', this.get('controller.model.PassportHeader.ZsLawToApprovId'));

        var _this;

        if (target.route) {
          _this = target.route;
        } else {
          _this = this;
        }

        if (this.get('devicejs').isPhone()) {
          this.get('controller').set('model.detail', model);
        } else {
          _this.render('passport-info', {
            into: 'application',
            outlet: 'right-menu',
            model: model
          });
        }
      },
      documentHistory: function documentHistory(model) {
        this.getRenderTarget(model).render('modals/document-history', {
          into: 'application',
          outlet: 'modal',
          model: model
        });
      },

      votingResult: function votingResult(model) {
        if (model) {
          model.set('showPersons', false);
          this.getRenderTarget(model).render('modals/vote', {
            into: 'application',
            outlet: 'modal',
            model: model
          });
        }
      },

      AddVotingResult: function AddVotingResult(model) {
        var _this3 = this;

        if (model.get('votingResultsPromise')) {
          model.get('votingResultsPromise').then(function (x) {
            if (x && x.get('firstObject')) {
              _this3.send('updatePassport');
              _this3.send('votingResult', x.get('firstObject'));
            }
          });
        } else {
          this.getRenderTarget(model).render('modals/vote', {
            into: 'application',
            outlet: 'modal',
            model: model
          });
        }
      },

      questionHistory: function questionHistory(model) {
        this.getRenderTarget(model).render('modals/question-history', {
          controller: 'modals/document-decisions',
          into: 'application',
          outlet: 'modal',
          model: model
        });
      },

      legalActPublication: function legalActPublication(model) {
        this.getRenderTarget(model).render('modals/legal-act-publication', {
          controller: 'modals/document-decisions',
          into: 'application',
          outlet: 'modal',
          model: model
        });
      },

      documentDecisions: function documentDecisions(model) {
        this.getRenderTarget(model).render('modals/document-decisions', {
          into: 'application',
          outlet: 'modal',
          model: model
        });
      },

      zsLawToApprov: function zsLawToApprov(model) {
        this.transitionTo('laws.passport', model);
      },

      agendaMembers: function agendaMembers(members, title) {
        this.getRenderTarget(members).render('modals/agenda-members', {
          into: 'application',
          outlet: 'modal',
          model: {
            members: members,
            dialogTitle: title
          }
        });
      },

      passportRelationships: function passportRelationships(model, dialogTitle) {
        this.getRenderTarget(model).render('modals/passport-relationships', {
          into: 'application',
          outlet: 'modal',
          model: {
            relationshipsPath: this.get('relationshipsPath'),
            dialogTitle: dialogTitle,
            relationships: model
          }
        });
      },

      goBack: function goBack() {
        if (history.length > 1 && history.back() !== undefined) {
          history.back();
        } else {
          this.replaceWith(this.routeName.split('.')[0], { queryParams: this.paramsFor });
        }
      },

      goToLaw: function goToLaw(model) {
        this.transitionTo(this.get('relationshipsPath'), model.get('id'));
      },

      /**
       * Открытие модального окна комментариев/поправок.
       * @param {Array<>} comments
       * @returns {}
       */
      showCommentsModal: function showCommentsModal(comments) {
        this.getRenderTarget(comments).render('modals/yp-comments', {
          into: 'application',
          outlet: 'modal',
          model: comments
        });
      },

      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'application'
        });
      }
    }
  });
});