define('deputee-office/components/pdf-viewer', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    fileService: _ember['default'].inject.service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.sendAction('onInsert');
      this.getFile();
    },

    versionObserver: _ember['default'].observer('version', function () {
      this.getFile();
    }),

    fileInfo: _ember['default'].computed('url', 'fileData', function () {
      if (this.get('fileData')) {
        return this.get('fileData');
      }

      var url = this.get('url');
      var subsystemKey = this.getUrlParam('subsystemKey', url);
      var docType = this.getUrlParam('docType', url);
      var subsystemFileId = this.getUrlParam('subsystemFileId', url);
      var fileDocumentId = this.getUrlParam('fileDocumentId', url);

      return {
        SubsystemKey: subsystemKey,
        DocType: docType,
        SubsystemFileId: subsystemFileId,
        fileDocumentId: fileDocumentId
      };
    }),

    openButtonChanged: _ember['default'].observer('openButton', function () {
      this.set('notPdf', this.get('openButton'));
    }),

    getUrlParam: function getParameterByName(name, url) {
      if (!url) {
        url = window.location.href;
      }

      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
      var results = regex.exec(url);
      if (!results) {
        return null;
      }

      if (!results[2]) {
        return '';
      }

      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },

    getFile: function getFile() {
      var _this = this;
      _this.set('PDFViewIsLoaded', false);
      _this.set('showButtons', false);
      _this.set('error', false);
      _this.set('openButton', false);

      var fileService = this.get('fileService');
      var fileInfo = this.get('fileInfo');
      this.get('store').query('file', fileInfo).then(function (fileRecords) {
        fileRecords = fileRecords.sortBy('FileVersion', 'UserFileVersion');
        var latestFile = fileRecords.get('lastObject');
        var neededVersion = _this.get('version');
        if (neededVersion) {
          latestFile = neededVersion;
        }

        function fileError(error) {
          _ember['default'].Logger.error('Ошибка открытия файла');
          console.error(error);
          _this.set('error', true);
        }

        fileService.crossplatformFileDownloadAndOpen(latestFile, fileRecords, function () {
          var iframe = _ember['default'].$('#pdfViewContent iframe')[0];
          if (iframe) {
            iframe.contentWindow.addEventListener('PDFViewIsLoaded', function () {
              _this.set('PDFViewIsLoaded', true);
              _this.set('showButtons', true);
              _this.set('maxPage', iframe.contentWindow.PDFViewerApplication.pagesCount);
            }, false);

            iframe.contentWindow.addEventListener('pagechange', function () {
              _this.set('currentPage', iframe.contentWindow.PDFViewerApplication.page);
            }, false);

            iframe.contentWindow.addEventListener('Error', function () {
              _this.set('error', true);
              _this.set('openButton', false);
            }, false);

            iframe.contentWindow.addEventListener('InvalidPDFException', function () {
              _this.set('error', true);
              _this.set('openButton', true);
            }, false);
          }
        }, _this.get('forcePDFObject'), fileError, fileError);

        var onFileLoad = _this.get('onFileLoad');

        if (onFileLoad) {
          onFileLoad(latestFile.get('id'));
          var pathToFile = _this.get('fileService').getFullFileName(latestFile);

          _this.set('filesForSharing', [pathToFile]);
        }

        if (!latestFile) {
          _this.set('error', true);
        }
      })['catch'](function () {
        _ember['default'].Logger.error('Ошибка получения списка версий файлов!');
        _this.set('error', true);
      });
    },

    actions: {
      goToPage: function goToPage(event) {
        _ember['default'].$('#pdfViewContent iframe')[0].contentWindow.PDFViewerApplication.page = event.target.value;
      },
      zoomIN: function functionName() {
        _ember['default'].$('#pdfViewContent iframe')[0].contentWindow.PDFViewerApplication.zoomIn();
      },
      zoomOut: function functionName() {
        _ember['default'].$('#pdfViewContent iframe')[0].contentWindow.PDFViewerApplication.zoomOut();
      },
      download: function functionName() {
        var fileService = this.get('fileService');
        if (fileService.get('platform') !== 'browser') {
          fileService.deviceNativeOpen();
        } else {
          _ember['default'].$('#pdfViewContent iframe')[0].contentWindow.PDFViewerApplication.download();
        }
      }
    }

  });
});