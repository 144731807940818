define('deputee-office/routes/base-agenda-route/base-agenda-index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    templateName: 'base-agenda-route.index',
    agendaDeepObservationService: _ember['default'].inject.service(),

    queryParams: {
      agendaSubState: {
        refreshModel: false,
        replace: false
      }
    },

    renderTemplate: function renderTemplate() {
      this._super.apply(this, arguments);
      if (this.get('devicejs').isPhone()) {
        this.render('search-box', {
          into: this.routeName,
          outlet: 'search-box',
          controller: this.controllerFor('base-agendas.base-agenda-search'),
          model: { searchText: this.get('searchText') || this.get('controller.searchText') }
        });
      }
    },

    actions: {
      downloadAgenda: function downloadAgenda(currentAgenda, questions) {
        this.render('modals/agenda-preload', {
          into: this.routeName,
          outlet: 'modal',
          model: {
            currentAgenda: currentAgenda,
            questions: questions
          }
        });
      },

      startObserveAgenda: function startObserveAgenda(currentAgenda) {
        this.get('agendaDeepObservationService').startObserveAgenda(currentAgenda.get('id'));
      },

      stopObserveAgenda: function stopObserveAgenda(currentAgenda) {
        this.get('agendaDeepObservationService').stopObserveAgenda(currentAgenda.get('id'));
      },

      goToState: function goToState(state) {
        var queryParams = {
          agendaSubState: state
        };

        this.transitionTo(this.routeName, {
          queryParams: queryParams
        });
      }
    }
  });
});