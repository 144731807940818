define('deputee-office/models/zs-passport/zs-passport-file', ['exports', 'ember-data', 'deputee-office/models/base'], function (exports, _emberData, _deputeeOfficeModelsBase) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Name: _emberData['default'].attr('string'),
    Url: _emberData['default'].attr('string'),
    OrderNum: _emberData['default'].attr('string'),
    TypeDocument: _emberData['default'].attr('string')
  });

  exports['default'] = Model;
});