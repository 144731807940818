define('deputee-office/mixins/favorites-component-base', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    willDestroyElement: function willDestroyElement() {
      var _this = this;

      this._super.apply(this, arguments);
      if (this.get('loadableModels')) {
        this.get('loadableModels').forEach(function (x) {
          return _this.get('store').cancelQuery(x);
        });
      }
    },

    searchStringChanged: _ember['default'].observer('searchString.value', function () {
      this.updateData(false);
    }),

    loadModel: (function () {
      this.updateData(true);
    }).on('didInsertElement'),

    /**
     * Преобразовываем получаем только разрешившиеся значения промисов
     * и разворачиваем их в одномерный массив.
     * @param result Результат Ember.RSVP.allSettled.
     * @returns {Array} Одноменый массив результатов для разрешиащихся промисов.
     */
    settledHelper: function settledHelper(result) {
      var resolveVal = result.filter(function (x) {
        return x.state === 'fulfilled';
      }).map(function (x) {
        return x.value;
      });

      if (resolveVal.some(function (x) {
        return _ember['default'].isArray(x);
      })) {
        return [].concat.apply([], resolveVal.map(function (x) {
          return x.toArray();
        }));
      } else {
        return resolveVal;
      }
    },

    updateData: function updateData(loadData) {
      var _this2 = this;

      this.sendAction('dataLoadStart');

      var displayedModel = this.get('displayedModel');

      var filterAndSendResult = function filterAndSendResult() {
        if (!_this2.isDestroyed && !_this2.isDestroying) {
          _this2.filterModel();

          if (displayedModel.length === 0) {
            _this2.sendAction('foundNothing');
          } else {
            _this2.sendAction('dataLoadEnd');
          }
        }
      };

      if (loadData) {
        this.loadData().then(function () {
          filterAndSendResult();
        })['catch'](function () {
          return _this2.sendAction('foundNothing');
        });
      } else {
        filterAndSendResult();
      }
    }

  });
});