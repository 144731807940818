define('deputee-office/models/zsquestion', ['exports', 'ember-data', 'deputee-office/models/base-question', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBaseQuestion, _ember, _moment) {

  var Model = _deputeeOfficeModelsBaseQuestion['default'].extend({
    HeadCommittee: _emberData['default'].attr('string'),
    Introduction: _emberData['default'].attr('string'),
    BeginTime: _ember['default'].computed('DateTimeBegin', function () {
      var timeBegin = this.get('DateTimeBegin');
      var result = '';

      if (timeBegin) {
        var count = timeBegin.length;
        result = timeBegin;

        if (count === 16) {
          result = (0, _moment['default'])(timeBegin, 'DD.MM.YYYY H:mm').format('H:mm');
        } else if (count === 5) {
          result = timeBegin;
        }

        if (count === 10) {
          result = '';
        }
      }

      return result;
    }),
    BeginDate: _ember['default'].computed('DateTimeBegin', function () {
      var timeBegin = this.get('DateTimeBegin');
      var result = '';

      if (timeBegin) {
        var count = timeBegin.length;

        if (count === 16) {
          result = (0, _moment['default'])(timeBegin, 'DD.MM.YYYY H:mm').format('DD.MM.YYYY');
        } else if (count === 10) {
          result = timeBegin;
        }
      }

      return result;
    }),
    EndTime: _ember['default'].computed('DateTimeEnd', function () {
      var timeEnd = this.get('DateTimeEnd');
      var result = '';

      if (timeEnd) {
        var count = timeEnd.length;

        if (count === 16) {
          result = (0, _moment['default'])(timeEnd, 'DD.MM.YYYY H:mm').format('H:mm');
        } else if (count === 5) {
          result = timeEnd;
        }
      }

      return result;
    }),
    EndDate: _ember['default'].computed('DateTimeEnd', function () {
      var timeEnd = this.get('DateTimeEnd');
      var result = '';

      if (timeEnd) {
        var count = timeEnd.length;

        if (count === 16) {
          result = (0, _moment['default'])(timeEnd, 'DD.MM.YYYY H:mm').format('DD.MM.YYYY');
        } else if (count === 10) {
          result = timeEnd;
        }
      }

      return result;
    }),
    SpeakerString: _ember['default'].computed('Speaker', function () {
      var result = this.get('Speaker');
      result = result.split(';').length > 1 ? 'Докладчики: ' + result : 'Докладчик: ' + result;
      return result;
    }),
    CoSpeakerString: _ember['default'].computed('CoSpeaker', function () {
      var result = this.get('CoSpeaker');
      result = result.split(';').length > 1 ? 'Cодокладчики: ' + result : 'Содокладчик: ' + result;
      return result;
    }),
    ChildCount: _emberData['default'].attr('string'),
    IdParent: _emberData['default'].attr('string'),
    Isreviewcompleted: _emberData['default'].attr('boolean')
  });

  exports['default'] = Model;
});