define('deputee-office/services/store', ['exports', 'ember', 'ember-data', 'ember-flexberry-data', 'deputee-office/mixins/offline-schema'], function (exports, _ember, _emberData, _emberFlexberryData, _deputeeOfficeMixinsOfflineSchema) {
  exports['default'] = _emberFlexberryData.Offline.Store.extend(_deputeeOfficeMixinsOfflineSchema['default'], {
    init: function init() {
      this._super.apply(this, arguments);
      var owner = _ember['default'].getOwner(this);
      var Store = _emberData['default'].Store;
      var onlineStore = Store.create(owner.ownerInjection());
      this.set('onlineStore', onlineStore);
    },

    cancelQuery: function cancelQuery(type) {
      var adapter = this.adapterFor(this.modelFor(type).modelName);
      if (typeof adapter.cancelQuery === 'function') {
        adapter.cancelQuery(this.modelFor(type).modelName);
      }
    }
  });
});