define('deputee-office/adapters/zs-passport/zs-passport-personal-document', ['exports', 'deputee-office/adapters/application', 'deputee-office/config/environment', 'ember'], function (exports, _deputeeOfficeAdaptersApplication, _deputeeOfficeConfigEnvironment, _ember) {
    exports['default'] = _deputeeOfficeAdaptersApplication['default'].extend({
        createRecord: function createRecord(store, type, snapshot) {
            var data = {};
            var serializer = store.serializerFor(type.modelName);
            var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

            serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

            var file = snapshot.record.get('file');
            var form = new FormData();
            form.append('data', JSON.stringify(data));
            if (snapshot.record.get('file')) {
                form.append('file', file, encodeURI(file.name));
            }

            return _ember['default'].$.ajax({
                url: url,
                type: 'POST',
                data: form,
                processData: false,
                contentType: false,
                dataType: 'json',
                timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
            });
        }
    });
});