define('deputee-office/mixins/yp-comments', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    notificationService: _ember['default'].inject.service(),
    ypCommentService: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    /**
     * Группировка и фильтрация поправок/комментариев.
     */
    DisplayedComments: _ember['default'].computed('AmendmentsOnly', 'model.[]', function () {
      var _this2 = this;

      var model = this.get('model');
      if (model) {
        this.updateScroll();
        return this.getGrouped(model.uniqBy('id').filter(function (x) {
          return x.get('isAmendment') || !_this2.get('AmendmentsOnly');
        }).sortBy('Time'), 'DisplayedDate', 'Date');
      }
    }),

    /**
     *  Функция скролящая вниз если добавлен новый комментарий, если мы были внизу.
     * @param {boolean} strollToBottom Если true - всегда скролим вниз.
     * @returns {}
     */
    updateScroll: function functionName(strollToBottom) {
      var scrollArea = _ember['default'].$('#scrollable-divcomments');

      var getAreaHeight = function functionName(area) {
        var height = 0;
        area.children().children().each(function () {
          var _this = _ember['default'].$(this);
          if (_this.attr('class').indexOf('dateRow') >= 0 || _this.attr('class').indexOf('commentRow') >= 0) {
            height = height + _this.outerHeight();
          }
        });
        return height;
      };

      if (scrollArea.scrollTop() + scrollArea.height() >= getAreaHeight(scrollArea) || strollToBottom) {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          scrollArea.perfectScrollbar('update');
          scrollArea.scrollTop(getAreaHeight(scrollArea) - scrollArea.children().height());
        });
      } else {
        _ember['default'].run.scheduleOnce('afterRender', this, function () {
          scrollArea.perfectScrollbar('update');
        });
      }
    },
    DisableButtons: _ember['default'].computed('CurrentAmendment.votingResult.hasDirtyAttributes', 'CurrentAmendment.votingResult.isSaving', function () {
      return this.get('CurrentAmendment.votingResult') && (!this.get('CurrentAmendment.votingResult.hasDirtyAttributes') && this.get('CurrentAmendment.votingResult.CurrentUserVote') || this.get('CurrentAmendment.votingResult.isSaving'));
    }),

    UpdateState: function functionName() {
      var _this3 = this;

      var model = this.get('model');
      if (model.filterBy('isAmendment', false).length < 1 && model.get('AmendmentVote')) {
        this.set('AmendmentsOnly', true);
        this.set('AmendmentsVoting', true);
        model.forEach(function (item) {
          return _this3.get('store').queryRecord('yp-passport/yp-amendment-voting-result', {
            PassportId: item.get('id'),
            projection: 'yp-amendment-voting-result-full'
          }).then(function (x) {
            return item.set('votingResult', x);
          })['catch'](function () {
            return item.set('votingResult', null);
          });
        });
      } else {
        this.set('AmendmentsVoting', false);
      }
    },

    actions: {
      didInsert: function functionName() {
        this.UpdateState();
      },
      closeModal: function closeModal() {
        this.set('isShowDiagram', false);
        this.send('cancel', this.get('CurrentAmendment'));
        this.set('AmendmentsOnly', null);
        this.set('CurrentAmendment', null);
        this.set('SendVoteAvailable', null);
        this.get('target').send('removeModal');
        this.get('ypCommentService').setCommentsAsRead(this.get('model.QuestionId'));
      },
      didShown: function functionName() {
        this.set('isShowDiagram', true);
        this.updateScroll(true);
        this.updateContainerHeight();
      },
      AmendmentsOnly: function AmendmentsOnly() {
        this.set('AmendmentsOnly', !this.get('AmendmentsOnly'));
      },
      votingResult: function votingResult(_votingResult) {
        if (this.get('AmendmentsVoting')) {
          if (_votingResult && _votingResult.get('votingResult') && !this.get('model.SendVoteAvailable')) {
            this.set('CurrentAmendment', _votingResult);
          } else if (this.get('model.SendVoteAvailable') && _votingResult.get('IsVoteAvailable')) {
            this.set('SendVoteAvailable', true);
            this.set('CurrentAmendment', _votingResult);
          }

          return true;
        }

        return false;
      },
      hideVote: function hideVote() {
        var model = this.get('model');
        model.set('showPersons', false);
        this.set('CurrentAmendment', null);
      },
      chooseVote: function functionName(vote) {
        if (!this.get('DisableButtons')) {
          this.set('CurrentAmendment.votingResult.CurrentUserVote', vote);
        }
      },
      showPersons: function showPersons() {
        var model = this.get('model');
        model.set('showPersons', !model.get('showPersons'));
      },
      sendVote: function functionName() {
        var _this4 = this;

        if (!this.get('DisableButtons')) {
          this.get('CurrentAmendment.votingResult').save().then(function () {
            _this4.send('hideVote');
            if (_this4.get('devicejs').isPhone() && _this4.get('passportStateParam')) {
              window.history.back();
            }
          })['catch'](function (e) {
            if (e.errors) {
              _this4.send('errorHandlerForVote', e.errors[0].detail);
            } else {
              _this4.send('errorHandlerForVote', e);
            }
          });
        }
      },
      cancel: function functionName(vote) {
        if (vote && vote.get('votingResult.hasDirtyAttributes')) {
          vote.get('votingResult').rollbackAttributes();
        }

        this.send('hideVote');
      },
      sendComment: function sendComment(isComment, commentObj) {
        var _this5 = this;

        if (!commentObj.get('loading')) {
          commentObj.set('loading', true);
          this.get('ypCommentService').setCommentsAsRead(this.get('model.QuestionId'));
          var comment = this.get('store').createRecord('yp-passport/yp-passport-comment', {
            Text: commentObj.get('newCommentText'),
            PassportId: this.get('model.QuestionId'),
            isAmendment: isComment
          });
          comment.save().then(function (x) {
            commentObj.set('newCommentText', '');
            commentObj.set('loading', false);
            _this5.get('model').addObject(x);
            if (_this5.get('allComments')) {
              _this5.get('allComments').addObject(x);
            }
          })['catch'](function (e) {
            commentObj.set('loading', false);
            if (comment.get('hasDirtyAttributes')) {
              comment.deleteRecord();
            }

            _this5.send('errorHandler', e.errors[0].detail);
          })['finally'](function () {
            return _this5.get('ypCommentService').setCommentsAsRead(_this5.get('model.QuestionId'));
          });
        }
      },
      errorHandler: function functionName(message) {
        var notificationService = this.get('notificationService');
        var messageForUser = '';
        switch (message) {
          case 'user error':
            messageForUser = 'Вы не допущены к обсуждению вопроса!';
            break;
          case 'agenda status error':
            messageForUser = 'Работа с поправками на данном этапе недоступна!';
            break;
          case 'vote type error':
            messageForUser = 'Ошибка типа голосования!';
            break;
          case 'too long':
            messageForUser = 'Слишком длинный текст!';
            break;
          case 'empty text':
            messageForUser = 'Пустой текст!';
            break;
          case 'stage finished':
            messageForUser = 'Этап заочного обсуждения завершен!';
            break;
          default:
            messageForUser = 'Произошла неизвестная ошибка!';
        }
        notificationService.alert(messageForUser, null, 'Ошибка!');
      },
      errorHandlerForVote: function functionName(message) {
        var notificationService = this.get('notificationService');
        var messageForUser = '';
        switch (message) {
          case 'user error':
            messageForUser = 'Вы не допущены до голосования!';
            break;
          case 'stage finished':
            messageForUser = 'Голосование по поправкам завершено!';
            break;
          case 'agenda status error':
            messageForUser = 'На данном этапе повестки голосование не доступно!';
            break;
          case 'vote type error':
            messageForUser = 'Заочное голосование по этому вопросу не доступно!';
            break;
          case 'already voted':
            messageForUser = 'Вы уже проголосовали по этой поправке!';
            break;
          default:
            messageForUser = 'Произошла неизвестная ошибка!';
        }
        notificationService.alert(messageForUser, null, 'Ошибка!');
      }
    }

  });
});