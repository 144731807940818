define('deputee-office/routes/eventsplan', ['exports', 'ember', 'deputee-office/mixins/territory-flag-name', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsTerritoryFlagName, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsTerritoryFlagName['default'], _deputeeOfficeMixinsServicesMixin['default'], {

    activate: function activate() {
      this.setFlagAndNameForZS();
    },

    deactivate: function deactivate() {
      this.setDefaultFlag();
    }
  });
});