define('deputee-office/routes/legal-acts/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    templateName: 'legal-acts.index',
    renderTemplate: function renderTemplate() {
      this._super();
      var controller = this.get('controller');
      var initFilter = controller.get('filter');
      if (!initFilter) {
        controller.set('filterIcon', 'glyphicon icon-filter');
      } else {
        if (initFilter.view !== 'Все' || initFilter.state !== 'Все' || initFilter.type !== 'Все') {
          controller.set('filterIcon', 'glyphicon icon-filter-selected');
        } else {
          controller.set('filterIcon', 'glyphicon icon-filter');
        }
      }

      var device = this.get('devicejs');
      if (device.isPhone()) {
        this.render('search-box', {
          into: 'legal-acts.index',
          outlet: 'search-box',
          controller: this.controllerFor('legal-acts.legal-acts-search'),
          model: { searchText: this.get('searchText') }
        });
      }
    },
    init: function init() {
      this._super();
    },

    actions: {
      search: function search(text) {
        this.set('searchText', text);
        return true;
      },

      showFilter: function showFilter() {
        var controller = this.get('controller');
        var filter = controller.get('filter');
        controller.set('selectedView', filter.view);
        controller.set('selectedState', filter.state);
        controller.set('selectedType', filter.type);
        this.render('modals/legal-acts-filter-modal', {
          into: 'legal-acts.index',
          outlet: 'modal'
        });
      },

      applyFilter: function applyFilter() {
        var controller = this.get('controller');
        var filter = controller.get('filter');
        filter.view = controller.get('selectedView');
        filter.state = controller.get('selectedState');
        filter.type = controller.get('selectedType');
        controller.send('filter', filter);
        if (filter.view !== 'Все' || filter.state !== 'Все' || filter.type !== 'Все') {
          controller.set('filterIcon', 'glyphicon icon-filter-selected');
        } else {
          controller.set('filterIcon', 'glyphicon icon-filter');
        }
      },

      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'legal-acts.index'
        });
      }
    }
  });
});