define('deputee-office/components/link-to-url', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'a',
    click: function click() {
      var url = this.get('url');
      if (url) {
        url = url.replace('#', '');
        this.get('target').transitionTo(url);
      }
    }
  });
});