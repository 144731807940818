define('deputee-office/components/current-questions-panel', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    currentQuestions: _ember['default'].inject.service('current-question-service'),
    model: _ember['default'].computed.alias('currentQuestions.model'),
    hidden: false,
    actions: {
      moveQuestions: function moveQuestions() {
        this.toggleProperty('hidden');
      }
    }
  });
});