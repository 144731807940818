define('deputee-office/models/omsuagenda', ['exports', 'ember-data', 'deputee-office/models/base-agenda', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBaseAgenda, _ember) {

  var Model = _deputeeOfficeModelsBaseAgenda['default'].extend({
    Members: _emberData['default'].attr('string'),
    DepartmentId: _emberData['default'].attr('guid'),
    TerritoryId: _emberData['default'].attr('guid'),
    ProtocolFileUrl: _emberData['default'].attr('string'),

    MembersList: _ember['default'].computed('Members', function () {
      return this.get('Members').split(',');
    })
  });

  exports['default'] = Model;
});