define('deputee-office/controllers/k-s-materials', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    viewMode: 'list',
    viewModeToggleIcon: 'assets/img/decreeviewtiles.png',
    searchPlaceholder: 'Поиск по материалам',

    // Фильтруем по годам и заголовку
    filter: _ember['default'].observer('selectedYear', 'searchText', function () {
      var _this = this;
      var text = this.get('searchText');
      var materials = this.get('model').filter(function (material) {
        if (text) {
          return material.get('Date') === _this.get('selectedYear') && material.get('Title').toLowerCase().indexOf(text.toLowerCase()) !== -1;
        } else {
          return material.get('Date') === _this.get('selectedYear');
        }
      });
      this.set('materials', materials);
    }),

    // Группируем по повеске
    grouped: _ember['default'].computed('materials.[]', function () {
      var materials = this.get('materials');
      if (materials) {
        var result = _ember['default'].A([]);
        var idx = [];
        materials.toArray().sortByProperty('OrderNum', 'asc').forEach(function (record) {
          var _agenda = record.get('Agenda');
          if (!result.isAny('agenda', _agenda)) {
            idx[_agenda] = _ember['default'].A([]);
            result.pushObject({ agenda: _agenda, items: idx[_agenda] });
          }

          idx[_agenda].pushObject(record);
        });
        return result;
      }
    }),

    // Вычисляем фильтры по годам и выбираем последжний год
    years: _ember['default'].observer('model.@each', function () {
      var years = [];

      this.get('model').forEach(function (item) {
        var year = item.get('Date');
        if (_ember['default'].$.inArray(year, years) === -1) {
          years.push(year);
        }
      });

      years.sort();

      if (!this.get('selectedYear')) {
        this.set('selectedYear', years[years.length - 1]);
      } else {
        var filter = this.get('filter');

        filter.apply(this);
      }

      this.set('yearsList', years);
    }),

    actions: {
      toggleViewMode: function toggleViewMode() {
        var currentMode = this.get('viewMode');
        if (currentMode === 'list') {
          this.set('viewMode', 'tiles');
          this.set('viewModeToggleIcon', 'assets/img/decreeviewlist.png');
        } else {
          this.set('viewMode', 'list');
          this.set('viewModeToggleIcon', 'assets/img/decreeviewtiles.png');
        }
      },

      selectYear: function selectYear(year) {
        this.set('selectedYear', year);
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      },

      search: function search(text) {
        this.set('searchText', text);
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      }
    }
  });
});