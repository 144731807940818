define('deputee-office/models/zs-passport/zs-passport-relationship-list', ['exports', 'ember-data', 'deputee-office/models/base'], function (exports, _emberData, _deputeeOfficeModelsBase) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Name: _emberData['default'].attr('string'),
    PassportId: _emberData['default'].attr('guid'),
    Type: _emberData['default'].attr('string'),
    Title: _emberData['default'].attr('string')
  });

  exports['default'] = Model;
});