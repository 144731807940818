define('deputee-office/routes/newslist', ['exports', 'ember', 'deputee-office/mixins/territory-flag-name', 'deputee-office/mixins/services-mixin', 'deputee-office/mixins/infinity-route'], function (exports, _ember, _deputeeOfficeMixinsTerritoryFlagName, _deputeeOfficeMixinsServicesMixin, _deputeeOfficeMixinsInfinityRoute) {
  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsInfinityRoute['default'], _deputeeOfficeMixinsTerritoryFlagName['default'], _deputeeOfficeMixinsServicesMixin['default'], {
    queryParams: {
      personal: {
        replace: true
      },
      category: {
        replace: true
      },
      date: {
        replace: true,
        refreshModel: true
      },
      sourceType: {
        replace: true,
        refreshModel: true
      },
      itemId: {
        refreshModel: false,
        replace: false
      },
      newsSubState: {
        refreshModel: false,
        replace: false
      }
    },
    modelLoadingWrapper: {
      value: false
    },
    searchText: '',
    perPage: 18,
    personalNews: [],
    news: [],
    modelLoading: _ember['default'].computed.alias('modelLoadingWrapper.value'),
    renderTemplate: function renderTemplate() {
      var device = this.get('devicejs');
      this.render();
      if (device.isPhone()) {
        this.render('search-box', {
          into: 'newslist',
          outlet: 'search-box',
          controller: this.controllerFor('newslist.newslist-search'),
          model: { searchText: this.get('searchText') }
        });
      } else {
        this.render('search-box', {
          outlet: 'toolbar-right-outlet',
          controller: this.controllerFor('newslist.newslist-search'),
          model: { searchText: this.get('searchText') }
        });
      }
    },

    model: function model(params) {
      var _this2 = this;

      this.set('modelLoading', true);
      params.perPage = this.get('perPage');
      params.startingPage = 1;
      var searchText = this.get('searchText');
      if (searchText && searchText.length > 0) {
        //searchText = encodeURI(this.get('searchText'));
        params.filter = searchText;
      }

      // По дизайну должен быть календарь, но с ним всё плохо.
      // if (!params.date)
      // {
      //   params.date = moment().format("DD.MM.YYYY");
      // }
      var _this = this;
      this.infinityModel('newsitem', params).then(function (news) {
        var controller = _this.controller;
        controller.set('model', news);
        _this.set('modelLoading', false);
      })['catch'](function (reason) {
        console.error(reason);
        _this2.set('modelLoading', false);
      });

      // Возвращается пустой массив, чтобы в контроллере не ломалась группировка.
      return new _ember['default'].A([]);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var modelLoading = this.get('modelLoadingWrapper');
      controller.set('modelLoading', modelLoading);
    },

    deactivate: function deactivate() {
      this.set('searchText', '');
    },

    getNextNews: function getNextNews(news, newsPers) {
      var _this3 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        try {
          var newsArray = _this3._infinityModel().toArray();

          var index = newsArray.indexOf(news);
          if (newsArray[index + 1]) {
            resolve(newsArray[index + 1]);
          } else {
            _this3._loadNextPage().then(function (x) {
              if (x.get('length') > 0) {
                _this3.getNextNews(news, newsPers).then(function (y) {
                  return resolve(y);
                });
              } else {
                resolve();
              }
            });
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    getPrevNews: function functionName(news) {
      var _this4 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        try {
          var newsArray = _this4._infinityModel().toArray();
          var index = newsArray.indexOf(news);
          if (index - 1 >= 0) {
            resolve(newsArray[index - 1]);
          } else {
            resolve();
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    actions: {
      searchNews: function searchNews(text) {
        this.set('searchText', text);
        this.refresh();
      },

      nextNews: function functionName() {
        var _this5 = this;

        this.get('CurrentNews.nextNews').then(function (x) {
          _this5.set('CurrentNews.news', x);
          _this5.set('CurrentNews.prevNews', _this5.getPrevNews(x, _this5.get('CurrentNews.newsPers')));
          _this5.set('CurrentNews.nextNews', _this5.getNextNews(x, _this5.get('CurrentNews.newsPers')));
          return x;
        });
      },

      prevNews: function functionName() {
        var _this6 = this;

        this.get('CurrentNews.prevNews').then(function (x) {
          _this6.set('CurrentNews.news', x);
          _this6.set('CurrentNews.prevNews', _this6.getPrevNews(x, _this6.get('CurrentNews.newsPers')));
          _this6.set('CurrentNews.nextNews', _this6.getNextNews(x, _this6.get('CurrentNews.newsPers')));
          return x;
        });
      },

      doOpenNews: function doOpenNews(item, newsPers) {
        var device = this.get('devicejs');
        if (device.isPhone() && (item.get('Type') === 'HTML' || !item.get('Url'))) {
          this.get('controller').set('currentNews', item);
          this.transitionTo(this.routeName, {
            queryParams: {
              newsSubState: 'showNews',
              itemId: item.id
            }
          });
        } else {
          var url = item.get('Url');
          if (item.get('Type') === 'HTML' || !url) {
            var model = _ember['default'].Object.extend({}).create({
              prevNews: this.getPrevNews(item, newsPers),
              nextNews: this.getNextNews(item, newsPers),
              news: item,
              newsPers: newsPers
            });
            this.render('modals/news', {
              into: 'newslist',
              outlet: 'modal',
              model: model
            });
            this.set('CurrentNews', model);
          } else {
            this.send('showUrl', url);
          }
        }
      },

      showUrl: function showUrl(url) {
        if (!window.device) {
          return;
        }

        var platform = window.device.platform.toLowerCase();
        if (platform === 'ios' || platform === 'android') {
          window.cordova.InAppBrowser.open(url, '_blank', 'location=yes,closebuttoncaption=Закрыть,enableviewportscale=yes');
        } else {
          window.cordova.InAppBrowser.open(url, '_system', 'location=yes,closebuttoncaption=Закрыть');
        }
      },

      removeModal: function removeModal() {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: 'newslist'
        });
      },
      showFilter: function showFilter() {
        this.get('controller').set('chosenSource', this.get('controller').get('selectedSource'));
        this.render('modals/newslist-filter-modal', {
          into: 'newslist',
          outlet: 'modal'
        });
      }
    }
  });
});