define('deputee-office/adapters/service-setting-offline', ['exports', 'deputee-office/adapters/application-offline', 'deputee-office/config/environment', 'ember'], function (exports, _deputeeOfficeAdaptersApplicationOffline, _deputeeOfficeConfigEnvironment, _ember) {
  exports['default'] = _deputeeOfficeAdaptersApplicationOffline['default'].extend({
    settingsService: _ember['default'].inject.service('settingsservice'),
    updateRecord: function updateRecord(store, type, snapshot) {
      var key = type.modelName;
      var settingsservice = this.get('settingsService');
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);
      var settingsData = settingsservice.getSettings(key, false);
      if (settingsData) {
        var index = settingsData.serviceSettings.map(function (el) {
          return el.id;
        }).indexOf(snapshot.id);
        var expireDate = new Date(new Date().getTime() + _deputeeOfficeConfigEnvironment['default'].APP.settingsExpirationSecondsTimeout * 1000);
        settingsData.serviceSettings[index].IsVisible = data.IsVisible;
        settingsData.serviceSettings[index].EditTime = new Date();
        settingsservice.putSettings(key, expireDate, settingsData);
      }

      return true;
    }
  });
});