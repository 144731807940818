define('deputee-office/helpers/format-HeadCommittee', ['exports', 'ember'], function (exports, _ember) {
  exports.formatHeadCommittee = formatHeadCommittee;

  function formatHeadCommittee(params /*, hash*/) {

    if (params[0].toLowerCase().indexOf('комитет') !== -1 || params[0].toLowerCase().indexOf('комиссия') !== -1) {
      return params[0].firstCharToLowerCase();
    } else {
      return params[0];
    }
  }

  exports['default'] = _ember['default'].Helper.helper(formatHeadCommittee);
});