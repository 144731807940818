define('deputee-office/routes/contacts', ['exports', 'ember', 'deputee-office/mixins/services-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'deputee-office/config/environment'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin, _emberSimpleAuthMixinsAuthenticatedRouteMixin, _deputeeOfficeConfigEnvironment) {
  var notFoundMessage = 'По вашему запросу ничего не нашлось';

  exports['default'] = _ember['default'].Route.extend(_deputeeOfficeMixinsServicesMixin['default'], _emberSimpleAuthMixinsAuthenticatedRouteMixin['default'], {

    modelLoadingWrapper: {
      value: false
    },

    modelLoading: _ember['default'].computed.alias('modelLoadingWrapper.value'),

    model: function model() {
      var result = this.store.findAll('department');

      return result;
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.set('controller', controller);
      controller.set('modelLoading', this.get('modelLoadingWrapper'));
      controller.set('contactData', null);
      controller.set('currentDepartment', null);
      controller.set('findTimer', null);
    },

    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition) {
        controller.set('subString', null);
      }
    },

    getGrouped: function getGrouped(model) {
      var result = _ember['default'].A([]);
      var idx = [];
      model.forEach(function (record) {
        var _type = record.get('department.Name');
        if (_type === '') {
          return;
        }

        if (!result.isAny('name', _type)) {
          idx[_type] = _ember['default'].A([]);
          result.pushObject({ name: _type, items: idx[_type] });
        }

        idx[_type].pushObject(record);
      });
      return result;
    },

    contactDataSplitter: function contactDataSplitter(contacts, substring) {

      var controller = this.get('controller');

      var byCondition = function byCondition(contact, prop) {
        return contact.get(prop) && contact.get(prop).toLowerCase().indexOf(substring) > -1;
      };

      var finded = contacts.filter(function (contact) {
        if (byCondition(contact, 'Name') || byCondition(contact, 'Post')) {
          return true;
        }

        return false;
      });

      var findedByDepartment = contacts.filter(function (contact) {
        if (byCondition(contact, 'department.Name')) {
          return true;
        }

        return false;
      }).sortBy('OrderNum');

      controller.set('finded', finded);
      controller.set('findedByDepartment', this.getGrouped(findedByDepartment));
    },

    setHeaderImages: function setHeaderImages(model) {
      var _this = this;
      var settingsService = this.get('settingsservice');
      var currentUserService = this.get('currentuserservice');

      model.map(function (contact) {
        var photoId = contact.get('Photo');
        var isIdNotNull = photoId != null;
        var defaultImage = _this.get('fotoService').getFotoUrlByName(contact.get('Name'));
        var isConnected = _this.get('networkstatus').isInternetConnection();

        if (isIdNotNull && isConnected) {
          _ember['default'].$.ajax({
            url: settingsService.getHost() + '/api/contacts/photos/' + photoId,
            headers: { Authorization: currentUserService.getBasicAuthHeader() },
            type: 'GET',
            xhrPool: _this.get('routeName'),
            timeout: _deputeeOfficeConfigEnvironment['default'].APP.ajaxTimeoutMs
          }).then(function (data) {
            if (data && data !== '') {
              contact.set('LocalPhoto', 'data:image;base64,' + data);
            } else {
              contact.set('LocalPhoto', defaultImage);
            }
          });
        } else {
          contact.set('LocalPhoto', defaultImage);
        }
      });
    },

    /**
     * Отмена выполняемых в рамках роута ajax-запросов.
     *
     * @return {undefined}
     */
    _abortRequests: function _abortRequests() {
      if (_ember['default'].$.xhrPool != null) {
        var routeName = this.get('routeName');

        _ember['default'].$.xhrPool.abort(routeName);
      }
    },

    /**
     * Отмена выполняемых ajax-запросов при выходе из роута.
     *
     * @return {undefined}
     */
    deactivate: function deactivate() {
      this._abortRequests();
    },

    actions: {

      contactInfo: function contactInfo(contact) {
        var sidebar = _ember['default'].$('[data-sidebar]#rightMenu');
        var overlay = _ember['default'].$('[data-sidebar-overlay]#rightMenu');
        if (overlay.is(':visible')) {
          sidebar.css('right', sidebar.width() * -1 + 'px');
          overlay.fadeTo('500', 0, function () {
            overlay.hide();
          });
        } else {
          sidebar.css('right', '0px');

          overlay.show(0, function () {
            overlay.fadeTo('500', 0.5);
          });
        }

        this.render('contact-info', {
          controller: 'contacts.contact-info',
          into: 'application',
          outlet: 'right-menu',
          model: contact
        });
      },

      back: function back() {
        window.history.back();
      },

      /**
       * Поиск контактов по подразделению.
       *
       * @param {department} department - Подразделение.
       */
      getContactsByDepartmentId: function getContactsByDepartmentId(department) {
        var _this2 = this;

        this.set('modelLoading', true);

        // Отмена выполняющихся в данный момент ajax-запросов.
        this._abortRequests();

        var controller = this.get('controller');

        controller.set('currentDepartment', department);

        var _this = this;

        this.store.query('contact', { departmentid: department.id }).then(function (records) {
          if (controller.get('currentDepartment') === department) {
            controller.set('contactData', records);
            _this2.setHeaderImages(records);
          }

          _this.set('modelLoading', false);
        })['catch'](function (reason) {
          console.error(reason);
          _this.set('modelLoading', false);
        });
      },

      /**
       * Поиск контактов, имя или должность которых соответствуют заданной строке.
       *
       * @param {string} Text - Текст для поиска.
       */
      findContactsBySubstring: function findContactsBySubstring(Text) {
        var _this3 = this;

        _ember['default'].$('.scrollable-div').trigger('updatescroll');

        var controller = this.get('controller');
        var _this = this;

        controller.set('currentDepartment', null);

        if (Text === '') {
          controller.set('model', this.store.peekAll('department'));
          controller.set('contactData', null);
          controller.set('subString', null);
          controller.set('errorMessage', null);
          controller.set('inputId', 'contactInputEmpty');

          _ember['default'].run.cancel(controller.get('findTimer'));
        } else {
          (function () {
            controller.set('model', null);
            controller.set('inputId', 'contactInputNotEmpty');
            var substring = Text.toLowerCase();
            var isConnected = _this3.get('networkstatus').isInternetConnection();

            if (isConnected) {
              _ember['default'].run.cancel(controller.get('findTimer'));
              _this.set('modelLoading', true);

              var timer = _ember['default'].run.later(_this, function () {
                var _this4 = this;

                this.store.query('contact', { substring: substring }).then(function (records) {

                  controller.set('contactData', records);
                  _this4.contactDataSplitter(records, substring);
                  _this.set('modelLoading', false);
                  if (records && records.get('length') > 0) {
                    controller.set('errorMessage', null);
                  } else {
                    controller.set('errorMessage', notFoundMessage);
                  }

                  _this4.setHeaderImages(records);
                })['catch'](function (reason) {
                  console.error(reason);
                  _this.set('modelLoading', false);
                });
              }, 1500);

              controller.set('findTimer', timer);
            } else {
              _this3.store.findAll('contact').then(function (records) {
                records = records.filter(function (contact) {
                  if (contact.get('Name') && contact.get('Name').toLowerCase().indexOf(substring) > -1 || contact.get('Post') && contact.get('Post').toLowerCase().indexOf(substring) > -1 || contact.get('department.Name') && contact.get('department.Name').toLowerCase().indexOf(substring) > -1) {
                    contact.set('LocalPhoto', _this.get('fotoService').getFotoUrlByName(contact.get('Name')));

                    return true;
                  }

                  return false;
                });

                controller.set('contactData', records);
                _this3.contactDataSplitter(records, substring);
                if (!records && records.get('length') <= 0) {
                  controller.set('errorMessage', notFoundMessage);
                } else {
                  controller.set('errorMessage', null);
                }
              });
            }
          })();
        }
      }
    }
  });
});