define('deputee-office/models/yp-passport/yp-passport-comment', ['exports', 'ember-data', 'ember', 'moment'], function (exports, _emberData, _ember, _moment) {
  exports['default'] = _emberData['default'].Model.extend({
    fotoService: _ember['default'].inject.service(),
    Name: _emberData['default'].attr('string'),
    Time: _emberData['default'].attr('date'),
    Text: _emberData['default'].attr('string'),
    PassportId: _emberData['default'].attr('guid'),
    isAmendment: _emberData['default'].attr('boolean'),
    IsVoteAvailable: _emberData['default'].attr('boolean'),
    isMy: _emberData['default'].attr('boolean'),

    /**
     * Получение текста без html тегов.
     */
    TextContent: _ember['default'].computed('Text', function () {
      var content = '<p>' + this.get('Text').replace(new RegExp('<br />', 'g'), ' ') + '</p>';
      var text = _ember['default'].$(content).text();
      return text;
    }),
    /**
     * Получение фотографии пользователя по имени.
     */
    Photo: _ember['default'].computed('Name', function () {
      return this.get('fotoService').getFotoUrlByName(this.get('Name'));
    }),
    /**
     * Получение времени отправки комментария.
     */
    DisplayedTime: _ember['default'].computed('Time', function () {
      if (this.get('Time')) {
        return (0, _moment['default'])('' + this.get('Time')).format('HH:mm');
      }
    }),
    /**
     * Получение даты отправки комментария.
     */
    DisplayedDate: _ember['default'].computed('Time', function () {
      if (this.get('Time')) {
        return (0, _moment['default'])('' + this.get('Time')).format('DD MMMM YYYY года');
      }
    })
  });
});