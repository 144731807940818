define('deputee-office/models/phonedepartment', ['exports', 'ember-data', 'deputee-office/models/base', 'ember'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember) {

    var Model = _deputeeOfficeModelsBase['default'].extend({
        Name: _emberData['default'].attr('string'),
        Level: _emberData['default'].attr('string'),
        Parent: _emberData['default'].attr('string'),
        IsHidden: _emberData['default'].attr('boolean'),
        Departments: _emberData['default'].hasMany('phonedepartment', { async: false, inverse: null }),
        OrderNum: _emberData['default'].attr('number'),
        parent: _ember['default'].computed('Parent', function () {
            return this.get('Parent');
        }),
        children: _ember['default'].computed('Departments', function () {
            return this.get('Departments');
        }),
        customSorting: ['OrderNum'],
        sortedChildren: _ember['default'].computed.sort('children', 'customSorting'),
        IsZspk: _emberData['default'].attr('boolean')
    });

    exports['default'] = Model;
});