define('deputee-office/models/decree', ['exports', 'ember-data', 'deputee-office/models/base', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Title: _emberData['default'].attr('string'),
    RegNumber: _emberData['default'].attr('string'),
    RegDate: _emberData['default'].attr('date'),
    Month: _ember['default'].computed('RegDate', function () {
      return (0, _moment['default'])('' + this.get('RegDate')).format('MMMM YYYY').firstCharToUpper();
    }),
    DayMonth: _ember['default'].computed('RegDate', function () {
      return (0, _moment['default'])('' + this.get('RegDate')).format('LL').slice(0, -8);
    }),
    FileDownloadUrl: _emberData['default'].attr('string'),
    DateTitle: _ember['default'].computed('Month', 'DayMonth', function () {
      return '№' + this.get('RegNumber') + ' от ' + this.get('DayMonth');
    })
  });

  exports['default'] = Model;
});