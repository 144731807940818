define('deputee-office/services/notification-service', ['exports', 'ember'], function (exports, _ember) {

  /**
   * Сервис для вывода нативных сообщений. Используется плагин cordova-plugin-dialogs.
   */
  exports['default'] = _ember['default'].Service.extend({

    /**
     * alert - Вызывает на устройствах диалоговое окно с заголовком, сообщением и кнопкой подтверждения.
     * В браузере вызывает alert.
     *
     * @param  {String} message       Сообщение
     * @param  {Function} alertCallback Функция, которая будет вызвана после нажатия кнопки подтверждения.
     * @param  {String} title         Заголовок сообщения. (Опционально, по умолчанию Alert)
     * @param  {String} buttonName    Лейбл кнопки. (Опционально, по умолчанию OK)
     * @return {type}
     */
    alert: function alert(message, alertCallback, title, buttonName) {
      navigator.notification.alert(message, alertCallback, title, buttonName);
    },

    /**
     * confirm - Вызывает на устройствах диалоговое окно с заголовком,
     *  сообщением и несколькими вариантами ответа(не более трех).
     * В браузере вызывает confirm.
     *
     * @param  {String} message         Сообщение.
     * @param  {Function} confirmCallback Функция, в которую будет передан номер нажатой клавиши (1,2 или 3).
     * На отклонение диалога будет передан 0. (Function)
     * @param  {String} title            Заголовок сообщения. (Опционально, по умолчанию Confirm)
     * @param  {Array} buttonLabels    Массив строк с лейблами кнопок. (Опционально, по умолчанию "OK","Cancel")
     * @return {type}                 description
     */
    confirm: function confirm(message, confirmCallback, title, buttonLabels) {
      navigator.notification.confirm(message, confirmCallback, title, buttonLabels);
    },

    /**
     * prompt - Вызывает на устройствах диалоговое окно с областью для ввода текста.
     * В браузере вызывает prompt.
     *
     * @param  {String} message        Сообщение
     * @param  {Function} promptCallback  Функция, в которую будет передан номер нажатой клавиши (1,2 или 3).
     * На отклонение диалога будет передан 0.
     * @param  {String} title           Заголовок сообщения. (Опционально, по умолчанию Prompt)
     * @param  {Array} buttonLabels   Массив строк с лейблами кнопок.	(Опционально,  по умолчанию "OK","Cancel")
     * @param  {String} defaultText    Текст по умолчанию. (Опционально, Default: empty string)
     * @return {type}                description
     */
    prompt: function prompt(message, promptCallback, title, buttonLabels, defaultText) {
      navigator.notification.prompt(message, promptCallback, title, buttonLabels, defaultText);
    },

    /**
     * beep - Вызывает звуковой сигнал.
     *
     * @param  {Number} times Количество звуковых сигналов.
     * @return {type}       description
     */
    beep: function beep(times) {
      navigator.notification.beep(times);
    }
  });
});