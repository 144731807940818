define('deputee-office/controllers/contacts/contact-info', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller('application'),
    contacts: _ember['default'].inject.controller('contacts'),

    actions: {
      rightMenu: function rightMenu() {
        this.get('application').send('rightMenu');
      },

      departmentClicked: function departmentClicked(department) {
        this.get('contacts').set('subString', department);
        this.get('target').send('findContactsBySubstring', department);
        this.send('rightMenu');
      }
    }
  });
});