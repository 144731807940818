define('deputee-office/controllers/pomopk-questions-search-box', ['exports', 'ember', 'deputee-office/mixins/services-mixin'], function (exports, _ember, _deputeeOfficeMixinsServicesMixin) {
  exports['default'] = _ember['default'].Controller.extend(_deputeeOfficeMixinsServicesMixin['default'], {
    searchPlaceholder: 'Поиск по вопросам',
    actions: {
      search: function search(text) {
        this.set('model.name', text);
        this.send('filterQuestions', this.get('model'));
        _ember['default'].$('#scrollable-div').trigger('updatescroll');
      }
    }
  });
});