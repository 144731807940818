define('deputee-office/controllers/base-agenda-route/passport/notes', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    notesStateName: 'base-agenda-route/notes/index',
    defaultnotesStateName: 'base-agenda-route/notes/index',

    queryParams: ['notesState', 'notesStateParam'],
    notesState: null,
    notesStateParam: null,

    currentNote: _ember['default'].computed('model.[]', 'notesStateParam', '_currentNote', function () {
      if (this.get('devicejs').isPhone()) {
        if (this.get('model') && this.get('notesStateParam')) {
          var model = this.get('model').findBy('id', this.get('notesStateParam'));
          if (model) {
            return model;
          }
        }

        if (this.get('_currentNote')) {
          return this.get('_currentNote');
        } else {
          var newNote = _ember['default'].Object.create({});
          this.get('target').send('getEmptyNote', newNote);
          this.set('_currentNote', newNote.get('model'));
          return newNote.get('model');
        }
      }
    }),

    stageDetailsStateChanged: _ember['default'].observer('notesState', function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        switch (decodeURI(this.get('notesState'))) {
          case 'items':
            this.set('notesStateName', 'base-agenda-route/notes/items');
            break;
          case 'edit':
            this.set('notesStateName', 'base-agenda-route/notes/edit');
            break;
          default:
            this.set('notesStateName', this.get('defaultnotesStateName'));
        }
      });
    })
  });
});