define('deputee-office/routes/laws/passport/notes', ['exports', 'deputee-office/routes/base-agenda-route/passport/notes'], function (exports, _deputeeOfficeRoutesBaseAgendaRoutePassportNotes) {
  exports['default'] = _deputeeOfficeRoutesBaseAgendaRoutePassportNotes['default'].extend({
    parentControllerName: 'laws.passport',

    getPassportPersonalDocumentQuery: function getPassportPersonalDocumentQuery(passportId) {
      return this.store.query('zs-passport/zs-passport-personal-document', {
        PassportId: passportId
      });
    }
  });
});