define('deputee-office/models/zs-passport/zs-passport-personal-document', ['exports', 'ember-data', 'deputee-office/models/base', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember, _moment) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    Name: _emberData['default'].attr('string'),
    QuestionId: _emberData['default'].attr('guid'),
    QuestionNum: _emberData['default'].attr('string'),
    QuestionName: _emberData['default'].attr('string'),
    PassportId: _emberData['default'].attr('guid'),
    PassportName: _emberData['default'].attr('string'),
    PassportTitle: _emberData['default'].attr('string'),
    Type: _emberData['default'].attr('string'),
    Text: _emberData['default'].attr('string'),
    Date: _emberData['default'].attr('date'),
    Url: _emberData['default'].attr('string'),
    DisplayedDate: _ember['default'].computed('Date', function () {
      if (this.get('Date')) {
        return (0, _moment['default'])('' + this.get('Date')).format('D MMMM YYYY года');
      }
    }),
    IsNote: _ember['default'].computed('Type', function () {
      return this.get('Type') === 'note';
    })
  });

  exports['default'] = Model;
});