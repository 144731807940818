define('deputee-office/services/favorites-service', ['exports', 'ember'], function (exports, _ember) {

  var servicesByModels = {
    // Названия моделей.
    budget: {
      componentName: 'favorites/favorites-budgets',
      serviceName: 'Исполнение бюджета',
      model: 'budget',
      group: 2
    },
    newsitem: {
      componentName: 'favorites/newsitem',
      serviceName: 'Новости',
      model: 'newsitem'
    },

    // contact: {
    //   componentName: 'favorites/favorites-contacts',
    //   serviceName: 'Контакты',
    //   model: 'contact',
    //   group: 1
    // },
    decree: {
      componentName: 'favorites/favorites-decrees',
      serviceName: 'Постановления Правительства',
      model: 'decree',
      group: 2
    },
    'governor-decree': {
      componentName: 'favorites/favorites-governor-decrees',
      serviceName: 'Указы губернатора',
      model: 'governor-decree',
      group: 2
    },
    'material-ksm': {
      componentName: 'favorites/favorites-k-s-materials',
      serviceName: 'Материалы КС',
      model: 'material-ksm',
      group: 2
    },
    'material-ksp': {
      componentName: 'favorites/favorites-k-s-p-materials',
      serviceName: 'Материалы КСП',
      model: 'material-ksp',
      group: 2
    },
    report: {
      componentName: 'favorites/favorites-reports',
      serviceName: 'Аналитические отчеты',
      model: 'report',
      group: 2
    },
    'gos-program': {
      componentName: 'favorites/favorites-gos-programs',
      serviceName: 'Государственные программы',
      model: 'gos-program',
      group: 2
    },

    // Названия роутов.
    'zs-agenda-departments.passport': {
      componentName: 'favorites/favorites-zs-agenda-departments',
      serviceName: 'Прочие повестки',
      apServiceName: 'zs-agenda-departments.passport',
      model: 'zs-passport/zs-passport-file',
      group: 2
    },
    'zs-agenda.passport': {
      componentName: 'favorites/favorites-zs-agenda',
      serviceName: 'Повестки ЗС',
      apServiceName: 'zs-agenda.passport',
      model: 'zs-passport/zs-passport-file',
      group: 2
    },
    'laws.passport': {
      componentName: 'favorites/favorites-laws',
      serviceName: 'Паспорт закона',
      apServiceName: 'laws.passport',
      model: 'zs-passport/zs-passport-file',
      group: 2
    },
    'yp-agenda-parlament.passport': {
      componentName: 'favorites/favorites-yp-agenda-parlament',
      serviceName: 'Заседания МП',
      apServiceName: 'yp-agenda-parlament.passport',
      model: 'zs-passport/zs-passport-file',
      group: 3
    },
    'yp-agenda-comissions.passport': {
      componentName: 'favorites/favorites-yp-agenda-comissions',
      serviceName: 'Комиссии МП',
      apServiceName: 'yp-agenda-comissions.passport',
      model: 'zs-passport/zs-passport-file',
      group: 3
    },
    'legal-acts.passport': {
      componentName: 'favorites/favorites-legal-acts',
      serviceName: 'Правовые акты',
      apServiceName: 'legal-acts.passport',
      model: 'zs-passport/zs-passport-file',
      group: 4
    },
    'omsu-agenda-assembly.passport': {
      componentName: 'favorites/favorites-omsu-agenda-assembly',
      serviceName: 'Заседания',
      apServiceName: 'omsu-agenda-assembly.passport',
      model: 'zs-passport/zs-passport-file',
      group: 4
    },
    'omsu-agenda-commitees.passport': {
      componentName: 'favorites/favorites-omsu-agenda-commitees',
      serviceName: 'Заседания комитетов/комиссий',
      apServiceName: 'omsu-agenda-commitees.passport',
      model: 'zs-passport/zs-passport-file',
      group: 4
    },
    'newquestions.passport': {
      componentName: 'favorites/favorites-newquestions',
      serviceName: 'Поступившие вопросы',
      apServiceName: 'newquestions.passport',
      model: 'zs-passport/zs-passport-file',
      group: 4
    },
    'omsu-agenda-pomopk.passport': {
      componentName: 'favorites/favorites-omsu-agenda-pomopk',
      serviceName: 'Заседания совета ПОМОПК',
      apServiceName: 'omsu-agenda-pomopk.passport',
      model: 'zs-passport/zs-passport-file',
      group: 4
    }
  };

  /**
   * Сервис избранного.
   */
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),

    /**
     * getInfoByModel - метод, извлекающий из модели элемента информацию, необходимую
     * для сохранения его в "Избранное".
     * @param  {DS.Model} model - модель элемента, для которого необходимо получить
     * информацию для "Избранного".
     * @return {Object} info - объект, содержащий информацию, необходимуб для
     * добавления элемента в "Избранное".
     */
    getInfoByModel: function getInfoByModel(model) {
      var info = null;

      var modelName = model.constructor.modelName;

      info = {
        modelName: modelName,
        params: {
          id: model.get('id')
        }
      };

      return info;
    },

    getFavServiceInfo: function getFavServiceInfo(modelNames) {
      var favServiceInfo = new _ember['default'].A([]);
      modelNames.forEach(function (item) {
        if (servicesByModels.hasOwnProperty(item)) {
          var value = servicesByModels[item];

          if (favServiceInfo.indexOf(value) === -1) {
            favServiceInfo.push(value);
          }
        }
      });

      return favServiceInfo;
    },

    /**
     * Возвращает коллекцию уникальных ключей из параметров
     * всех моделей избранного.
     */
    getUnique: function getUnique(favorites, paramName) {

      function fetchFromObject(_x, _x2) {
        var _again = true;

        _function: while (_again) {
          var obj = _x,
              prop = _x2;
          _again = false;

          var _index = prop.indexOf('.');
          if (_index > -1) {
            _x = obj[prop.substring(0, _index)];
            _x2 = prop.substr(_index + 1);
            _again = true;
            _index = undefined;
            continue _function;
          }

          return obj[prop];
        }
      }

      var uniques = favorites.map(function (favorite) {
        var params = JSON.parse(favorite.get('Params'));

        return fetchFromObject(params, paramName);
      });

      return uniques.filter(function (item, i) {
        return uniques.indexOf(item) === i;
      });
    },

    /**
     * addToFavorites - метод, добавляющий элемент в "Избранное".
     * @param  {DS.Model} model - модель "favorites".
     * @return {DS.Model} result - сохраненная модель.
     */
    addToFavorites: function addToFavorites(model, additionalParams, docId) {
      var info = this.getInfoByModel(model);

      var saveFailed = function saveFailed() {
        alert('Произошла ошибка при сохранении.');

        return null;
      };

      if (additionalParams) {
        info.params.additionalParams = additionalParams;
      } else {
        if (info.modelName === 'zs-passport/zs-passport-file') {
          return _ember['default'].RSVP.reject()['catch'](saveFailed);
        }
      }

      if (docId) {
        info.params.docId = docId;
      }

      var record = this.get('store').createRecord('favorite', {
        Model: info.modelName,
        Params: JSON.stringify(info.params)
      });

      return record.save()['catch'](saveFailed);
    },

    /**
     * deleteFromFavorites - метод, удаляющий элемент из "Избранного".
     * @param  {DS.Model} model - модель "favorites".
     * @return {boolean} result - результат удаления. true - элемент удален, false - не удалось удалить.
     */
    deleteFromFavorites: function deleteFromFavorites(favorite) {
      favorite.deleteRecord();
      return favorite.save().then(function () {

        return true;
      })['catch'](function () {
        alert('Произошла ошибка при удалении.');

        return false;
      });
    },

    /**
     * clearFavorites - метод полной очистки "избранного" текущего пользователя.
     * @return {undefined}
     */
    clearFavorites: function clearFavorites(modelName, apServiceName) {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.get('store').findAll('favorite', { reload: true }).then(function (items) {
          return items.filter(function (item) {
            var condition = item.get('Model') === modelName && (apServiceName ? item.get('Params').indexOf(apServiceName) > -1 : true);
            return condition;
          });
        }).then(function (items) {
          var deleteRecords = [];
          items.toArray().forEach(function (item) {
            return deleteRecords.pushObject(item.destroyRecord());
          });
          _ember['default'].RSVP.all(deleteRecords, 'deleteRecords for ' + modelName).then(function () {
            return resolve();
          })['catch'](function (reason) {
            return reject(reason);
          });
        })['catch'](function (reason) {
          return reject(reason);
        });
      }, 'clearFavorites for ' + modelName);
    },

    getGroupName: function getGroupName(groupNumber) {
      switch (groupNumber) {
        case 2:
          return 'СУБЪЕКТ РФ';
        case 3:
          return 'МОЛОДЕЖНЫЙ ПАРЛАМЕНТ';
        case 4:
          return 'ОМСУ';
        default:
          return null;
      }
    },

    /**
     * _getParamsFromUrl - вспомогательный метод.
     * Извлекает из строки url'а параметры и помещает их в объект.
     * Каждый параметр url'a становится свойством объекта.
     * @param  {String} url - строка url'а.
     * @return {Object} - объект, свойства которого являются параметрами переданного url'а.
     */
    _getParamsFromUrl: function _getParamsFromUrl(url) {
      var queryParams = {};
      var hashes = url.slice(url.indexOf('?') + 1).split('&');

      for (var i = 0; i < hashes.length; i++) {
        var hash = hashes[i].split('=');
        queryParams[hash[0]] = hash[1];
      }

      return queryParams;
    },

    /**
     * getUniqForModels - Возвращает только уникальные модели, которые нужны для избранного.
     */
    getUniqForModels: function getUniqForModels(models, array, arrayItemName, wrapperBuilder) {
      var uniques = [];
      var tmp = array.slice(0);
      var sourceForWraper = null;

      function inner(item) {
        if (arrayItemName && item[arrayItemName]) {
          sourceForWraper = item;
          item = item[arrayItemName];
        }

        if (item.toArray) {
          item = item.toArray();
        }

        if (Array.isArray(item)) {
          item.forEach(inner);
        } else {
          var idx = tmp.indexOf(item.get('id'));

          if (idx !== -1) {
            if (wrapperBuilder && sourceForWraper) {
              item = wrapperBuilder(item, sourceForWraper);
            }

            uniques.push(item);
            tmp.splice(idx, 1);
          }
        }
      }

      models.forEach(inner);

      return uniques;
    }

  });
});