define('deputee-office/components/favorites-button', ['exports', 'ember'], function (exports, _ember) {

  // Константы с путем для изображений состояний компонента избранного.
  var notStarred = 'icon-empty-star'; //'assets/img/star.png';
  var starred = 'icon-star'; //'assets/img/starred.png';

  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    favoritesService: _ember['default'].inject.service(),
    currentuserservice: _ember['default'].inject.service(),
    notificationService: _ember['default'].inject.service(),
    networkstatus: _ember['default'].inject.service(),

    // Модель ""избранного".
    favorite: null,

    // Модель, переданная компоненту.
    model: null,
    starredFlag: false,
    disabled: false,
    notGuest: true,

    favImage: _ember['default'].computed('starredFlag', function () {
      var starredFlag = this.get('starredFlag');

      if (starredFlag) {

        return starred;
      } else {

        return notStarred;
      }
    }),

    init: function init() {
      var _this2 = this;

      var notGuest = !this.get('currentuserservice.isGuest');
      this.set('notGuest', notGuest);
      this._super.apply(this, arguments);
      if (notGuest) {
        (function () {
          var store = _this2.get('store');
          var favorites = store.peekAll('favorite');
          var info = _this2.get('favoritesService').getInfoByModel(_this2.get('model'));

          // Если в компонент переданы дополнительные параметры - они присваиваются
          // параметрам объекта информации о модели.
          if (_this2.get('additionalParams')) {
            info.params.additionalParams = _this2.get('additionalParams');
          }

          if (_this2.get('docId')) {
            info.params.docId = _this2.get('docId');
          }

          var params = JSON.stringify(info.params);

          // Поиск среди подгруженного в Ember Data избранного элемента,
          // соответствующего значениям, переданным компоненту.
          var filtered = favorites.filter(function (record) {
            if (info.modelName === record.get('Model') && params === record.get('Params')) {

              return true;
            } else {

              return false;
            }
          });

          if (filtered.length > 0) {
            _this2.set('favorite', filtered[0]);
            _this2.set('starredFlag', true);
          }
        })();
      }
    },

    actions: {
      /**
       * clicked - обработчик события клика по элементу добавления в "Избранное".
       * При клике на добавленный элемент производится удаление, при клике на элемент,
       * отсутсвующий в "Избранном" - добавление.
       *
       * @return {undefined}
       */
      clicked: function clicked() {
        var _this3 = this;

        if (!this.get('networkstatus').isInternetConnection()) {
          if (!this.get('starredFlag')) {
            this.get('notificationService').alert('Для добавления документа в избранное необходимо подключение к сети интернет.', null, 'Внимание!');
          } else {
            this.get('notificationService').alert('Для удаления документа из избранного необходимо подключение к сети интернет.', null, 'Внимание!');
          }

          return;
        }

        if (!this.get('starredFlag')) {
          (function () {
            var model = _this3.get('model');
            var _this = _this3;

            _this3.set('disabled', true);

            _this3.get('favoritesService').addToFavorites(model, _this3.get('additionalParams'), _this3.get('docId')).then(function (savedRecord) {
              if (savedRecord) {
                _this.toggleProperty('starredFlag');
              }

              _this.set('favorite', savedRecord);
              _this.set('disabled', false);
            });
          })();
        } else {
          (function () {
            var favorite = _this3.get('favorite');
            var _this = _this3;

            _this3.set('disabled', true);

            _this3.get('favoritesService').deleteFromFavorites(favorite).then(function (deleted) {
              if (deleted) {
                _this.toggleProperty('starredFlag');
                _this.set('favorite', null);
              }

              _this.set('disabled', false);
            });
          })();
        }
      }
    }
  });
});