define('deputee-office/models/omsu-passport/omsu-legal-act-publication', ['exports', 'ember-data', 'deputee-office/models/base', 'ember', 'moment'], function (exports, _emberData, _deputeeOfficeModelsBase, _ember, _moment) {
  exports['default'] = _deputeeOfficeModelsBase['default'].extend({
    Name: _emberData['default'].attr('string'),
    Number: _emberData['default'].attr('string'),
    Date: _emberData['default'].attr('date'),
    Volume: _emberData['default'].attr('string'),
    Part: _emberData['default'].attr('string'),
    PassportId: _emberData['default'].attr('guid'),
    TerritoryId: _emberData['default'].attr('guid'),
    DisplayedDate: _ember['default'].computed('Date', function () {
      if (this.get('Date') && this.get('Date').getTime() > 0) {
        return (0, _moment['default'])('' + this.get('Date')).format('D MMMM YYYY года');
      }
    })
  });
});