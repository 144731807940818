define('deputee-office/models/file', ['exports', 'ember-data', 'deputee-office/models/base'], function (exports, _emberData, _deputeeOfficeModelsBase) {

  var Model = _deputeeOfficeModelsBase['default'].extend({
    FileInfoId: _emberData['default'].attr('guid'),
    FileVersion: _emberData['default'].attr('number'),
    UserFileVersion: _emberData['default'].attr('number'),
    SubsystemFileId: _emberData['default'].attr('string'),
    DocType: _emberData['default'].attr('number'),
    SubsystemKey: _emberData['default'].attr('string'),
    MediaType: _emberData['default'].attr('string'),
    Extension: _emberData['default'].attr('string')
  });

  exports['default'] = Model;
});